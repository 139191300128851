import React, { useState, useEffect } from 'react';
import {Row,Col} from 'reactstrap'; 
import { config } from '../../utils/config';
import { callApi, getEmpresa } from '../../utils/utils'; 
import { useTranslation } from 'react-i18next'; 
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx'
import SeguridadAutorizacionRecompensas from './SeguridadAutorizacionRecompensas'
import SeguridadAutorizacionCuponesDescuento from './SeguridadAutorizacionCuponesDescuento'
import PageContent from '../../layouts/PageContent'; 
import { Panel } from 'primereact/panel';
import * as yup from 'yup';

const SeguridadAutorizacion = (props) => { 
  const { t } = useTranslation(['formulario','common','seguridad']);
  const [errors, setErrors] = useState({});   
  const [Filters, setFilters] = useState({});  
  const [ClaTipoAutorizacion, setClaTipoAutorizacion] = useState(1);
  const [TipoAutorizaciones, setTipoAutorizaciones] = useState([]); 

  useEffect(() => {
    const paramsService = null;
    const urlWebServiceExpirationType = `${config.UrlApiProject}Seguridad/GetTipoAutorizaciones?BajaLogica=0`; 
    callApi(urlWebServiceExpirationType, 'GET', paramsService, (response) => {
      setTipoAutorizaciones(response.data.TipoAutorizaciones); 
    });  
  }, []);
 
  const onAfterSave = () => {    
    cerrarModalCampania();
  }

  const onChangeTipoAutorizacion = (value) => {
    let iClaTipoCupon = 1; // Cupon Descuento
    if(value===3){
      iClaTipoCupon = 2;// Deals
    }
    setFilters({ ...Filters, ClaTipoCupon:iClaTipoCupon, ClaTipoAutorizacion:value});
    setClaTipoAutorizacion(value);
  }

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={`card-header danger collapsed-header${  className}`}>
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={`${toggleIcon  } light-color`}></span>
              </button>
              &nbsp;
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div> 
          <div className="p-toolbar-group-right">
            &nbsp;
          </div>
        </div> 
      </div>
    )
}

  return (
    <>
      <PageContent title={t('seguridad:Autorizacion')}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <Panel className="panel-radius" header="Filters" toggleable headerTemplate={filterHeaderTemplate}> 
              <Row>
                &nbsp;
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}> 
                  <div className="p-fluid p-field p-col-12"> 
                    <FieldDropdown  
                      name="ClaTipoAutorizacion"
                      value={ClaTipoAutorizacion} 
                      options={TipoAutorizaciones}  
                      optionValue="ClaTipoAutorizacion"
                      optionLabel="NomTipoAutorizacion" 
                      label={t('seguridad:TipoAutorizacion')}
                      errors={errors}
                      onChange={(e) => onChangeTipoAutorizacion(e.value)}
                    />   
                  </div>
                </Col>
              </Row>
              <Row>
                &nbsp;
              </Row>
            </Panel> 
          </Col> 
        </Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col>
            <div hidden={ClaTipoAutorizacion!==1}> 
              <SeguridadAutorizacionRecompensas isHidden={true} onAfterSave={onAfterSave} filters={Filters} />
            </div> 
          </Col>
        </Row>   
        {
          ClaTipoAutorizacion===2 ? (
            <>
              <SeguridadAutorizacionCuponesDescuento isHidden={true} onAfterSave={onAfterSave} filters={Filters} />
            </>
          ):(
            <>
            </>
          )
        }  
        {
          ClaTipoAutorizacion===3 ? (
            <>
              <SeguridadAutorizacionCuponesDescuento isHidden={true} onAfterSave={onAfterSave} filters={Filters} />
            </>
          ):(
            <>
            </>
          )
        }      
      </PageContent>
    </>
);
};
export default SeguridadAutorizacion;
