/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';  
import PropTypes from "prop-types";
import { Button } from 'primereact/button'; 
import { FileUpload } from 'primereact/fileupload';
import { Row,Col} from 'reactstrap';   
import { config } from '../../utils/config';
import { callApi,showSweetAlert } from '../../utils/utils'; 
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next'; 
import Highcharts from 'highcharts';

const FieldUpload = ({  
  onChange,
  files,
  name,
  errors,
  isRequired
}) => {

  const { t } = useTranslation(['scoreCard', 'common']);
  const [Files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  
  useEffect(() => {     
    setFiles(files);
  }, [files]); 

 const onUpload = async (e) => {
  console.log('onUpload');
  console.log(e);
    const info = JSON.parse( e.xhr.response );
    if (info.data.HasError){
      console.log(info.data.Message);
      return;
    }
    info.data.objectURL = e.files[0].objectURL;
    setFiles([...[], info.data]);
    
    if (typeof(onChange) !== 'undefined' && typeof(onChange) ==='function' && onChange !== null){
        onChange([...Files, info.data]);
    }
  }

  const onBeforeUpload = async (e) => {
   
  /* 
    const data = JSON.parse( e.xhr.response );
    const response = data;
    console.log('onBeforeUpload');
    console.log(e); 
     e.xhr.setRequestHeader('Pr', '123');
      return e;
  */

  }


  const onClear = () => {
    setFiles([]);
    if (typeof(onChange) !== 'undefined' && typeof(onChange) ==='function' && onChange !== null){
        onChange([]);
    }
  }

  const isFormFieldInValid = (name) => !!(typeof(errors) !== 'undefined' && errors !== null && errors[name]);

  const emptyTemplate = () => {
    console.log('emptyTemplate');
    console.log(Files);
    if ( Files.length > 0 )
    {
        return (    
          <div>
            <Row className="p-my-5">
              <Col>
                <Row>
                  <Col>
                    <div className="p-d-flex p-ai-center" style={{width: '40%'}}>
                      <img alt={Files[0].FileName} src={Files[0].objectURL} role="presentation" width={100} />
                      <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {Files[0].FileName}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <Tag value={`${ Highcharts.numberFormat(Files[0].FileSize,0) } KB`} severity="warning" className="p-px-3 p-py-2" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
      );
    } 
    return (
      <div className={isRequired === true ? 'p-d-flex p-ai-center p-dir-col p-inputtext p-component p-invalid' : 'p-d-flex p-ai-center p-dir-col'}>
        <i className="pi pi-image" style={{'fontSize': '3em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
        <span style={{'fontSize': '1.5em', color: 'var(--text-color-secondary)'}} className="p-my-2">{t('common:DragDrop')}</span>
        <small> <label className="p-error">{t('common:RequiredField')}</label></small> 
      </div>
      
    )
  }
  
  const headerTemplate = (options) => {
    const { className, chooseButton  } = options;
    const value = totalSize/10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
    
    return (
      <div className={`${className  } text-right`} style={{backgroundColor: 'transparent'}}>
        {
          Files.length === 0 ? (
            <>
              {chooseButton}
            </>
          ):(
            <>
              <Button
                icon="pi pi-times" 
                onClick={onClear}
                className="p-button-rounded p-button-danger p-button-outlined"
              />
            </>
)
        } 
      </div>
    );
  }

const chooseOptions = {title: 'Subir Archivo', icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

  return (
    <>
      <FileUpload 
        ref={fileUploadRef}
        mode="advanced"
        name="file"
        url={`${config.UrlApiProject}Upload/Upload`}  
        onBeforeUpload={onBeforeUpload}
        accept=".png" // .png,.sql,.txt
        emptyTemplate={emptyTemplate}
        headerTemplate={headerTemplate}
        auto
        maxFileSize={1000000}
        onUpload={onUpload} 
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
      /> 
    </>
  );
};
 

export default FieldUpload;
