import React, { useState, useEffect, useRef } from 'react';
import {Card,CardTitle,CardBody,CardFooter,Row,Col}        from 'reactstrap'; 
import { DataTable }                  from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import moment from 'moment';  
import { Dialog } from 'primereact/dialog'; 
import { Button } from 'primereact/button';

// START COMPONENT
const EstadoDeCuentaRecompensasDetalle = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [Pedido, setPedido] = useState(null);
  const [PedidoDetalle, setPedidoDetalle] = useState([]);
  const [EsVerRecompensaDetalle, setEsVerRecompensaDetalle] = useState(false); 

  useEffect(() => {
    onData(); 
  }, [props.filters]);

  const onData = () => {
    const paramsToService = {
      IdPedido:props.filters.IdPedido,
      ClaEmpresa,
      Idioma:''
    }
    const GetClienteGridService = `${config.UrlApiProject}Cliente/GetClienteEstadoCuentaRecompensaDetalle`;
    callApi(GetClienteGridService, 'POST', paramsToService, (response) => {
      setPedido(response.data.Recompensa[0]);
      setPedidoDetalle(response.data.RecompensaDetalle);
      // if(props.isModal){
        // document.body.classList.add('bodynotscroll');
      // }
      setEsVerRecompensaDetalle(true);
    });    
  };

  const modalFooterEdoCta = () => {
  return (
    <>
      <div className="text-left pt-1">
        <Button label={t('common:Close')} icon="pi pi-times" className="p-button-rounded p-button-warning p-mr-2" onClick={cerrarModal} />
      </div>
    </>
  );
  } 
  
  const modalHeaderEdoCta = () => {
  return (
    <>
      <div className="pt-1">
        <Row className="align-items-start padding5">
          <Col lg={4} md={4} sm={4} xs={4}>
            <h1>
              {moment(Pedido.Fecha).format("LL")}
            </h1>
          </Col>
          <Col lg={8} md={8} sm={8} xs={8}>
            <Row className="align-items-start padding5">
              <Col lg={8} md={8} sm={8} xs={12}>
                <h1>
                  {t('recompensas:Order #')}&nbsp;{Pedido.NumPedido}
                </h1>
              </Col>
              <Col lg={4} md={4} sm={4} xs={12} className='text-right'>
                <h1>
                  {Pedido.EstatusPedido}
                </h1>
              </Col>
            </Row> 
          </Col>
        </Row>
      </div>
    </>
  );
} 

const bodyEdoCta = () => {
  return (
    <>
      <Row className="align-items-start padding5">
        <Col lg={4} md={4} sm={4} xs={4}>
          <Card className="radiuscard padding5"> 
            <CardBody> 
              <Row id="direccionEnvio">
                <Col>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <span className="secondary-color fsize-1 bold"> 
                        {t('estadocuenta:DireccionDeEnvio')}   
                      </span>
                    </Col>
                  </Row> 
                  <Row className="pt-1">
                    <Col>
                      {Pedido.ClienteEnvio}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {Pedido.DireccionEnvio}  
                    </Col>
                  </Row> 
                  <Row>
                    <Col>
                      {Pedido.DireccionEnvio2}  
                    </Col>
                  </Row> 
                </Col>
              </Row>
              <Row id="direccionFacturacion">
                <Col>
                  <Row>
                    <Col>
                      <hr></hr>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <span className="secondary-color fsize-1 bold"> 
                        {t('estadocuenta:DireccionDeFacturacion')}   
                      </span>
                    </Col>
                  </Row> 
                  <Row className="pt-1">
                    <Col>
                      {Pedido.ClienteFacturacion}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {Pedido.DireccionFacturacion}  
                    </Col>
                  </Row> 
                  <Row>
                    <Col>
                      {Pedido.DireccionFacturacion2}  
                    </Col>
                  </Row> 
                  <Row>
                    <Col>
                      <hr></hr>
                    </Col>
                  </Row> 
                </Col>
              </Row> 
              <Row id="metodoPago">
                <Col>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <span className="secondary-color fsize-1 bold"> 
                        {t('estadocuenta:MetodoPago')}   
                      </span>
                    </Col>
                  </Row> 
                  <Row className="pt-1">
                    <Col>
                      {Pedido.MetodoPago}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr></hr>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row id="totales">
                <Col>
                  <Row className='pb-2'>
                    <Col lg={8} md={12} sm={12} xs={12}>
                      <span className="secondary-color fsize-1 bold"> 
                        {t('estadocuenta:Subtotal')}   
                      </span>
                    </Col>
                    <Col lg={4} md={12} sm={12} xs={12} className='text-right'>
                      ${Highcharts.numberFormat(Pedido.Subtotal,2,'.',',')}
                    </Col>
                  </Row> 
                  <Row className='pb-2 danger-color' hidden={Pedido.TotalDescuentoCupon===0}>
                    <Col lg={8} md={12} sm={12} xs={12}>
                      <span className="secondary-color fsize-1 bold"> 
                        {t('estadocuenta:DescuentoCupon')}   
                      </span>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className='text-right'>
                      ${Highcharts.numberFormat(Pedido.TotalDescuentoCupon,2,'.',',')}
                    </Col>
                  </Row> 
                  <Row className='pb-2 danger-color' hidden={Pedido.TotalDescuentoOferta===0}>
                    <Col lg={8} md={12} sm={12} xs={12}>
                      <span className="secondary-color fsize-1 bold"> 
                        {t('estadocuenta:DescuentoOferta')}   
                      </span>
                    </Col>
                    <Col lg={4} md={6} sm={12} xs={12} className='text-right'>
                      ${Highcharts.numberFormat(Pedido.TotalDescuentoOferta,2,'.',',')}
                    </Col>
                  </Row> 
                  <Row className='pb-2'>
                    <Col lg={8} md={12} sm={12} xs={12}>
                      <span className="secondary-color fsize-1 bold"> 
                        {t('estadocuenta:CostoEnvio')}   
                      </span>
                    </Col>
                    <Col lg={4} md={12} sm={12} xs={12} className='text-right'>
                      ${Highcharts.numberFormat(Pedido.CostoEnvio,2,'.',',')}
                    </Col>
                  </Row> 
                  <Row className='pb-2'>
                    <Col lg={8} md={12} sm={12} xs={12}>
                      <span className="secondary-color fsize-1 bold"> 
                        {t('estadocuenta:Impuestos')}   
                      </span>
                    </Col>
                    <Col lg={4} md={12} sm={12} xs={12} className='text-right'>
                      ${Highcharts.numberFormat(Pedido.Impuestos,2,'.',',')}
                    </Col>
                  </Row> 
                  <Row className='pb-2'>
                    <Col lg={8} md={12} sm={12} xs={12}>
                      <span className="secondary-color fsize-1 bold"> 
                        {t('estadocuenta:Total')}   
                      </span>
                    </Col>
                    <Col lg={4} md={12} sm={12} xs={12} className='text-right bold'>
                      ${Highcharts.numberFormat(Pedido.Total,2,'.',',')}
                    </Col>
                  </Row> 
                  <Row>
                    <Col>
                      <hr></hr>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="alert success3" role="alert">
                    <Row>
                      <Col lg={2} md={2} sm={2} xs={2} className='text-center'>
                        <span className="fa-stack fa-lg">
                          <i className="fa fa-circle success2-color fa-stack-2x"></i>
                          <i className="fa fa-star white-color fa-stack-1x fa-inverse"></i>
                        </span>
                      </Col>
                      <Col lg={10} md={10} sm={10} xs={10}>
                        {t('estadocuenta:LeyendaPuntos')}
                        <span className='success2-color bold'> &nbsp;{Highcharts.numberFormat(Pedido.PuntosGenerados,0,'.',',')}&nbsp;{t('estadocuenta:Puntos')}&nbsp;</span>
                        {t('estadocuenta:LeyendaPuntos2')}  
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>  
            </CardBody> 
          </Card>
        </Col>
        <Col lg={8} md={8} sm={8} xs={8}>
          {PedidoDetalle.map((item) => { 
                                    return (
                                      <div className='pb-3'>
                                        <Card className="radiuscard padding10"> 
                                          <CardTitle>
                                            <Row>
                                              <Col lg={6} md={6} sm={6} xs={6}>
                                                <h1>
                                                  {item.Estatus}
                                                </h1>
                                              </Col>
                                            </Row>
                                          </CardTitle>
                                          <CardBody> 
                                            <Row className="align-items-start padding5">
                                              <Col lg={2} md={2} sm={2} xs={2}>
                                                <img alt="" src={item.Img} role="presentation" className='img-fluid img-thumbnail' />
                                              </Col>
                                              <Col lg={10} md={10} sm={10} xs={10}>
                                                <Row id="producto">
                                                  <Col lg={9} md={9} sm={12} xs={12}> 
                                                    <Row>
                                                      <Col>
                                                        <h2> {item.NomProductoReferencia} &nbsp; {item.NomProductoCategoriaReferencia} </h2>
                                                      </Col> 
                                                    </Row> 
                                                    <Row className='pt-1 default-color'>
                                                      <Col>
                                                        {t('estadocuenta:CantidadAbrev')} {item.Cantidad} 
                                                      </Col> 
                                                    </Row>
                                                  </Col>
                                                  <Col lg={3} md={3} sm={12} xs={12} className='text-right bold'> 
                                                    <Row>
                                                      <Col>
                                                        {
                                                        item.Importe !== item.ImporteDescuento ? (
                                                          <>
                                                            <h3 className='danger-color' style={{ textDecoration: 'line-through'}}> 
                                                              ${Highcharts.numberFormat(item.Importe,2,'.',',')} 
                                                            </h3>
                                                          </>
                                                        ):(
                                                          <>
                                                          </>
                                                        )
                                                      }
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <h3> ${Highcharts.numberFormat(item.ImporteDescuento,2,'.',',')} </h3>
                                                      </Col>
                                                    </Row> 
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col>
                                                  </Col>
                                                  <Col className='text-right'>
                                                    {
                                                              item.TotalDescuentoCupon > 0 ? (
                                                                <>
                                                                  <h5 className='orange-color'>
                                                                    {t('estadocuenta:DescuentoCupon')}&nbsp;
                                                                    ${Highcharts.numberFormat(item.TotalDescuentoCupon,2,'.',',')} 
                                                                  </h5>
                                                                </>
                                                              ):(
                                                                <>
                                                                </>
                                                              )
                                                            }
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </CardBody> 
                                          <CardFooter>
                                            <Row>
                                              <Col>
                                                <h3 className='success2-color'>
                                                  <i className='fa fa-plus'></i>&nbsp;{Highcharts.numberFormat(item.Puntos,0,'.',',')}  {t('estadocuenta:PuntosAbrev')} 
                                                </h3>
                                              </Col>
                                              <Col className='text-right'>
                                                <h3> ${Highcharts.numberFormat(item.Total,2,'.',',')} </h3>
                                              </Col>
                                            </Row>
                                          </CardFooter>
                                        </Card>
                                      </div>
                                    );
                     })}
        </Col>
      </Row>
    </>
  );
} 
  
const cerrarModal = () => {
  // document.body.classList.remove('bodynotscroll');
  setEsVerRecompensaDetalle(false);
}  

  return (
    <>
      {
        Pedido!= null ? (
          <> 
            <div>
              {
              props.isModal ? (
                <>
                  <Dialog
                    className=''
                    visible={EsVerRecompensaDetalle} 
                    style={{width: '70vw' }} 
                    footer={modalFooterEdoCta}
                    header={modalHeaderEdoCta}
                    closable={false}
                  >
                    {bodyEdoCta()}
                  </Dialog>
                </>
              ):(
                <>
                  {bodyEdoCta()}
                </>
              )
            }
            </div>
          </>
        ):
        (
          <> 
          </>
        )
      } 
    </>
);
};
export default EstadoDeCuentaRecompensasDetalle;
