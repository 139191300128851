import React from 'react';
import { Redirect,Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types'; 
import LoginEmpresas from '../../views/Login/LoginEmpresa.jsx';
// import images
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  Row, 
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Button,
  Avatar
} from 'reactstrap';
import logo from '../../assets/img/RW_txt.png';
// reactstrap components
import { logOut, getSessionItem, getCliente } from '../../utils/utils';
import { Badge } from 'primereact/badge';
import { withTranslation } from 'react-i18next'

class AdminNavbar extends React.Component {

  constructor(props) {
    super(props); 
    this.state = {
      usuario:'',
      abrirModal : false,
      redireccionar: false
    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAlertaSubmit = this.handleAlertaSubmit.bind(this);
    this.cerrarModalLogin = this.cerrarModalLogin.bind(this);
    this.onAceptarClick = this.onAceptarClick.bind(this);
  }

  handleAlertaSubmit(e) {
    e.preventDefault();
    this.onClickAlerta();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.onClick();
  }

  cerrarModalLogin()  { 
    this.setState({ 
      abrirModal:false,
    });
  }

  onAceptarClick()  {  
    this.setState({ 
      abrirModal:false,
    });
  }

  onClick() {
    this.setState({ 
      abrirModal:true,
      usuario:getCliente()
    });
  }

  onClickAlerta() {
    console.log('onClickAlerta');
    this.setState({ 
      redireccionar:true
    });
  }
  
  onClickOffAlerta() {
    this.setState({ 
      redireccionar:!this.state.redireccionar
    });
  }
  

  toggleSideAdminBar = (e) => {  
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden'); 
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden'); 
    } 
  };
 
      
  render() {

    if (this.state.abrirModal){
      return   (
        <>
          <LoginEmpresas onAceptarClick={this.onAceptarClick} cerrarModalLogin={this.cerrarModalLogin} esVisible={this.state.abrirModal} user={this.state.usuario}></LoginEmpresas>
        </>
      )
    }
    if (!this.state.abrirModal){
      return (
        <>
          <Navbar
            className={classnames(
              'navbar-top navbar-expand border-bottom',
              { 'navbar-dark bg-header': this.props.theme === 'dark' },
              { 'navbar-light bg-secondary': this.props.theme === 'light' }
            )}
          >
            <Container fluid className="layout-container">
              <Collapse navbar isOpen>
                <Nav className="align-items-left ml-md-0 d-xl-none" navbar>
                  <div 
                    className='d-xl-none Cursor' 
                    style={{paddingRight:'10px'}}
                    onClick={this.toggleSideAdminBar}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                    <div className="sidenav-toggler-inner">
                     
                    </div>
                  </div>
                </Nav>    
                <Nav className="align-items-left ml-md-0 d-xl-none" navbar>
                  <div 
                    className='d-xl-none' 
                    style={{paddingRight:'10px'}}
                  >
                    <div className="sidenav-toggler-inner">
                      <img height={20} src={logo} alt="deaceroLogo"  />
                    </div>
                  </div>
                </Nav>    
                <Nav className="align-items-center ml-md-auto ml-md-auto" navbar>
                  <UncontrolledDropdown nav style={{ cursor: 'pointer' }}>
                    <DropdownToggle className="nav-link pr-0" color="" tag="a">
                      <Media className="align-items-center"> 
                        <Media className="ml-3">
                          <span
                            className="header-label-value d-none d-lg-block"
                            onClick={this.handleSubmit}
                          >
                            <span className="header-label"> {this.props.t('common:Empresa')}:</span>&nbsp;
                            {getSessionItem('NomEmpresa', '')}
                          </span>  
                          &nbsp;
                        </Media>
                        <Media className="ml-3">
                          <span className="header-label-value d-none d-lg-block">
                            <span className="header-label"> {this.props.t('common:Bienvenido')}: &nbsp;</span> {getSessionItem('NomUsuario', '')}
                          </span>  
                          
                          {/* <span className="avatar avatar-md rounded-circle d-block d-lg-none">
                            <img alt="..." src={team4}  />
                          </span>  */}
                          &nbsp;
                        </Media>
                        
                      </Media>
                    </DropdownToggle>
                    {/* <DropdownMenu right>
                      <DropdownItem className="noti-title" header tag="div">
                        <h6 className="text-overflow m-0">Welcome!</h6>
                      </DropdownItem>
                      <DropdownItem className="noti-title" header tag="div">
                        <i className="fa fa-home" />
                        <h6 className="text-overflow m-0">Home</h6>
                      </DropdownItem>
                      <DropdownItem className="noti-title" header tag="div">
                        <i className="fa fa-user" />
                        <h6 className="text-overflow m-0">User profile</h6>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem href="#salir" onClick={logOut}>
                        <i className="fa fa-power-off" />
                        <span>Log out</span>
                      </DropdownItem>
                    </DropdownMenu> */}
                  </UncontrolledDropdown>
                  <Media className="ml-2 d-none d-lg-block">
                    {
                      /*
                      <Button
                      className="p-button p-component p-button-rounded p-button-text p-button-icon-only" 
                      type="button"
                      color="secondary"
                    >
                      <i className="fa fa-lg fa-home" />
                    </Button> 
  
                      */
                    }
                    
                    <Link 
                      to={{
                                pathname: "SecurityAuthorization",
                              }}
                    >

                      <i
                        hidden={this.props.alertas===0}
                        className="fa fa-bell p-mr-4 p-text-secondary p-overlay-badge" 
                        style={{ fontSize: '1.3rem' }}
                      >
                        <Badge value={this.props.alertas} severity="warning"></Badge>
                      </i>

                      <Button
                        hidden={this.props.alertas>0}
                        className="p-button p-component p-button-rounded p-button-text p-button-icon-only"
                        type="button" 
                        color="secondary"
                      >
                        <i className="fa fa-lg fa-bell">
                        </i>
                        
                      </Button>    
                    </Link>
                    {
                      /*            
                    <Button
                      className="p-button p-component p-button-rounded p-button-text p-button-icon-only"
                      type="button" 
                    >
                      <i className="fa fa-lg fa-user-alt" />
                    </Button>                
                    */
                    }
                    <Button
                      className="p-button p-component p-button-rounded p-button-text p-button-icon-only" 
                      type="button"
                      href="#salir"
                      color="secondary"
                      onClick={logOut}
                    >
                      <i className="fa fa-lg fa-sign-out-alt" />
                    </Button>                
                  </Media>
                </Nav>
              </Collapse>
            </Container> 
          </Navbar> 
          <Redirect from="*" to="/layout/Formulario" />
        </>
      );
    }
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
  isVisible:true,
  abrirModal : false 
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
  isVisible: PropTypes.bool,
  abrirModal: PropTypes.bool
};
export default withTranslation(['common'])(AdminNavbar);
