import React from 'react';
// react library for routing
import { Route, Switch, Redirect } from 'react-router-dom';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js'; 
import Components  from 'routes.js'; 
import { config } from '../utils/config';
import { callApi, getCliente, getEmpresa } from '../utils/utils';
import { useTranslation } from 'react-i18next';    

function LegendSiteTest() {
  const { t, i18n } = useTranslation();
  return  (
    <span>
      {t('common:SiteTestMessage')}
    </span>
  )
}

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidenavOpen: false,
      routes: [],
      alertas: 0
    };
  }

  componentDidUpdate(e) {
    console.log('componentDidUpdate Admin'); 
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  componentDidMount()   
  {  
     /* obenemos la informacion de las opciones de menu */
      const urlWebService = `${config.UrlApiProject}Seguridad/GetMenu?LoginName=${getCliente()}`; 
      const paramsService = null;
      callApi(urlWebService, 'GET', paramsService, (response) => {
          this.setState({routes: response.data});
          const paramsToService = { 
            ClaEmpresa: getEmpresa()
          }
          const GetCategpriaGridService = `${config.UrlApiProject}Seguridad/GetAutorizaciones`;
          callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
            let iAlertas = 0;
            if(response.data.Autorizaciones.length>0){
              iAlertas = response.data.Autorizaciones[0].CountAutorizaciones;
            }
            this.setState({alertas: iAlertas});
          });    
      });   
  }      
  
 

  getRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/layout') {
        return <Route path={prop.layout + prop.path} component={Components[prop.component]} key={key} />;
      }
      return null;
    });

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(this.state.routes[i].layout + routes[i].path) !== -1) {
        return this.state.routes[i].name;
      }
    }
    return 'Brand';
  }; 
  
  getNavbarTheme = () =>
    this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';

  closeSidenav = (e) => {  
        document.body.classList.remove('g-sidenav-pinned');
        document.body.classList.add('g-sidenav-hidden'); 
    };

  render() {
    return (
      <> 
        <Sidebar
          {...this.props}
          routes={this.state.routes}
        /> 
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} alertas={this.state.alertas} />
          <div onClick={this.closeSidenav}> 
            <Switch>
              {this.getRoutes(this.state.routes)}
              <Redirect from="*" to="/layout/Formulario" />
            </Switch>  
          </div>
          <div className='pt-5' hidden={config.Site===1}>
          &nbsp;
          </div>
          <div hidden={config.Site===1} className='rowtestfixed pt-2 pb-2'>
            <LegendSiteTest />
          </div> 
          <AdminFooter />
        </div> 
        
      </>
    );
  }
}

export default Admin;
