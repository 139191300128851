import React, { useState, useEffect, useRef } from 'react';
import {Card,CardBody,Row,Col}        from 'reactstrap'; 
import { DataTable }                  from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator';
import { Panel } from 'primereact/panel';
import {Steps} from 'primereact/steps'; 
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import FieldText from '../../components/Controls/FieldText.jsx' 
import FieldNumber from '../../components/Controls/FieldNumber.jsx'
import FieldTextArea from '../../components/Controls/FieldTextArea.jsx'
import FieldCalendar from '../../components/Controls/FieldCalendar.jsx'
import FieldSelectButton from '../../components/Controls/FieldSelectButton.jsx'
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar'; 
import moment from 'moment';  
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx'
import FieldUpload from '../../components/Controls/FieldUpload.jsx';
import FieldRadioButton from '../../components/Controls/FieldRadioButton.jsx';
import FieldTreeviewButton from '../../components/Controls/FieldTreeviewButton.jsx'; 

// START COMPONENT
const CuponesDescuentoDetalle = (props) => { 
  const { t } = useTranslation(['recompensas','common','cupones']);
  const [errors, setErrors] = useState({});
  const [IdCupon, setIdCupon] = useState(props.filters === undefined ? 0 : props.filters.IdCupon);
  const [ClaTipoCupon, setClaTipoCupon] = useState(props.filters === undefined ? 0 : props.filters.ClaTipoCupon);
  const [NomCuponDescuento, setNomCuponDescuento] = useState(null);  
  const [NomCortoCuponDescuento, setNomCortoCuponDescuento] = useState(null);  
  const [ImagenCupon, setImagenCupon] = useState(null); 
  const [ImagenCuponBase64, setImagenCuponBase64] = useState(null); 
  const [NomArchivo, setNomArchivo] = useState(null);  
  const [Extension, setExtension] = useState(null);  
  const [Descripcion, setDescripcion] = useState(null);  
  const [EsMostrarImagen, setEsMostrarImagen] = useState(false);  
  const [CodigoPromocion, setCodigoPromocion] = useState(null);  
  const [Descuento, setDescuento] = useState(null);  
  const [EsExcluirProductoConDescuento, setEsExcluirProductoConDescuento] = useState(false);  
  const [rangoFechaExpiracion, setRangoFechaExpiracion] = useState([]);
  const [FechaInicial, setFechaInicial] = useState(null);
  const [FechaFinal, setFechaFinal] = useState(null);
  const [CompraMinima, setCompraMinima] = useState(null);  
  const [TotalDisponiblePorCliente, setTotalDisponiblePorCliente] = useState(null);  
  const [EsLimitarPorCliente, setEsLimitarPorCliente] = useState(false);  
  const [EsLimitarPorCategoriaCliente, setEsLimitarPorCategoriaCliente] = useState(false);  
  const [EsLimitarPorProducto, setEsLimitarPorProducto] = useState(false);  
  const [EsLimitarPorCategoriaProducto, setEsLimitarPorCategoriaProducto] = useState(false);  
  const [activeIndex, setActiveIndex] = useState(0);
  const [showStep1, setShowStep1] = useState(true);
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [showStep4, setShowStep4] = useState(false);
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa()); 
  const [categoriaRowsGrid, setCategoriaRowsGrid] = useState([]);  
  const [originalCategoriaRows, setOriginalCategoriaRows] = useState([]);     
  const [OpcionesYesNo, setOpcionesYesNo] = useState([{ value: true, name: t('common:Si')}, { value: false, name: t('common:No') }]);
  const [abrirDetalle, setAbrirDetalle] = useState(false); 
  const [FiltroCliente, setFiltroCliente] = useState([]);
  const [Clientes, setClientes] = useState([]);
  const [FiltroProductoCategoria, setFiltroProductoCategoria] = useState([]);
  const [ClienteCategoria, setClienteCategoria] = useState([]);
  const [ProductosCategoria, setProductosCategoria] = useState([]);
  const [Productos, setProductos] = useState([]);
  const [FiltroProducto, setFiltroProducto] = useState([]);
  const [EsLimitarPorTienda, setEsLimitarPorTienda] = useState(false);
  const [tiendaRowsGrid, setTiendaRowsGrid] = useState([]);  
  const [originalTiendaRows, setOriginalTiendaRows] = useState([]);  
  const [Tiendas, setTiendas] = useState([]); 
  const [TipoDescuento, setTipoDescuento] = useState([]); 
  const [ClaTipoDescuento , setClaTipoDescuento] = useState(null);
  const [datafiles, setdatafiles] = useState([]); 
  const [nodes, setNodes] = useState([]);
  const [Categorias, setCategorias] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({}); 
  const [SelectedCategoria, setSelectedCategoria] = useState(0);
  const [EsDialogConfirmarCupon, setEsDialogConfirmarCupon] = useState(false);  
  const [ImagenCuponMovil, setImagenCuponMovil] = useState(null); 
  const [ImagenCuponBase64Movil, setImagenCuponBase64Movil] = useState(null); 
  const [NomArchivoMovil, setNomArchivoMovil] = useState(null);  
  const [ExtensionMovil, setExtensionMovil] = useState(null);  
  const [EsMostrarImagenMovil, setEsMostrarImagenMovil] = useState(false);  
  const [datafilesMovil, setdatafilesMovil] = useState([]); 

  const [NomCuponDescuentoIngles, setNomCuponDescuentoIngles] = useState(null);  
  const [NomCortoCuponDescuentoIngles, setNomCortoCuponDescuentoIngles] = useState(null);
  const [DescripcionIngles, setDescripcionIngles] = useState(null);  
  const [datafilesIngles, setdatafilesIngles] = useState([]); 

  const [ImagenCuponMovilIngles, setImagenCuponMovilIngles] = useState(null); 
  const [ImagenCuponBase64MovilIngles, setImagenCuponBase64MovilIngles] = useState(null); 
  const [NomArchivoMovilIngles, setNomArchivoMovilIngles] = useState(null);  
  const [ExtensionMovilIngles, setExtensionMovilIngles] = useState(null);  
  const [EsMostrarImagenMovilIngles, setEsMostrarImagenMovilIngles] = useState(false);  
  const [datafilesMovilIngles, setdatafilesMovilIngles] = useState([]); 
  const [ImagenCuponIngles, setImagenCuponIngles] = useState(null); 
  const [ImagenCuponBase64Ingles, setImagenCuponBase64Ingles] = useState(null); 
  const [EsMostrarImagenIngles, setEsMostrarImagenIngles] = useState(false);  
  const [NomArchivoIngles, setNomArchivoIngles] = useState(null);  
  const [ExtensionIngles, setExtensionIngles] = useState(null);  


  const nodeTemplate = (node, options) => { 
    console.log('nodeTemplate',node,options)
    return (
      <span className={options.className}>
        {node.label}
      </span>
    )
  }

  useEffect(() => {
    const paramsService = {};
    const urlWebServiceTipoDescuento = `${config.UrlApiProject}CuponDescuento/GetTipoDescuento`;
    callApi(urlWebServiceTipoDescuento, 'GET',  paramsService, (response) => {  
      setTipoDescuento(response.data.TipoDescuento);
      const iCupon = (props.filters === undefined ? 0 : props.filters.IdCupon);
      const iClaTipoCupon = (props.filters === undefined ? 0 : props.filters.ClaTipoCupon);
      const iEsDialogConfirmarCupon = (props.visible === undefined ? false : props.visible);
      setIdCupon(iCupon);
      setClaTipoCupon(iClaTipoCupon);
      setEsDialogConfirmarCupon(iEsDialogConfirmarCupon);
      if(iCupon>0){
        onDetalleCuponDescuento(props.filters);
      }else{
      resetCuponDescuento();
      }
      getCategoryList();
    });    
  }, [props.filters]);

  const onChangeEsExcluirProductoConDescuento = (valor) => {
    if(valor===null){
      return;
    }
    setEsExcluirProductoConDescuento(valor);  
  }; 

  const onChangeEsLimitarPorProducto = (value) => {  
    if(value===null){
      return;
    }
    setEsLimitarPorProducto(value);  
  }; 
 
  const onChangeNomCuponDescuentoIngles = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomCuponDescuentoIngles;   
    setErrors(newErrors)  
    setNomCuponDescuentoIngles(e.target.value)
  }

  const onChangeNomCortoCuponDescuentoIngles = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomCortoCuponDescuentoIngles;   
    setErrors(newErrors)  
    setNomCortoCuponDescuentoIngles(e.target.value)
  }

  const onChangeDescripcionIngles = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.DescripcionIngles;   
    setErrors(newErrors)    
    setDescripcionIngles(e.target.value)
  }


  const onChangeNomCuponDescuento = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomCuponDescuento;   
    setErrors(newErrors)  
    setNomCuponDescuento(e.target.value)
  }

  const onChangeNomCortoCuponDescuento = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomCortoCuponDescuento;   
    setErrors(newErrors)  
    setNomCortoCuponDescuento(e.target.value)
  }

  const onChangeDescripcion = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Descripcion;   
    setErrors(newErrors)    
    setDescripcion(e.target.value)
  }

  const onChangeCodigoPromocion = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.CodigoPromocion;   
    setErrors(newErrors)    
    setCodigoPromocion(e.target.value)
  }

  const onChangeDescuento = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Descuento;   
    setErrors(newErrors)    
    setDescuento(e.value)
  }

  const onChangeFechaExpiracion = (e) => {  
    if(e[0]!==null){
      setFechaInicial(moment(e[0]).format("LL"));
    }
    if(e[1]!==null){
      setFechaFinal(moment(e[1]).format("LL"));
    }
    setRangoFechaExpiracion(e);
  }

  const onDetalleCuponDescuento = (rowData) => {
    const paramsService = {
      IdCupon:rowData.IdCupon,
      ClaTipoCupon:rowData.ClaTipoCupon,
      ClaEmpresa
    }
    const urlWebServiceExpirationType = `${config.UrlApiProject}CuponDescuento/GetCuponDescuento`; 
    callApi(urlWebServiceExpirationType, 'POST', paramsService, (response) => {
      resetErrors();
      const data = response.data.CuponesDescuento[0];
      const listaClienteCategoria = response.data.ClienteCategorias;
      const listaCategoriasProducto = response.data.CategoriasProductos;
      const listaProductos = response.data.Productos;
      const listaClientes = response.data.Clientes;
      const listaTienda = response.data.Tiendas;
      if(data!== undefined){
        if(data.ImagenCupon!=null){
          setEsMostrarImagen(true);
          const base64Icon = `data:image/${data.TipoExtension};base64,${data.ImagenCupon}`;
          setImagenCuponBase64(base64Icon);
        }
        if(data.ImagenCuponMovil!=null){
          setEsMostrarImagenMovil(true);
          const base64IconMovil = `data:image/${data.TipoExtensionMovil};base64,${data.ImagenCuponMovil}`;
          setImagenCuponBase64Movil(base64IconMovil);
        }

        if(data.ImagenCuponIngles!=null){
          setEsMostrarImagenIngles(true);
          const base64Icon = `data:image/${data.TipoExtensionIngles};base64,${data.ImagenCuponIngles}`;
          setImagenCuponBase64Ingles(base64Icon);
        }
        if(data.ImagenCuponMovilIngles!=null){
          setEsMostrarImagenMovilIngles(true);
          const base64IconMovil = `data:image/${data.TipoExtensionMovilIngles};base64,${data.ImagenCuponMovilIngles}`;
          setImagenCuponBase64MovilIngles(base64IconMovil);
        }
        const rangoFecha = [];
        rangoFecha[0] = new Date(data.FechaInicioExpiracion);
        rangoFecha[1] = new Date(data.FechaFinalExpiracion);
        setIdCupon(data.IdCupon);  
        setClaTipoCupon(data.ClaTipoCupon);  
        setNomCuponDescuento(data.NomCuponDescuento);
        setNomCortoCuponDescuento(data.NomCortoCuponDescuento);
        setImagenCupon(data.ImagenCupon);
        setdatafiles([]); 
        setNomArchivo(data.NomArchivo); 
        setExtension(data.Extension); 
        setImagenCuponMovil(data.ImagenCuponMovil);
        setdatafilesMovil([]); 
        setNomArchivoMovil(data.NomArchivoMovil); 
        setExtensionMovil(data.ExtensionMovil); 
        setDescripcion(data.Descripcion);
        setCodigoPromocion(data.CodigoPromocion); 
        setClaTipoDescuento(data.ClaTipoDescuento);  
        setDescuento(data.Descuento);  
        setEsExcluirProductoConDescuento(data.EsExcluirProductoConDescuento);
        setRangoFechaExpiracion(rangoFecha);
        setFechaInicial(moment(rangoFecha[0]).format("LL")); 
        setFechaFinal(moment(rangoFecha[1]).format("LL"));
        setCompraMinima(data.CompraMinima);  
        setTotalDisponiblePorCliente(data.TotalDisponiblePorCliente);  
        setEsLimitarPorCliente(data.EsLimitarPorCliente);  
        setEsLimitarPorCategoriaCliente(data.EsLimitarPorCategoriaCliente);  
        setEsLimitarPorProducto(data.EsLimitarPorProducto);  
        setEsLimitarPorCategoriaProducto(data.EsLimitarPorCategoriaProducto);
        setClienteCategoria(listaClienteCategoria);
        setCategoriaRowsGrid(listaClienteCategoria);
        setOriginalCategoriaRows(listaClienteCategoria); 
        setEsLimitarPorTienda(data.EsLimitarPorTienda);  
        setTiendaRowsGrid(listaTienda);
        setOriginalTiendaRows(listaTienda);
        setTiendas(listaTienda);
        setProductosCategoria(listaCategoriasProducto); 

        setNomCuponDescuentoIngles(data.NomCuponDescuentoIngles);
        setNomCortoCuponDescuentoIngles(data.NomCortoCuponDescuentoIngles);
        setDescripcionIngles(data.DescripcionIngles);
        

        
        setImagenCuponIngles(data.ImagenCuponIngles);
        setdatafilesIngles([]); 
        setNomArchivoIngles(data.NomArchivoIngles); 
        setExtensionIngles(data.ExtensionIngles); 
        setImagenCuponMovilIngles(data.ImagenCuponMovilIngles);
        setdatafilesMovilIngles([]); 
        setNomArchivoMovilIngles(data.NomArchivoMovilIngles); 
        setExtensionMovilIngles(data.ExtensionMovilIngles);  

        const obj = {};
        for (const key of listaCategoriasProducto) {
             obj[key.ClaProductoCategoria] = {checked:true};
        }
        setSelectedCategoria(obj);
        setProductos(listaProductos); 
        setClientes(listaClientes); 
        setActiveIndex(0);
        setShowStep1(true);
        setShowStep2(false);
        setShowStep3(false);
        setShowStep4(false);
        resetErrors();
        if (props.filters.isDuplicate != null){ 
          if(props.filters.isDuplicate){
            setIdCupon(0);  
            setNomCuponDescuento('');
            setCodigoPromocion('');
            setNomCuponDescuentoIngles(''); 
          }
        }
        if (props.isHidden != null){ 
          if(props.isHidden){
            setShowStep1(false);
            setShowStep2(false);
            setShowStep3(false);
            setShowStep4(true);
            setActiveIndex(2);
          }
        }else{
          setAbrirDetalle(true); 
        }

        if (typeof(props.isShowSummary) !== 'undefined' && props.isShowSummary && props.isShowSummary !== null){
          setShowStep1(false);
          setShowStep2(false);
          setShowStep3(false);
          setShowStep4(true);
          setAbrirDetalle(false); 
        }
      }
    });  
  };
 

  const onCodigoCupon = () => {
    const paramsService = {
      ClaEmpresa
    }
    const urlWebServiceExpirationType = `${config.UrlApiProject}CuponDescuento/GetCodigoCuponDescuento`; 
    callApi(urlWebServiceExpirationType, 'POST', paramsService, (response) => {
      if(response.data.CodigoCuponDescuento.length>0){
        setCodigoPromocion(response.data.CodigoCuponDescuento[0].CodigoPromocion);
      }
    });  
  };

  const onDetalleCampaniaCategoriaCliente = (rowData) => {
    const paramsService = {
      IdCupon:rowData.IdCupon,
      ClaEmpresa
    }
    const urlWebServiceExpirationType = `${config.UrlApiProject}CampaniaRecompensa/GetCampaniaCategoriaCliente`; 
    callApi(urlWebServiceExpirationType, 'POST', paramsService, (response) => {
      setCategoriaRowsGrid(response.data.CampaniaCategorias);
      setOriginalCategoriaRows(response.data.CampaniaCategorias); 
    });  
  }; 
  const onDetalleCuponDescuentoTiendas = (rowData) => {
    const paramsService = {
      IdCupon:rowData.IdCupon,
      ClaEmpresa
    }
    const urlWebService = `${config.UrlApiProject}CuponDescuento/GetCuponDescuentoTiendas`; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      setTiendaRowsGrid(response.data.CuponDescuentoTiendas);
      setOriginalTiendaRows(response.data.CuponDescuentoTiendas); 
    });  
  };

  const regresar = () => {
    resetCuponDescuento();
    if (props.onAfterReturn !=  null){ 
      props.onAfterReturn();
    }
  }

  const onChangeUpload = (e) => {  
    if(e===null){
      return;
    } 
    setdatafiles(e);
    setImagenCuponBase64(null);
    if(e[0]!==undefined){
      setEsMostrarImagen(true);
      setImagenCuponBase64(e[0].objectURL);
    }
  }; 

  const onChangeUploadMovil = (e) => {  
    if(e===null){
      return;
    } 
    setdatafilesMovil(e);
    setImagenCuponBase64Movil(null);
    if(e[0]!==undefined){
      setEsMostrarImagenMovil(true);
      setImagenCuponBase64Movil(e[0].objectURL);
    }
  }; 

  const onChangeUploadIngles = (e) => {  
    if(e===null){
      return;
    } 
    setdatafilesIngles(e);
    setImagenCuponBase64Ingles(null);
    if(e[0]!==undefined){
      setEsMostrarImagenIngles(true);
      setImagenCuponBase64Ingles(e[0].objectURL);
    }
  }; 

  const onChangeUploadMovilIngles = (e) => {  
    if(e===null){
      return;
    } 
    setdatafilesMovilIngles(e);
    setImagenCuponBase64MovilIngles(null);
    if(e[0]!==undefined){
      setEsMostrarImagenMovilIngles(true);
      setImagenCuponBase64MovilIngles(e[0].objectURL);
    }
  }; 

  const removerImagenMovilIngles = () => {
    setdatafilesMovilIngles([]);
    setImagenCuponBase64MovilIngles(null);
    setImagenCuponMovilIngles(null); 
    setNomArchivoMovilIngles(null); 
    setExtensionMovilIngles(null); 
    setEsMostrarImagenMovilIngles(false);
  }

  const removerImagenIngles = () => {
    setdatafilesIngles([]);
    setImagenCuponBase64Ingles(null);
    setImagenCuponIngles(null); 
    setNomArchivoIngles(null); 
    setExtensionIngles(null); 
    setEsMostrarImagenIngles(false);
  }

  const removerImagenMovil = () => {
    setdatafilesMovil([]);
    setImagenCuponBase64Movil(null);
    setImagenCuponMovil(null); 
    setNomArchivoMovil(null); 
    setExtensionMovil(null); 
    setEsMostrarImagenMovil(false);
  }

  const removerImagen = () => {
    setdatafiles([]);
    setImagenCuponBase64(null);
    setImagenCupon(null); 
    setNomArchivo(null); 
    setExtension(null); 
    setEsMostrarImagen(false);
  }



  const resetErrors = () => {
    const newErrors = { ...errors } 
    delete newErrors.NomCuponDescuento;   
    delete newErrors.Descripcion;   
    delete newErrors.CodigoPromocion;    
    delete newErrors.Descuento;    
    setErrors(newErrors)  
  }

 const onchangeProductoCategoria = (e) => {
    setProductosCategoria(e.value);
  }

 const onchangeCliente = (e) => {
    setClientes(e.value);
  }
  
 const onchangeProducto = (e) => {
    setProductos(e.value);
  } 
  
  const resetCuponDescuento = () => { 
    setIdCupon(0);  
    setNomCuponDescuento('');
    setNomCortoCuponDescuento('');
    setDescripcion('');
    setCodigoPromocion(null); 
    setDescuento(null); 
    setClaTipoDescuento(1); 
    setRangoFechaExpiracion([]);
    setEsExcluirProductoConDescuento(false); 
    setCompraMinima(null);  
    setTotalDisponiblePorCliente(null);  
    setEsLimitarPorCliente(false);  
    setEsLimitarPorCategoriaCliente(false);  
    setEsLimitarPorProducto(false);  
    setEsLimitarPorCategoriaProducto(false);
    resetErrors();
    setClientes([]);
    setClienteCategoria([]);
    setProductos([]);
    setProductosCategoria([]);
    setSelectedCategoria([]);
    setTiendas([]);
    setdatafiles([]); 
    setImagenCuponBase64(null);
    setImagenCupon(null);
    setNomArchivo(null); 
    setExtension(null); 
    setdatafilesMovil([]); 
    setImagenCuponBase64Movil(null);
    setImagenCuponMovil(null);
    setNomArchivoMovil(null); 
    setExtensionMovil(null); 
    const rowData = {IdCupon:0}
    onDetalleCampaniaCategoriaCliente(rowData);
    onDetalleCuponDescuentoTiendas(rowData);
    setActiveIndex(0);
    setShowStep1(true);
    setShowStep2(false);
    setShowStep3(false);
    setShowStep4(false); 
    setAbrirDetalle(true);
  } 
  
  const valitationScheme = yup.object().shape({
    NomCuponDescuento: yup.string().required(t('common:RequiredField')).nullable(),
    NomCuponDescuentoIngles: yup.string().required(t('common:RequiredField')).nullable()
  });

  const valitationSchemeStep1 = yup.object().shape({
    ClaTipoDescuento: yup.mixed().required(t('common:RequiredField')).nullable(),
    NomCuponDescuento: yup.string().required(t('common:RequiredField')).nullable(),
    NomCuponDescuentoIngles: yup.string().required(t('common:RequiredField')).nullable(),
    Descuento: yup.string().required(t('common:RequiredField')).nullable(),
    CodigoPromocion: (
      ClaTipoCupon === 1
      ? yup.string().required(t('common:RequiredField')).nullable() 
      : yup.string()
    )
  });

  const valitationSchemeStep2 = yup.object().shape({
    CompraMinima: (
      ClaTipoCupon === 1
      ? yup.mixed().required(t('common:RequiredField')).nullable() 
      : yup.string()
    ),
    TotalDisponiblePorCliente: (
      ClaTipoCupon === 1
      ? yup.string().required(t('common:RequiredField')).nullable() 
      : yup.string()
    )
  });

  const generar = async () => {
    onCodigoCupon();
  }

  const guardar = async () => {
    const value = {
      NomCuponDescuento,
      NomCuponDescuentoIngles
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}CuponDescuento/PostCuponDescuento`;
    const paramsService = {
      IdCupon,
      ClaEmpresa,
      ClaTipoCupon,
      NomCortoCuponDescuento,
      NomCuponDescuento,
      ImagenCupon:(datafiles.length > 0 ? datafiles[0].FileData : ImagenCupon),
      NomArchivo:(datafiles.length > 0 ? datafiles[0].FileName: NomArchivo),
      Extension:(datafiles.length > 0 ? datafiles[0].FileExtension: Extension),
      ImagenCuponMovil:(datafilesMovil.length > 0 ? datafilesMovil[0].FileData : ImagenCuponMovil),
      NomArchivoMovil:(datafilesMovil.length > 0 ? datafilesMovil[0].FileName: NomArchivoMovil),
      ExtensionMovil:(datafilesMovil.length > 0 ? datafilesMovil[0].FileExtension: ExtensionMovil),
      Descripcion,
      CodigoPromocion,
      ClaTipoDescuento,
      Descuento,
      EsExcluirProductoConDescuento,
      FechaInicioExpiracion:(rangoFechaExpiracion[0] === undefined ? null : rangoFechaExpiracion[0]),
      FechaFinalExpiracion:(rangoFechaExpiracion[1] === undefined ? null : rangoFechaExpiracion[1]),
      CompraMinima,
      TotalDisponiblePorCliente,
      EsLimitarPorCliente,
      EsLimitarPorCategoriaCliente,
      EsLimitarPorProducto,
      EsLimitarPorCategoriaProducto,
      EsLimitarPorTienda,
      CuponDescuentoCliente:Clientes,
      CuponDescuentoClienteCategoria:ClienteCategoria,
      CuponDescuentoProductoCategoria:ProductosCategoria,
      CuponDescuentoProducto:Productos,
      CuponDescuentoTienda:Tiendas,

      NomCortoCuponDescuentoIngles,
      NomCuponDescuentoIngles,
      ImagenCuponIngles:(datafilesIngles.length > 0 ? datafilesIngles[0].FileData : ImagenCuponIngles),
      NomArchivoIngles:(datafilesIngles.length > 0 ? datafilesIngles[0].FileName: NomArchivoIngles),
      ExtensionIngles:(datafilesIngles.length > 0 ? datafilesIngles[0].FileExtension: ExtensionIngles),
      ImagenCuponMovilIngles:(datafilesMovilIngles.length > 0 ? datafilesMovilIngles[0].FileData : ImagenCuponMovilIngles),
      NomArchivoMovilIngles:(datafilesMovilIngles.length > 0 ? datafilesMovilIngles[0].FileName: NomArchivoMovilIngles),
      ExtensionMovilIngles:(datafilesMovilIngles.length > 0 ? datafilesMovilIngles[0].FileExtension: ExtensionMovilIngles),
      DescripcionIngles
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      removerImagen();
      if (props.onAfterSave !=  null){ 
        props.onAfterSave();
      }
    });  
  }

  const onActiveIndex = async () => {
    if(showStep1){
      const value = {
        NomCuponDescuento,
        NomCuponDescuentoIngles,
        Descuento,
        ClaTipoDescuento
      }
      if(ClaTipoCupon===1){
        value.CodigoPromocion = CodigoPromocion;
      }
      const validator = new YupValidator(valitationSchemeStep1);
      const esValido = await validator.validate(value);
      if (!esValido){ 
        setErrors(validator.errors); 
        showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
        return;
      }
      setActiveIndex(1);
      setShowStep1(false);
      setShowStep2(true);
      setShowStep3(false); 
      setShowStep4(false); 
    }
    if(showStep2){
      if(ClaTipoCupon===1){
        const valueStep2 = {
          CompraMinima,
          TotalDisponiblePorCliente
        }
        const validator = new YupValidator(valitationSchemeStep2);
        const esValido = await validator.validate(valueStep2);
        if (!esValido){ 
          setErrors(validator.errors); 
          showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
          return;
        }
      }
      if(datafiles.length === 0 && ImagenCupon === null){
        showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
        return;
      }
      if(datafilesMovil.length === 0 && ImagenCuponMovil === null){
        showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
        return;
      }

      if(datafilesIngles.length === 0 && ImagenCuponIngles === null){
        showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
        return;
      }
      if(datafilesMovilIngles.length === 0 && ImagenCuponMovilIngles === null){
        showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
        return;
      }
      if(
        (rangoFechaExpiracion[0] === undefined || rangoFechaExpiracion[0] === null) || 
        (rangoFechaExpiracion[1] === undefined || rangoFechaExpiracion[1] === null)
      )
      {
        showSweetAlert('Warning', t('cupones:MensajePeriodoValido'), 'warning');
        return;
      }
      setActiveIndex(2);
      setShowStep1(false);
      setShowStep2(false);
      setShowStep3(true);
      setShowStep4(false);  
    }
    if(showStep3){
      if(EsLimitarPorCliente){
        if(Clientes.length===0){
          showSweetAlert('Warning', t('recompensas:MensajeSeleccionarCliente'), 'warning');
          return;
        }
      }
      if(EsLimitarPorCategoriaCliente){ 
        const aCategoriaCliente = [];
        categoriaRowsGrid.forEach((item) => {
          if(item.EsSeleccionado){
            aCategoriaCliente.push(item);
          }
        })
        if(aCategoriaCliente.length===0){
          showSweetAlert('Warning', t('recompensas:MensajeSeleccionarCategoriaCliente'), 'warning');
          return;
        }
        setClienteCategoria(aCategoriaCliente);
      }
      if(EsLimitarPorTienda){ 
        const aTienda = [];
        tiendaRowsGrid.forEach((item) => {
          if(item.EsSeleccionado){
            aTienda.push(item);
          }
        })
        if(aTienda.length===0){
          showSweetAlert('Warning', t('cupones:MensajeSeleccionarTienda'), 'warning');
          return;
        }
        setTiendas(aTienda);
      }
      if(EsLimitarPorCategoriaProducto){
        if(ProductosCategoria.length===0){
          showSweetAlert('Warning', t('recompensas:MensajeSeleccionarCategoriaProducto'), 'warning');
          return;
        }
      }
      if(EsLimitarPorProducto){
        if(Productos.length===0){
          showSweetAlert('Warning', t('recompensas:MensajeSeleccionarProducto'), 'warning');
          return;
        }
      }
      setActiveIndex(3);
      setShowStep1(false);
      setShowStep2(false);
      setShowStep3(false);
      setShowStep4(true);  
    } 
  }

  const stepHeaderTemplate = (options) => {
  return (
    <div className="card-header danger">
      <div className="p-toolbar p-component">
        <div className="p-toolbar-group-left">
          <b>{NomCuponDescuentoIngles} </b>
        </div> 
        <div className="p-toolbar-group-right">
          <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={regresar}>
            <i className="pi pi-arrow-left"></i>
          </button>
        </div>
      </div> 
    </div>
  )
} 

const printStep4 = () => {
  return (
    <CardBody>
      <Panel header={t('common:Ingles')} toggleable>
        <div className="p-field">
          {
          EsMostrarImagenIngles && ImagenCuponBase64Ingles!=null ? (
            <>
              <Row>
                <Col className='text-center'>
                  <img alt={NomArchivoIngles} src={ImagenCuponBase64Ingles} role="presentation" />
                </Col>
              </Row>
            </>
          ):( <></>
          )
        }
          <Row> 
            <Col>    
              <h1>{NomCuponDescuentoIngles}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3> {NomCortoCuponDescuentoIngles}</h3> 
            </Col>
          </Row>
          <Row>
            <Col>
              {DescripcionIngles}
            </Col>
          </Row>
        </div>
      </Panel>
      <br></br>
      <Panel header={t('common:Espanol')} toggleable>
        <div className="p-field">
          {
          EsMostrarImagen && ImagenCuponBase64!=null ? (
            <>
              <Row>
                <Col className='text-center'>
                  <img alt={NomArchivo} src={ImagenCuponBase64} role="presentation" />
                </Col>
              </Row>
            </>
          ):( <></>
          )
        }
          <Row> 
            <Col>    
              <h1>{NomCuponDescuento}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3> {NomCortoCuponDescuento}</h3> 
            </Col>
          </Row>
          <Row>
            <Col>
              {Descripcion}
            </Col>
          </Row>
        </div>
      </Panel>

     
      <div className="p-field">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <b> {t('cupones:Periodo')} </b> 
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {FechaInicial} - {FechaFinal}
          </Col> 
        </Row>
      </div>
      {
  ClaTipoCupon=== 1? (
    <>
      <div className="p-field">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <b> {t('cupones:CodigoPromocion')} </b> 
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {CodigoPromocion}
          </Col> 
        </Row>
      </div>
    </>
  ):(
    <>
    </>
  )
}
      <div className="p-field">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <b> {t('cupones:AplicarTipoDescuento')} </b> 
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {
        ClaTipoDescuento=== 1? (
          <>
            {t('cupones:EsDescuentoPorcentaje')}
          </>
        ):(
          <>
            {t('cupones:EsDescuentoImporte')}
          </>
        )
      }
          </Col> 
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {Descuento}
          </Col> 
        </Row>
      </div>

      {
  ClaTipoCupon===1 ? (
    <>
      <div className="p-field">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <b> {t('cupones:CompraMinima')} </b> 
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {CompraMinima}
          </Col> 
        </Row>
      </div>
      <div className="p-field">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <b> {t('cupones:TotalDisponiblePorCliente')} </b> 
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {TotalDisponiblePorCliente}
          </Col> 
        </Row>
      </div>
    </>
  ):(
    <>
    </>
  )
}
      <div>
        <hr></hr>
      </div>
      <h3> 
        <span> 
          {
      ClaTipoCupon===1 ? (
        <>
          {t('cupones:EtiquetaAplicaCriteriosCupones')} 
        </>
      ):(
        <>
          {t('cupones:EtiquetaAplicaCriteriosDeal')} 
        </>
      )
    }
        </span>
      </h3>
      <Row>&nbsp;</Row>
      
      <div hidden={EsLimitarPorTienda}> 
        <h3> <span>{t('cupones:Tienda')} </span></h3>
        <span> {t('cupones:EsTodasTiendas')} </span>
      </div>
      <div hidden={!EsLimitarPorTienda}> 
        <h3> <span> {t('cupones:Tienda')} </span></h3>
        <ul className="list-group list-group-flush">
          {Tiendas.map((item) => {
          return item.EsSeleccionado === 0 ? <> </> : <li className="list-group-item">{item.NomTienda}</li>  
        })} 
        </ul> 
      </div>
      <div>
        <hr></hr>
      </div>  
      <div hidden={EsLimitarPorCliente}> 
        <h3> <span> {t('cupones:Clientes')} </span></h3>
        <span> {t('cupones:EsTodosClientes')} </span>
      </div>
      <div hidden={!EsLimitarPorCliente}>
        <h3> <span> {t('cupones:Clientes')} </span></h3>
        <ul className="list-group list-group-flush">
          {Clientes.map((item) => { 
        return (
          <li className="list-group-item">{item.NomCliente}</li>
        );
    })}  
        </ul> 
      </div>
      <div>
        <hr></hr>
      </div>      
      <div hidden={EsLimitarPorCategoriaCliente}> 
        <h3> <span>{t('cupones:CategoriasCliente')} </span></h3>
        <span> {t('cupones:EsTodasCategoriaCliente')} </span>
      </div>
      <div hidden={!EsLimitarPorCategoriaCliente}> 
        <h3> <span> {t('cupones:CategoriasCliente')} </span></h3>
        <ul className="list-group list-group-flush">
          {ClienteCategoria.map((item) => {
          return item.EsSeleccionado === 0 ? <> </> : <li className="list-group-item">{item.NomCategoria}</li>  
        })} 
        </ul> 
      </div>
      <div>
        <hr></hr>
      </div>        
      <div hidden={EsLimitarPorCategoriaProducto}> 
        <h3> <span> {t('recompensas:CategoriasProducto')} </span></h3>
        <span> {t('recompensas:EsTodasCategoriaProducto')} </span>
      </div>
      <div hidden={!EsLimitarPorCategoriaProducto}> 
        <h3> <span> {t('recompensas:CategoriasProducto')} </span></h3>
        <ul className="list-group list-group-flush">
          {ProductosCategoria.map((item) => { 
        return (
          <li className="list-group-item">{item.NomProductoCategoria}</li>
        );
    })}  
        </ul> 
      </div>
      <div>
        <hr></hr>
      </div>        
      <div hidden={EsLimitarPorProducto}> 
        <h3> <span> {t('recompensas:Productos')} </span></h3>
        <span> {t('recompensas:EsTodosProducto')} </span>
      </div>
      <div hidden={!EsLimitarPorProducto}> 
        <h3> <span> {t('recompensas:Productos')} </span></h3>
        <ul className="list-group list-group-flush">
          {Productos.map((item) => { 
        return (
          <li className="list-group-item">{item.NomProducto}</li>
        );
    })}  
        </ul> 
      </div>
    </CardBody>
  )
} 

const onInActiveIndex = () => { 
  if(showStep2){
    setActiveIndex(0);
    setShowStep1(true);
    setShowStep2(false);
    setShowStep3(false); 
    setShowStep4(false); 
  }
  if(showStep3){
    setActiveIndex(1);
    setShowStep1(false);
    setShowStep2(true);
    setShowStep3(false); 
    setShowStep4(false); 
  }
  if(showStep4){
    setActiveIndex(2);
    setShowStep1(false);
    setShowStep2(false);
    setShowStep3(true); 
    setShowStep4(false); 
  }
};

const stepItems = [
  {
      label: t('cupones:InformacionCupon'),
      className:'itemInicio',
      command: (event) => {
          setShowStep1(true);
          setShowStep2(false);
          setShowStep3(false); 
          setShowStep4(false);
      }
  },
  {
      label: t('common:Configuracion'),
      command: (event) => {
        setShowStep1(false);
        setShowStep2(true);
        setShowStep3(false); 
        setShowStep4(false);
      }
  },
  {
      label: t('cupones:RestriccionesAdicionales'),
      className:'itemFinal',
      command: (event) => {
        setShowStep1(false);
        setShowStep2(false);
        setShowStep3(true);
        setShowStep4(false);
      }
  }/* ,
  {
      label: t('common:Confirmacion'),
      className:'itemFinal',
      command: (event) => {
        setShowStep1(false);
        setShowStep2(false);
        setShowStep3(false);
        setShowStep4(true);
      }
  } */
];

const onCategoriaRowEditInit = (event) => {
  originalCategoriaRows[event.index] = { ...categoriaRowsGrid[event.index] };
  setOriginalCategoriaRows(originalCategoriaRows); 
};

const onCategoriaRowEditCancel = (event) => {
  const data = [...categoriaRowsGrid];
  data[event.index] = originalCategoriaRows[event.index];
  delete originalCategoriaRows[event.index];
  setCategoriaRowsGrid(data);
};

const onTiendaRowEditInit = (event) => {
  originalTiendaRows[event.index] = { ...tiendaRowsGrid[event.index] };
  setOriginalTiendaRows(originalTiendaRows); 
};

const onTiendaRowEditCancel = (event) => {
  const data = [...categoriaRowsGrid];
  data[event.index] = originalTiendaRows[event.index];
  delete originalTiendaRows[event.index];
  setTiendaRowsGrid(data);
};

const onChangeCompraMinima = (e) => {
  const newErrors = { ...errors } 
  delete newErrors.CompraMinima;   
  setErrors(newErrors)  
  setCompraMinima(e.value)
}

const onChangeTotalDisponiblePorCliente = (e) => {
  const newErrors = { ...errors } 
  delete newErrors.TotalDisponiblePorCliente;   
  setErrors(newErrors)  
  setTotalDisponiblePorCliente(e.value)
}

const onChangeEsLimitarPorCliente = (e) => {  
  if(e===null){
    return;
  }
  setEsLimitarPorCliente(e);  
}; 

const onChangeEsLimitarPorCategoriaCliente = (e) => {  
  if(e===null){
    return;
  }
  setEsLimitarPorCategoriaCliente(e);  
}; 
const onChangeEsLimitarPorTienda = (e) => {  
  if(e===null){
    return;
  }
  setEsLimitarPorTienda(e);  
}; 
const onChangeEsLimitarPorCategoriaProducto = (e) => {
  if(e===null){
    return;
  } 
  setEsLimitarPorCategoriaProducto(e);  
}; 


const onCategoriaEditorValueChange = (props, value ) => {  
  const updatedProducts = [...props.value];
  updatedProducts[props.rowIndex][props.field] = value;
  setCategoriaRowsGrid(updatedProducts);
}; 

const seleccionarCategoriaEditor = (props ) => {  
  return (
    <Checkbox 
      checked={props.rowData.EsSeleccionado} 
      onChange={(e) => onCategoriaEditorValueChange(props, e.checked)} 
    />
)
}; 

const seleccionarTiendaEditor = (props ) => {  
  return (
    <Checkbox 
      checked={props.rowData.EsSeleccionado} 
      onChange={(e) => onTiendaEditorValueChange(props, e.checked)} 
    />
)
}; 

const onTiendaEditorValueChange = (props, value ) => {  
  const updatedProducts = [...props.value];
  updatedProducts[props.rowIndex][props.field] = value;
  setTiendaRowsGrid(updatedProducts);
}; 

const seleccionarBodyTemplate = (rowData) => {
  return rowData.EsSeleccionado ? t('common:Si') : t('common:No');
}

const busquedaCliente = (event) => {
  setTimeout(() => {
    let paramsService = null;
    paramsService = {
      Busqueda:event.query,
      ClaEmpresa
    }
    const urlWebService = `${config.UrlApiProject}Cliente/GetClienteAutocomplete`; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      let _FiltroCliente;
      if (!event.query.trim().length) {
        _FiltroCliente = [...response.data.Cientes];
      }
      else {
        _FiltroCliente = response.data.Clientes.filter((info) => {
              return info.NomCliente.toLowerCase().includes(event.query.toLowerCase());
          });
      }
      setFiltroCliente(_FiltroCliente);
    });    
  }, 250);
}


const busquedaProductoCategoria = (event) => {
  setTimeout(() => {
    let paramsService = null;
    paramsService = {
      Busqueda:event.query,
      ClaEmpresa
    }
    const urlWebService = `${config.UrlApiProject}Categoria/GetProductoCategoriaAutoComplete`; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      let _FiltroProductoCategoria;
      if (!event.query.trim().length) {
          _FiltroProductoCategoria = [...response.data.ProductoCategorias];
      }
      else {
          _FiltroProductoCategoria = response.data.ProductoCategorias.filter((info) => {
              return event.query.toLowerCase().includes(event.query.toLowerCase());
          });
      }
      setFiltroProductoCategoria(_FiltroProductoCategoria);
    });    
  }, 250);
}

const getCategoryList = () => { 
  let paramsService = null;
  paramsService = {
    Busqueda:"",
    ClaEmpresa
  }
  const GetServiceCategories = `${config.UrlApiProject}Categoria/GetProductoCategoriaList`;  
  callApi(GetServiceCategories, 'POST', paramsService, (response) => { 
    setNodes(response.data.List);
    setCategorias(response.data.CategoriaList);
  });
}
 
const busquedaProducto = (event) => {
  setTimeout(() => {
    let paramsService = null;
    paramsService = {
      Busqueda:event.query,
      ClaEmpresa
    }
    const urlWebService = `${config.UrlApiProject}Categoria/GetProductoAutoComplete`; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      let _FiltroProducto;
      if (!event.query.trim().length) {
          _FiltroProducto = [...response.data.Productos];
      }
      else {
          _FiltroProducto = response.data.Productos.filter((info) => {
              return info.NomProducto.toLowerCase().includes(event.query.toLowerCase());
          });
      }
      setFiltroProducto(_FiltroProducto);
    });    
  }, 250);
}

const itemTemplate = (item) => {
  const iPaddingLeft = (item.Nivel>1 ? (item.Nivel-1) * 20 :10);
  let sBackColor = '';
  let sColor = '';
 switch (item.Nivel) {
  case 1:
    sBackColor = '#FE6869';
    sColor = '#FFF';
    break;
  case 2:
    sBackColor = '#FEC2C2';
    sColor = '#777';
    break;
  case 3:
    sBackColor = '#FFE7E7';
    sColor = '#777';
    break;
  case 4:
    sBackColor = '#F5F5F5';
    sColor = '#777';
    break;
  default:
    sBackColor = '#FFFFFF';
    sColor = '#777';
}

  return (
    <> 
      <div className="row-Autocomplete" style={{ paddingLeft: `${iPaddingLeft  }px`, background: `${sBackColor}`, color: `${sColor}`}}>
        <div> {item.NomProductoCategoria}</div>
      </div>
    </>
  );
}

const itemTemplateCliente = (item) => {
  const sBackColor = '#FFF';
  const sColor = '#777777';
  return (
    <> 
      <div className="row-Autocomplete" style={{ paddingLeft: '5px', background: `${sBackColor}`, color: `${sColor}`}}>
        <div> {item.NomCliente}</div>
      </div>
    </>
  );
}

const onChangeTipoDescuento = (value) => {  
  const newErrors = { ...errors } 
  delete newErrors.ClaTipoDescuento;   
  setErrors(newErrors)  
  setClaTipoDescuento(value);
}  

const itemTemplateProducto = (item) => {
  const sBackColor = '#FFF';
  const sColor = '#777777';
  return (
    <> 
      <div className="row-Autocomplete" style={{ paddingLeft: '5px', background: `${sBackColor}`, color: `${sColor}`}}>
        <div> {item.NomProducto}</div>
      </div>
    </>
  );
}

const onChange = (node) => { 
  setSelectedCategoria(node);
  const result = Object.keys(node).map((key) => {
    return [Number(key), node[key]];
  });
  const arrayCategorias = [];
  for (let index = 0; index < result.length; index++) {
    const element = result[index];
    const elementArrayChild = element[1];
    if (elementArrayChild.checked) {
      // Busca nombre
      let sCategoria = '';
      const categoriaIndex =  Categorias.findIndex(item => item.ClaProductoCategoria === element[0]);
        if (categoriaIndex !== -1) {
          sCategoria= Categorias[categoriaIndex].NomProductoCategoria; 
        }
      const categorias = {
        ClaProductoCategoria: element[0],
        NomProductoCategoria:sCategoria
      };
      arrayCategorias.push(categorias);
    }
  }
  setProductosCategoria(arrayCategorias);
}

const onClick = () => { 
  setSelectedCategoria(0);
}

const onCloseDialogConfirmarCupon = () => { 
  setEsDialogConfirmarCupon(false);
  setActiveIndex(2);
  setShowStep1(false);
  setShowStep2(false);
  setShowStep3(true);
  setShowStep4(false); 
} 

const onOpenDialogConfirmarCupon = () => { 
  setShowStep4(true);
  setShowStep3(false);
  setShowStep2(false);
  setEsDialogConfirmarCupon(true);
} 

const renderHeaderDialogConfirmarCupon = () => {
  return (
    <> 
      <Row>
        <Col>
          &nbsp;
        </Col>
        <Col className='text-center'>
          <h2 hidden={ClaTipoCupon===2}>{t('cupones:ConfirmationCoupon')}</h2> 
          <h2 hidden={ClaTipoCupon===1}>{t('cupones:ConfirmationDeal')}</h2> 
        </Col>
        <Col className="text-right">
          <Button hidden={(showStep1 || showStep2 || showStep3 || !abrirDetalle)} onClick={guardar} className="p-button-rounded p-button-success p-mr-2">
            <span className="p-button-label p-c">{t('common:Save')}</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}

  return (
    <>
      <div>
        <Row hidden={!abrirDetalle}>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <div hidden={props.isHidden}>
              <Panel className="panel-radius" headerTemplate={stepHeaderTemplate}> 
                <Row className="padding10 text-center">
                  <Col lg={3} md={3} sm={12} xs={12}>
                    <div
                      hidden={showStep1} 
                      onClick={onInActiveIndex}
                      className="label-lg info-color Cursor"
                    >
                      <span className="p-button-label p-c"> <i className="pi pi-arrow-circle-left"></i> {t('common:Anterior')}</span>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}> 
                    <div className="steps padding10">
                      <Steps
                        model={stepItems}
                        activeIndex={activeIndex} 
                        onSelect={(e) => setActiveIndex(e.index)}
                        readOnly={true}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={12} xs={12}>
                    <div
                      hidden={showStep3}
                      onClick={onActiveIndex} 
                      className="label-lg info-color p-c Cursor"
                    >
                      <span className="p-button-label p-c"> {t('common:Siguiente')}&nbsp; 
                        <i className="pi pi-arrow-circle-right"> 
                            &nbsp;
                        </i>
                      </span>
                    </div>
                    <div
                      hidden={showStep1 || showStep2}
                      onClick={onActiveIndex} 
                      className="label-lg info-color p-c Cursor"
                    >
                      <Button
                        onClick={(e) => onOpenDialogConfirmarCupon()}
                        className="p-button-rounded p-button-info p-mr-2"
                      >
                        <span className="p-button-label p-c">{t('common:Confirmation')}</span>
                      </Button> 
                    </div>
                    <Button
                      hidden={(showStep1 || showStep2 || showStep3)}
                      label={t('common:Save')}
                      icon="pi pi-save" 
                      className="p-button-rounded p-button-success p-mr-2" 
                      onClick={guardar}
                    /> 
                  </Col>
                </Row> 
              </Panel>
            </div> 
          </Col>
        </Row>
        <Row className='pt-3' hidden={!abrirDetalle}>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <Card className="radiuscard padding50"> 
              {
                showStep1 ? 
                (
                  <CardBody>
                    <div className="p-field">
                      <Panel header={t('common:Ingles')} toggleable>
                        <Row>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <span className="bold"> {t('cupones:Nombre')}</span>
                          </Col> 
                          <Col lg={8} md={6} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12"> 
                              <FieldText  
                                name="NomCuponDescuentoIngles"
                                value={NomCuponDescuentoIngles}  
                                errors={errors}
                                onChange={onChangeNomCuponDescuentoIngles}
                              /> 
                            </div>
                          </Col> 
                        </Row>   
                        <Row>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <span className="bold"> {t('cupones:NombreCorto')}</span>
                          </Col> 
                          <Col lg={8} md={6} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12"> 
                              <FieldText  
                                name="NomCortoCuponDescuentoIngles"
                                value={NomCortoCuponDescuentoIngles}  
                                errors={errors}
                                onChange={onChangeNomCortoCuponDescuentoIngles}
                              /> 
                            </div>
                          </Col> 
                        </Row>   
                        <Row>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <span className="bold"> {t('cupones:Descripcion')}</span>
                          </Col> 
                          <Col lg={8} md={6} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12"> 
                              <FieldTextArea
                                name="DescripcionIngles"
                                value={DescripcionIngles} 
                                onChange={onChangeDescripcionIngles}   
                                rows={4}
                                cols={30}
                                autoResize
                                errors={errors}
                              />
                            </div>
                          </Col> 
                        </Row>  
                      </Panel>
                      <br></br>
                      <Panel header={t('common:Espanol')} toggleable>
                        <Row>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <span className="bold"> {t('cupones:Nombre')}</span>
                          </Col> 
                          <Col lg={8} md={6} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12"> 
                              <FieldText  
                                name="NomCuponDescuento"
                                value={NomCuponDescuento}  
                                errors={errors}
                                onChange={onChangeNomCuponDescuento}
                              /> 
                            </div>
                          </Col> 
                        </Row>   
                        <Row>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <span className="bold"> {t('cupones:NombreCorto')}</span>
                          </Col> 
                          <Col lg={8} md={6} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12"> 
                              <FieldText  
                                name="NomCortoCuponDescuento"
                                value={NomCortoCuponDescuento}  
                                errors={errors}
                                onChange={onChangeNomCortoCuponDescuento}
                              /> 
                            </div>
                          </Col> 
                        </Row>   
                        <Row>
                          <Col lg={4} md={6} sm={12} xs={12}>
                            <span className="bold"> {t('cupones:Descripcion')}</span>
                          </Col> 
                          <Col lg={8} md={6} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12"> 
                              <FieldTextArea
                                name={t('cupones:Descripcion')}
                                value={Descripcion} 
                                onChange={onChangeDescripcion}   
                                rows={4}
                                cols={30}
                                autoResize
                                errors={errors}
                              />
                            </div>
                          </Col> 
                        </Row>  
                      </Panel>
                      <br></br>
                      <hr></hr>
                      {
                        ClaTipoCupon=== 1 ? (
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:CodigoPromocion')}</span>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <Row className="align-items-start"> 
                                  <Col lg={8} md={8} sm={12} xs={12}>
                                    <div className="p-fluid p-field p-col-12"> 
                                      <FieldText  
                                        name="CodigoPromocion"
                                        value={CodigoPromocion}  
                                        errors={errors}
                                        onChange={onChangeCodigoPromocion}
                                      />   
                                      <br></br>
                                      <small>{t('cupones:GenerarEtiqueta')}</small>
                                    </div>
                                  </Col>
                                  <Col lg={4} md={4} sm={12} xs={12}>
                                    <div className="p-fluid p-field p-col-12"> 
                                      <Button
                                        label={t('common:Generar')}
                                        className="p-button-rounded p-button-success p-ml-2 padding10 success-gradient"
                                        onClick={generar}
                                      />
                                    </div>
                                  </Col>
                                </Row> 
                              </Col>  
                            </Row>
                          </>
                        ):(
                          <>
                          </>
                        )
                      } 
                      <hr></hr>
                      <Row>
                        <Col lg={4} md={6} sm={12} xs={12}>
                          <span className="bold"> {t('cupones:AplicarTipoDescuento')}</span>
                        </Col> 
                        <Col lg={8} md={6} sm={12} xs={12}>
                          <div className="p-fluid p-field"> 
                            {
                            TipoDescuento.map((item) => {
                            return ( 
                              <div key={item.ClaTipoDescuento}>
                                <FieldRadioButton
                                  inputId={`tipoDescuento-${item.ClaTipoDescuento}`}
                                  name="ClaTipoDescuento" 
                                  value={item.ClaTipoDescuento}
                                  label={item.NomTipoDescuento}
                                  onChange={(value) => onChangeTipoDescuento(value)} 
                                  checked={ClaTipoDescuento === item.ClaTipoDescuento}
                                  errors={errors}
                                >
                                </FieldRadioButton>
                              </div> 
                            )
                          })
                          } 
                          </div>
                        </Col> 
                      </Row>  
                      <Row hidden={ClaTipoDescuento===undefined}>
                        <Col lg={4} md={6} sm={12} xs={12}>
                      &nbsp;
                        </Col>
                        <Col lg={8} md={6} sm={12} xs={12}>
                          {
                          ClaTipoDescuento=== 1? (
                            <>
                              <div className="p-fluid p-field p-col-6"> 
                                <FieldNumber
                                  isInputGroup
                                  classGroup="pi pi-percentage"
                                  name="Descuento"
                                  value={Descuento} 
                                  onChange={onChangeDescuento}
                                  mode="decimal"
                                  errors={errors}
                                />
                              </div>
                            </>
                          ):(
                            <>
                              <div className="p-fluid p-field p-col-12"> 
                                <FieldNumber
                                  isInputGroup
                                  classGroup="pi pi-dollar"
                                  name="Descuento"
                                  value={Descuento} 
                                  onChange={onChangeDescuento}
                                  mode="decimal"
                                  errors={errors}
                                />
                              </div>
                            </>
                          )
                        }
                          <Row>
                            <Col>
                              <small>{t('cupones:EtiquetaAplicaDescuento')}</small> 
                            </Col>
                          </Row>
                        </Col>
                      </Row> 
                      <hr></hr>
                      {
                        ClaTipoCupon=== 1 ? (
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:EsExcluirProductoConDescuentoEtiqueta')}</span>
                              </Col>
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <FieldSelectButton 
                                  name="EsExcluirProductoConDescuento"
                                  value={EsExcluirProductoConDescuento} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onChangeEsExcluirProductoConDescuento(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"
                                />  
                              </Col> 
                            </Row>
                          </>
                        ):(<></>)
                      } 
                    </div>  
                  </CardBody>
                ):
                (
                  <></>
                )
              }
              {
                showStep2 ?
                (
                  <CardBody>
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold"> {t('cupones:Valido')}</span>
                        </Col> 
                        <Col lg={8} md={8} sm={12} xs={12}>
                          <div className="p-fluid p-field p-col-12">
                            <Calendar
                              id="range" 
                              value={rangoFechaExpiracion}  
                              onChange={(e) => onChangeFechaExpiracion(e.value)} 
                              selectionMode="range"
                              readOnlyInput
                            />
                          </div>
                        </Col>  
                      </Row>  
                      {
                        ClaTipoCupon=== 1 ? (
                          <>
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:CompraMinima')}</span>
                              </Col> 
                              <Col lg={8} md={8} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldNumber
                                    isInputGroup
                                    classGroup="pi pi-dollar"
                                    name="CompraMinima"
                                    value={CompraMinima} 
                                    onChange={onChangeCompraMinima}
                                    mode="decimal"
                                    errors={errors}
                                  />
                                </div>
                              </Col>  
                            </Row>  
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:TotalDisponiblePorCliente')}</span>
                              </Col> 
                              <Col>
                                <div className="p-fluid p-field p-col-6"> 
                                  <FieldNumber
                                    showButtons
                                    name="TotalDisponiblePorCliente"
                                    value={TotalDisponiblePorCliente} 
                                    onChange={onChangeTotalDisponiblePorCliente}
                                    mode="decimal"
                                    errors={errors}
                                  />
                                </div>
                              </Col>  
                            </Row>  
                            <div>
                              <hr></hr>
                            </div>
                          </>
                        ):(
                          <>
                          </>
                        )
                      }
                      
                      <Panel header={t('common:Ingles')} toggleable>
                        {
                        EsMostrarImagenIngles && ImagenCuponBase64Ingles!=null ? (
                          <>
                            <Row> 
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:PromotionPicture')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('cupones:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('cupones:RecommendedSizeValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className='pb-2 pt-2'>
                                  <Card className="product-card-category">
                                    <div className='text-right'>
                                      <i className="fa fa-minus-circle danger-color Cursor" onClick={removerImagenIngles}></i>
                                    </div>
                                    <div className='product-card-category-image-container'>
                                      <div className='product-card-category-image-container-content'>
                                        <img alt={NomArchivoIngles} src={ImagenCuponBase64Ingles} role="presentation" width="50%" />
                                      </div>
                                    </div>
                                  </Card> 
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        ):(
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:PromotionPicture')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('cupones:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('cupones:RecommendedSizeValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldUpload 
                                    files={datafilesIngles} 
                                    onChange={onChangeUploadIngles}
                                    isRequired={true}
                                  >
                                  </FieldUpload>
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        )
                      }
                        {
                        EsMostrarImagenMovilIngles && ImagenCuponBase64MovilIngles!=null ? (
                          <>
                            <Row> 
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:PromotionPictureMovil')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('cupones:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('cupones:RecommendedSizeValueMovil')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className='pb-2 pt-2'>
                                  <Card className="product-card-category">
                                    <div className='text-right'>
                                      <i className="fa fa-minus-circle danger-color Cursor" onClick={removerImagenMovilIngles}></i>
                                    </div>
                                    <div className='product-card-category-image-container'>
                                      <div className='product-card-category-image-container-content'>
                                        <img alt={NomArchivoMovilIngles} src={ImagenCuponBase64MovilIngles} role="presentation" width="50%" />
                                      </div>
                                    </div>
                                  </Card> 
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        ):(
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:PromotionPictureMovil')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('cupones:RecommendedSizeMovil')}
                                    <div>
                                      <small className='text-center'>
                                        {t('cupones:RecommendedSizeValueMovil')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldUpload 
                                    files={datafilesMovilIngles} 
                                    onChange={onChangeUploadMovilIngles}
                                    isRequired={true}
                                  >
                                  </FieldUpload>
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        )
                      }
                      </Panel>
                      <br></br>
                      <Panel header={t('common:Espanol')} toggleable>
                        {
                        EsMostrarImagen && ImagenCuponBase64!=null ? (
                          <>
                            <Row> 
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:PromotionPicture')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('cupones:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('cupones:RecommendedSizeValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className='pb-2 pt-2'>
                                  <Card className="product-card-category">
                                    <div className='text-right'>
                                      <i className="fa fa-minus-circle danger-color Cursor" onClick={removerImagen}></i>
                                    </div>
                                    <div className='product-card-category-image-container'>
                                      <div className='product-card-category-image-container-content'>
                                        <img alt={NomArchivo} src={ImagenCuponBase64} role="presentation" width="50%" />
                                      </div>
                                    </div>
                                  </Card> 
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        ):(
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:PromotionPicture')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('cupones:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('cupones:RecommendedSizeValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldUpload 
                                    files={datafiles} 
                                    onChange={onChangeUpload}
                                    isRequired={true}
                                  >
                                  </FieldUpload>
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        )
                      }
                        {
                        EsMostrarImagenMovil && ImagenCuponBase64Movil!=null ? (
                          <>
                            <Row> 
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:PromotionPictureMovil')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('cupones:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('cupones:RecommendedSizeValueMovil')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className='pb-2 pt-2'>
                                  <Card className="product-card-category">
                                    <div className='text-right'>
                                      <i className="fa fa-minus-circle danger-color Cursor" onClick={removerImagenMovil}></i>
                                    </div>
                                    <div className='product-card-category-image-container'>
                                      <div className='product-card-category-image-container-content'>
                                        <img alt={NomArchivoMovil} src={ImagenCuponBase64Movil} role="presentation" width="50%" />
                                      </div>
                                    </div>
                                  </Card> 
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        ):(
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('cupones:PromotionPictureMovil')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('cupones:RecommendedSizeMovil')}
                                    <div>
                                      <small className='text-center'>
                                        {t('cupones:RecommendedSizeValueMovil')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldUpload 
                                    files={datafilesMovil} 
                                    onChange={onChangeUploadMovil}
                                    isRequired={true}
                                  >
                                  </FieldUpload>
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        )
                      }
                      </Panel>
                    </div>
                  </CardBody>
                ):
                (<></>)
              }
              {
                showStep3 ?
                (
                  <CardBody>
                    
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold">{t('cupones:EtiquetaEsLimitarPorTienda')}</span>
                        </Col> 
                        <Col>
                          <div className="p-field">
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <FieldSelectButton 
                                  name="EsLimitarPorTienda"
                                  value={EsLimitarPorTienda} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onChangeEsLimitarPorTienda(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"
                                /> 
                              </Col>
                            </Row>
                          </div>
                        </Col> 
                      </Row> 
                    </div>
                    <div hidden={!EsLimitarPorTienda}>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                          &nbsp;
                          </Col>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <span>{t('cupones:EtiquetaHabilitarCuponesPorTienda')}</span>
                            <Row>
                              <Col>    
                                <FieldDataTable 
                                  value={tiendaRowsGrid}
                                  header={t('cupones:Tienda')} 
                                  editMode="row" 
                                  className="editable-cells-table"
                                  onRowEditInit={onTiendaRowEditInit} 
                                  onRowEditCancel={onTiendaRowEditCancel}
                                >
                                  <Column field="NomTienda" header={t('cupones:Nombre')} columnKey="Tienda"></Column> 
                                  <Column field="EsSeleccionado" header={t('common:Seleccionar')} body={seleccionarBodyTemplate} editor={(props) => seleccionarTiendaEditor(props)}></Column>
                                  <Column rowEditor bodyStyle={{ textAlign: 'center' }}></Column>
                                </FieldDataTable>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={3} md={3} sm={3} xs={3}>&nbsp;</Col>
                        </Row> 
                      </div>
                    </div> 
                    <div>
                      <hr></hr>
                    </div>
                    <Row>&nbsp;</Row>
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold">{t('cupones:EsLimitarPorCliente')}</span> 
                        </Col> 
                        <Col lg={8} md={8} sm={12} xs={12}>
                          <div className="p-field"> 
                            <FieldSelectButton
                              name="EsLimitarPorCliente"
                              value={EsLimitarPorCliente} 
                              options={OpcionesYesNo} 
                              onChange={(e) => onChangeEsLimitarPorCliente(e.value)} 
                              optionValue="value"
                              optionLabel="name"
                            />  
                          </div> 
                        </Col> 
                      </Row> 
                    </div>
                    <div hidden={!EsLimitarPorCliente}>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                          &nbsp;
                          </Col>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <span>{t('cupones:EtiquetaEsLimitarPorCliente')}</span>
                            <Row>
                              <Col>    
                                <span className="p-fluid">
                                  <AutoComplete
                                    value={Clientes} 
                                    suggestions={FiltroCliente} 
                                    completeMethod={busquedaCliente} 
                                    field="NomCliente"
                                    multiple
                                    onChange={(e) => onchangeCliente(e)}
                                    itemTemplate={itemTemplateCliente}
                                    className='cssAutocomplete'
                                  /> 
                                </span> 
                                <Row className='pt-2'>
                                  <Col>
                                    <small>{t('cupones:EtiquetaNotaEsLimitarPorCliente')}</small>
                                  </Col>
                                </Row>
                              </Col>
                            </Row> 
                          </Col>
                        </Row> 
                      </div>
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                    <Row>&nbsp;</Row>
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold">{t('cupones:EtiquetaEsLimitarPorCategoriaCliente')}</span>
                        </Col> 
                        <Col>
                          <div className="p-field">
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <FieldSelectButton 
                                  name="EsLimitarPorCategoriaCliente"
                                  value={EsLimitarPorCategoriaCliente} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onChangeEsLimitarPorCategoriaCliente(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"
                                /> 
                              </Col>
                            </Row>
                          </div>
                        </Col> 
                      </Row> 
                    </div>
                    <div hidden={!EsLimitarPorCategoriaCliente}>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                          &nbsp;
                          </Col>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <span>{t('recompensas:EtiquetaHabilitarCampaniaPorCategoriaCliente')}</span>
                            <Row>
                              <Col>    
                                <FieldDataTable 
                                  value={categoriaRowsGrid}
                                  header={t('recompensas:Categoria')} 
                                  editMode="row" 
                                  className="editable-cells-table"
                                  onRowEditInit={onCategoriaRowEditInit} 
                                  onRowEditCancel={onCategoriaRowEditCancel}
                                >
                                  <Column field="NomCategoria" header={t('recompensas:Nombre')} columnKey="Categoria"></Column> 
                                  <Column field="EsSeleccionado" header={t('common:Seleccionar')} body={seleccionarBodyTemplate} editor={(props) => seleccionarCategoriaEditor(props)}></Column>
                                  <Column rowEditor bodyStyle={{ textAlign: 'center' }}></Column>
                                </FieldDataTable>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={3} md={3} sm={3} xs={3}>&nbsp;</Col>
                        </Row> 
                      </div>
                    </div> 
                    <div>
                      <hr></hr>
                    </div> 
                    <Row>&nbsp;</Row>
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold">{t('cupones:EtiquetaEsLimitarPorCategoriaProducto')}</span>
                        </Col> 
                        <Col lg={8} md={8} sm={12} xs={12}>
                          <div className="p-field">
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <FieldSelectButton 
                                  name="EsLimitarPorCategoriaProducto"
                                  value={EsLimitarPorCategoriaProducto} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onChangeEsLimitarPorCategoriaProducto(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"
                                /> 
                              </Col>
                            </Row>
                          </div> 
                        </Col> 
                      </Row> 
                    </div>
                    <div hidden={!EsLimitarPorCategoriaProducto}>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                          &nbsp;
                          </Col>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <span>{t('recompensas:EtiquetaHabilitarCampaniaPorCategoriaProducto')}</span>
                            <Row>
                              <Col>     
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldTreeviewButton
                                    nodes={nodes} 
                                    showAsTreeSelect={true} 
                                    name="SelectedCategoria"
                                    optionLabel="label" 
                                    expandedKeys={expandedKeys} 
                                    nodeTemplate={nodeTemplate} 
                                    icon="pi pi-users"
                                    selectedKey={SelectedCategoria}
                                    selectionMode="checkbox"
                                    onChange={onChange}
                                    onClick={onClick}
                                    errors={errors}
                                  >
                                  </FieldTreeviewButton>   
                                </div> 
                              </Col>
                            </Row>
                          </Col>
                        </Row> 
                      </div>
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold">{t('cupones:EsLimitarPorProducto')}</span>
                        </Col> 
                        <Col lg={8} md={8} sm={12} xs={12}>
                          <div className="p-field">
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <FieldSelectButton 
                                  name="EsLimitarPorProducto"
                                  value={EsLimitarPorProducto} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onChangeEsLimitarPorProducto(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"
                                /> 
                              </Col>
                            </Row>
                          </div> 
                        </Col> 
                      </Row> 
                    </div>
                    <div hidden={!EsLimitarPorProducto}>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={4} xs={4}>
                          &nbsp;
                          </Col>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <span>{t('cupones:EtiquetaEsLimitarPorProducto')}</span>
                            <Row>
                              <Col lg={12} md={12} sm={12} xs={12}>  
                                <span className="p-fluid">
                                  <AutoComplete
                                    value={Productos} 
                                    suggestions={FiltroProducto} 
                                    completeMethod={busquedaProducto} 
                                    field="NomProducto"
                                    multiple 
                                    itemTemplate={itemTemplateProducto}
                                    onChange={(e) => onchangeProducto(e)}
                                  /> 
                                </span> 
                              </Col>
                            </Row>
                          </Col>
                        </Row> 
                      </div>
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                  </CardBody>  
                ):
                (
                  <></>
                )
              } 
            </Card>  
          </Col>
        </Row>
        <Row>
          <Col>
            {
                showStep4 ?
                (
                  <>
                    {
                      props.isModal ? 
                      (
                        <Dialog
                          visible={EsDialogConfirmarCupon}
                          modal
                          maximized={true}
                          header={renderHeaderDialogConfirmarCupon}
                          onHide={() => onCloseDialogConfirmarCupon()}
                          blockScroll={true}
                        >
                          {printStep4()}
                        </Dialog>
                      ):
                      (
                        <>
                          {printStep4()}
                        </>
                      )
                    }
                  </>
                ):
                (
                  <></>
                )
              } 
          </Col>
        </Row>
      </div>
    </>
);
};
export default CuponesDescuentoDetalle;
