import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import RecompensasCampaniaLista from './RecompensasCampaniaLista.jsx' 
import RecompensasCampaniaDetalle from './RecompensasCampaniaDetalle.jsx' 
import PageContent from '../../layouts/PageContent'; 
// START COMPONENT
const RecompensasCampania = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [EsListadoCampania, setEsListadoCampania] = useState(true); 
  const [Filters, setFilters] = useState({});  

  useEffect(() => {
    const filtersData = {};
    filtersData.IdCampania = 0;
    filtersData.EsListadoCampania = EsListadoCampania;
    setFilters(filtersData); 

  }, [props.filters]);
 
const onAfterSave = () => {  
   setEsListadoCampania(true);
   setFilters({ ...Filters, EsListadoCampania : true});
}

const onAfterNew = () => {  
  setEsListadoCampania(false);
  setFilters({ ...Filters, EsListadoCampania : false, IdCampania: 0});
}

const onAfterReturn = () => {  
  setEsListadoCampania(true);
  setFilters({ ...Filters, EsListadoCampania : true});
}

const onAfterSelect = (data) => {  
  setEsListadoCampania(false);
  setFilters({ ...Filters, EsListadoCampania : false, IdCampania: data.IdCampania, isDuplicate: data.isDuplicate});
}
 
  return (
    <>
      <PageContent title={t('recompensas:Campania')}>
         
        {
          EsListadoCampania ?
          (
            <RecompensasCampaniaLista onAfterNew={onAfterNew} onAfterSelect={onAfterSelect} filters={Filters} />)
          :
          (<></>)
        } 
        <div hidden={EsListadoCampania}>
          <RecompensasCampaniaDetalle isHidden={false} isModal={true} onAfterSave={onAfterSave} onAfterReturn={onAfterReturn} filters={Filters} />
        </div> 
      </PageContent>
    </>
);
};
export default RecompensasCampania;
