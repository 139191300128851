import React, { useState, useEffect, useRef } from 'react';
import {Card,CardBody,Row,Col}        from 'reactstrap'; 
import { DataTable }                  from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel'; 
import { Button } from 'primereact/button';
import FieldText from '../../components/Controls/FieldText.jsx' 
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx'
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'
import Highcharts from 'highcharts'; 
import FieldButton from 'components/Controls/FieldButton/FieldButton';

// START COMPONENT
const EstadoDeCuentaListado = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [rowsGrid, setRowsGrid] = useState([]);  
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [Filters, setFilters] = useState({}); 
  const [ClaCliente, setClaCliente] = useState(null);
  const [CategoriasLista, setCategoriasLista] = useState([]);
  const [ClaCategoriaFiltro, setClaCategoriaFiltro] = useState(null);
  const [DescripcionFiltro, setDescripcionFiltro] = useState('');

  useEffect(() => {
    onCategoria();
  }, [props.filters]);

  const onGridClientes = () => {
        const paramsToService = {
          ClaCliente:0,
          ClaEmpresa,
          NombreCliente:DescripcionFiltro,
          ClaCategoria:ClaCategoriaFiltro
        }
        const GetClienteGridService = `${config.UrlApiProject}Cliente/GetCliente`;
        callApi(GetClienteGridService, 'POST', paramsToService, (response) => { 
            setRowsGrid(response.data.Clientes);
        });    
  };

  const onCategoria = () => {
    const paramsToService = {
      ClaCategoria:null,
      ClaEmpresa
    }
    const GetCategpriaGridService = `${config.UrlApiProject}Categoria/GetCategoria`;
    callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
      setCategoriasLista(response.data.Categorias); 
        onGridClientes(); 
    });    
};

  const onDetalleCliente = (rowData) => {
    console.log('onDetalleCliente');
    console.log(rowData);
    if (props.onAfterSelect !=  null){ 
      props.onAfterSelect(rowData);
    }
  };

  const verBodyTemplate = (rowData) => {
    return (
      <>
        <Button onClick={(e) => onDetalleCliente(rowData)} icon="pi pi-eye" className="p-button-rounded p-button-info" />
      </>
      ); 
  } 
  
  const ExportExcelAccounts = () => {  
    const paramsToService = {
      ClaCliente:0,
      ClaEmpresa,
      NombreCliente:DescripcionFiltro,
      ClaCategoria:ClaCategoriaFiltro
    }
    const ExportarClientes = `${config.UrlApiProject}Cliente/ExportarClientes`;
    callApi(ExportarClientes, 'POST', paramsToService, (response) => { 
      const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
      const downloadLink = document.createElement("a");
      const fileName = `${response.data.FileName}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName  }.${  response.data.FileExtension}`;
      downloadLink.click();
    });     
  };

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={`card-header danger collapsed-header${  className}`}>
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={`${toggleIcon  } light-color`}></span>
                <Ripple />
              </button>
              &nbsp;
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div> 
          <div className="p-toolbar-group-right"> 
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={onGridClientes}>
              <i className="pi pi-search"></i>
            </button>
          </div>
        </div> 
      </div>
    )
}

const onChangeDescripcionFiltro  = (e) => {  
  setDescripcionFiltro(e.target.value);
} 

const onChangeCategoriaFiltro = (value) => {  
  setClaCategoriaFiltro(value);
}  

const ColumnPointAvailableTemplate = (rowData) => {
  return (
    <>
      { rowData.PuntosDisponibles !== null ? Highcharts.numberFormat(rowData.PuntosDisponibles,0,'.',',') : rowData.PuntosDisponibles }
    </>
  );
}

const headerCustomers = (   
  <Row>
    <Col lg={6} md={6} sm={6} xs={6}>
      {t('recompensas:Clientes')}
    </Col>
    <Col lg={6} md={6} sm={6} xs={6} className='text-right'>
      <FieldButton label={t('misActividades:Export')} icon="fa fa-file-excel" className="success no-border" onClick={ExportExcelAccounts}>              
      </FieldButton>
    </Col>
  </Row>
  
 ) 

return (
  <>
    <Row>
      <Col lg={12} md={12} sm={12} xs={12}>  
        <Panel className="panel-radius" header="Filters" toggleable headerTemplate={filterHeaderTemplate}> 
          <div className="row">
            <Row>&nbsp;</Row>
          </div>
          <div className="p-fluid p-grid">
            <div className="p-grid p-field p-col-12"> 
              <div className="p-field col-lg-3">
                <FieldText  
                  name="DescripcionFiltro"
                  value={DescripcionFiltro}  
                  onChange={onChangeDescripcionFiltro}
                  label={t('recompensas:Cliente')}
                /> 
              </div>
              <div className="p-field col-lg-3">
                <FieldDropdown  
                  name="ClaCategoria"
                  value={ClaCategoriaFiltro} 
                  options={CategoriasLista}  
                  optionValue="ClaCategoria"
                  optionLabel="NomCategoria"
                  onChange={(e) => onChangeCategoriaFiltro(e.value)}
                  label={t('recompensas:Categoria')}
                />    
              </div>
            </div>  
          </div> 
        </Panel>
        <p>&nbsp;</p>
      </Col>
      <Row><Col><p> &nbsp;</p></Col></Row>
      <Col lg={12} md={12} sm={12} xs={12}>
        <FieldDataTable
          value={rowsGrid}
          header={headerCustomers}
          scrollable 
          className="p-datatable-striped"  
          selectionMode="single"  
          metaKeySelection={false}
        >
          <Column field="NombreCompleto" header={t('recompensas:Nombre')} columnKey="NombreCompleto" headerStyle={{ width: '150px' }}></Column> 
          <Column field="Categoria" header={t('recompensas:Categoria')} columnKey="Categoria" headerStyle={{ width: '90px' }}></Column> 
          <Column field="CorreoElectronico" header={t('recompensas:Correo')} columnKey="CorreoElectronico" headerStyle={{ width: '90px' }}></Column>  
          <Column field="PuntosDisponibles" body={ColumnPointAvailableTemplate} header={t('recompensas:PuntosDisponibles')} columnKey="PuntosDisponibles" style={{ textAlign: 'center' }} headerStyle={{ width: '100px', textAlign: 'center' }}></Column>  
          <Column body={verBodyTemplate} style={{ textAlign: 'center' }} headerStyle={{ width: '100px' }}></Column>
        </FieldDataTable>   
      </Col>
    </Row>
  </>
);
};
export default EstadoDeCuentaListado;
