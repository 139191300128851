import React, { useState, useEffect, useRef } from 'react';
import {Card,CardBody,CardHeader,Row,Col}        from 'reactstrap'; 
import { DataTable }                  from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import RecompensasCampaniaDetalle from "./RecompensasCampaniaDetalle.jsx"
import Highcharts from 'highcharts';
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'

// START COMPONENT
const RecompensasCampaniaAlcance = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [rowsGrid, setRowsGrid] = useState([]);  
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [Filters, setFilters] = useState({}); 
  const dt = useRef(null); 

  useEffect(() => {
    onGridAlcance(); 
  }, [props.filters]);

  const onGridAlcance = () => { 
        const paramsToService = {
          IdCampania:(props.filters.IdCampania !== undefined ? props.filters.IdCampania : null),
          ClaEmpresa
        }
        const GetCategpriaGridService = `${config.UrlApiProject}CampaniaRecompensa/GetCampaniaAlcance`;
        callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
          setFilters({ ...Filters, EsListadoCampania : false, IdCampania: props.filters.IdCampania !== undefined ? props.filters.IdCampania : null});  
          setRowsGrid(response.data.CampaniaAlcance);
        });    
  };

  const onExportGrid = () => { 
    dt.current.exportCSV();
  }

  const clienteColumnaInfo = (rowData) => {
    return (
      <> 
        <Row className="border-bottom paddignBottom5">
          <Col lg={1} md={1} sm={1} xs={1}>
            {rowData.EsBeneficiado ? <i className="pi pi-check-circle success-color"></i> : <></>}
          </Col>
          <Col>
            <Row className="align-items-start paddignTop5">
              <Col className="bold">
                {rowData.NombreCompleto}
              </Col>
            </Row> 
            <Row className="align-items-start paddignTop5">
              <Col>
                <small>{rowData.CorreoElectronico}</small> 
              </Col>
            </Row> 
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}>
            {
              rowData.EsBeneficiado ? (
                <> 
                  <div className="text-center">
                    <Row className="paddignBottom10 orange-color">
                      <Col>
                        <span>{Highcharts.numberFormat(rowData.PuntosBeneficiado,0,'.',',')}</span> &nbsp;
                        <small>{t('recompensas:Puntos')}</small>
                      </Col>
                    </Row>
                    <Row className="paddignBottom5 info-color">
                      <Col> 
                        <span>${Highcharts.numberFormat(rowData.MontoPuntosBeneficiado,2,'.',',')}</span> &nbsp;
                        <small>{t('recompensas:Importe')}</small>
                      </Col>
                    </Row>
                  </div>
                </>
              )
              : <></>
              }
          </Col>
        </Row>
      </>
    );
  }
  
  return (
    <>  
      <Row className="align-items-start">
        <Col lg={6} md={6} sm={12} xs={12}>
          <RecompensasCampaniaDetalle isShowSummary={true} filters={Filters} />
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <Card className="radiuscard">
            <CardHeader className="default-light">
              <Row>
                <Col className="text-right">
                  <button
                    type="button" 
                    className="p-button p-button-rounded p-button-icon-only p-button-secondary"
                    onClick={onExportGrid}
                  >
                    <i className="pi pi-download"></i>
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>    
              <FieldDataTable
                reference={dt} 
                value={rowsGrid}
                className="datatable-custom"  
                selectionMode="single"  
                metaKeySelection={false}
              >
                <Column field="NombreCompleto" header={t('recompensas:Nombre')} columnKey="NombreCompleto" style={{ display :'none' }} headerStyle={{ width: '200px', display :'none' }}></Column> 
                <Column field="CorreoElectronico" header={t('recompensas:Correo')} columnKey="CorreoElectronico" style={{ display :'none' }} headerStyle={{ width: '200px', display :'none' }}></Column> 
                <Column body={clienteColumnaInfo} headerStyle={{ width: '220px' }}></Column> 
              </FieldDataTable>   
            </CardBody> 
          </Card> 
         
        </Col> 
      </Row>
    </>
);
};
export default RecompensasCampaniaAlcance;
