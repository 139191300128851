const config = {
  LoginMensaje: 'Powered by RWP',
  LoginLogoTamanio: '48px',
  ApiKey: 'ApiKey para Kraken',
  
  // LOCAL
  // UrlLoginServer: 'https://sweetsrv.azurewebsites.net/LoginSandbox',
  // UrlApiProject: 'http://localhost:4271/',
  // Site: 0,
  
  // QA
  // UrlLoginServer: 'https://sweetsrv.azurewebsites.net/Login',
  // UrlApiProject: 'https://rwpapi.sandbox.cranepointllc.com/',
  // cdSite: 0,
  
  // PRODUCCION
  UrlLoginServer: 'https://sweetsrv.azurewebsites.net/Login',
  UrlApiProject: 'https://rwpapi.cranepointllc.com/',
  Site: 1,

  // ** Kraken Produccion
  KrakenService: 'http://10.1.1.50:2022/KrakenServices',
  
  
  // Debugging mode
  DebuggingMode: true,
};

function GetToken() {
  return sessionStorage.getItem('Token');
}

export { config, GetToken };
