import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import EstadoDeCuentaListado from './EstadoDeCuentaListado.jsx'   
import EstadoDeCuentaDetalle from './EstadoDeCuentaDetalle.jsx'  
import PageContent from '../../layouts/PageContent'; 
// START COMPONENT
const EstadoDeCuenta = (props) => { 
  const { t } = useTranslation(['recompensas','formulario','common']);
  const [EsListadoEstadoCuenta, setEsListadoEstadoCuenta] = useState(true);
  const [EsRecompensas, setEsRecompensas] = useState(false); 
  const [Filters, setFilters] = useState({});  
  const [Titulo, setTitulo] = useState(t('recompensas:EstadoDeCuenta'));  

  useEffect(() => {
    const filtersData = {};
    filtersData.ClaCliente = 0;
    filtersData.EsListadoEstadoCuenta = EsListadoEstadoCuenta;
    setFilters(filtersData); 
  }, [props.filters]);
 
const onAfterSave = () => {  
   setEsListadoEstadoCuenta(true);
   setFilters({ ...Filters, EsListadoEstadoCuenta : true});
}

const onAfterNew = () => {  
  setEsListadoEstadoCuenta(false);
  setFilters({ ...Filters, EsListadoEstadoCuenta : false, IdCampania: 0});
}

const onAfterReturn = () => {  
  setEsListadoEstadoCuenta(true);
  setFilters({ ...Filters, EsListadoEstadoCuenta : true});
}

const onAfterRecompensas = () => {  
  setEsListadoEstadoCuenta(true);
  setFilters({ ...Filters, EsListadoEstadoCuenta : true});
}

const onAfterSelect = (data) => {  
  setEsListadoEstadoCuenta(false);
  setFilters({ ...Filters, EsListadoEstadoCuenta : false, ClaCliente: data.ClaCliente});
}
 
  return (
    <>
      <PageContent title={Titulo}>
        {
          EsListadoEstadoCuenta ? 
          (
            <EstadoDeCuentaListado onAfterNew={onAfterNew} onAfterSelect={onAfterSelect} filters={Filters} />
          ):
          (
            <EstadoDeCuentaDetalle onAfterSave={onAfterSave} onAfterReturn={onAfterReturn} onAfterRecompensas={onAfterRecompensas} filters={Filters} />
          )
        }
      </PageContent>
    </>
);
};
export default EstadoDeCuenta;
