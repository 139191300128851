import React, { useState, useEffect, useRef } from 'react';
import {Card,CardBody,CardHeader,Row,Col}        from 'reactstrap'; 
import { DataTable }                  from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import FieldText from '../../components/Controls/FieldText.jsx' 
import FieldNumber from '../../components/Controls/FieldNumber.jsx' 
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator'; 
import { Panel } from 'primereact/panel';
import {Steps} from 'primereact/steps';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar'; 
import { AutoComplete } from 'primereact/autocomplete'; 
import { Dialog } from 'primereact/dialog';
import moment from 'moment';  
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'
import FieldSelectButton from '../../components/Controls/FieldSelectButton.jsx'
import FieldTextArea from '../../components/Controls/FieldTextArea.jsx'
import FieldUpload from '../../components/Controls/FieldUpload.jsx';
import FieldTreeviewButton from '../../components/Controls/FieldTreeviewButton.jsx';

// START COMPONENT
const RecompensasCampania = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [errors, setErrors] = useState({});   
  const [IdCampania, setIdCampania] = useState(props.filters === undefined ? 0 : props.filters.IdCampania);
  const [NomCampania, setNomCampania] = useState(null);  
  const [NomCortoCampaniaRecompensa, setNomCortoCampaniaRecompensa] = useState(null);  
  const [ImagenCampaniaRecompensa, setImagenCampaniaRecompensa] = useState(null); 
  const [NomArchivo, setNomArchivo] = useState(null);  
  const [Extension, setExtension] = useState(null);  
  const [EsMostrarImagen, setEsMostrarImagen] = useState(false); 
  const [ImagenCampaniaRecompensaBase64, setImagenCampaniaRecompensaBase64] = useState(null);  
  const [datafiles, setdatafiles] = useState([]); 
  const [Descripcion, setDescripcion] = useState(null);  
  const [abrirNuevaCampania, setAbrirNuevaCampania] = useState(false); 
  const [activeIndex, setActiveIndex] = useState(0);
  const [showStep1, setShowStep1] = useState(true);
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [FactorBase,setFactorBase] = useState(null);
  const [EsLimitarPorCategoriaCliente, setEsLimitarPorCategoriaCliente] = useState(false);  
  const [EsLimitarPorProducto, setEsLimitarPorProducto] = useState(false);  
  const [EsLimitarPorCategoriaProducto, setEsLimitarPorCategoriaProducto] = useState(false);
  const [EsLimitarPorTienda, setEsLimitarPorTienda] = useState(false); 
  const [tiendaRowsGrid, setTiendaRowsGrid] = useState([]);  
  const [originalTiendaRows, setOriginalTiendaRows] = useState([]);   
  const [categoriaRowsGrid, setCategoriaRowsGrid] = useState([]);  
  const [originalCategoriaRows, setOriginalCategoriaRows] = useState([]);  
  const [rangoFechaExpiracion, setRangoFechaExpiracion] = useState([]);
  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [FiltroProductoCategoria, setFiltroProductoCategoria] = useState([]);
  const [ClienteCategoria, setClienteCategoria] = useState([]);
  const [Tiendas, setTiendas] = useState([]);
  const [ProductosCategoria, setProductosCategoria] = useState([]);
  const [Productos, setProductos] = useState([]);
  const [FiltroProducto, setFiltroProducto] = useState([]);
  const [OpcionesYesNo, setOpcionesYesNo] = useState([{ value: true, name: t('common:Si')}, { value: false, name: t('common:No') }]);
  const [EjemploMontoDeCompra1, setEjemploMontoDeCompra1] = useState(null);
  const [EjemploMontoDeCompra2, setEjemploMontoDeCompra2] = useState(null);
  const [FactorBaseConfiguracion,setFactorBaseConfiguracion] = useState(null);
  const [EjemploMontoDeCompraPuntos1, setEjemploMontoDeCompraPuntos1] = useState(null);
  const [EjemploMontoDeCompraPuntos2, setEjemploMontoDeCompraPuntos2] = useState(null);
  const [EjemploCostoDeCompraPuntos1, setEjemploCostoDeCompraPuntos1] = useState(null);
  const [EjemploCostoDeCompraPuntos2, setEjemploCostoDeCompraPuntos2] = useState(null);
  const [ImportePorPuntos, setImportePorPuntos] = useState(null); 
  const [nodes, setNodes] = useState([]);
  const [Categorias, setCategorias] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({}); 
  const [SelectedCategoria, setSelectedCategoria] = useState(0); 
  const [EsDialogConfirmarCampania, setEsDialogConfirmarCampania] = useState(false);
  const [ImagenCampaniaRecompensaMovil, setImagenCampaniaRecompensaMovil] = useState(null); 
  const [NomArchivoMovil, setNomArchivoMovil] = useState(null);  
  const [ExtensionMovil, setExtensionMovil] = useState(null);   
  const [EsMostrarImagenMovil, setEsMostrarImagenMovil] = useState(false); 
  const [ImagenCampaniaRecompensaBase64Movil, setImagenCampaniaRecompensaBase64Movil] = useState(null);  
  const [datafilesMovil, setdatafilesMovil] = useState([]);   

  const [NomCampaniaIngles, setNomCampaniaIngles] = useState(null);  
  const [NomCortoCampaniaRecompensaIngles, setNomCortoCampaniaRecompensaIngles] = useState(null);  
  const [ImagenCampaniaRecompensaIngles, setImagenCampaniaRecompensaIngles] = useState(null); 
  const [NomArchivoIngles, setNomArchivoIngles] = useState(null);  
  const [ExtensionIngles, setExtensionIngles] = useState(null);  
  const [EsMostrarImagenIngles, setEsMostrarImagenIngles] = useState(false); 
  const [ImagenCampaniaRecompensaBase64Ingles, setImagenCampaniaRecompensaBase64Ingles] = useState(null);  
  const [datafilesIngles, setdatafilesIngles] = useState([]); 
  const [DescripcionIngles, setDescripcionIngles] = useState(null);  
  const [ImagenCampaniaRecompensaMovilIngles, setImagenCampaniaRecompensaMovilIngles] = useState(null); 
  const [NomArchivoMovilIngles, setNomArchivoMovilIngles] = useState(null);  
  const [ExtensionMovilIngles, setExtensionMovilIngles] = useState(null);   
  const [EsMostrarImagenMovilIngles, setEsMostrarImagenMovilIngles] = useState(false); 
  const [ImagenCampaniaRecompensaBase64MovilIngles, setImagenCampaniaRecompensaBase64MovilIngles] = useState(null);  
  const [datafilesMovilIngles, setdatafilesMovilIngles] = useState([]);  

  const nodeTemplate = (node, options) => { 
    console.log('nodeTemplate',node,options)
    return (
      <span className={options.className}>
        {node.label}
      </span>
    )
  }

  useEffect(() => {
    const iCampania = (props.filters === undefined ? 0 : props.filters.IdCampania);
    const iEsDialogConfirmarCampania = (props.visible === undefined ? false : props.visible);
    setIdCampania(iCampania);
    setEsDialogConfirmarCampania(iEsDialogConfirmarCampania);
    if(iCampania>0){
      onDetalleCampania(props.filters);
    }else{
      resetCampania();
      DataConfiguration();
    }
    getCategoryList();
  }, [props.filters]);

  
  const onChangeEsLimitarPorCategoriaCliente = (e) => {  
    if(e===null){
      return;
    }
    setEsLimitarPorCategoriaCliente(e);  
  }; 
  const onChangeEsLimitarPorTienda = (e) => {  
    if(e===null){
      return;
    }
    setEsLimitarPorTienda(e);  
  }; 
  const onChangeEsLimitarPorCategoriaProducto = (e) => {
    if(e===null){
      return;
    } 
    setEsLimitarPorCategoriaProducto(e);  
  }; 
  const onChangeEsLimitarPorProducto = (value) => {  
    if(value===null){
      return;
    }
    setEsLimitarPorProducto(value);  
  }; 
     
  const onChangeFactorBase = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.FactorBase;   
    setErrors(newErrors);   
    setFactorBase(e.value);
    examplePoints(e.value,ImportePorPuntos);
  }
  
  const onChangeNomCampaniaIngles = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomCampaniaIngles;   
    setErrors(newErrors)  
    setNomCampaniaIngles(e.target.value)
  }

 const onChangeNomCortoCampaniaRecompensaIngles = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomCortoCampaniaRecompensaIngles;   
    setErrors(newErrors)  
    setNomCortoCampaniaRecompensaIngles(e.target.value)
  }

  const onChangeDescripcionIngles = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.DescripcionIngles;   
    setErrors(newErrors)    
    setDescripcionIngles(e.target.value)
  }


  const onChangeNomCampania = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomCampania;   
    setErrors(newErrors)  
    setNomCampania(e.target.value)
  }

 const onChangeNomCortoCampaniaRecompensa = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomCortoCampaniaRecompensa;   
    setErrors(newErrors)  
    setNomCortoCampaniaRecompensa(e.target.value)
  }

  const onChangeDescripcion = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Descripcion;   
    setErrors(newErrors)    
    setDescripcion(e.target.value)
  }


  const onChangeFechaExpiracion = (e) => {  
    if(e[0]!==null){
      setFechaInicial(moment(e[0]).format("LL")); 
    }
    if(e[1]!==null){
      setFechaFinal(moment(e[1]).format("LL"));
    }
    setRangoFechaExpiracion(e);
  }

  const onDetalleCampania = (rowData) => {
    const paramsService = {
      IdCampania:rowData.IdCampania,
      ClaEmpresa,
      Idioma:'',
      ClaCampaniaRecompensaEstatus:rowData.ClaCampaniaRecompensaEstatus
    }
    const urlWebServiceExpirationType = `${config.UrlApiProject}CampaniaRecompensa/GetCampania`; 
    callApi(urlWebServiceExpirationType, 'POST', paramsService, (response) => {
      const data = response.data.Campanias[0];
      const listaClienteCategoria = response.data.ClienteCategorias;
      const listaTiendas = response.data.Tiendas;
      const listaCategoriasProducto = response.data.CategoriasProductos;
      const listaProductos = response.data.Productos;
      if(data!== undefined){
        if(data.ImagenCampaniaRecompensa!=null){
          setEsMostrarImagen(true);
          const base64Icon = `data:image/${data.TipoExtension};base64,${data.ImagenCampaniaRecompensa}`;
          setImagenCampaniaRecompensaBase64(base64Icon);
        }
        if(data.ImagenCampaniaRecompensaMovil!=null){
          setEsMostrarImagenMovil(true);
          const base64IconMovil = `data:image/${data.TipoExtensionMovil};base64,${data.ImagenCampaniaRecompensaMovil}`;
          setImagenCampaniaRecompensaBase64Movil(base64IconMovil);
        }

        if(data.ImagenCampaniaRecompensaIngles!=null){
          setEsMostrarImagenIngles(true);
          const base64Icon = `data:image/${data.TipoExtensionIngles};base64,${data.ImagenCampaniaRecompensaIngles}`;
          setImagenCampaniaRecompensaBase64Ingles(base64Icon);
        }
        if(data.ImagenCampaniaRecompensaMovilIngles!=null){
          setEsMostrarImagenMovilIngles(true);
          const base64IconMovil = `data:image/${data.TipoExtensionMovilIngles};base64,${data.ImagenCampaniaRecompensaMovilIngles}`;
          setImagenCampaniaRecompensaBase64MovilIngles(base64IconMovil);
        }
        const rangoFecha = [];
        rangoFecha[0] = new Date(data.FechaInicioExpiracion);
        rangoFecha[1] = new Date(data.FechaFinalExpiracion);
        setIdCampania(data.IdCampania);  
        setNomCampania(data.NomCampania);
        setFactorBase(data.FactorBase);
        setImagenCampaniaRecompensa(data.ImagenCampaniaRecompensa); 
        setImagenCampaniaRecompensaMovil(data.ImagenCampaniaRecompensaMovil); 
        setNomArchivo(data.NomArchivo); 
        setExtension(data.Extension); 
        setRangoFechaExpiracion(rangoFecha);
        setNomCortoCampaniaRecompensa(data.NomCortoCampaniaRecompensa);
        setDescripcion(data.Descripcion);
        setEsLimitarPorCategoriaCliente(data.EsLimitarPorCategoriaCliente);  
        setEsLimitarPorProducto(data.EsLimitarPorProducto);  
        setEsLimitarPorCategoriaProducto(data.EsLimitarPorCategoriaProducto);
        setEsLimitarPorTienda(data.EsLimitarPorTienda);  
        setFechaInicial(moment(rangoFecha[0]).format("LL"));
        setFechaFinal(moment(rangoFecha[1]).format("LL")); 
        setClienteCategoria(listaClienteCategoria);
        setTiendas(listaTiendas);
        setCategoriaRowsGrid(listaClienteCategoria);
        setOriginalCategoriaRows(listaClienteCategoria); 
        setProductosCategoria(listaCategoriasProducto);
        const obj = {};
        for (const key of listaCategoriasProducto) {
             obj[key.ClaProductoCategoria] = {checked:true};
        }
        setSelectedCategoria(obj);
        setProductos(listaProductos); 
        setActiveIndex(0);
        setShowStep1(true);
        setShowStep2(false);
        setShowStep3(false);
        resetErrors();
        
        setNomArchivoMovil(data.NomArchivoMovil); 
        setExtensionMovil(data.ExtensionMovil); 


        setNomCampaniaIngles(data.NomCampaniaIngles); 
        setImagenCampaniaRecompensaIngles(data.ImagenCampaniaRecompensaIngles); 
        setImagenCampaniaRecompensaMovilIngles(data.ImagenCampaniaRecompensaMovilIngles); 
        setNomArchivoIngles(data.NomArchivoIngles); 
        setExtensionIngles(data.ExtensionIngles); 
        setNomCortoCampaniaRecompensaIngles(data.NomCortoCampaniaRecompensaIngles);
        setDescripcionIngles(data.DescripcionIngles);  
        setNomArchivoMovilIngles(data.NomArchivoMovilIngles); 
        setExtensionMovilIngles(data.ExtensionMovilIngles); 

        if (props.filters.isDuplicate != null){ 
          if(props.filters.isDuplicate){
            setIdCampania(0);  
            setNomCampania('');
            setNomCampaniaIngles('');
          }
        }
        if (props.isHidden != null){ 
          if(props.isHidden){
            setShowStep1(false);
            setShowStep2(false);
            setShowStep3(true);
            setActiveIndex(2);
          }
        }else{
          setAbrirNuevaCampania(true);
        }

        if (typeof(props.isShowSummary) !== 'undefined' && props.isShowSummary && props.isShowSummary !== null){
          setShowStep1(false);
          setShowStep2(false);
          setShowStep3(true);
          setActiveIndex(2);
          setAbrirNuevaCampania(false);
        }
      }
    });  
  };

  const onDetalleCampaniaCategoriaCliente = (rowData) => {
    const paramsService = {
      IdCampania:rowData.IdCampania,
      ClaEmpresa
    }
    const urlWebServiceExpirationType = `${config.UrlApiProject}CampaniaRecompensa/GetCampaniaCategoriaCliente`; 
    callApi(urlWebServiceExpirationType, 'POST', paramsService, (response) => {
      setCategoriaRowsGrid(response.data.CampaniaCategorias);
      setOriginalCategoriaRows(response.data.CampaniaCategorias); 
    });  
  };

  const DataConfiguration = () => {
    const paramsService = {
      ClaEmpresa
    }
    const GetCategpriaGridService = `${config.UrlApiProject}Configuracion/GetConfiguracion`;
    callApi(GetCategpriaGridService, 'POST', paramsService, (response) => { 
      const data = response.data.Configuracion[0];
      const dataFactor = response.data.ConfiguracionFactor; 
      if(data!==undefined){  
        setEjemploMontoDeCompra1(50);
        setEjemploMontoDeCompra2(150); 
        setImportePorPuntos(data.ImportePorPuntos); 
        setFactorBaseConfiguracion(data.FactorBase);
        examplePoints(data.FactorBase,data.ImportePorPuntos);
      }
    });    
  }; 

  const onDetalleCampaniaTienda = (rowData) => {
    const paramsService = {
      IdCampania:rowData.IdCampania,
      ClaEmpresa
    }
    const urlWebService = `${config.UrlApiProject}CampaniaRecompensa/GetCampaniaTienda`; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      setTiendaRowsGrid(response.data.CampaniaTiendas);
      setOriginalTiendaRows(response.data.CampaniaTiendas);
    });  
  };

  const regresarCampania = () => {
    resetCampania();
    if (props.onAfterReturn !=  null){ 
      props.onAfterReturn();
    }
  }

  const resetErrors = () => {
    const newErrors = { ...errors } 
    delete newErrors.NomCampania;   
    delete newErrors.NomCampaniaIngles;   
    delete newErrors.FactorBase;   
    setErrors(newErrors)  
  }

 const onchangeProductoCategoria = (e) => {
    setProductosCategoria(e.value);
  }

  const onchangeProducto = (e) => {
    setProductos(e.value);
  } 
  
  const resetCampania = () => { 
    setIdCampania(0);  
    setNomCampania('');
    setNomCampaniaIngles('');
    setActiveIndex(0);
    setdatafiles(null);
    setShowStep1(true);
    setShowStep2(false);
    setShowStep3(false);
    setFactorBase(null);
    setEsLimitarPorCategoriaCliente(false);  
    setEsLimitarPorProducto(false);  
    setEsLimitarPorCategoriaProducto(false);
    setEsLimitarPorTienda(false);
    setNomCortoCampaniaRecompensa(null);
    setDescripcion(null);
    setRangoFechaExpiracion([]);
    setFechaInicial(null);
    setFechaFinal(null);
    setImagenCampaniaRecompensa(null); 
    setNomArchivo(null); 
    setExtension(null); 
    setdatafiles([]); 
    setEsMostrarImagen(false);
    setNomArchivo(null); 
    setExtension(null); 

    setImagenCampaniaRecompensaMovil(null); 
    setNomArchivoMovil(null); 
    setExtensionMovil(null); 
    setdatafilesMovil([]); 
    setEsMostrarImagenMovil(false);
    setNomArchivoMovil(null); 
    setExtensionMovil(null); 
    
    resetErrors();
    setSelectedCategoria([]);
    setProductosCategoria([]);
    setProductos([]);
    const rowData = {IdCampania:0}
    onDetalleCampaniaCategoriaCliente(rowData);
    onDetalleCampaniaTienda(rowData);
    setAbrirNuevaCampania(true); 
  } 

  const cerrarModal = () => {
    setAbrirNuevaCampania(false);
  }  
  
  const valitationScheme = yup.object().shape({
    NomCampania: yup.string().required(t('common:RequiredField')).nullable(),
    NomCampaniaIngles: yup.string().required(t('common:RequiredField')).nullable(),
    FactorBase: yup.string().required(t('common:RequiredField')).nullable()
  });

  const guardarCampania = async () => {
    const value = {
      NomCampania,
      NomCampaniaIngles,
      FactorBase
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}CampaniaRecompensa/PostCampania`;
 
    const paramsService = {
      ClaEmpresa,
      IdCampania,
      NomCampania,
      NomCortoCampaniaRecompensa, 
      ImagenCampaniaRecompensa:(datafiles.length > 0 ? datafiles[0].FileData : ImagenCampaniaRecompensa),
      NomArchivo:(datafiles.length > 0 ? datafiles[0].FileName: NomArchivo),
      Extension:(datafiles.length > 0 ? datafiles[0].FileExtension: Extension),
      Descripcion,
      FactorBase,
      EsLimitarPorCategoriaCliente,
      EsLimitarPorProducto,
      EsLimitarPorCategoriaProducto,
      EsLimitarPorTienda,
      FechaInicioExpiracion:(rangoFechaExpiracion[0] === undefined ? null : rangoFechaExpiracion[0]),
      FechaFinalExpiracion:(rangoFechaExpiracion[1] === undefined ? null : rangoFechaExpiracion[1]),
      ClienteCategoria,
      Tiendas,
      ProductosCategoria,
      Productos,
      ImagenCampaniaRecompensaMovil:(datafilesMovil.length > 0 ? datafilesMovil[0].FileData : ImagenCampaniaRecompensaMovil),
      NomArchivoMovil:(datafilesMovil.length > 0 ? datafilesMovil[0].FileName: NomArchivoMovil),
      ExtensionMovil:(datafilesMovil.length > 0 ? datafilesMovil[0].FileExtension: ExtensionMovil),

      NomCampaniaIngles,
      NomCortoCampaniaRecompensaIngles, 
      ImagenCampaniaRecompensaIngles:(datafilesIngles.length > 0 ? datafilesIngles[0].FileData : ImagenCampaniaRecompensaIngles),
      NomArchivoIngles:(datafilesIngles.length > 0 ? datafilesIngles[0].FileName: NomArchivoIngles),
      ExtensionIngles:(datafilesIngles.length > 0 ? datafilesIngles[0].FileExtension: ExtensionIngles),
      DescripcionIngles,
      ImagenCampaniaRecompensaMovilIngles:(datafilesMovilIngles.length > 0 ? datafilesMovilIngles[0].FileData : ImagenCampaniaRecompensaMovilIngles),
      NomArchivoMovilIngles:(datafilesMovilIngles.length > 0 ? datafilesMovilIngles[0].FileName: NomArchivoMovilIngles),
      ExtensionMovilIngles:(datafilesMovilIngles.length > 0 ? datafilesMovilIngles[0].FileExtension: ExtensionMovilIngles),
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      removerImagen();
      if (props.onAfterSave !=  null){ 
        props.onAfterSave();
      }
    });  
  }

  const examplePoints = (pFactor, pImporte) => {
    const ifactor = (pFactor === null ? 1 : pFactor);
    const iImporte = (pImporte === null ? 1 : pImporte);
    setEjemploMontoDeCompraPuntos1(parseInt(50 * ifactor,10));
    setEjemploMontoDeCompraPuntos2(parseInt(150 *ifactor,10));
    setEjemploCostoDeCompraPuntos1((parseInt(50 * ifactor,10) / iImporte).toFixed(2));
    setEjemploCostoDeCompraPuntos2((parseInt(150 *ifactor,10) / iImporte).toFixed(2));
  }


  const onActiveIndex = async () => {
    const value = {
      NomCampania,
      NomCampaniaIngles,
      FactorBase
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    console.log('onActiveIndex',value);
    console.log('ImagenCampaniaRecompensa',ImagenCampaniaRecompensa);
    console.log('datafiles.length',datafiles.length);
    if (!esValido || (datafiles.length ===0 && ImagenCampaniaRecompensa === null)){
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
      return;
    }
    if (!esValido || (datafilesMovil.length ===0 && ImagenCampaniaRecompensaMovil === null)){
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:RequiredField'), 'warning');
      return;
    }
    if(showStep1){
      if(
          (rangoFechaExpiracion[0] === undefined || rangoFechaExpiracion[0] === null) || 
          (rangoFechaExpiracion[1] === undefined || rangoFechaExpiracion[1] === null)
        )
      {
        showSweetAlert('Warning', t('recompensas:EtiquetaCampaniaFechaExpiracion'), 'warning');
        return;
      }
      setActiveIndex(1);
      setShowStep1(false);
      setShowStep2(true);
      setShowStep3(false); 
    }
    if(showStep2){
      if(EsLimitarPorCategoriaCliente){
        const aCategoriaCliente = [];
        categoriaRowsGrid.forEach((item) => {
          if(item.EsSeleccionado){
            aCategoriaCliente.push(item);
          }
        })
        if(aCategoriaCliente.length===0){
          showSweetAlert('Warning', t('recompensas:MensajeSeleccionarCategoriaCliente'), 'warning');
          return;
        }
        setClienteCategoria(aCategoriaCliente);
      }
      if(EsLimitarPorTienda){
        const aTienda = [];
        tiendaRowsGrid.forEach((item) => {
          if(item.EsSeleccionado){
            aTienda.push(item);
          }
        })
        if(aTienda.length===0){
          showSweetAlert('Warning', t('recompensas:MensajeSeleccionarTienda'), 'warning');
          return;
        }
        setTiendas(aTienda);
      }
      if(EsLimitarPorCategoriaProducto){
        if(ProductosCategoria.length===0){
          showSweetAlert('Warning', t('recompensas:MensajeSeleccionarCategoriaProducto'), 'warning');
          return;
        }
      }
      if(EsLimitarPorProducto){
        if(Productos.length===0){
          showSweetAlert('Warning', t('recompensas:MensajeSeleccionarProducto'), 'warning');
          return;
        }
      }
      setActiveIndex(2);
      setShowStep1(false);
      setShowStep2(false);
      setShowStep3(true); 
      setEsDialogConfirmarCampania(true);
    }
  }

  const stepHeaderTemplate = (options) => {
  return (
    <div className="card-header danger">
      <div className="p-toolbar p-component">
        <div className="p-toolbar-group-left">
          <b>{NomCampaniaIngles} </b> 
        </div> 
        <div className="p-toolbar-group-right">
          <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={regresarCampania}>
            <i className="pi pi-arrow-left"></i>
          </button>
        </div>
      </div> 
    </div>
  )
} 

const onInActiveIndex = () => {
  if(showStep3){
    setActiveIndex(1);
    setShowStep1(false);
    setShowStep2(true);
    setShowStep3(false); 
  }
  if(showStep2){
    setActiveIndex(0);
    setShowStep1(true);
    setShowStep2(false);
    setShowStep3(false); 
  }
};

const stepItems = [
  {
      label: t('recompensas:Campania'),
      className:'itemInicio',
      command: (event) => {
          setShowStep1(true);
          setShowStep2(false);
          setShowStep3(false); 
      }
  },
  {
      label: t('common:Configuracion'),
      className:'itemFinal',
      command: (event) => {
        setShowStep1(false);
        setShowStep2(true);
        setShowStep3(false); 
      }
  }
  /* ,{
      label: t('common:Confirmacion'),
      className:'itemFinal',
      command: (event) => {
        setShowStep1(false);
        setShowStep2(false);
        setShowStep3(true);
      }
  } */
];

const onCategoriaRowEditInit = (event) => {
  originalCategoriaRows[event.index] = { ...categoriaRowsGrid[event.index] };
  setOriginalCategoriaRows(originalCategoriaRows); 
};

const onCategoriaRowEditCancel = (event) => {
  const data = [...categoriaRowsGrid];
  data[event.index] = originalCategoriaRows[event.index];
  delete originalCategoriaRows[event.index];
  setCategoriaRowsGrid(data);
};

const onCategoriaEditorValueChange = ( productKey, props, value ) => {  
  const updatedProducts = [...props.value];
  updatedProducts[props.rowIndex][props.field] = value;
  setCategoriaRowsGrid(updatedProducts);
}; 

const seleccionarCategoriaEditor = ( productKey, props ) => {  
  return (
    <Checkbox 
      checked={props.rowData.EsSeleccionado} 
      onChange={(e) => onCategoriaEditorValueChange(productKey, props, e.checked)} 
    />
)
}; 

const onTiendaRowEditInit = (event) => {
  originalTiendaRows[event.index] = { ...tiendaRowsGrid[event.index] };
  setOriginalTiendaRows(originalTiendaRows); 
};
const onTiendaRowEditCancel = (event) => {
  const data = [...tiendaRowsGrid];
  data[event.index] = originalTiendaRows[event.index];
  delete originalTiendaRows[event.index];
  setTiendaRowsGrid(data);
};
const onTiendaEditorValueChange = ( productKey, props, value ) => {  
  const updatedProducts = [...props.value];
  updatedProducts[props.rowIndex][props.field] = value;
  setTiendaRowsGrid(updatedProducts);
}; 
const seleccionarTiendaEditor = ( productKey, props ) => {  
  return (
    <Checkbox 
      checked={props.rowData.EsSeleccionado} 
      onChange={(e) => onTiendaEditorValueChange(productKey, props, e.checked)} 
    />
)
}; 

const seleccionarBodyTemplate = (rowData) => {
  return rowData.EsSeleccionado ? t('common:Si') : t('common:No');
}

const busquedaProductoCategoria = (event) => {
  setTimeout(() => {
    let paramsService = null;
    paramsService = {
      Busqueda:event.query,
      ClaEmpresa
    }
    const urlWebService = `${config.UrlApiProject}Categoria/GetProductoCategoriaAutoComplete`; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      let _FiltroProductoCategoria;
      if (!event.query.trim().length) {
          _FiltroProductoCategoria = [...response.data.ProductoCategorias];
      }
      else {
          _FiltroProductoCategoria = response.data.ProductoCategorias.filter((info) => {
              return event.query.toLowerCase().includes(event.query.toLowerCase());
          });
      }
      setFiltroProductoCategoria(_FiltroProductoCategoria);
    });    
  }, 250);
}
 
const busquedaProducto = (event) => {
  setTimeout(() => {
    let paramsService = null;
    paramsService = {
      Busqueda:event.query,
      ClaEmpresa
    }
    const urlWebService = `${config.UrlApiProject}Categoria/GetProductoAutoComplete`; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      let _FiltroProducto;
      if (!event.query.trim().length) {
          _FiltroProducto = [...response.data.Productos];
      }
      else {
          _FiltroProducto = response.data.Productos.filter((info) => {
              return info.NomProducto.toLowerCase().includes(event.query.toLowerCase());
          });
      }
      setFiltroProducto(_FiltroProducto);
    });    
  }, 250);
}

const itemTemplate = (item) => {
  const iPaddingLeft = (item.Nivel>1 ? (item.Nivel-1) * 20 :0);
  let sBackColor = '';
  let sColor = '';

  switch (item.Nivel) {
    case 1:
      sBackColor = '#FE6869';
      sColor = '#FFF';
      break;
    case 2:
      sBackColor = '#FEC2C2';
      sColor = '#777';
      break;
    case 3:
      sBackColor = '#FFE7E7';
      sColor = '#777';
      break;
    case 4:
      sBackColor = '#F5F5F5';
      sColor = '#777';
      break;
    default:
      sBackColor = '#FFFFFF';
      sColor = '#777';
  }

  return (
    <> 
      <div className="row-Autocomplete" style={{ paddingLeft: `${iPaddingLeft  }px`, background: `${sBackColor}`, color: `${sColor}`}}>
        <div> {item.NomProductoCategoria}</div>
      </div>
    </>
  );
}

const itemTemplateProducto = (item) => {
  const sBackColor = '#FFF';
  const sColor = '#777777';
  return (
    <> 
      <div className="row-Autocomplete" style={{ paddingLeft: '5px', background: `${sBackColor}`, color: `${sColor}`}}>
        <div> {item.NomProducto}</div>
      </div>
    </>
  );
}


const onChangeUploadIngles = (e) => {  
  if(e===null){
    return;
  } 
  setdatafilesIngles(e);
  setImagenCampaniaRecompensaBase64Ingles(null);
  if(e[0]!==undefined){
    setEsMostrarImagenIngles(true);
    setImagenCampaniaRecompensaBase64Ingles(e[0].objectURL);
  }
}; 

const onChangeUploadMovilIngles = (e) => {  
  if(e===null){
    return;
  } 
  setdatafilesMovilIngles(e);
  setImagenCampaniaRecompensaBase64MovilIngles(null);
  if(e[0]!==undefined){
    setEsMostrarImagenMovilIngles(true);
    setImagenCampaniaRecompensaBase64MovilIngles(e[0].objectURL);
  }
}; 

const removerImagenIngles = () => {
  setdatafilesIngles([]);
  setImagenCampaniaRecompensaIngles(null); 
  setNomArchivoIngles(null); 
  setExtensionIngles(null); 
  setEsMostrarImagenIngles(false);
}


const removerImagenMovilIngles = () => {
  setdatafilesIngles([]);
  setImagenCampaniaRecompensaMovilIngles(null); 
  setNomArchivoMovilIngles(null); 
  setExtensionMovilIngles(null); 
  setEsMostrarImagenMovilIngles(false);
}

const onChangeUpload = (e) => {  
  if(e===null){
    return;
  } 
  setdatafiles(e);
  setImagenCampaniaRecompensaBase64(null);
  if(e[0]!==undefined){
    setEsMostrarImagen(true);
    setImagenCampaniaRecompensaBase64(e[0].objectURL);
  }
}; 

const onChangeUploadMovil = (e) => {  
  if(e===null){
    return;
  } 
  setdatafilesMovil(e);
  setImagenCampaniaRecompensaBase64Movil(null);
  if(e[0]!==undefined){
    setEsMostrarImagenMovil(true);
    setImagenCampaniaRecompensaBase64Movil(e[0].objectURL);
  }
}; 

const removerImagen = () => {
  setdatafiles([]);
  setImagenCampaniaRecompensa(null); 
  setNomArchivo(null); 
  setExtension(null); 
  setEsMostrarImagen(false);
}


const removerImagenMovil = () => {
  setdatafiles([]);
  setImagenCampaniaRecompensaMovil(null); 
  setNomArchivoMovil(null); 
  setExtensionMovil(null); 
  setEsMostrarImagenMovil(false);
}

const getCategoryList = () => { 
  let paramsService = null;
  paramsService = {
    Busqueda:"",
    ClaEmpresa
  }
  const GetServiceCategories = `${config.UrlApiProject}Categoria/GetProductoCategoriaList`;  
  callApi(GetServiceCategories, 'POST', paramsService, (response) => { 
    setNodes(response.data.List);
    setCategorias(response.data.CategoriaList);
  });
}

const onChange = (node) => { 
  setSelectedCategoria(node);
  const result = Object.keys(node).map((key) => {
    return [Number(key), node[key]];
  });
  const arrayCategorias = [];
  for (let index = 0; index < result.length; index++) {
    const element = result[index];
    const elementArrayChild = element[1];
    if (elementArrayChild.checked) {
      // Busca nombre
      let sCategoria = '';
      const categoriaIndex =  Categorias.findIndex(item => item.ClaProductoCategoria === element[0]);
        if (categoriaIndex !== -1) {
          sCategoria= Categorias[categoriaIndex].NomProductoCategoria; 
        }
      const categorias = {
        ClaProductoCategoria: element[0],
        NomProductoCategoria:sCategoria
      };
      arrayCategorias.push(categorias);
    }
  }
  setProductosCategoria(arrayCategorias);
}

const onClick = () => { 
  setSelectedCategoria(0);
}

const onCloseDialogConfirmarCampania = () => { 
  setEsDialogConfirmarCampania(false);
  setShowStep2(true);
  setShowStep3(false);
} 

const onOpenDialogConfirmarCampania = () => { 
  setShowStep3(true);
  setShowStep2(false);
  setEsDialogConfirmarCampania(true);
} 


 const printStep3 = () => {
  return (
    <CardBody>
      <div className="p-field">
        <Panel header={t('common:Ingles')} toggleable>
          {
          EsMostrarImagenIngles && ImagenCampaniaRecompensaBase64Ingles!=null ? (
            <>
              <Row>
                <Col className='text-center'>
                  <div height={380} width={780}>
                    <img alt={NomArchivoIngles} src={ImagenCampaniaRecompensaBase64Ingles} role="presentation" />
                  </div>
                </Col>
              </Row>
            </>
          ):( <></>
          )
        }
          <Row>
            <Col>    
              <h1>{NomCampaniaIngles}</h1>
            </Col>
          </Row>
          <Row>
            <Col>    
              <h3>{NomCortoCampaniaRecompensaIngles}</h3>
            </Col>
          </Row>
          <Row>
            <Col>    
              {DescripcionIngles}
            </Col>
          </Row>
        </Panel>
        <br></br>
        <Panel header={t('common:Espanol')} toggleable>
          {
          EsMostrarImagen && ImagenCampaniaRecompensaBase64!=null ? (
            <>
              <Row>
                <Col className='text-center'>
                  <div height={380} width={780}>
                    <img alt={NomArchivo} src={ImagenCampaniaRecompensaBase64} role="presentation" />
                  </div>
                </Col>
              </Row>
            </>
          ):( <></>
          )
        }
          <Row>
            <Col>    
              <h1>{NomCampania}</h1>
            </Col>
          </Row>
          <Row>
            <Col>    
              <h3>{NomCortoCampaniaRecompensa}</h3>
            </Col>
          </Row>
          <Row>
            <Col>    
              {Descripcion}
            </Col>
          </Row>
        </Panel>
        
      </div>
      <div className="p-field">
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <b> {t('recompensas:FactorMultiplicador')} </b> 
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>    
            {FactorBase} 
          </Col>
        </Row>
      </div>
      <div className="p-field">
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <b> {t('recompensas:Periodo')} </b> 
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>   
            {`${fechaInicial} - ${  fechaFinal}`} 
          </Col>
        </Row>
      </div>
      <div>
        <hr></hr>
      </div>
      <h3> <span> {t('recompensas:EtiquetaAplicaCriterios')} </span></h3>
      <Row>&nbsp;</Row>
      <div hidden={EsLimitarPorTienda}> 
        <h3> <span> {t('recompensas:Tienda')} </span></h3>
        <span> {t('recompensas:EsTodasTiendas')} </span>
      </div>
      <div hidden={!EsLimitarPorTienda}> 
        <h3> <span> {t('recompensas:Tienda')} </span></h3>
        <ul className="list-group list-group-flush">
          {Tiendas.map((item) => {
          return item.EsSeleccionado === 0 ? <> </> : <li className="list-group-item">{item.NomTienda}</li>  
        })} 
        </ul> 
      </div>
      <div>
        <hr></hr>
      </div>     
      <Row>&nbsp;</Row>
      <div hidden={EsLimitarPorCategoriaCliente}> 
        <h3> <span> {t('recompensas:CategoriasCliente')} </span></h3>
        <span> {t('recompensas:EsTodasCategoriaCliente')} </span>
      </div>
      <div hidden={!EsLimitarPorCategoriaCliente}> 
        <h3> <span> {t('recompensas:CategoriasCliente')} </span></h3>
        <ul className="list-group list-group-flush">
          {ClienteCategoria.map((item) => {
          return item.EsSeleccionado === 0 ? <> </> : <li className="list-group-item">{item.NomCategoria}</li>  
        })} 
        </ul> 
      </div>
      <div>
        <hr></hr>
      </div>        
      <div hidden={EsLimitarPorCategoriaProducto}> 
        <h3> <span> {t('recompensas:CategoriasProducto')} </span></h3>
        <span> {t('recompensas:EsTodasCategoriaProducto')} </span>
      </div>
      <div hidden={!EsLimitarPorCategoriaProducto}> 
        <h3> <span> {t('recompensas:CategoriasProducto')} </span></h3>
        <ul className="list-group list-group-flush">
          {ProductosCategoria.map((item) => { 
      return (
        <li className="list-group-item">{item.NomProductoCategoria}</li>
      );
  })}  
        </ul> 
      </div>
      <div>
        <hr></hr>
      </div>        
      <div hidden={EsLimitarPorProducto}> 
        <h3> <span> {t('recompensas:Productos')} </span></h3>
        <span> {t('recompensas:EsTodosProducto')} </span>
      </div>
      <div hidden={!EsLimitarPorProducto}> 
        <h3> <span>  {t('recompensas:Productos')} </span></h3>
        <ul className="list-group list-group-flush">
          {Productos.map((item) => { 
      return (
        <li className="list-group-item">{item.NomProducto}</li>
      );
  })}  
        </ul> 
      </div>
    </CardBody>
  )
} 


const renderHeaderDialogConfirmarCampania = () => {
  return (
    <> 
      <Row>
        <Col>
          &nbsp;
        </Col>
        <Col className='text-center'>
          <h2>{t('recompensas:ConfirmationReward')}</h2> 
        </Col>
        <Col className="text-right">
          <Button hidden={(showStep1 || showStep2 || !abrirNuevaCampania)} onClick={guardarCampania} className="p-button-rounded p-button-success p-mr-2">
            <span className="p-button-label p-c">{t('common:Save')}</span>
          </Button>
        </Col>
      </Row>
    </>
  );
}

  return (
    <>
      <div>
        <Row hidden={!abrirNuevaCampania}>
          <Col lg={12} md={12} sm={12} xs={12}>   
            <div hidden={props.isHidden}>
              <Panel className="panel-radius" headerTemplate={stepHeaderTemplate}>  
                <Row className="padding10 text-center">
                  <Col lg={3} md={3} sm={12} xs={12}>
                    <div
                      hidden={showStep1} 
                      onClick={onInActiveIndex}
                      className="label-lg info-color Cursor"
                    >
                      <span className="p-button-label p-c"> <i className="pi pi-arrow-circle-left"></i> {t('common:Anterior')}</span>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <div className="steps padding10">
                      <Steps
                        model={stepItems}
                        activeIndex={activeIndex} 
                        onSelect={(e) => setActiveIndex(e.index)}
                        readOnly={true}
                      /> 
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={12} xs={12}>
                    <div
                      hidden={showStep2}
                      onClick={onActiveIndex} 
                      className="label-lg info-color p-c Cursor"
                    >
                      <span className="p-button-label p-c"> {t('common:Siguiente')} <i className="pi pi-arrow-circle-right"></i> </span>
                    </div> 
                    <div
                      hidden={showStep3}
                      className="label-lg info-color p-c Cursor"
                    >
                      <Button
                        hidden={(showStep1)} 
                        onClick={(e) => onOpenDialogConfirmarCampania()}
                        className="p-button-rounded p-button-info p-mr-2"
                      >
                        <span className="p-button-label p-c">{t('common:Confirmation')}</span>
                      </Button>
                    </div> 
                  </Col>
                </Row>
              </Panel>
            </div> 
          </Col> 
        </Row>
        <Row className='pt-3' hidden={!abrirNuevaCampania}>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <Card className="radiuscard padding50">
              {
                showStep1 ? (
                  <CardBody>
                    <Panel header={t('common:Ingles')} toggleable>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            <span className="bold"> {t('recompensas:Campania')}</span>
                          </Col> 
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12">
                              <small><span>{t('recompensas:EtiquetaNombreCampania')}</span></small>
                              <FieldText  
                                name="NomCampaniaIngles"
                                value={NomCampaniaIngles}  
                                errors={errors}
                                onChange={onChangeNomCampaniaIngles}
                              /> 
                            </div>
                          </Col> 
                        </Row>  
                      </div> 
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            <span className="bold"> {t('recompensas:NombreCorto')}</span>
                          </Col> 
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12">
                              <small><span>{t('recompensas:EtiquetaNombreCorto')}</span></small>
                              <FieldText  
                                name="NomCortoCampaniaRecompensaIngles"
                                value={NomCortoCampaniaRecompensaIngles}  
                                errors={errors}
                                onChange={onChangeNomCortoCampaniaRecompensaIngles}
                              /> 
                            </div>
                          </Col> 
                        </Row>  
                      </div> 
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            <span className="bold"> {t('recompensas:Descripcion')}</span>
                          </Col> 
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12">
                              <small><span>{t('recompensas:EtiquetaDescripcionCampania')}</span></small>
                              <FieldTextArea
                                name="DescripcionIngles"
                                value={DescripcionIngles} 
                                onChange={onChangeDescripcionIngles}   
                                rows={4}
                                cols={30}
                                autoResize
                                errors={errors}
                              />
                            </div>
                          </Col> 
                        </Row>  
                      </div>
                    </Panel>
                    <br></br>
                    <Panel header={t('common:Espanol')} toggleable>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            <span className="bold"> {t('recompensas:Campania')}</span>
                          </Col> 
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12">
                              <small><span>{t('recompensas:EtiquetaNombreCampania')}</span></small>
                              <FieldText  
                                name="NomCampania"
                                value={NomCampania}  
                                errors={errors}
                                onChange={onChangeNomCampania}
                              /> 
                            </div>
                          </Col> 
                        </Row>  
                      </div> 
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            <span className="bold"> {t('recompensas:NombreCorto')}</span>
                          </Col> 
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12">
                              <small><span>{t('recompensas:EtiquetaNombreCorto')}</span></small>
                              <FieldText  
                                name="NomCortoCampaniaRecompensa"
                                value={NomCortoCampaniaRecompensa}  
                                errors={errors}
                                onChange={onChangeNomCortoCampaniaRecompensa}
                              /> 
                            </div>
                          </Col> 
                        </Row>  
                      </div> 
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            <span className="bold"> {t('recompensas:Descripcion')}</span>
                          </Col> 
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <div className="p-fluid p-field p-col-12">
                              <small><span>{t('recompensas:EtiquetaDescripcionCampania')}</span></small>
                              <FieldTextArea
                                name="Descripcion"
                                value={Descripcion} 
                                onChange={onChangeDescripcion}   
                                rows={4}
                                cols={30}
                                autoResize
                                errors={errors}
                              />
                            </div>
                          </Col> 
                        </Row>  
                      </div>
                    </Panel>
                    


                    <div>
                      <hr></hr>
                    </div> 
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold"> {t('recompensas:FactorMultiplicador')}</span>
                        </Col> 
                        <Col lg={8} md={8} sm={12} xs={12}>
                          <div className="p-fluid p-field p-col-12">
                            <small><span>{t('recompensas:EtiquetaCampaniaFactor')}</span></small>
                            <FieldNumber
                              name="FactorBase"
                              value={FactorBase} 
                              onChange={onChangeFactorBase}
                              mode="decimal"
                              minFractionDigits={2}
                              errors={errors}
                            />
                          </div>
                        </Col> 
                      </Row>  
                    </div>
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <div className="p-field">
                            <Row>
                              <Col>
                                <div className="p-grid small">
                                  <div className="p-col text-center bold secondary-color">{t('recompensas:ExampleCurrentFactor')}{FactorBaseConfiguracion} </div>
                                </div>
                                <div className="text-center"><small>{t('recompensas:ExampleCurrentFactorLegend')}</small></div> 
                                <div className="p-grid small">   
                                  <div className="p-col text-left bold secondary-color">{t('recompensas:Buyer1')}</div>
                                  <div className="p-col text-right">$ {EjemploMontoDeCompra1} &nbsp; {t('recompensas:Dlls')}</div> 
                                  <div className="p-col text-right">{EjemploMontoDeCompraPuntos1} &nbsp; {t('recompensas:Pts')}</div> 
                                  <div className="p-col text-right">$ {EjemploCostoDeCompraPuntos1} &nbsp; {t('recompensas:Dlls')}</div> 
                                </div>
                                <div className="p-grid small">
                                  <div className="p-col text-left bold secondary-color">{t('recompensas:Buyer2')}</div>
                                  <div className="p-col text-right">$ {EjemploMontoDeCompra2} &nbsp; {t('recompensas:Dlls')}</div> 
                                  <div className="p-col text-right">{EjemploMontoDeCompraPuntos2} &nbsp; {t('recompensas:Pts')}</div> 
                                  <div className="p-col text-right">$ {EjemploCostoDeCompraPuntos2} &nbsp; {t('recompensas:Dlls')}</div> 
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col> 
                        <Col lg={8} md={8} sm={12} xs={12}>
                          &nbsp;
                        </Col> 
                      </Row>  
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold"> {t('recompensas:Periodo')}</span>
                        </Col> 
                        <Col lg={8} md={8} sm={12} xs={12}>
                          <div className="p-fluid p-field p-col-12">
                            <small><span>{t('recompensas:EtiquetaCampaniaFechaExpiracion')}</span></small>
                            <Calendar
                              id="range" 
                              value={rangoFechaExpiracion}  
                              onChange={(e) => onChangeFechaExpiracion(e.value)} 
                              selectionMode="range"
                              readOnlyInput
                            />
                          </div>
                        </Col> 
                      </Row>  
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                    <Panel header={t('common:Ingles')} toggleable>
                      {
                        EsMostrarImagenIngles && ImagenCampaniaRecompensaBase64Ingles!=null ? (
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('recompensas:CampaniaPicture')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('recompensas:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('recompensas:RecommendedSizeValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col>
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className='pb-2 pt-2'>
                                  <Card className="product-card-category">
                                    <div className='text-right'>
                                      <i className="fa fa-minus-circle danger-color Cursor" onClick={removerImagenIngles}></i>
                                    </div>
                                    <div className='product-card-category-image-container'>
                                      <div className='product-card-category-image-container-content'>
                                        <img alt={NomArchivoIngles} src={ImagenCampaniaRecompensaBase64Ingles} role="presentation" width="50%" />
                                      </div>
                                    </div>
                                  </Card> 
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        ):(
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('recompensas:CampaniaPicture')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('recompensas:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('recompensas:RecommendedSizeValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldUpload
                                    files={datafilesIngles} 
                                    onChange={onChangeUploadIngles}
                                    isRequired={true}
                                  >
                                  </FieldUpload>
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        )
                      }
                      {
                        EsMostrarImagenMovilIngles && ImagenCampaniaRecompensaBase64MovilIngles!=null ? (
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('recompensas:CampaniaPictureMovil')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('recompensas:RecommendedSizeMovil')}
                                    <div>
                                      <small className='text-center'>
                                        {t('recompensas:RecommendedSizeMovilValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col>
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className='pb-2 pt-2'>
                                  <Card className="product-card-category">
                                    <div className='text-right'>
                                      <i className="fa fa-minus-circle danger-color Cursor" onClick={removerImagenMovilIngles}></i>
                                    </div>
                                    <div className='product-card-category-image-container'>
                                      <div className='product-card-category-image-container-content'>
                                        <img alt={NomArchivoMovilIngles} src={ImagenCampaniaRecompensaBase64MovilIngles} role="presentation" width="50%" />
                                      </div>
                                    </div>
                                  </Card> 
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        ):(
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('recompensas:CampaniaPictureMovil')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('recompensas:RecommendedSizeMovil')}
                                    <div>
                                      <small className='text-center'>
                                        {t('recompensas:RecommendedSizeMovilValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldUpload
                                    files={datafilesMovilIngles} 
                                    onChange={onChangeUploadMovilIngles}
                                    isRequired={true}
                                  >
                                  </FieldUpload>
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        )
                      }
                    </Panel>
                    <br></br>
                    <Panel header={t('common:Espanol')} toggleable>
                      {
                        EsMostrarImagen && ImagenCampaniaRecompensaBase64!=null ? (
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('recompensas:CampaniaPicture')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('recompensas:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('recompensas:RecommendedSizeValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col>
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className='pb-2 pt-2'>
                                  <Card className="product-card-category">
                                    <div className='text-right'>
                                      <i className="fa fa-minus-circle danger-color Cursor" onClick={removerImagen}></i>
                                    </div>
                                    <div className='product-card-category-image-container'>
                                      <div className='product-card-category-image-container-content'>
                                        <img alt={NomArchivo} src={ImagenCampaniaRecompensaBase64} role="presentation" width="50%" />
                                      </div>
                                    </div>
                                  </Card> 
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        ):(
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('recompensas:CampaniaPicture')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('recompensas:RecommendedSize')}
                                    <div>
                                      <small className='text-center'>
                                        {t('recompensas:RecommendedSizeValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldUpload
                                    files={datafiles} 
                                    onChange={onChangeUpload}
                                    isRequired={true}
                                  >
                                  </FieldUpload>
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        )
                      }
                      {
                        EsMostrarImagenMovil && ImagenCampaniaRecompensaBase64Movil!=null ? (
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('recompensas:CampaniaPictureMovil')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('recompensas:RecommendedSizeMovil')}
                                    <div>
                                      <small className='text-center'>
                                        {t('recompensas:RecommendedSizeMovilValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col>
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className='pb-2 pt-2'>
                                  <Card className="product-card-category">
                                    <div className='text-right'>
                                      <i className="fa fa-minus-circle danger-color Cursor" onClick={removerImagenMovil}></i>
                                    </div>
                                    <div className='product-card-category-image-container'>
                                      <div className='product-card-category-image-container-content'>
                                        <img alt={NomArchivoMovil} src={ImagenCampaniaRecompensaBase64Movil} role="presentation" width="50%" />
                                      </div>
                                    </div>
                                  </Card> 
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        ):(
                          <>
                            <Row>
                              <Col lg={4} md={6} sm={12} xs={12}>
                                <span className="bold"> {t('recompensas:CampaniaPictureMovil')}</span>
                                <div className="p-grid small">
                                  <div className="p-col bold secondary-color">
                                    {t('recompensas:RecommendedSizeMovil')}
                                    <div>
                                      <small className='text-center'>
                                        {t('recompensas:RecommendedSizeMovilValue')}
                                      </small>
                                    </div> 
                                  </div>
                                </div>
                              </Col> 
                              <Col lg={8} md={6} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldUpload
                                    files={datafilesMovil} 
                                    onChange={onChangeUploadMovil}
                                    isRequired={true}
                                  >
                                  </FieldUpload>
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        )
                      }
                    </Panel>
                  </CardBody>
                ):
                  <></>
              }
              {
                showStep2 ? (
                  <CardBody>
                    <div className="p-field">
                      <Row className="align-items-start">
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold">{t('recompensas:AsignarCampaniaPorTienda')}</span>
                        </Col> 
                        <Col>
                          <div className="p-field">
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <FieldSelectButton 
                                  name="EsLimitarPorTienda"
                                  value={EsLimitarPorTienda} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onChangeEsLimitarPorTienda(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"
                                /> 
                              </Col>
                            </Row>
                          </div>
                        </Col> 
                      </Row> 
                    </div>
                    <div hidden={!EsLimitarPorTienda}>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                          &nbsp;
                          </Col>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <span>{t('recompensas:EtiquetaHabilitarCampaniaPorTienda')}</span>
                            <Row>
                              <Col lg={12} md={12} sm={12} xs={12}>    
                                <FieldDataTable 
                                  value={tiendaRowsGrid} 
                                  header={t('recompensas:Tienda')}
                                  editMode="row" 
                                  className="editable-cells-table"
                                  onRowEditInit={onTiendaRowEditInit} 
                                  onRowEditCancel={onTiendaRowEditCancel}
                                >
                                  <Column field="NomTienda" header={t('recompensas:Nombre')} columnKey="Categoria"></Column> 
                                  <Column field="EsSeleccionado" header={t('common:Seleccionar')} body={seleccionarBodyTemplate} editor={(props) => seleccionarTiendaEditor('EsSeleccionado', props)}></Column>
                                  <Column rowEditor bodyStyle={{ textAlign: 'center' }}></Column>
                                </FieldDataTable>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={3} md={3} sm={3} xs={3}>&nbsp;</Col>
                        </Row> 
                      </div>
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                    <div className="p-field">
                      <Row className="align-items-start">
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold">{t('recompensas:AsignarCampaniaPorCategoriaCliente')}</span>
                        </Col> 
                        <Col>
                          <div className="p-field">
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <FieldSelectButton 
                                  name="EsLimitarPorCategoriaCliente"
                                  value={EsLimitarPorCategoriaCliente} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onChangeEsLimitarPorCategoriaCliente(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"
                                /> 
                              </Col>
                            </Row>
                          </div>
                        </Col> 
                      </Row> 
                    </div>
                    <div hidden={!EsLimitarPorCategoriaCliente}>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                          &nbsp;
                          </Col>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <span>{t('recompensas:EtiquetaHabilitarCampaniaPorCategoriaCliente')}</span>
                            <Row>
                              <Col lg={12} md={12} sm={12} xs={12}>    
                                <FieldDataTable 
                                  value={categoriaRowsGrid} 
                                  header={t('recompensas:Categoria')}
                                  editMode="row" 
                                  className="editable-cells-table"
                                  onRowEditInit={onCategoriaRowEditInit} 
                                  onRowEditCancel={onCategoriaRowEditCancel}
                                >
                                  <Column field="NomCategoria" header={t('recompensas:Nombre')} columnKey="Categoria"></Column> 
                                  <Column field="EsSeleccionado" header={t('common:Seleccionar')} body={seleccionarBodyTemplate} editor={(props) => seleccionarCategoriaEditor('EsSeleccionado', props)}></Column>
                                  <Column rowEditor bodyStyle={{ textAlign: 'center' }}></Column>
                                </FieldDataTable>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={3} md={3} sm={3} xs={3}>&nbsp;</Col>
                        </Row> 
                      </div>
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                    <Row>&nbsp;</Row>
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold">{t('recompensas:AsignarCampaniaPorCategoriaProducto')}</span>
                        </Col> 
                        <Col lg={8} md={8} sm={12} xs={12}>
                          <div className="p-field">
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <FieldSelectButton 
                                  name="EsLimitarPorCategoriaProducto"
                                  value={EsLimitarPorCategoriaProducto} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onChangeEsLimitarPorCategoriaProducto(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"
                                /> 
                              </Col>
                            </Row>
                          </div> 
                        </Col> 
                      </Row> 
                    </div>
                    <div hidden={!EsLimitarPorCategoriaProducto}>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            &nbsp;
                          </Col>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <span>{t('recompensas:EtiquetaHabilitarCampaniaPorCategoriaProducto')}</span>
                            <Row>
                              <Col>    
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldTreeviewButton
                                    nodes={nodes} 
                                    showAsTreeSelect={true} 
                                    name="SelectedCategoria"
                                    optionLabel="label" 
                                    expandedKeys={expandedKeys} 
                                    nodeTemplate={nodeTemplate} 
                                    icon="pi pi-users"
                                    selectedKey={SelectedCategoria}
                                    selectionMode="checkbox"
                                    onChange={onChange}
                                    onClick={onClick}
                                    errors={errors}
                                  >
                                  </FieldTreeviewButton>   
                                </div> 
                              </Col>
                            </Row>
                          </Col>
                        </Row> 
                      </div>
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                    <div className="p-field">
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <span className="bold">{t('recompensas:AsignarCampaniaPorProducto')}</span>
                        </Col> 
                        <Col lg={8} md={8} sm={12} xs={12}>
                          <div className="p-field">
                            <Row>
                              <Col lg={4} md={4} sm={12} xs={12}>
                                <FieldSelectButton 
                                  name="EsLimitarPorProducto"
                                  value={EsLimitarPorProducto} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onChangeEsLimitarPorProducto(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"
                                /> 
                              </Col>
                            </Row>
                          </div> 
                        </Col> 
                      </Row> 
                    </div>
                    <div hidden={!EsLimitarPorProducto}>
                      <div className="p-field">
                        <Row>
                          <Col lg={4} md={4} sm={12} xs={12}>
                          &nbsp;
                          </Col>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <span>{t('recompensas:EtiquetaHabilitarCampaniaPorProducto')}</span>
                            <Row>
                              <Col>    
                                <span className="p-fluid">
                                  <AutoComplete
                                    value={Productos} 
                                    suggestions={FiltroProducto} 
                                    completeMethod={busquedaProducto} 
                                    field="NomProducto"
                                    multiple
                                    itemTemplate={itemTemplateProducto}
                                    onChange={(e) => onchangeProducto(e)}
                                  /> 
                                </span> 
                              </Col>
                            </Row>
                          </Col>
                        </Row> 
                      </div>
                    </div>
                    <div>
                      <hr></hr>
                    </div>
                  </CardBody>  
                ):
                (
                  <></>
                )
              } 
            </Card>  
          </Col>
        </Row>
        <Row>
          <Col>
            {
                showStep3 ? (
                  <>
                    {
                      props.isModal ? 
                      (
                        <Dialog
                          visible={EsDialogConfirmarCampania}
                          modal
                          maximized={true}
                          header={renderHeaderDialogConfirmarCampania}
                          onHide={() => onCloseDialogConfirmarCampania()}
                          blockScroll={true}
                        >
                          {printStep3()}
                        </Dialog>
                      ):
                      (
                        <>
                          {printStep3()}
                        </>
                      )
                    }
                  </> 
                ):
                (
                  <></>
                )
              }
          </Col>
        </Row>
      </div>
    </>
);
};
export default RecompensasCampania;
