
import React  from 'react';       

function PageContent (props) { 
    return (  
      <>  
        <div className="bg-title">  
          {props.title}  
        </div>  
        <div className="content">
          { props.children }
        </div> 
      </> 
    )
}   
export default PageContent; 
