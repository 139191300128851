/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState }  from 'react';     
import { Button } from 'primereact/button'; 

const FieldButton =  (props) => {
    
    const onClick = (e) => {  
        if (props.onClick !== null && typeof(props.onClick) === 'function'){
            props.onClick(e);
        } 
    }   
 
      return (  
        <>   
          <Button   
            hidden={props.hidden}
            label={props.label} 
            className={props.className}
            onClick={onClick} 
            disabled={props.disabled}
            style={props.style}
            icon={props.icon}
            tooltip={props.tooltip}
            title={props.title}
          >
            {props.children}
          </Button>   
        </> 
      )
}   
    
export default FieldButton; 
