import React from 'react'; 
import { Row, Col, Card,CardHeader, CardBody} from 'reactstrap';
import { config } from '../../utils/config';
import {callApi,getSessionItem } from '../../utils/utils';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog'; 
import logo from '../../assets/img/rwp_card.png';
import back from '../../assets/img/RW_back.jpg'; 
import { withTranslation } from 'react-i18next'

const url = getSessionItem('url', '/layout/RewardsSettings');
class LoginEmpresa extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            claEmpresa:0,
            NomEmpresa:'',
            empresas: [],
            usuario: props.user
        }
        this.setState({
            usuario: props.user,
          });

        this.cerrarModal = this.cerrarModal.bind(this);
        this.onChangeEmpresa = this.onChangeEmpresa.bind(this);
        this.headerBody = this.headerBody.bind(this);
    }

    headerBody = (e) => {
        return (
          <> 
            <div className="p-fluid">
             
            </div>
          </>
        );
    } 

    cerrarModal = (e) => {  
        this.props.cerrarModalLogin();
    }

    onChangeEmpresa = (info) => {
        localStorage.setItem('ClaEmpresa', info.ClaEmpresa);
        localStorage.setItem('NomEmpresa', info.NombreEmpresa);
       this.setState({
           claEmpresa: info.ClaEmpresa,
           NomEmpresa: info.NombreEmpresa,
       }); 
       this.props.onAceptarClick();
     }
  
      componentDidMount() {
       const data = {};
       const urlWebServiceE = `${config.UrlApiProject}Seguridad/GetEmpresa?LoginName=${this.props.user}`; 
       callApi(urlWebServiceE, 'GET', data, (response) => {
         this.state.empresas = response.data.Empresas;
         this.setState({
           empresas: response.data.Empresas
        });
        if(this.state.empresas.length===1){
            this.onChangeEmpresa(this.state.empresas[0]);
        }
      });
   }

    render() {
    if(this.props.esVisible){
       return(
         <>
           <div className="login-bg" style={{ backgroundImage: `url(${back})`}}></div>
           <Row>
             <Dialog 
               className='dialogradius'
               style={{ width: '520px',textAlign:'center'}}
               visible={this.props.esVisible} 
               closable={false}
               showHeader={false}
               onHide={this.cerrarModal} 
               contentStyle={{borderRadius: '25px 25px 25px 25px',color:'#fff', backgroundColor: '#1C1920'}}
             >
               {
                   this.state.empresas.length > 0 ? (
                     <div style={{ padding: '10px'}}>
                       <Row>
                         <Col lg={11} md={11} sm={11} xs={11}> 
                           <img style={{ textAlign:'center'}} height={30} src={logo} alt="deaceroLogo" />
                         </Col>
                         <Col lg={1} md={1} sm={1} xs={1}> 
                           <i
                             className="fa fa-2x fa-times-circle Cursor"
                             aria-hidden="true"
                             onClick={this.cerrarModal} 
                           >
                           </i>
                         </Col>
                       </Row> 
                       <Row> <p> &nbsp; </p> </Row>
                       <Row>
                         <Col lg={12} md={12} sm={12} xs={12}> 
                           <span>
                             {this.props.t('common:SeleccionarEmpresa')}
                           </span> 
                         </Col>
                       </Row> 
                       <Row>
                         &nbsp;
                       </Row>
                       <Row>
                         {this.state.empresas.map((item) => { 
                        return (
                          <Col className="padding10" lg={6} md={6} sm={6} xs={6} key={item.NomEmpresa}> 
                            <Card
                              className="dark Cursor"
                              onClick={(e) => this.onChangeEmpresa({ ClaEmpresa: item.ClaEmpresa, NombreEmpresa: item.NombreEmpresa})}
                            >
                              <CardBody className="text-center">    
                                <b> {item.NombreEmpresa} </b>
                              </CardBody> 
                            </Card>
                          </Col>
                        );
                    })} 
                       </Row>
                     </div> 
                    ) : <></>
                }  
 
             </Dialog>
           </Row>
         </>
       )
    }
    return (
      <>
      </>
    );
  }
}
LoginEmpresa.propTypes = {
  user: PropTypes.string,
  esVisible: PropTypes.bool
};
export default withTranslation(['common'])(LoginEmpresa);
