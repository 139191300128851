/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from "react"; 
import PropTypes from "prop-types"; 
import { Tree } from 'primereact/tree';
import { TreeSelect } from 'primereact/treeselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import onClickOutside from "react-onclickoutside"; 
import FieldText from "./FieldText.jsx";
import "./FieldTreeviewButton.css";

const FieldTreeviewButton = ({
    name,
    label,
    errors,
    nodes,
    panelHeadertemplate,
    selectedKey,
    selectionMode,
    classError,
    optionLabel,
    onChange,
    onClick,
    icon,
    nodeTemplate,
    expandedKeys,
    showAsTreeSelect
}) => {
     
    const [isOpen, setIsOpen] = useState(false);
    const [selectedText, setSelectedText] = useState(null); 
    const [selectedNodeKey2, setSelectedNodeKey2] = useState(null);

    FieldTreeviewButton.handleClickOutside = () => setIsOpen(false);
      
    const onNodeSelect = (node) => {
        setSelectedText(node.node[optionLabel]); 
        setIsOpen(false);
    }

    const onNodeUnselect = (node) => { 
        setSelectedText(null);
        setIsOpen(false);
    }


    const getSelectedNodes = () => {
        const selectedNodes = [];
        if (selectedKey && nodes) {
            const keys =  {[`${selectedKey}`]: true};
            findSelectedNodes(null, keys, selectedNodes);
        }

        return selectedNodes;
    }

    const findSelectedNodes = (node, keys, selectedNodes) => {
        if (node) {
            if (isSelected(node, keys)) {
                selectedNodes.push(node);
                delete keys[node.key];
            }

            if (Object.keys(keys).length && node.children) {
                for (const childNode of node.children) {
                    findSelectedNodes(childNode, keys, selectedNodes);
                }
            }
        }
        else {
            for (const childNode of nodes) {
                findSelectedNodes(childNode, keys, selectedNodes);
            }
        }
    }

    const isSelected = (node, keys) => {
        return  keys[node.key];
    } 

    console.log('isFormFieldInValid',errors);
    const isFormFieldInValid = (name) => !!(typeof(errors) !== 'undefined' && errors !== null && errors[name]);

    const nodeTemplateDefault = (node, options) => {
        
       if (nodeTemplate !== null && typeof(nodeTemplate) === 'function'){
          return nodeTemplate(node, options);
       }

       return (
         <span className={options.className}>
           {node.label}
         </span>
        )
    }
  

  return (
    <> 
      {
      showAsTreeSelect?(
        <>
          <div className="tree p-float-label"> 
            <TreeSelect
              value={selectedKey} 
              options={nodes}
              onChange={e => onChange(e.value)} 
              filter
              placeholder="Select Items" 
              selectionMode={selectionMode}
              resetFilterOnHide
              className={`${  isFormFieldInValid(name)}` === true ? 'p-invalid' : ''}
            >
            </TreeSelect>
          </div>
        </>
      ):(
        <>
          <div className="treeViewWrapper">
            <div className="treevew-button" onClick={(e) => setIsOpen(!isOpen)}>
              <i className={icon === undefined ? "fas fa-bars" : icon} style={{fontSize: '1.3rem'}}></i>&nbsp;&nbsp;
              { getSelectedNodes().length > 0 ? getSelectedNodes()[0][optionLabel] : null }
            </div>
        
            { isOpen ? (
              <div className="treeButtonPanel">
                <Tree value={nodes} selectionMode="single" expandedKeys={expandedKeys} nodeTemplate={nodeTemplateDefault} filter selectionKeys={selectedKey} onSelectionChange={e =>  onChange(e.value)} onSelect={onNodeSelect} onUnselect={onNodeUnselect} />
              </div>
        ) : <></>}
          </div>
        </>
      )
    }
    </>
  );
};

const clickOutsideConfig = {
    handleClickOutside: () => FieldTreeviewButton.handleClickOutside
  };
 
export default onClickOutside(FieldTreeviewButton, clickOutsideConfig);
