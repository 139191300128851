import React, { useState, useEffect, useRef } from 'react';
import {Row,Col,Card,CardHeader,CardTitle,CardBody}        from 'reactstrap'; 
import { DataTable }                  from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { config } from '../../utils/config';
import { callApi, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import FieldText from '../../components/Controls/FieldText.jsx'  
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel'; 
import FieldCalendar from '../../components/Controls/FieldCalendar.jsx'
import moment from 'moment';  
import {SplitButton} from 'primereact/splitbutton'; 
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';  
import CuponesDescuentoDetalle from './CuponesDescuentoDetalle.jsx'
import CuponesDescuentoAlcance from './CuponesDescuentoAlcance.jsx'
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'
import FieldDropdownMultiSelect from '../../components/Controls/FieldDropdownMultiSelect.jsx'
import Highcharts from 'highcharts';

// START COMPONENT
const CuponesDescuentoLista = (props) => { 
  const { t } = useTranslation(['formulario','common','cupones']);
  const [errors, setErrors] = useState({});
  const [rowsGrid, setRowsGrid] = useState([]);
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [FechaInicialFiltro, setFechaInicialFiltro] = useState(null);
  const [FechaFinalFiltro, setFechaFinalFiltro] = useState(null);
  const [NomCuponDescuentoFiltro, setNomCuponDescuentoFiltro] = useState(null);
  const [Filters, setFilters] = useState({}); 
  const [IdCupon, setIdCupon] = useState(null);
  const [ClaTipoCupon, setClaTipoCupon] = useState(null);
  const [AbrirModal, setAbrirModal] = useState(false);  
  const [EsLista, setEsLista] = useState(true); 
  const [AbrirPantallaAlcance, setAbrirPantallaAlcance] = useState(false);
  const [CuponDescuentoInfo, setCuponDescuentoInfo] = useState({});
  const [ClaCuponDescuentoEstatus, setClaCuponDescuentoEstatus] = useState([]);
  const [CouponStatusList, setCouponStatusList] = useState([]);
  

  useEffect(() => {
    obtenerEstatus(); 
    setEsLista(true);
    setClaTipoCupon((props.filters.ClaTipoCupon !== undefined ? props.filters.ClaTipoCupon : 0))
    onGridCupones(props.filters.ClaTipoCupon); 
  }, [props.filters]);

  const obtenerEstatus = () => {
    const urlGetCuponEstatus = `${config.UrlApiProject}CuponDescuento/GetCuponEstatus`;
    const paramsToService = {
      Idioma:''
    }
    callApi(urlGetCuponEstatus, 'POST', paramsToService, (response) => {
      const options = response.data.CuponEstatus.map((d) => ({
        value: d.ClaCuponDescuentoEstatus,
        label: d.NomCuponDescuentoEstatus,
      }));
      setCouponStatusList(options);
    });
  };

  const onGridCupones = (nClaTipoCupon) => {
        let estatus = "";
        if (ClaCuponDescuentoEstatus !== null) {
          for (let index = 0; index < ClaCuponDescuentoEstatus.length; index++) {
            const element = ClaCuponDescuentoEstatus[index].value;
            estatus = `${estatus + element},`;
          } 
        }
        const paramsToService = {
          IdCupon:null,
          ClaTipoCupon:nClaTipoCupon,
          ClaEmpresa,
          NomCuponDescuento: NomCuponDescuentoFiltro,
          FechaInicial: FechaInicialFiltro,
          FechaFinal: FechaFinalFiltro,
          ClaCuponDescuentoEstatusList:estatus
        }
        const GetCuponDescuentoService = `${config.UrlApiProject}CuponDescuento/GetCuponDescuento`;
        callApi(GetCuponDescuentoService, 'POST', paramsToService, (response) => { 
            setRowsGrid(response.data.CuponesDescuento);
        });    
  };

  const onClickLista = () => {
    setEsLista(!EsLista);
};

  const onDetalleCuponDescuento = (rowData) => {
    if (props.onAfterSelect !=  null){ 
      props.onAfterSelect(rowData);
    }
  };

  const menu = useRef(null);

  const items = [
    {
        label:'File',
        icon:'pi pi-fw pi-file',
        items:[
            {
                label:'New',
                icon:'pi pi-fw pi-plus',
                items:[
                {
                    label:'Bookmark',
                    icon:'pi pi-fw pi-bookmark'
                },
                {
                    label:'Video',
                    icon:'pi pi-fw pi-video'
                },

                ]
            },
            {
                label:'Delete',
                icon:'pi pi-fw pi-trash'
            },
            {
                separator:true
            },
            {
                label:'Export',
                icon:'pi pi-fw pi-external-link'
            }
        ]
    },
    {
        label:'Edit',
        icon:'pi pi-fw pi-pencil',
        items:[
            {
                label:'Left',
                icon:'pi pi-fw pi-align-left'
            },
            {
                label:'Right',
                icon:'pi pi-fw pi-align-right'
            },
            {
                label:'Center',
                icon:'pi pi-fw pi-align-center'
            },
            {
                label:'Justify',
                icon:'pi pi-fw pi-align-justify'
            },

        ]
    },
    {
        label:'Users',
        icon:'pi pi-fw pi-user',
        items:[
            {
                label:'New',
                icon:'pi pi-fw pi-user-plus',

            },
            {
                label:'Delete',
                icon:'pi pi-fw pi-user-minus',

            },
            {
                label:'Search',
                icon:'pi pi-fw pi-users',
                items:[
                {
                    label:'Filter',
                    icon:'pi pi-fw pi-filter',
                    items:[
                        {
                            label:'Print',
                            icon:'pi pi-fw pi-print'
                        }
                    ]
                },
                {
                    icon:'pi pi-fw pi-bars',
                    label:'List'
                }
                ]
            }
        ]
    },
    {
        label:'Events',
        icon:'pi pi-fw pi-calendar',
        items:[
            {
                label:'Edit',
                icon:'pi pi-fw pi-pencil',
                items:[
                {
                    label:'Save',
                    icon:'pi pi-fw pi-calendar-plus'
                },
                {
                    label:'Delete',
                    icon:'pi pi-fw pi-calendar-minus'
                }
                ]
            },
            {
                label:'Archieve',
                icon:'pi pi-fw pi-calendar-times',
                items:[
                {
                    label:'Remove',
                    icon:'pi pi-fw pi-calendar-minus'
                }
                ]
            }
        ]
    },
    {
        separator:true
    },
    {
        label:'Quit',
        icon:'pi pi-fw pi-power-off'
    }
];
  
  const dataInfo = (rowData) => {
    const arreglo = [];
    rowData.Opciones.forEach((item) => { 
      const arrItem =   { 
        label: item.NomCuponDescuentoEstatus,
        icon: sIcon(item.ClaCuponDescuentoEstatus),
        command: () => {
          if(item.ClaCuponDescuentoEstatus===1 || item.ClaCuponDescuentoEstatus===2){
            if(item.ClaCuponDescuentoEstatus===2){
              item.isDuplicate = true;
            }
            onDetalleCuponDescuento(item);
          }else if(item.ClaCuponDescuentoEstatus!==-1){
              updateCuponDescuento(item);
            }
        }
      }
      arreglo.push(arrItem);
    })
    return (
      arreglo
    );
  }

  const sIcon = (EstatusId) => {
    let sclass = '';
    switch (EstatusId) {
      case -1:
        sclass = ''; 
        break;
      case 1:
        sclass = 'pi pi-pencil'; 
        break;
      case 2:
        sclass = 'pi pi-copy'; 
        break;
      case 50:
          sclass = 'pi pi-ban'; 
          break;
      default:
        sclass = 'pi pi-send'; 
    }
    return (
      sclass
    );
  }

  const sClase = (EstatusId) => {
    let sclass = '';
    switch (EstatusId) {
      case 10:
        sclass = 'secondary'; 
        break;
      case 20:
        sclass = 'warning'; 
        break;
      case 30:
        sclass = 'success'; 
        break;
      case 40:
        sclass = 'danger'; 
        break;
      case 50:
          sclass = 'orange'; 
          break;
      default:
        sclass = 'default'; 
    }
    return (
      sclass
    );
  }
  
  const estatusBodyTemplate = (rowData) => { 
    return (
      <>
        <Card className={`radiuscard ${  sClase(rowData.ClaCuponDescuentoEstatus)}`}>
          <CardBody className="text-center">    
            {rowData.NomEstatus}
          </CardBody> 
        </Card> 
      </>
    ); 
  }

  const verBodyTemplate = (rowData) => {
    if (rowData.EsUsuarioSeguimiento){
      return (
        <>
          {props.IsHideActions ? <> </> : <SplitButton className="hidebutton" buttonTemplate="danger" dropdownIcon="pi pi-bars" model={dataInfo(rowData)}></SplitButton>} 
          &nbsp; 
          {props.IsHideScope  ? <></>  : ClaTipoCupon === 1 ? <Button onClick={(e) => onVerAlcance(rowData)} icon="pi pi-users" className="p-button-rounded p-button-success" /> : <></> } 
          &nbsp; 
          <Button onClick={(e) => onVer(rowData)} icon="pi pi-eye" className="p-button-rounded p-button-info" />
        </>
      );
    }
      return (
        <>
          <Button onClick={(e) => onVer(rowData)} icon="pi pi-eye" className="p-button-rounded p-button-info" />
        </>
      );
  } 

  const nuevoCuponDescuento = () => { 
    if (props.onAfterNew !=  null){ 
      props.onAfterNew();
    }
  } 

  const updateCuponDescuento = (item) => {
    const urlWebServiceSave = `${config.UrlApiProject}CuponDescuento/PostCuponDescuentoSeguimiento`;
    const paramsService = { 
      IdCupon: item.IdCupon,
      ClaCuponDescuentoEstatus: item.ClaCuponDescuentoEstatus,
      ClaEmpresa
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      onGridCupones(); 
    });  
  }  

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;
    const listIcon = EsLista ? 'pi pi-th-large' : 'pi pi-bars';

    return ( 
      <div className={`card-header danger collapsed-header${  className}`}>
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={`${toggleIcon  } light-color`}></span>
                <Ripple />
              </button>
              &nbsp;
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div> 
          <div className="p-toolbar-group-right mr-3">
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={(e) => onGridCupones(props.filters.ClaTipoCupon)}>
              <i className="pi pi-search"></i>
            </button>
            &nbsp;
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={nuevoCuponDescuento}>
              <i className="pi pi-plus"></i>
            </button> 
            &nbsp;
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={onClickLista}>
              <i className={listIcon}></i>
            </button>
            &nbsp;
          </div>
        </div> 
      </div> 
    )
}

const onChangeFechaInicialFiltro = (e) => {  
  setFechaInicialFiltro(e.target.value);
} 
const onChangeFechaFinalFiltro = (e) => {  
  setFechaFinalFiltro(e.target.value);
} 
const onChangeNomCuponDescuentoFiltro  = (e) => {  
  setNomCuponDescuentoFiltro(e.target.value);
} 

const onChangeCouponStatus = (e) => {
  const newErrors = { ...errors };
  delete newErrors.ClaCuponDescuentoEstatus;
  setErrors(newErrors);
  setClaCuponDescuentoEstatus(e); 
};

const FechaInicialTemplate = (rowData) => {
  return (
    <>
      { rowData.FechaInicioExpiracion !== null ? moment(rowData.FechaInicioExpiracion).format("MM/DD/YYYY") : rowData.FechaInicioExpiracion }
    </>
  );
}

const FechaFinalTemplate = (rowData) => {
  return (
    <>
      { rowData.FechaFinalExpiracion !== null ? moment(rowData.FechaFinalExpiracion).format("MM/DD/YYYY") : rowData.FechaFinalExpiracion }
    </>
  );
}

const onVer = (row) =>{   
  setIdCupon(row.IdCupon);
  setClaTipoCupon(row.ClaTipoCupon);
  setFilters({ ...Filters, IdCupon:row.IdCupon, ClaTipoCupon:row.ClaTipoCupon});
  setAbrirModal(true);
} 

const onVerAlcance = (row) =>{   
  const urlWebServiceSave = `${config.UrlApiProject}CuponDescuento/GetCuponDescuentoInformacion`;
  const paramsService = { 
    IdCupon: row.IdCupon,
    ClaEmpresa
  }; 
  callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      setIdCupon(row.IdCupon);
      setCuponDescuentoInfo(response.data.CuponDescuentoInformacion[0])
      setFilters({ ...Filters, IdCupon:row.IdCupon, ClaTipoCupon});
      setAbrirPantallaAlcance(true);
  });  
} 
const onRegresar = () => {
  setAbrirPantallaAlcance(false);
}

const cerrarModal = () => {
  setAbrirModal(false);
}  


const modalFooter = () => {
  return (
    <>
      <div>
        &nbsp;
      </div>
    </>
  );
} 

  return (
    <> 
      {
        AbrirPantallaAlcance ? (
          <>
            <Row className="text-center">
              <Col>
                <Card className="radiuscard">
                  <CardHeader className="default-light">
                    <Row>
                      <Col className="text-right">
                        <button
                          type="button" 
                          className="p-button p-button-rounded p-button-icon-only p-button-default"
                          onClick={onRegresar}
                        >
                          <i className="pi pi-angle-left"></i>
                        </button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="paddignBottom5">
                      <Col>
                        <div className="pt-2 pb-1 text-center">
                          <div className="fsize-4">
                            <Row>
                              <Col className="text-center">
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-black"
                                    >
                                      <i className="pi pi-users"></i>
                                    </button>
                                  </Col>
                                </Row> 
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>{CuponDescuentoInfo.ClientesTotal}</span>
                                  </Col>
                                </Row> 
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:ClientesTotal')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row> 
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1">
                          <div className="fsize-4">
                            <Row className="text-center">
                              <Col>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-info"
                                    >
                                      <i className="pi pi-users"></i>
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>{CuponDescuentoInfo.ClientesBeneficiados}</span>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:ClientesBeneficiados')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1">
                          <div className="fsize-4">
                            <Row className="text-center">
                              <Col>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-success"
                                    >
                                      <i className="pi pi-percentage"></i>
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>{CuponDescuentoInfo.EfectividadClienteCupon}</span>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:EfectividadClientes')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1 text-center">
                          <div className="fsize-4">
                            <Row>
                              <Col className="text-center">
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-danger"
                                    >
                                      <i className="pi pi-ticket"></i>
                                    </button>
                                  </Col>
                                </Row> 
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>{CuponDescuentoInfo.CantidadCuponesPorCliente}</span>
                                  </Col>
                                </Row> 
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:CuponesTotales')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row> 
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1 text-center">
                          <div className="fsize-4">
                            <Row>
                              <Col className="text-center">
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-orange"
                                    >
                                      <i className="pi pi-ticket"></i>
                                    </button>
                                  </Col>
                                </Row> 
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>{CuponDescuentoInfo.CuponesUtilizados}</span>
                                  </Col>
                                </Row> 
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:CuponesAplicados')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row> 
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1">
                          <div className="fsize-4">
                            <Row className="text-center">
                              <Col>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-success"
                                    >
                                      <i className="pi pi-percentage"></i>
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>{CuponDescuentoInfo.EfectividadCuponesAplicados}</span>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:EfectividadCuponesAplicados')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1">
                          <div className="fsize-4">
                            <Row className="text-center">
                              <Col>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-secondary"
                                    >
                                      <i className="pi pi-dollar"></i>
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>${Highcharts.numberFormat(CuponDescuentoInfo.MontoPuntos,2,'.',',')}</span>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:Monto Descuento')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row> 
            <p>&nbsp;</p>
            <CuponesDescuentoAlcance isHidden={true} filters={Filters} />
          </>
        ):(
          <>
            <Row hidden={props.isHidden}>
              <Col lg={12} md={12} sm={12} xs={12}>   
                <Panel className="panel-radius" header="Filters" toggleable headerTemplate={filterHeaderTemplate}> 
                  <div className="row">
                    <Row>&nbsp;</Row>
                  </div>
                  <div className="p-fluid p-grid">
                    <div className="p-grid p-field p-col-12"> 
                      <div className="p-field col-lg-2">
                        <FieldCalendar
                          className="p-calendar-danger"
                          name="FechaInicialFiltro" 
                          label={t('cupones:FechaInicial')}
                          value={FechaInicialFiltro}
                          onChange={(e) => onChangeFechaInicialFiltro(e)}
                        /> 
                      </div>
                      <div className="p-field col-lg-2">
                        <FieldCalendar
                          className="p-calendar-danger"
                          name="FechaFinalFiltro"
                          label={t('cupones:FechaFinal')}
                          value={FechaFinalFiltro}
                          onChange={(e) => onChangeFechaFinalFiltro(e)}
                        /> 
                      </div>
                      <div className="p-field col-lg-2">
                        <FieldText  
                          name="NomCuponDescuentoFiltro"
                          value={NomCuponDescuentoFiltro}  
                          onChange={onChangeNomCuponDescuentoFiltro}
                          label={(ClaTipoCupon===1 ? t('cupones:Cupon') : t('cupones:Deal'))}
                        /> 
                      </div> 
                      <div className="p-field col-lg-6">
                        <FieldDropdownMultiSelect
                          isMulti={true}
                          options={CouponStatusList}
                          isClearable={true}
                          isSearchable={true}
                          value={ClaCuponDescuentoEstatus}
                          name="ClaCuponDescuentoEstatus"
                          onChange={(e) => onChangeCouponStatus(e)}
                          errors={errors}
                          placeholder={t('cupones:Status')}
                        />
                      </div>
                    </div> 
                  </div> 
                </Panel>
                  &nbsp;
              </Col> 
            </Row>
            <Row className="align-items-start"> 
              <Col lg={12} md={12} sm={12} xs={12}>  
                {
            EsLista ? (
              <Row>
                <Col>
                  <FieldDataTable
                    value={rowsGrid}
                    header={(ClaTipoCupon===1 ? t('cupones:Cupon') : t('cupones:Deal'))}
                    scrollable 
                    className="p-datatable-striped"  
                    selectionMode="single"  
                    metaKeySelection={false}
                  >
                    <Column field="NomCuponDescuento" header={t('cupones:Nombre')} columnKey="NomCuponDescuento" headerStyle={{ width: '200px' }}></Column> 
                    <Column field="UsuarioCreo" header={t('cupones:CreadoPor')} columnKey="UsuarioCreo" headerStyle={{ width: '220px' }}></Column> 
                    <Column field="CodigoPromocion" header={t('cupones:CodigoPromocion')} columnKey="CodigoPromocion" style={{ display : (ClaTipoCupon===2?'none':'')}} headerStyle={{ width: '120px', display :(ClaTipoCupon===2?'none':'') }}></Column> 
                    <Column field="FechaInicioExpiracion" columnKey="FechaInicial" body={FechaInicialTemplate} header={t('cupones:FechaInicial')} headerStyle={{ width: '110px' }}></Column>  
                    <Column field="FechaFinalExpiracion" columnKey="FechaFinal" body={FechaFinalTemplate} header={t('cupones:FechaFinal')} headerStyle={{ width: '110px' }}></Column>  
                    <Column body={estatusBodyTemplate} header={t('cupones:Estatus')} headerStyle={{ width: '150px' }}></Column>
                    <Column body={verBodyTemplate} headerStyle={{ width: '130px' }}></Column>
                  </FieldDataTable> 
                </Col>
              </Row>
              ) : (
                <Row className="align-items-start"> 
                  {rowsGrid.map((item) => { 
                  return (
                    <Col className="paddignTop10" lg={4} md={8} sm={12} xs={12} key={item.IdCupon}> 
                      <Card className="radiuscard boxshadow padding10"> 
                        <CardBody> 
                          <Row>
                            <Col className="secondary-color bold">{t('cupones:Nombre')}</Col>
                            <Col className="secondary-color bold">{t('cupones:FechaInicial')}</Col>
                            <Col className="secondary-color bold">{t('cupones:FechaFinal')}</Col>
                          </Row>
                          <Row>
                            <Col className="primary-color">
                              <b> {item.NomCuponDescuento} </b>
                            </Col>
                            <Col className="success-color">
                              {FechaInicialTemplate(item)}
                            </Col>
                            <Col className="danger-color">
                              {FechaFinalTemplate(item)} 
                            </Col>
                          </Row>
                          <Row>&nbsp;</Row>
                          <Row>
                            <Col className="secondary-color bold"> 
                              {t('cupones:CreadoPor')} 
                            </Col>
                            <Col className="success-color">
                              <Col className="secondary-color bold">&nbsp;</Col>
                            </Col>
                            <Col hidden={ClaTipoCupon===2} className="secondary-color bold"> 
                              {t('cupones:CodigoPromocion')}
                            </Col> 
                          </Row>
                          <Row>
                            <Col> 
                              {item.UsuarioCreo}
                            </Col>
                            <Col className="secondary-color">
                              <div hidden={!item.EsExcluirProductoConDescuento}>
                                {t('cupones:EsExcluirProductoConDescuentoEtiqueta')}
                              </div>
                            </Col>
                            <Col> 
                              {item.CodigoPromocion}
                            </Col> 
                          </Row>
                          <Row>&nbsp;</Row>
                          <Row className="align-items-start">
                            <Col lg={6} md={6} sm={6} xs={6}> 
                              <Card className={`radiuscard ${  sClase(item.ClaCuponDescuentoEstatus)}`}>
                                <CardBody className="text-center">    
                                  {item.NomEstatus}
                                </CardBody> 
                              </Card> 
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}> 
                            &nbsp;
                            </Col>
                            <Col lg={5} md={5} sm={5} xs={5}> 
                              <div className="text-right">  
                                {verBodyTemplate(item)}
                              </div>
                            </Col> 
                          </Row>
                        </CardBody> 
                      </Card>
                    </Col>
                  );
              })} 
                </Row>
              )
            }
              </Col>
            </Row>
            <Row>
              <Col>
                {
              AbrirModal ? (
                <>
                  <div className="p-fluid p-field p-col-12">
                    <CuponesDescuentoDetalle visible={AbrirModal} isModal={true} isHidden={true} filters={Filters} />
                  </div>
                </>
              ):(
                <>
                </>
              )
            }
              </Col>
            </Row>
          </>
        )
      } 
    </>
);
};
export default CuponesDescuentoLista;
