import React, { useState, useEffect } from 'react';
import {  Row,  Col} from 'reactstrap'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'; 
import { config } from '../../utils/config';
import { callApi, getEmpresa } from '../../utils/utils'; 
import { confirmDialog } from 'primereact/confirmdialog'; 
import { useTranslation } from 'react-i18next';
import FieldAutoComplete from '../../components/Controls/FieldAutoComplete.jsx'
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx'
import YupValidator from '../../utils/YupValidator';
import {Checkbox} from 'primereact/checkbox';
import PageContent from '../../layouts/PageContent'; 
import * as yup from 'yup';
import { Panel } from 'primereact/panel';
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'
// START COMPONENT
const SeguridadFlujoAutorizacion = (props) => { 
  const { t } = useTranslation(['formulario','common']);
  const [errors, setErrors] = useState({});   
  const [rowsGrid, setRowsGrid] = useState([]);  
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [IdCfgFlujoAutorizacion, setIdCfgFlujoAutorizacion] = useState(0); 
  const [ClaTipoAutorizacion, setClaTipoAutorizacion] = useState(1);
  const [NomTipoAutorizacion, setNomTipoAutorizacion] = useState(null);  
  const [ClaAutorizadorReferencia, setClaAutorizadorReferencia] = useState(null);   
  const [ClaEstatusReferenciaRecibe, setClaEstatusReferenciaRecibe] = useState(null);   
  const [ClaEstatusReferenciaCambia, setClaEstatusReferenciaCambia] = useState(null);  
  const [ListaRecompensaEstatus, setListaRecompensaEstatus] = useState([]);  
  const [AbrirModal, setAbrirModal] = useState(false); 
  const [FilterClaAutorizadorReferencia, setFilterClaAutorizadorReferencia] = useState([]); 
  const [TipoAutorizaciones, setTipoAutorizaciones] = useState(false); 
  const [BajaLogicaFiltro, setBajaLogicaFiltro] = useState(0); 

  useEffect(() => {
    const paramsService = null;
    let urlWebServiceExpirationType = `${config.UrlApiProject}Seguridad/GetTipoAutorizaciones?BajaLogica=0`; 
    
    callApi(urlWebServiceExpirationType, 'GET', paramsService, (response) => {
      setTipoAutorizaciones(response.data.TipoAutorizaciones); 
      
      urlWebServiceExpirationType = `${config.UrlApiProject}Seguridad/GetRecompensaEstatus?BajaLogica=0`;
        callApi(urlWebServiceExpirationType, 'GET',  paramsService, (response) => {  
         setListaRecompensaEstatus(response.data.RecompensaEstatus);
         onGridFlujoAutorizaciones(1,BajaLogicaFiltro); 
      });    

    });  
  }, []);


  const onGridFlujoAutorizaciones = (tipoAutorizacion, esBaja) => {
        const paramsToService = {
            IdCfgFlujoAutorizacion:0,
            ClaTipoAutorizacion: tipoAutorizacion,
            BajaLogica: esBaja,
            ClaEmpresa
        }
        const GetCategpriaGridService = `${config.UrlApiProject}Seguridad/GetFlujoAutorizacion`;
        callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
            setRowsGrid(response.data.FlujoAutorizaciones); 
        });    
  };

  const onDetalleFlujoAutorizacion = (row) =>{ 
    const urlWebService = `${config.UrlApiProject}Seguridad/GetFlujoAutorizacion`;
    const paramsToService = {
        IdCfgFlujoAutorizacion:row.IdCfgFlujoAutorizacion,
        ClaTipoAutorizacion,
        BajaLogica: BajaLogicaFiltro,
        ClaEmpresa
    }
    callApi(urlWebService, 'POST', paramsToService, (response) => { 
      const data = response.data.FlujoAutorizaciones[0];
      if(data !== undefined){
        const AutoCompleteData = {
            ClaUsuario: data.ClaAutorizadorReferencia,
            NombreCompleto: data.Autorizador
          };
        setIdCfgFlujoAutorizacion(data.IdCfgFlujoAutorizacion);
        setClaTipoAutorizacion(data.ClaTipoAutorizacion);
        setNomTipoAutorizacion(data.NomTipoAutorizacion);
        setClaAutorizadorReferencia(AutoCompleteData);
        setClaEstatusReferenciaRecibe(data.ClaEstatusReferenciaRecibe); 
        setClaEstatusReferenciaCambia(data.ClaEstatusReferenciaCambia);
        setAbrirModal(true);
      }
    });    
  }
   

  const iconTemplate = (rowData) => {
    return rowData.BajaLogica === 0 ? "pi pi-check" : "pi pi-minus";
  }

 const colorTemplate = (rowData) => {
  return rowData.BajaLogica === 0 ? "p-button-rounded p-button-success" : "p-button-rounded p-button-danger";
 }

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button onClick={(e) => onDetalleFlujoAutorizacion(rowData)} icon="pi pi-pencil" className="p-button-rounded p-button-info p-mr-2" />
        &nbsp;
        <Button onClick={(e) => confirmar(rowData)} icon={iconTemplate(rowData)} className={colorTemplate(rowData)} />
      </>
    );
  }

  const nuevoForm = async () => {
    const value = {
       ClaTipoAutorizacion
    }
    const validator = new YupValidator(valitationSchemeNew);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      return;
    }
    TipoAutorizaciones.forEach((item) => {
        if(item.ClaTipoAutorizacion === value.ClaTipoAutorizacion){
            setNomTipoAutorizacion(item.NomTipoAutorizacion);
        }
    });
    setClaAutorizadorReferencia(null); 
    setClaEstatusReferenciaRecibe(null); 
    setClaEstatusReferenciaCambia(null);
    setIdCfgFlujoAutorizacion(0);
    resetErrors();
    setAbrirModal(true); 
  } 

  const resetErrors = () => {
    const newErrors = { ...errors } 
    delete newErrors.ClaTipoAutorizacion;   
    delete newErrors.ClaAutorizadorReferencia;   
    delete newErrors.ClaEstatusReferenciaRecibe;   
    delete newErrors.ClaEstatusReferenciaCambia; 
    setErrors(newErrors)  
  }

  const cerrarModal = () => {
    resetErrors();
    setAbrirModal(false);
  }  

  const valitationScheme = yup.object().shape({
    ClaAutorizadorReferencia: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaEstatusReferenciaRecibe: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaEstatusReferenciaCambia: yup.mixed().required(t('common:RequiredField')).nullable() 
  });

  const valitationSchemeNew = yup.object().shape({
    ClaTipoAutorizacion: yup.mixed().required(t('common:RequiredField')).nullable()
  });

  const guardarFlujoAutorizacion = async () => {
    const value = {
      ClaTipoAutorizacion,
      ClaAutorizadorReferencia,
      ClaEstatusReferenciaRecibe,
      ClaEstatusReferenciaCambia

    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}Seguridad/PostFlujoAutorizacion`;
    const paramsService = {
      IdCfgFlujoAutorizacion,
      ClaEmpresa,
      ClaTipoAutorizacion,
      ClaAutorizadorReferencia:ClaAutorizadorReferencia.ClaUsuario,
      ClaEstatusReferenciaRecibe,
      ClaEstatusReferenciaCambia,
      PorcDifImporte:0,
      ImporteAutorizacion:0
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      onGridFlujoAutorizaciones(ClaTipoAutorizacion,BajaLogicaFiltro);
      cerrarModal();
    });  
  }

  const modalFooter = () => {
    return (
      <>
        <div className="text-center">
          <Button label={t('common:Cancel')} icon="pi pi-times" className="p-button-rounded p-button-danger p-mr-2" onClick={cerrarModal}    />
          <Button label={t('common:Save')} icon="pi pi-check" className="p-button-rounded p-button-success p-mr-2" onClick={guardarFlujoAutorizacion} />
        </div>
      </>
    );
  } 

  const onChangeTipoAutorizacion = (value) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaTipoAutorizacion;   
    setErrors(newErrors)  
    setClaTipoAutorizacion(value);
    TipoAutorizaciones.forEach((item) => {
        if(item.ClaTipoAutorizacion === value){
            setNomTipoAutorizacion(item.NomTipoAutorizacion);
        }
    });
    onGridFlujoAutorizaciones(value,BajaLogicaFiltro);
  }  

  const onChangeBajaLogicaFiltro = (checked) => {   
    setBajaLogicaFiltro(checked);
    onGridFlujoAutorizaciones(ClaTipoAutorizacion,checked);
  }   
  
  const onChangeClaEstatusReferenciaRecibe = (value) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaEstatusReferenciaRecibe;   
    setErrors(newErrors)  
    setClaEstatusReferenciaRecibe(value);
  }  
  
  const onChangeClaEstatusReferenciaCambia = (value) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaEstatusReferenciaCambia;   
    setErrors(newErrors)  
    setClaEstatusReferenciaCambia(value);
  }  

  const onChangeClaAutorizadorReferencia = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaAutorizadorReferencia;   
    setErrors(newErrors)
    setClaAutorizadorReferencia(e.value)
  }

  const FilteredClaAutorizadorReferencia = (event) => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Seguridad/GetUsuariosAutorizador?Busqueda=${event.query}`;
    callApi(urlWebService, 'GET',  paramsService, (response) => {  
        setFilterClaAutorizadorReferencia(response.data.Usuarios);
    });    
  } 

  
  const confirmar = (rowData) => {
    confirmDialog({
        message: (rowData.BajaLogica===0 ? t('recompensas:MessageConfirmInActive') : t('recompensas:MessageConfirmActive')),
        header: t('common:Confirmacion'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => (rowData.BajaLogica===0 ? InactivarConfirmacion(rowData) : ActivarConfirmacion(rowData)) 
    });
}

const InactivarConfirmacion = (row) =>{   
    const urlWebServiceSave = `${config.UrlApiProject}Seguridad/PostFlujoAutorizacion`;
    const paramsService = {
      IdCfgFlujoAutorizacion: row.IdCfgFlujoAutorizacion,
      ClaEmpresa: row.ClaEmpresa,
      ClaTipoAutorizacion: row.ClaTipoAutorizacion,
      ClaAutorizadorReferencia: row.ClaAutorizadorReferencia,
      ClaEstatusReferenciaRecibe: row.ClaEstatusReferenciaRecibe,
      ClaEstatusReferenciaCambia: row.ClaEstatusReferenciaCambia,
      PorcDifImporte:0,
      ImporteAutorizacion:0,
      EsBaja:1
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      onGridFlujoAutorizaciones(row.ClaTipoAutorizacion,BajaLogicaFiltro);
      cerrarModal();
    });  
} 

const ActivarConfirmacion = (row) =>{   
    const urlWebServiceSave = `${config.UrlApiProject}Seguridad/PostFlujoAutorizacion`;
    const paramsService = {
      IdCfgFlujoAutorizacion: row.IdCfgFlujoAutorizacion,
      ClaEmpresa: row.ClaEmpresa,
      ClaTipoAutorizacion: row.ClaTipoAutorizacion,
      ClaAutorizadorReferencia: row.ClaAutorizadorReferencia,
      ClaEstatusReferenciaRecibe: row.ClaEstatusReferenciaRecibe,
      ClaEstatusReferenciaCambia: row.ClaEstatusReferenciaCambia,
      PorcDifImporte:0,
      ImporteAutorizacion:0,
      EsBaja:0
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      onGridFlujoAutorizaciones(row.ClaTipoAutorizacion,BajaLogicaFiltro);
      cerrarModal();
    });  
} 


const filterHeaderTemplate = (options) => {
  const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
  const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
  const titleClassName = `${options.titleClassName} p-pl-1`;

  return (
    <div className={`card-header danger collapsed-header${  className}`}>
      <div className="p-toolbar p-component">
        <div className="p-toolbar-group-left">
          <div className="card-title">
            <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
              <span className={`${toggleIcon  } light-color`}></span>
            </button>
              &nbsp;
            <span className={titleClassName}>
              {t('common:Filters')}
            </span>
          </div>  
        </div> 
        <div className="p-toolbar-group-right">
          <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={nuevoForm}>
            <i className="pi pi-plus"></i>
          </button>
          &nbsp;
        </div>
      </div> 
    </div>
  )
}

  return (
    <> 
      <PageContent title={t('seguridad:FlujoAutorizacion')}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <Panel className="panel-radius" header="Filters" toggleable headerTemplate={filterHeaderTemplate}>
              <Row>
                &nbsp;
              </Row>
              <Row>
                <Col lg={4} md={4} sm={6} xs={8}>
                  <div className="p-fluid p-field p-col-12"> 
                    <FieldDropdown  
                      name="ClaTipoAutorizacion"
                      value={ClaTipoAutorizacion} 
                      options={TipoAutorizaciones}  
                      optionValue="ClaTipoAutorizacion"
                      optionLabel="NomTipoAutorizacion" 
                      label={t('seguridad:TipoAutorizacion')}
                      errors={errors}
                      onChange={(e) => onChangeTipoAutorizacion(e.value)}
                    />   
                  </div>
                </Col>
                <Col lg={8} md={8} sm={4} xs={4}>
                  <div className="p-fluid p-field p-col-12"> 
                    <label htmlFor="binary">
                      {t('seguridad:MostrarActivos')} &nbsp;
                      <Checkbox 
                        checked={BajaLogicaFiltro} 
                        onChange={(e) => onChangeBajaLogicaFiltro(e.checked)}
                      />    
                    </label>
                  </div>
                </Col> 
              </Row>
              <Row>&nbsp;</Row>
            </Panel> 
            <Row>&nbsp;</Row> 
          </Col>
        </Row>    
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <FieldDataTable 
              value={rowsGrid}  
              header={t('seguridad:TitleGridFlujoAutorizacion')}
              scrollable 
              className="p-datatable-striped"  
              selectionMode="single"  
              metaKeySelection={false}
            >  
              <Column field="Autorizador" header={t('seguridad:Autorizador')} columnKey="Autorizador" headerStyle={{ width: '280px' }}></Column> 
              <Column field="EstatusRecibe" header={t('seguridad:EstatusRecibe')} columnKey="EstatusRecibe" headerStyle={{ width: '180px' }}></Column> 
              <Column field="EstatusCambia" header={t('seguridad:EstatusCambia')} columnKey="EstatusCambia" headerStyle={{ width: '180px' }}></Column> 
              <Column body={actionBodyTemplate} headerStyle={{ width: '100px' }}></Column>
            </FieldDataTable>   
          </Col>
        </Row>
        <Dialog
          className='dialogradius'
          header={`${t('seguridad:DialogFlujoAutorizacion')  } ${  NomTipoAutorizacion}`}
          visible={AbrirModal} 
          style={{ width: '450px' }} 
          footer={modalFooter}
          onHide={cerrarModal}
        > 
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid p-field p-col-12"> 
                <FieldAutoComplete
                  dropdown
                  name="ClaAutorizadorReferencia"
                  label={t('seguridad:Autorizador')}
                  value={ClaAutorizadorReferencia} 
                  onChange={onChangeClaAutorizadorReferencia}  
                  suggestions={FilterClaAutorizadorReferencia}
                  field="NombreCompleto" 
                  completeMethod={FilteredClaAutorizadorReferencia}
                  errors={errors}
                >
                </FieldAutoComplete>   
              </div>
              <div className="p-fluid p-field p-col-12"> 
                <FieldDropdown  
                  name="ClaEstatusReferenciaRecibe"
                  value={ClaEstatusReferenciaRecibe} 
                  options={ListaRecompensaEstatus}  
                  label={t('seguridad:EstatusRecibe')}
                  optionValue="ClaCampaniaRecompensaEstatus"
                  optionLabel="NomCampaniaRecompensaEstatus" 
                  errors={errors}
                  onChange={(e) => onChangeClaEstatusReferenciaRecibe(e.value)}
                />   
              </div>
              <div className="p-fluid p-field p-col-12"> 
                <FieldDropdown  
                  name="ClaEstatusReferenciaCambia"
                  value={ClaEstatusReferenciaCambia} 
                  options={ListaRecompensaEstatus}  
                  label={t('seguridad:EstatusCambia')}
                  optionValue="ClaCampaniaRecompensaEstatus"
                  optionLabel="NomCampaniaRecompensaEstatus" 
                  errors={errors}
                  onChange={(e) => onChangeClaEstatusReferenciaCambia(e.value)}
                />   
              </div>
            </Col>
          </Row>
        </Dialog>  
      </PageContent> 
    </>
);
};
export default SeguridadFlujoAutorizacion;