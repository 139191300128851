import React from 'react';
import { Route, Router, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import env from '@beam-australia/react-env';
import {  Button,  Card,  CardBody,  FormGroup,  Form,  Input, InputGroup,
          Container,  Row,  Col } from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader';
import { config } from '../../utils/config';
import AdminLayout from '../../layouts/Admin';
import queryString from 'query-string' 
import NoMenu from '../../layouts/NoMenu';
import LoginEmpresa from './LoginEmpresa.jsx';
import {  setSessionData, sessionAlive, logOut, showSweetAlert, callApi, getSessionItem} from '../../utils/utils';
import logo from '../../assets/img/rwp_card.png';
import back from '../../assets/img/RW_back.jpg';

const REACT_APP_VAR = env('VAR');
const REACT_APP_OTRO = env('OTRO');

let user = '';
let pass = ''; 
let noMenu =  '0';
let redirectURL = ''; 

class Login extends React.Component {

  constructor(props) {
    super(props); 

    this.state = {
      username: '' ,
      password: '',
      abrirModal : false 
    };

    /* si recibimos la informacion de usuario y password intentamos logear en automatico y 
       redireccionamos al URL solicitada */
    const params=queryString.parse(props.location.search);
    user = params.usuario ? params.usuario : '';
    pass = params.password ? params.password : ''; 
    noMenu = params.nomenu ? params.nomenu : '0';

    delete params.usuario; 
    delete params.password; 
    delete params.nomenu; 
    const aditionalParams = queryString.stringify(params); 
    redirectURL =  props.location.pathname ? `${props.location.pathname  }?${  aditionalParams}` : '';

    setSessionData({
      url: redirectURL,
    });

    // si recibimos usuario y password entonces hacemos clear de la session para que inicie todo de nuevo
    if (user !== '' && pass !== ''){
      // clear 
      localStorage.clear();
    }

    
    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onStartAutoLogin = this.onStartAutoLogin.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
    this.submitButton = React.createRef();
    this.cerrarModalLogin = this.cerrarModalLogin.bind(this);
    this.onAceptarClick = this.onAceptarClick.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.onLoginClick();
  }

  onLoginClick() {
    const urlWebService = `${config.UrlLoginServer}/authenticate`;

    const data = {
      username: this.state.usuario,
      password: this.state.password,
    };

    
    if (data.username === '' || data.password === '') {
      showSweetAlert('Warning', 'User and password are required.', 'warning');
      return;
    }

    callApi(urlWebService, 'POST', data, (res) => {
      if (!res.token) {
        showSweetAlert('Error', res.mensaje, 'error');
      } else {
        setSessionData({
          NomUsuario: res.nombreUsuario,
          Token: res.token,
          NumUsuario: data.username,
        });

        this.setState({
          numUsuario: data.username,
        });
        // user = data.username
        this.setState({
          user: data.username,
        });
        // abrirModal = true;
        this.setState({
          abrirModal: true,
        });

      }
    });
  }

  cerrarModalLogin()  { 
    this.setState({ 
      abrirModal:false,
    });
    logOut();
  }

  onAceptarClick()  {  
    this.setState({ 
      abrirModal:false,
    });
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onLoginClick();
    }
  }


  onStartAutoLogin() {
    const urlWebService = `${config.UrlLoginServer}/authenticate`; 
 

    const data = {
      username: user,
      password: pass,
    };

    if (user === '' || pass === '') {
      showSweetAlert('Warning', 'User and password are required.', 'warning');
      return;
    }
    
    // limpiamos la informacion

    
    callApi(urlWebService, 'POST', data, (res) => {

      
      if (!res.token) {
        showSweetAlert('Error', res.mensaje, 'error');
      } else {
        setSessionData({
          NomUsuario: res.nombreUsuario,
          Token: res.token,
          NumUsuario: data.username,
        });

        setSessionData({
          url: redirectURL,
        });


        this.setState({
          numUsuario: data.username,
        });
      }

      user = '';
      pass = ''; 
      redirectURL = '';
      

    });
  }


  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeUsuario(e) {
    this.setState({
      usuario: e.target.value,
    });
  }

  componentDidMount() {
    // document.body.classList.add('bg-default');
  }

  componentWillUnmount() {
    // document.body.classList.remove('bg-default');
  }

  render() { 

    if (this.state.abrirModal){
      return   (
        <>
          <LoginEmpresa onAceptarClick={this.onAceptarClick} cerrarModalLogin={this.cerrarModalLogin} esVisible={this.state.abrirModal} user={this.state.usuario}></LoginEmpresa>
        </>
      )
    }

    if(!this.state.abrirModal){
    /* si recibimos la informacion de usuario y password intentamos logear en automatico y 
       redireccionamos al URL solicitada */
       if (user !== '' && pass !== '' && !sessionAlive()){  
        // this.onStartAutoLogin();
      }
      else{
      if (sessionAlive()) {
        const url = getSessionItem('url', '/layout/Formularios'); 
  
        if (noMenu === '1'){
          // document.body.classList.remove('bg-default');
          // Primer componente al que se va a redirigir después de iniciar sesión
          return (
            <Router history={this.props.history}>
              <Route path="/" render={(props) => <NoMenu {...props} />} />
              <Redirect from="/" to={url} />
            </Router>
          );
        }
          // document.body.classList.remove('bg-default');
          // Primer componente al que se va a redirigir después de iniciar sesión
          return (
            <Router history={this.props.history}>
              <Route path="/" render={(props) => <AdminLayout {...props} />} />
              <Redirect from="/" to={url} />
            </Router>
          );
      }
      logOut();
    }
   }
    // document.body.classList.add('bg-default');

    return (
      <>
        <div className="login-bg" style={{ backgroundImage: `url(${back})`}}></div>
        <AuthHeader />
        <Container className="mt--7 pb-5">
          <Row className="justify-content-center">
            <Col lg={6} md={7}>
              <Card className="card-login bg-secondary border-0 mb-0 bg-color">                
                <CardBody className="card-login bg-color text-center pl-5 pr-5 pt-5 pb-5">
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <img height={30} src={logo} alt="deaceroLogo" /> 
                    </Col>
                  </Row>
                  <Form role="form">
                    <FormGroup
                      className={classnames('mb-3', {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <div className="mb-2 login-welcome">
                        <span className="kar-label mb-1 login-welcome-text">Welcome</span>
                      </div>
                      <div className="mb-2">
                        {/* <span className="kar-label">User</span> */}
                      </div>
                      
                      <InputGroup className="input-group-merge input-group-alternative login-form">
                        {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText className="kar-input-login">
                            <i className="far fa-user kar-icon-color" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                        <Input
                          type="text"
                          className="kar-input-login login-input"
                          onChange={this.onChangeUsuario}
                          placeholder="Username"
                          onKeyDown={this._handleKeyDown}
                          value={this.state.usuario}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                    >
                      {/* <div className="mb-2">
                        <span className="kar-label mb-1">Password</span>
                      </div> */}
                      <InputGroup className="input-group-merge input-group-alternative">
                        {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText className="kar-input-login">
                            <i className="fas fa-unlock-alt kar-icon-color" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                        <Input
                          type="password"
                          className="kar-input-login login-input"
                          onChange={this.onChangePassword}
                          style={{ marginTop: '5%' }}
                          placeholder="Password"
                          onKeyDown={this._handleKeyDown}
                          value={this.state.password}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        onClick={this.handleSubmit}
                        className="kar-btn-login login-button"
                        type="button"
                        style={{ width: '50%' }}
                        ref={this.submitButton}
                      >
                        Log in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card> 
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;
