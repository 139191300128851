import React, { useState, useEffect } from 'react';
import { Row, Col,Card, CardBody, CardHeader, CardTitle} from 'reactstrap'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button'; 
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import FieldText from '../../components/Controls/FieldText.jsx' 
import FieldNumber from '../../components/Controls/FieldNumber.jsx'  
import YupValidator from '../../utils/YupValidator'; 
import FieldSelectButton from '../../components/Controls/FieldSelectButton.jsx' 
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx'
import { RadioButton } from 'primereact/radiobutton';
import PageContent from '../../layouts/PageContent';  
import * as yup from 'yup';
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'

// START COMPONENT
const RecompensasConfiguracion = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [errors, setErrors] = useState({});   
  const [rowsGrid, setRowsGrid] = useState([]);  
  const [originalRows, setOriginalRows] = useState([]);  
  const [ConfiguracionFactor, setConfiguracionFactor] = useState([]); 
  const [OpcionesYesNo, setOpcionesYesNo] = useState([{ value: true, name: t('common:Si')}, { value: false, name: t('common:No') }]);
  const [Frecuencias, setFrecuencias] = useState([]);  
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [ClaConfiguracion, setClaConfiguracion] = useState(0);
  const [FactorBase, setFactorBase] = useState(null);
  const [ExpiracionDePuntos, setExpiracionDePuntos] = useState(null);
  const [PuntosAlSubirCategoria, setPuntosAlSubirCategoria] = useState(null);  
  const [HabilitarPuntosAlSubirCategoria, setHabilitarPuntosAlSubirCategoria] = useState(false); 
  const [HabilitarDiferenteFactorPorCategoria, setHabilitarDiferenteFactorPorCategoria] = useState(false);
  const [HabilitarPuntosProductosConDescuento, setHabilitarPuntosProductosConDescuento] = useState(false);
  const [HabilitarExpiracionDePuntos, setHabilitarExpiracionDePuntos] = useState(false);
  const [ClaFrecuencia, setClaFrecuencia] = useState(null); 
  const [HabilitarPuntosAlRegistrarCliente, setHabilitarPuntosAlRegistrarCliente] = useState(false);
  const [HabilitarPuntosAlRecomendarCliente, setHabilitarPuntosAlRecomendarCliente] = useState(false);
  const [HabilitarPuntosAlCompartirEnRedes, setHabilitarPuntosAlCompartirEnRedes] = useState(false);
  const [HabilitarPuntosPorCompra, setHabilitarPuntosPorCompra] = useState(false);
  const [HabilitarPuntosPorCumpleanios, setHabilitarPuntosPorCumpleanios] = useState(false);
  const [PuntosAlRegistrarCliente, setPuntosAlRegistrarCliente] = useState(null);
  const [PuntosAlRecomendarCliente, setPuntosAlRecomendarCliente] = useState(null);
  const [PuntosAlCompartirEnRedes, setPuntosAlCompartirEnRedes] = useState(null);
  const [HabilitarPuntosPorCompraRecurrente, setHabilitarPuntosPorCompraRecurrente] = useState(false);
  const [HabilitarPuntosPorMontoDeCompra, setHabilitarPuntosPorMontoDeCompra] = useState(false);
  const [PuntosPorCompraRecurrente, setPuntosPorCompraRecurrente] = useState(null);
  const [PuntosPorMontoDeCompra, setPuntosPorMontoDeCompra] = useState(null);
  const [CantidadCompraRecurrentes, setCantidadCompraRecurrentes] = useState(null);
  const [MontoDeCompra, setMontoDeCompra] = useState(null);
  const [MinimoMontoCompraRecurrente, setMinimoMontoCompraRecurrente] = useState(null);
  const [PuntosPorCumpleanios, setPuntosPorCumpleanios] = useState(null); 
  const [TiempoComprasRecurrentes, setTiempoComprasRecurrentes] = useState(null); 
  const [ClaTiempoCompraRecurrentes, setClaTiempoCompraRecurrentes] = useState(null); 
  const [ImportePorPuntos, setImportePorPuntos] = useState(null); 
  const [EjemploMontoDeCompra1, setEjemploMontoDeCompra1] = useState(null);
  const [EjemploMontoDeCompra2, setEjemploMontoDeCompra2] = useState(null);
  const [EjemploMontoDeCompraPuntos1, setEjemploMontoDeCompraPuntos1] = useState(null);
  const [EjemploMontoDeCompraPuntos2, setEjemploMontoDeCompraPuntos2] = useState(null);
  const [EjemploCostoDeCompraPuntos1, setEjemploCostoDeCompraPuntos1] = useState(null);
  const [EjemploCostoDeCompraPuntos2, setEjemploCostoDeCompraPuntos2] = useState(null);
  useEffect(() => {
    const paramsToService = {
      Idioma:'',
      BajaLogica:0
    }
    
    const urlWebServiceFrecuencia = `${config.UrlApiProject}Frecuencia/GetFrecuencia`;
    callApi(urlWebServiceFrecuencia, 'POST', paramsToService, (response) => { 
      setFrecuencias(response.data.Frecuencias); 
      DataConfiguration();
    });     
  }, []);

  const DataConfiguration = () => {
    const paramsService = {
      ClaEmpresa
    }
    const GetCategpriaGridService = `${config.UrlApiProject}Configuracion/GetConfiguracion`;
    callApi(GetCategpriaGridService, 'POST', paramsService, (response) => { 
      const data = response.data.Configuracion[0];
      const dataFactor = response.data.ConfiguracionFactor; 
      if(data!==undefined){  
        setEjemploMontoDeCompra1(50);
        setEjemploMontoDeCompra2(150); 
        setClaConfiguracion(data.ClaConfiguracion);
        setFactorBase(data.FactorBase);
        setPuntosAlSubirCategoria(data.PuntosAlSubirCategoria);
        setHabilitarPuntosAlSubirCategoria(data.HabilitarPuntosAlSubirCategoria);
        setHabilitarDiferenteFactorPorCategoria(data.HabilitarDiferenteFactorPorCategoria);
        setHabilitarPuntosProductosConDescuento(data.HabilitarPuntosProductosConDescuento);
        setHabilitarExpiracionDePuntos(data.HabilitarExpiracionDePuntos);
        setClaFrecuencia(data.ClaFrecuencia);
        setExpiracionDePuntos(data.ExpiracionDePuntos);
        setHabilitarPuntosAlRegistrarCliente(data.HabilitarPuntosAlRegistrarCliente);
        setHabilitarPuntosAlRecomendarCliente(data.HabilitarPuntosAlRecomendarCliente);
        setHabilitarPuntosAlCompartirEnRedes(data.HabilitarPuntosAlCompartirEnRedes);
        setHabilitarPuntosPorCompra(data.HabilitarPuntosPorCompra);
        setHabilitarPuntosPorCumpleanios(data.HabilitarPuntosPorCumpleanios);
        setPuntosAlRegistrarCliente(data.PuntosAlRegistrarCliente);
        setPuntosAlRecomendarCliente(data.PuntosAlRecomendarCliente);
        setPuntosAlCompartirEnRedes(data.PuntosAlCompartirEnRedes);
        setHabilitarPuntosPorCompraRecurrente(data.HabilitarPuntosPorCompraRecurrente);
        setHabilitarPuntosPorMontoDeCompra(data.HabilitarPuntosPorMontoDeCompra);
        setPuntosPorCompraRecurrente(data.PuntosPorCompraRecurrente);
        setPuntosPorMontoDeCompra(data.PuntosPorMontoDeCompra);
        setClaTiempoCompraRecurrentes(data.ClaTiempoCompraRecurrentes);
        setTiempoComprasRecurrentes(data.TiempoComprasRecurrentes);
        setCantidadCompraRecurrentes(data.CantidadCompraRecurrentes);
        setMontoDeCompra(data.MontoDeCompra);
        setMinimoMontoCompraRecurrente(data.MinimoMontoCompraRecurrente);
        setPuntosPorCumpleanios(data.PuntosPorCumpleanios);
        setImportePorPuntos(data.ImportePorPuntos); 
        examplePoints(data.FactorBase,data.ImportePorPuntos);
      }
      if(dataFactor!==undefined){  
        setRowsGrid(dataFactor);
        setOriginalRows(dataFactor); 
      }
    });    
  }; 

  const valitationScheme =  yup.object().shape({ 
    FactorBase: yup.mixed().required(t('common:RequiredField')).nullable(),
    ImportePorPuntos: yup.mixed().required(t('common:RequiredField')).nullable(),
    PuntosAlSubirCategoria: (
                         HabilitarPuntosAlSubirCategoria 
                         ? yup.string().required(t('common:RequiredField')).nullable() 
                         : yup.string()
                         ),
    ExpiracionDePuntos: (
                        HabilitarExpiracionDePuntos 
                         ? yup.string().required(t('common:RequiredField')).nullable() 
                         : yup.string()
                         ),
    ClaFrecuencia: (
                        HabilitarExpiracionDePuntos 
                         ? yup.mixed().required(t('common:RequiredField')).nullable() 
                         : yup.mixed()
                         ),
    PuntosAlRegistrarCliente: (
                        HabilitarPuntosAlRegistrarCliente 
                        ? yup.string().required(t('common:RequiredField')).nullable() 
                        : yup.string()
                        ),
    PuntosAlRecomendarCliente: (
                        HabilitarPuntosAlRecomendarCliente 
                        ? yup.string().required(t('common:RequiredField')).nullable() 
                        : yup.string()
                      ),
    PuntosPorCumpleanios: (
                        HabilitarPuntosPorCumpleanios 
                        ? yup.string().required(t('common:RequiredField')).nullable() 
                        : yup.string()
                      ),
     CantidadCompraRecurrentes: (
                        HabilitarPuntosPorCompraRecurrente 
                        ? yup.string().required(t('common:RequiredField')).nullable() 
                        : yup.string()
                      ),
     PuntosPorCompraRecurrente: (
                          HabilitarPuntosPorCompraRecurrente 
                          ? yup.string().required(t('common:RequiredField')).nullable() 
                          : yup.string()
                        ),
    ClaTiempoCompraRecurrentes: (
                        HabilitarPuntosPorCompraRecurrente 
                        ? yup.string().required(t('common:RequiredField')).nullable() 
                        : yup.string()
                      ),
    TiempoComprasRecurrentes: (
            HabilitarPuntosPorCompraRecurrente 
            ? yup.string().required(t('common:RequiredField')).nullable() 
            : yup.string()
            ),
    MinimoMontoCompraRecurrente: (
              HabilitarPuntosPorCompraRecurrente 
                          ? yup.string().required(t('common:RequiredField')).nullable() 
                          : yup.string()
                        ) ,
      MontoDeCompra: (
                          HabilitarPuntosPorMontoDeCompra 
                          ? yup.string().required(t('common:RequiredField')).nullable() 
                          : yup.string()
                        ) ,
      PuntosPorMontoDeCompra: (
                            HabilitarPuntosPorMontoDeCompra 
                                  ? yup.string().required(t('common:RequiredField')).nullable() 
                                  : yup.string()
                        )
  })
 

  const guardarConfiguracion = async () => {
    const value = {
      FactorBase,
      ImportePorPuntos
    }
    if(HabilitarPuntosAlSubirCategoria){
      value.PuntosAlSubirCategoria = PuntosAlSubirCategoria;
    }
    if(HabilitarExpiracionDePuntos){
      value.ClaFrecuencia = ClaFrecuencia;
      value.ExpiracionDePuntos = ExpiracionDePuntos;
    }
    if(HabilitarPuntosAlRegistrarCliente){
      value.PuntosAlRegistrarCliente = PuntosAlRegistrarCliente; 
    }
    if(HabilitarPuntosAlRecomendarCliente){
      value.PuntosAlRecomendarCliente = PuntosAlRecomendarCliente; 
    }
    if(HabilitarPuntosPorCumpleanios){
      value.PuntosPorCumpleanios = PuntosPorCumpleanios; 
    }
    if(HabilitarPuntosPorCompraRecurrente){
      value.PuntosPorCompraRecurrente = PuntosPorCompraRecurrente;
      value.CantidadCompraRecurrentes = CantidadCompraRecurrentes;
      value.ClaTiempoCompraRecurrentes = ClaTiempoCompraRecurrentes;
      value.TiempoComprasRecurrentes = TiempoComprasRecurrentes;
      value.MinimoMontoCompraRecurrente = MinimoMontoCompraRecurrente;
    }
    if(HabilitarPuntosPorMontoDeCompra){
      value.PuntosPorMontoDeCompra = PuntosPorMontoDeCompra;
      value.MontoDeCompra = MontoDeCompra;
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}Configuracion/PostConfiguracion`;
    const paramsService = {
      ClaEmpresa,
      ClaConfiguracion,
      FactorBase,
      ImportePorPuntos,
      HabilitarPuntosAlSubirCategoria,
      HabilitarDiferenteFactorPorCategoria,
      HabilitarPuntosProductosConDescuento,
      HabilitarExpiracionDePuntos,
      PuntosAlSubirCategoria,
      ClaFrecuencia,
      ExpiracionDePuntos,
      HabilitarPuntosAlRegistrarCliente,
      HabilitarPuntosAlRecomendarCliente,
      HabilitarPuntosAlCompartirEnRedes,
      HabilitarPuntosPorCompra,
      HabilitarPuntosPorCumpleanios,
      PuntosAlRegistrarCliente,
      PuntosAlRecomendarCliente,
      PuntosAlCompartirEnRedes,
      HabilitarPuntosPorCompraRecurrente,
      HabilitarPuntosPorMontoDeCompra,
      PuntosPorCompraRecurrente,
      PuntosPorMontoDeCompra,
      CantidadCompraRecurrentes,
      TiempoComprasRecurrentes,
      ClaTiempoCompraRecurrentes,
      MontoDeCompra,
      PuntosPorCumpleanios,
      Factor: ConfiguracionFactor,
      MinimoMontoCompraRecurrente
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      DataConfiguration();
    });  
  }

  const onChangeFactorBase = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.FactorBase;   
    setErrors(newErrors);
    setFactorBase(e.value);
    examplePoints(e.value,ImportePorPuntos);
  }

  const onChangeImportePorPuntos = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.ImportePorPuntos;   
    setErrors(newErrors);
    setImportePorPuntos(e.value);
    examplePoints(FactorBase,e.value);
  }

  const examplePoints = (pFactor, pImporte) => {
    const ifactor = (pFactor === null ? 1 : pFactor);
    const iImporte = (pImporte === null ? 1 : pImporte);
    setEjemploMontoDeCompraPuntos1(parseInt(50 * ifactor,10));
    setEjemploMontoDeCompraPuntos2(parseInt(150 *ifactor,10));
    setEjemploCostoDeCompraPuntos1((parseInt(50 * ifactor,10) / iImporte).toFixed(2));
    setEjemploCostoDeCompraPuntos2((parseInt(150 *ifactor,10) / iImporte).toFixed(2));
  }

  const onChangeExpiracionDePuntos = (e) => {
      const newErrors = { ...errors } 
      delete newErrors.ExpiracionDePuntos;   
      setErrors(newErrors)  
    setExpiracionDePuntos(e.value)
  }
  
  const onChangePuntosAlSubirCategoria = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.PuntosAlSubirCategoria;   
    setErrors(newErrors)  
    setPuntosAlSubirCategoria(e.value)
  }
  
  const onChangeFrecuencia = (value) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaFrecuencia;   
    setErrors(newErrors)  
    setClaFrecuencia(value);
  }  
  const onChangeTiempoCompraRecurrentes = (value) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaTiempoCompraRecurrentes;   
    setErrors(newErrors)  
    setClaTiempoCompraRecurrentes(value);
  }  
 
  const onHabilitarPuntosAlSubirCategoria = ( value ) => {  
    if(value===null){
      return;
    }
    if(!value){
      const newErrors = { ...errors } 
      delete newErrors.PuntosAlSubirCategoria;   
      setErrors(newErrors) 
    }
    setHabilitarPuntosAlSubirCategoria(value); 
  }; 
  const onHabilitarDiferenteFactorPorCategoria = ( value ) => {  
    if(value===null){
      return;
    }
    setHabilitarDiferenteFactorPorCategoria(value); 
  }; 
  const onHabilitarPuntosProductosConDescuento = ( value ) => {  
    if(value===null){
      return;
    }
    setHabilitarPuntosProductosConDescuento(value); 
  }; 
  const onHabilitarExpiracionDePuntos = ( value ) => {
    if(value===null){
      return;
    }
    if(!value){
      const newErrors = { ...errors } 
      delete newErrors.ExpiracionDePuntos;   
      delete newErrors.ClaFrecuencia;  
      setErrors(newErrors);
      setExpiracionDePuntos(null);
      setClaFrecuencia(1);
    }
    setHabilitarExpiracionDePuntos(value); 
  }; 
  const onHabilitarPuntosAlRegistrarCliente = ( value ) => {  
    if(value===null){
      return;
    }
    if(!value){
      const newErrors = { ...errors } 
      delete newErrors.PuntosAlRegistrarCliente;   
      setErrors(newErrors); 
      setPuntosAlRegistrarCliente(null);
    }  
    setHabilitarPuntosAlRegistrarCliente(value); 
  }; 
  const onHabilitarPuntosAlRecomendarCliente = ( value ) => {  
    if(value===null){
      return;
    }
    if(!value){
      const newErrors = { ...errors } 
      delete newErrors.PuntosAlRecomendarCliente;   
      setErrors(newErrors); 
      setPuntosAlRecomendarCliente(null);
    }  
    setHabilitarPuntosAlRecomendarCliente(value); 
  }; 
  const onHabilitarPuntosAlCompartirEnRedes = ( value ) => { 
    if(value===null){
      return;
    }
    setHabilitarPuntosAlCompartirEnRedes(value); 
  }; 
  const onHabilitarPuntosPorCompra = ( value ) => {  
    if(value===null){
      return;
    }
    setHabilitarPuntosPorCompra(value); 
  }; 
  const onHabilitarPuntosPorCumpleanios = ( value ) => {  
    if(value===null){
      return;
    }
    if(!value){
      const newErrors = { ...errors } 
      delete newErrors.PuntosPorCumpleanios;  
      setErrors(newErrors);
      setPuntosPorCumpleanios(null); 
    }  
    setHabilitarPuntosPorCumpleanios(value); 
  }; 
  const onChangePuntosAlRegistrarCliente = ( e ) => {  
    const newErrors = { ...errors } 
    delete newErrors.PuntosAlRegistrarCliente;   
    setErrors(newErrors)  
    setPuntosAlRegistrarCliente(e.value); 
  }; 
  const onChangePuntosAlRecomendarCliente = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.PuntosAlRecomendarCliente;   
    setErrors(newErrors)   
    setPuntosAlRecomendarCliente(e.value)
  }
  const onChangePuntosAlCompartirEnRedes = (e) => {  
    setPuntosAlCompartirEnRedes(e.target.value)
  }
  const onHabilitarPuntosPorCompraRecurrente = (e) => {  
    if(e.checked){
      const newErrors = { ...errors } 
      delete newErrors.PuntosPorCompraRecurrente;  
      delete newErrors.CantidadCompraRecurrentes;  
      delete newErrors.ClaTiempoCompraRecurrentes;  
      delete newErrors.TiempoComprasRecurrentes;  
      setErrors(newErrors); 
      setPuntosPorCompraRecurrente(null);
      setCantidadCompraRecurrentes(null);
      setClaTiempoCompraRecurrentes(1);
      setTiempoComprasRecurrentes(null);
      setMinimoMontoCompraRecurrente(null);
    }
    setHabilitarPuntosPorCompraRecurrente(e.checked)
    setHabilitarPuntosPorMontoDeCompra(false);
  }
  const onHabilitarPuntosPorMontoDeCompra = (e) => {  
    if(e.checked){
      const newErrors = { ...errors } 
      delete newErrors.PuntosPorMontoDeCompra;  
      delete newErrors.MontoDeCompra;  
      setErrors(newErrors); 
      setPuntosPorMontoDeCompra(null);
      setMontoDeCompra(null);
    }
    setHabilitarPuntosPorMontoDeCompra(e.checked);
    setHabilitarPuntosPorCompraRecurrente(false);
  }
  const onChangePuntosPorCompraRecurrente = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.PuntosPorCompraRecurrente;   
    setErrors(newErrors)     
    setPuntosPorCompraRecurrente(e.value)
  }
  const onChangeTiempoComprasRecurrentes = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.TiempoComprasRecurrentes;   
    setErrors(newErrors)
    setTiempoComprasRecurrentes(e.value)
  }
  const onChangePuntosPorMontoDeCompra = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.PuntosPorMontoDeCompra;   
    setErrors(newErrors)   
    setPuntosPorMontoDeCompra(e.value)
  }
  const onChangeCantidadCompraRecurrentes = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.CantidadCompraRecurrentes;   
    setErrors(newErrors)   
    setCantidadCompraRecurrentes(e.value)
  } 
  const onChangeMontoDeCompra = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.MontoDeCompra;   
    setErrors(newErrors)  
    setMontoDeCompra(e.value)
  }
  const onChangeMinimoMontoCompraRecurrente = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.MinimoMontoCompraRecurrente;   
    setErrors(newErrors)  
    setMinimoMontoCompraRecurrente(e.value)
  }
  const onChangePuntosPorCumpleanios = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.PuntosPorCumpleanios;   
    setErrors(newErrors)  
    setPuntosPorCumpleanios(e.value)
  }

  const onEditorValueChange = ( productKey, props, value ) => {  
    const updatedProducts = [...props.value];
    updatedProducts[props.rowIndex][props.field] = value;
    const aViews = [];
    props.value.forEach((item) => {
      aViews.push(item);
    })
    setConfiguracionFactor(aViews);
    setRowsGrid(updatedProducts);
  }; 

  const onRowEditInit = (event) => {
    originalRows[event.index] = { ...rowsGrid[event.index] };
    setOriginalRows(originalRows); 
  };

  const onRowEditCancel = (event) => {
    const products = [...rowsGrid];
    products[event.index] = originalRows[event.index];
    delete originalRows[event.index];
    setRowsGrid(products);
  };

  const factorEditor = ( productKey, props ) => { 
    return (
      <FieldNumber
        value={props.rowData.Factor} 
        onChange={(e) => onEditorValueChange(productKey, props, e.value)} 
        mode="decimal"
        minFractionDigits="2"
      />
)
  }; 

  const factorImporteEditor = ( productKey, props ) => { 
    return (
      <FieldNumber
        value={props.rowData.ImportePorPuntos} 
        onChange={(e) => onEditorValueChange(productKey, props, e.value)} 
        mode="decimal"
        minFractionDigits="2"
      />
)
  }; 

 const factorBodyTemplate = (rowData) => {
    return new Intl.NumberFormat('en-US', {style: 'decimal', minimumFractionDigits:"2", currency: 'USD'}).format(rowData.Factor);
 }

 const factorImporteBodyTemplate = (rowData) => {
  return new Intl.NumberFormat('en-US', {style: 'decimal', minimumFractionDigits:"2", currency: 'USD'}).format(rowData.ImportePorPuntos);
}

 const rightToolbarTemplate = () => {
  return (
    <>
      <Button label={t('common:Save')} icon="pi pi-save" className="p-button-rounded p-button-success p-mr-2" onClick={guardarConfiguracion} /> 
    </>
  )
}
  return (
    <>
      <PageContent title={t('common:Configuracion')}>
        <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
        <Row className="align-items-start"> 
          <Col lg={6} md={12} sm={12} xs={12}>  
            <Card className="radiuscard"> 
              <CardHeader className="warning">
                <CardTitle className="text-center p-2">{t('recompensas:Settings')}&nbsp;<small>{t('recompensas:Points')}</small></CardTitle>
              </CardHeader>    
              <CardBody className="padding50">
                <Row>
                  <Col>
                    <span className="labelconfig bold"> {t('recompensas:EtiquetaPuntosPorDolar')}</span>
                  </Col>
                </Row>
                <Row>&nbsp;</Row> 
                <Row>
                  <Col lg={4} md={12} sm={12} xs={12}> 
                    <div className="p-fluid p-field p-col">
                      <FieldNumber
                        isInputGroup={true}
                        labelGroup="Pts"
                        name="FactorBase"
                        value={FactorBase} 
                        onChange={onChangeFactorBase}
                        mode="decimal"
                        minFractionDigits={2}
                        errors={errors}
                      /> 
                    </div>
                  </Col>
                  <Col lg={8} md={12} sm={12} xs={12}>
                    <Row>
                      <Col>
                        <div className="p-grid small">
                          <div className="p-col text-center bold secondary-color">{t('recompensas:Example')}</div>
                        </div>
                        <div className="p-grid small">
                          <div className="p-col text-left bold secondary-color">{t('recompensas:Buyer1')}</div>
                          <div className="p-col text-right">$ {EjemploMontoDeCompra1} &nbsp; {t('recompensas:Dlls')}</div> 
                          <div className="p-col text-right">{EjemploMontoDeCompraPuntos1} &nbsp; {t('recompensas:Pts')}</div> 
                          <div className="p-col text-right">$ {EjemploCostoDeCompraPuntos1} &nbsp; {t('recompensas:Dlls')}</div> 
                        </div>
                        <div className="p-grid small">
                          <div className="p-col text-left bold secondary-color">{t('recompensas:Buyer2')}</div>
                          <div className="p-col text-right">$ {EjemploMontoDeCompra2} &nbsp; {t('recompensas:Dlls')}</div> 
                          <div className="p-col text-right">{EjemploMontoDeCompraPuntos2} &nbsp; {t('recompensas:Pts')}</div> 
                          <div className="p-col text-right">$ {EjemploCostoDeCompraPuntos2} &nbsp; {t('recompensas:Dlls')}</div> 
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row> 
                <Row>&nbsp;</Row> 
                <Row>
                  <Col>
                    <span className="labelconfig bold"> {t('recompensas:EtiquetaPuntosNecesitaPorDolar')}</span>
                  </Col>
                </Row>
                <Row>&nbsp;</Row> 
                <Row>
                  <Col lg={4} md={12} sm={12} xs={12}> 
                    <div className="p-fluid p-field p-col">
                      <FieldNumber
                        isInputGroup={true}
                        labelGroup="Pts"
                        name="ImportePorPuntos"
                        value={ImportePorPuntos} 
                        onChange={onChangeImportePorPuntos}
                        mode="decimal"
                        errors={errors}
                      /> 
                    </div>
                  </Col>
                </Row> 
                
                <div>
                  <hr></hr>
                </div>
                <Row>
                  <Col>
                    <span className="labelconfig bold"> {t('recompensas:HabilitarPuntosAlSubirCategoria')}</span>
                  </Col>
                </Row>
                <Row>&nbsp;</Row> 
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>  
                    <div className="p-fluid p-field p-col-6">
                      <FieldSelectButton 
                        name="HabilitarPuntosAlSubirCategoria"
                        value={HabilitarPuntosAlSubirCategoria} 
                        options={OpcionesYesNo} 
                        onChange={(e) => onHabilitarPuntosAlSubirCategoria(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                      /> 
                    </div>
                  </Col> 
                  <Col lg={8} md={8} sm={12} xs={12}> 
                    <div hidden={!HabilitarPuntosAlSubirCategoria}>
                      <div className="p-fluid p-field p-col-12">
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}> 
                            <span className="labeldescconfig">{t('recompensas:EtiquetaHabilitarPuntosAlSubirCategoria')}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}>    
                            <FieldNumber
                              name="PuntosAlSubirCategoria"
                              value={PuntosAlSubirCategoria} 
                              onChange={onChangePuntosAlSubirCategoria}
                              mode="decimal"
                              errors={errors}
                            />
                          </Col>
                        </Row> 
                      </div>
                    </div>
                  </Col>
                </Row> 
                <div>
                  <hr></hr>
                </div>
                <Row>
                  <Col>
                    <span className="labelconfig bold"> {t('recompensas:HabilitarDiferenteFactorPorCategoria')}</span>
                  </Col>
                </Row>
                <Row>&nbsp;</Row> 
                <Row className="align-items-start">
                  <Col lg={4} md={4} sm={12} xs={12}> 
                    <div className="p-fluid p-field p-col-12">
                      <FieldSelectButton 
                        name="HabilitarDiferenteFactorPorCategoria"
                        value={HabilitarDiferenteFactorPorCategoria} 
                        options={OpcionesYesNo} 
                        onChange={(e) => onHabilitarDiferenteFactorPorCategoria(e.value)} 
                        optionValue="value"
                        optionLabel="name"  
                        errors={errors}
                      /> 
                    </div> 
                  </Col> 
                  <Col lg={8} md={8} sm={12} xs={12}> 
                    <div hidden={!HabilitarDiferenteFactorPorCategoria}>
                      <span className="labeldescconfig">{t('recompensas:EtiquetaHabilitarDiferenteFactorPorCategoria')}</span>
                      <div className="p-fluid p-field p-col-12">
                        <FieldDataTable 
                          value={rowsGrid}  
                          header={t('recompensas:Categoria')}
                          editMode="row" 
                          className="editable-cells-table"
                          onRowEditInit={onRowEditInit} 
                          onRowEditCancel={onRowEditCancel}
                        >
                          <Column field="NomCategoria" header={t('recompensas:Nombre')} columnKey="Categoria"></Column> 
                          <Column field="Factor" header={t('recompensas:Factor')} body={factorBodyTemplate} editor={(props) => factorEditor('Factor', props)}></Column>
                          <Column rowEditor bodyStyle={{ textAlign: 'center' }}></Column>
                        </FieldDataTable>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div>
                  <hr></hr>
                </div>
                <Row>
                  <Col>
                    <span className="labelconfig bold"> {t('recompensas:HabilitarExpiracionDePuntos')}</span>
                  </Col>
                </Row>
                <Row>&nbsp;</Row> 
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>  
                    <div className="p-fluid p-field p-col-12">
                      <FieldSelectButton 
                        name="HabilitarExpiracionDePuntos"
                        value={HabilitarExpiracionDePuntos} 
                        options={OpcionesYesNo} 
                        onChange={(e) => onHabilitarExpiracionDePuntos(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                      /> 
                    </div>
                  </Col> 
                  <Col lg={8} md={8} sm={12} xs={12}>
                    <div hidden={!HabilitarExpiracionDePuntos}>
                      <div className="p-fluid p-field p-col-12">
                        <span className="labeldescconfig">{t('recompensas:EtiquetaExpiracionDePuntos')}</span>
                        
                        <Row>
                          <Col lg={6} md={6} sm={6} xs={12}>
                            <FieldNumber
                              isInputGroup
                              labelGroup={t('recompensas:Tiempo')}
                              name="ExpiracionDePuntos"
                              value={ExpiracionDePuntos} 
                              onChange={onChangeExpiracionDePuntos}
                              mode="decimal"
                              errors={errors}
                            />
                          </Col>
                          <Col lg={6} md={6} sm={6} xs={12}>
                            <FieldDropdown  
                              name="ClaFrecuencia"
                              value={ClaFrecuencia} 
                              options={Frecuencias}  
                              optionValue="ClaFrecuencia"
                              optionLabel="NomFrecuencia"
                              errors={errors}
                              onChange={(e) => onChangeFrecuencia(e.value)}
                            />    
                          </Col>
                        </Row>
                      </div> 
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12}> 
            <Card className="radiuscard"> 
              <CardHeader className="default">
                <CardTitle className="text-center p-2">{t('recompensas:Adicional')}&nbsp;<small>{t('recompensas:Points')}</small></CardTitle>
              </CardHeader>    
              <CardBody className="backg padding50">
                <Row>
                  <Col>  
                    <span className="labelconfig bold"> {t('recompensas:HabilitarPuntosAlRegistrarCliente')}</span>
                  </Col>
                </Row>
                <Row>&nbsp;</Row> 
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}> 
                    <div className="p-fluid p-field p-col-12">
                      <FieldSelectButton 
                        name="HabilitarPuntosAlRegistrarCliente"
                        value={HabilitarPuntosAlRegistrarCliente} 
                        options={OpcionesYesNo} 
                        onChange={(e) => onHabilitarPuntosAlRegistrarCliente(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                      /> 
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={12}>  
                    <div hidden={!HabilitarPuntosAlRegistrarCliente}> 
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}> 
                          <span className="labeldescconfig">{t('recompensas:EtiquetaHabilitarPuntosAlRegistrarCliente')}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}> 
                          <div className="p-fluid p-field p-col-12">
                            <FieldNumber
                              name="PuntosAlRegistrarCliente"
                              value={PuntosAlRegistrarCliente} 
                              onChange={onChangePuntosAlRegistrarCliente}
                              mode="decimal"
                              errors={errors}
                            />
                          </div>   
                        </Col>
                      </Row> 
                    </div> 
                  </Col>
                </Row> 
                <div hidden={true}>
                  <hr></hr>
                </div>
                <Row>
                  <Col>  
                    <span className="labelconfig bold"> {t('recompensas:HabilitarPuntosAlRecomendarCliente')}</span>
                  </Col>
                </Row> 
                <Row>&nbsp;</Row> 
                <Row className="align-items-start">
                  <Col lg={4} md={4} sm={12} xs={12}> 
                    <div className="p-fluid p-field p-col-12">
                      <FieldSelectButton 
                        name="HabilitarPuntosAlRecomendarCliente"
                        value={HabilitarPuntosAlRecomendarCliente} 
                        options={OpcionesYesNo} 
                        onChange={(e) => onHabilitarPuntosAlRecomendarCliente(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                      /> 
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={12}>  
                    <div hidden={!HabilitarPuntosAlRecomendarCliente}>
                      <div className="p-fluid p-field p-col-12">
                        <Row className="align-items-start">
                          <Col lg={12} md={12} sm={12} xs={12}> 
                            <span className="labeldescconfig">{t('recompensas:EtiquetaPuntosAlRecomendarCliente')}</span>
                          </Col>
                        </Row>
                        <Row className="align-items-start">
                          <Col lg={12} md={12} sm={12} xs={12}>    
                            <FieldNumber
                              name="PuntosAlRecomendarCliente"
                              value={PuntosAlRecomendarCliente} 
                              onChange={onChangePuntosAlRecomendarCliente}
                              mode="decimal"
                              errors={errors}
                            />
                          </Col>
                        </Row> 
                      </div>
                    </div> 
                  </Col>
                </Row> 
                <div>
                  <hr></hr>
                </div>
                <Row>
                  <Col>  
                    <span className="labelconfig bold"> {t('recompensas:HabilitarPuntosPorCompra')}</span>
                  </Col>
                </Row> 
                <Row>&nbsp;</Row> 
                <Row className="align-items-start">
                  <Col lg={4} md={4} sm={12} xs={12}> 
                    <div className="p-fluid p-field p-col-12">
                      <FieldSelectButton 
                        name="HabilitarPuntosPorCompra"
                        value={HabilitarPuntosPorCompra}
                        options={OpcionesYesNo} 
                        onChange={(e) => onHabilitarPuntosPorCompra(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                      /> 
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={12}> 
                    <div hidden={!HabilitarPuntosPorCompra}>
                      <Row className="align-items-start">
                        <Col>
                          <RadioButton 
                            value={HabilitarPuntosPorCompraRecurrente} 
                            name="porCompra" 
                            onChange={(e) => onHabilitarPuntosPorCompraRecurrente(e)}
                            checked={HabilitarPuntosPorCompraRecurrente}
                          />
                          <span> {t('recompensas:HabilitarPuntosPorCompraRecurrente')} </span>
                        </Col>
                      </Row>
                      <Row>&nbsp;</Row>
                      <Row hidden={!HabilitarPuntosPorCompraRecurrente} className="align-items-start paddingLeft10">
                        <Col>
                          <span className="labeldescconfig">{t('recompensas:AmountPurchasesTimeFrame')}</span>
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorCompraRecurrente}>
                        <Col>
                          <div className="p-fluid p-field p-col-12">
                            <FieldNumber
                              isInputGroup
                              labelGroup={t('recompensas:Compras')}
                              name="CantidadCompraRecurrentes"
                              value={CantidadCompraRecurrentes} 
                              onChange={onChangeCantidadCompraRecurrentes}
                              mode="decimal"
                              errors={errors}
                            /> 
                          </div>
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorCompraRecurrente} className="align-items-start paddingLeft10">
                        <Col>
                          <span className="labeldescconfig"> {t('recompensas:TimeFrame')}</span>
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorCompraRecurrente}>
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <div className="p-fluid p-field p-col-12">
                            <FieldNumber
                              isInputGroup
                              labelGroup={t('recompensas:Frecuencia')}
                              name="TiempoComprasRecurrentes"
                              value={TiempoComprasRecurrentes} 
                              onChange={onChangeTiempoComprasRecurrentes}
                              mode="decimal"
                              errors={errors}
                            />  
                          </div> 
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <div className="p-fluid p-field p-col-12">
                            <FieldDropdown  
                              name="ClaTiempoCompraRecurrentes"
                              value={ClaTiempoCompraRecurrentes} 
                              options={Frecuencias}  
                              optionValue="ClaFrecuencia"
                              optionLabel="NomFrecuencia" 
                              errors={errors}
                              onChange={(e) => onChangeTiempoCompraRecurrentes(e.value)}
                            />   
                          </div> 
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorCompraRecurrente} className="align-items-start paddingLeft10">
                        <Col>
                          <span className="labeldescconfig">{t('recompensas:MinimiumAmountPurchaseRequired')}</span>
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorCompraRecurrente}>
                        <Col>
                          <div className="p-fluid p-field p-col-12">
                            <FieldNumber
                              isInputGroup
                              labelGroup={t('recompensas:Monto')}
                              name="MinimoMontoCompraRecurrente"
                              value={MinimoMontoCompraRecurrente} 
                              onChange={onChangeMinimoMontoCompraRecurrente}
                              mode="decimal"
                              errors={errors}
                            /> 
                          </div>
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorCompraRecurrente} className="align-items-start paddingLeft10">
                        <Col>
                          <span className="labeldescconfig">{t('recompensas:AmountOfPoints')}</span>
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorCompraRecurrente}>
                        <Col>
                          <div className="p-fluid p-field p-col-12">
                            <FieldNumber
                              isInputGroup
                              labelGroup={t('recompensas:Points')}
                              name="PuntosPorCompraRecurrente"
                              value={PuntosPorCompraRecurrente} 
                              onChange={onChangePuntosPorCompraRecurrente}
                              mode="decimal"
                              errors={errors}
                            /> 
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col>
                          <RadioButton 
                            value={HabilitarPuntosPorMontoDeCompra} 
                            name="porCompra" 
                            onChange={(e) => onHabilitarPuntosPorMontoDeCompra(e)} 
                            checked={HabilitarPuntosPorMontoDeCompra}
                          />
                          <span> {t('recompensas:HabilitarPuntosPorMontoDeCompra')} </span>
                                            
                        </Col>
                      </Row>
                      <Row>&nbsp;</Row>
                      <Row hidden={!HabilitarPuntosPorMontoDeCompra} className="align-items-start paddingLeft10">
                        <Col>
                          <span className="labeldescconfig">{t('recompensas:MinimiumAmountPurchaseRequired')}</span>
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorMontoDeCompra} className="align-items-start paddingLeft10 paddignBottom10">
                        <Col>
                          <FieldNumber
                            isInputGroup
                            labelGroup={t('recompensas:Monto')}
                            name="MontoDeCompra"
                            value={MontoDeCompra} 
                            onChange={onChangeMontoDeCompra}
                            mode="decimal"
                            errors={errors}
                          />  
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorMontoDeCompra} className="align-items-start paddingLeft10">
                        <Col>
                          <span className="labeldescconfig">Amount of points</span>
                        </Col>
                      </Row>
                      <Row hidden={!HabilitarPuntosPorMontoDeCompra} className="align-items-start paddingLeft10 paddignBottom10">
                        <Col>
                          <FieldNumber
                            isInputGroup
                            labelGroup={t('recompensas:Points')}
                            name="PuntosPorMontoDeCompra"
                            value={PuntosPorMontoDeCompra} 
                            onChange={onChangePuntosPorMontoDeCompra}
                            mode="decimal"
                            errors={errors}
                          /> 
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row> 
                <div>
                  <hr></hr>
                </div>
                <Row>
                  <Col>  
                    <span className="labelconfig bold"> {t('recompensas:HabilitarPuntosPorCumpleanios')}</span>
                  </Col>
                </Row> 
                <Row>&nbsp;</Row> 
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}> 
                    <div className="p-fluid p-field p-col-12">
                      <FieldSelectButton 
                        name="HabilitarPuntosPorCumpleanios"
                        value={HabilitarPuntosPorCumpleanios} 
                        options={OpcionesYesNo}
                        onChange={(e) => onHabilitarPuntosPorCumpleanios(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                      /> 
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={12}> 
                    <div hidden={!HabilitarPuntosPorCumpleanios}>
                      <div className="p-fluid p-field p-col-12">
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}> 
                            <span className="labeldescconfig">{t('recompensas:EtiquetaPuntosPorCumpleanios')}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}>   
                            <div className="p-fluid p-field p-col-12">
                              <FieldNumber
                                name="PuntosPorCumpleanios"
                                value={PuntosPorCumpleanios} 
                                onChange={onChangePuntosPorCumpleanios}
                                mode="decimal"
                                errors={errors}
                              /> 
                            </div>
                          </Col>
                        </Row> 
                      </div>
                    </div>
                  </Col>
                </Row> 
                <div>
                  <hr></hr>
                </div>
                <div className="p-field" hidden={true}>
                  <Row>
                    <Col>
                      <span className="labelconfig bold">{t('recompensas:HabilitarPuntosAlCompartirEnRedes')}</span>
                    </Col> 
                    <Col>
                      <FieldSelectButton 
                        name="HabilitarPuntosAlCompartirEnRedes"
                        value={HabilitarPuntosAlCompartirEnRedes} 
                        options={OpcionesYesNo} 
                        onChange={(e) => onHabilitarPuntosAlCompartirEnRedes(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                      /> 
                    </Col> 
                  </Row> 
                </div>
                <div hidden={!HabilitarPuntosAlCompartirEnRedes}>
                  <div className="p-field">
                    <Row>
                      <Col>
                        &nbsp;
                      </Col>
                      <Col>
                        <span className="labeldescconfig">{t('recompensas:EtiquetaPuntosAlCompartirEnRedes')}</span>
                        <Row>
                          <Col lg={8} md={8} sm={8} xs={8}>  
                            <FieldText  
                              name="PuntosAlCompartirEnRedes" 
                              value={PuntosAlCompartirEnRedes}  
                              errors={errors}
                              onChange={onChangePuntosAlCompartirEnRedes}
                            /> 
                          </Col>
                        </Row>
                      </Col>
                    </Row> 
                  </div>
                </div>
                <div className="p-field" hidden={true}>
                  <Row>
                    <Col>
                      <span className="labelconfig bold">{t('recompensas:HabilitarPuntosProductosConDescuento')}</span>
                    </Col> 
                    <Col>
                      <FieldSelectButton 
                        name="HabilitarPuntosProductosConDescuento"
                        value={HabilitarPuntosProductosConDescuento} 
                        options={OpcionesYesNo} 
                        onChange={(e) => onHabilitarPuntosProductosConDescuento(e.value)} 
                        optionValue="value"
                        optionLabel="name"  
                        errors={errors}
                      /> 
                    </Col> 
                  </Row> 
                </div>
                <div hidden={true}>
                  <hr></hr>
                </div>  
              </CardBody>
            </Card>
          </Col>
        </Row>     
      </PageContent>
    </>
);
};
export default RecompensasConfiguracion;
