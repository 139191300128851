import React, { useState, useEffect, useRef } from 'react';
import {Card,CardBody,CardTitle,CardHeader,CardFooter,Row,Col}        from 'reactstrap'; 
import { useTranslation } from 'react-i18next';
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils'; 
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import logo from '../../assets/img/RW_icn_gray.png';
import { Timeline } from 'primereact/timeline';
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog'; 
import { Checkbox } from 'primereact/checkbox';
import moment from 'moment';  
import EstadoDeCuentaRecompensas from './EstadoDeCuentaRecompensas.jsx' 
import EstadoDeCuentaRecompensasDetalle from './EstadoDeCuentaRecompensasDetalle.jsx' 
import EstadoDeCuentaCupones from './EstadoDeCuentaCupones.jsx' 
import Highcharts from 'highcharts'; 
import { Knob } from 'primereact/knob';
import FieldTag from '../../components/Controls/FieldTag.jsx'  

// START COMPONENT
const EstadoDeCuentaDetalle = (props) => { 
  const { t } = useTranslation(['estadocuenta','common']);
  const [errors, setErrors] = useState({});   
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [ClaCliente, setClaCliente] = useState(props.filters === undefined ? 0 : props.filters.ClaCliente);
  const [NombreCliente, setNombreCliente] = useState(null); 
  const [SegundoNomCliente, setSegundoNomCliente] = useState(null); 
  const [ApellidoPaterno, setApellidoPaterno] = useState(null); 
  const [ApellidoMaterno, setApellidoMaterno] = useState(null); 
  const [FechaNacimiento, setFechaNacimiento] = useState(null); 
  const [FechaRegistro, setFechaRegistro] = useState(null); 
  const [FechaExpiracionCategoria, setFechaExpiracionCategoria] = useState(null); 
  const [Referencia, setReferencia] = useState(null); 
  const [Direccion1, setDireccion1] = useState(null); 
	const [TelefonoMovil,setTelefonoMovil] = useState(null); 
	const [CodigoPostal,setCodigoPostal] = useState(null); 
	const [CorreoElectronico,setCorreoElectronico] = useState(null); 
	const [ClaveCategoria,setClaveCategoria] = useState(null); 
	const [Color,setColor] = useState(null); 
	const [EsPermitirPagoConTarjetaDeCredito,setEsPermitirPagoConTarjetaDeCredito] = useState(false); 
	const [EsPermitirPagoEnTienda,setEsPermitirPagoEnTienda] = useState(false); 
	const [EsPermitirPagarConCuentaDeComercio,setEsPermitirPagarConCuentaDeComercio] = useState(false); 
	const [NomCategoria,setNomCategoria] = useState(null); 
	const [NomEmpresa,setNomEmpresa] = useState(null); 
  const [PuntosDisponibles, setPuntosDisponibles] = useState(true); 
  const [Filters, setFilters] = useState({});  
  const [EsLista, setEsLista] = useState(false); 
  const [EsInfoExpiracion, setEsInfoExpiracion] = useState(false); 
  const [EsInfoReferencia, setEsInfoReferencia] = useState(false);
  const [Items, setItems] = useState([]); 
  const [CuponesDisponibles, setCuponesDisponibles] = useState([]); 
  const [CampaniasDisponibles, setCampaniasDisponibles] = useState([]); 
  const [EsVerRecompensas, setEsVerRecompensas] = useState(false); 
  const [EsVerCupones, setEsVerCupones] = useState(false); 
  const [EsVerRecompensaDetalle, setEsVerRecompensaDetalle] = useState(false); 
  const [PuntosAUtilizar, setPuntosAUtilizar] = useState(0);
  const [ImportePorPuntos, setImportePorPuntos] = useState(0);
  const [MontoPorPuntos, setMontoPorPuntos] = useState(0);
  const [AbrirModalReedem, setAbrirModalReedem] = useState(false);
  const [CuponGenerateReedem, setCuponGenerateReedem] = useState({});
  
  useEffect(() => { 
    const iCliente = (props.filters === undefined ? 0 : props.filters.ClaCliente);
    setClaCliente(iCliente);
    if(iCliente>0){
      onDetalleCliente(props.filters);
    }else{
      resetCliente();
    }
  }, [props.filters]);

  useEffect(() => {
  }, [Items]);

  const increment = () => {
   setPuntosAUtilizar(PuntosDisponibles);
   const iImporte = (ImportePorPuntos === null ? 1 : ImportePorPuntos);
  setMontoPorPuntos((PuntosDisponibles / iImporte).toFixed(2));
}

const decrement = () => {
  setPuntosAUtilizar(0);
   const iImporte = (ImportePorPuntos === null ? 1 : ImportePorPuntos);
  setMontoPorPuntos((0 / iImporte).toFixed(2));
  
}

  const onDetalleCliente = (rowData) => {
    const paramsService = {
      ClaCliente:rowData.ClaCliente,
      ClaEmpresa
    }
    const urlWebServiceExpirationType = `${config.UrlApiProject}Cliente/GetClienteEstadoCuenta`; 
    callApi(urlWebServiceExpirationType, 'POST', paramsService, (response) => {
      const data = response.data.Cliente[0];
      const dataRecompensas = response.data.Recompensas;
      const dataCupones = response.data.Cupones;
      const dataCampanias = response.data.Campanias;
      if(data!== undefined){
        setClaCliente(data.ClaCliente);  
        setNombreCliente(data.NomCliente);
        setSegundoNomCliente(data.SegundoNomCliente);
        setApellidoPaterno(data.ApellidoPaterno);
        setApellidoMaterno(data.ApellidoMaterno);
        setFechaNacimiento(moment(data.FechaNacimiento).format("LL"));
        setFechaRegistro(moment(data.FechaRegistro).format("MM/DD/YYYY"));
        setFechaExpiracionCategoria(moment(data.FechaExpiracionCategoria).format("MM/DD/YYYY"));
        setReferencia(data.Referencia);
        setDireccion1(data.Direccion1);
        setTelefonoMovil(data.TelefonoMovil);
        setCodigoPostal(data.CodigoPostal);
        setCorreoElectronico(data.CorreoElectronico);
        setClaveCategoria(data.ClaveCategoria);
        setColor(data.Color);
        setNomCategoria(data.NomCategoria);
        setNomEmpresa(data.NombreEmpresa);
        setPuntosDisponibles(data.PuntosDisponibles);
        setImportePorPuntos(data.ImportePorPuntos);
        setEsPermitirPagoConTarjetaDeCredito(data.EsPermitirPagoConTarjetaDeCredito); 
        setEsPermitirPagoEnTienda(data.EsPermitirPagoEnTienda);  
        setEsPermitirPagarConCuentaDeComercio(data.EsPermitirPagarConCuentaDeComercio); 
        setPuntosAUtilizar(0);
        setMontoPorPuntos(0);
        setItems(dataRecompensas);
        setCuponesDisponibles(dataCupones);
        setCampaniasDisponibles(dataCampanias);
      }
    });  
  };

  const onClickDetalle = (item) => {
    const paramsService = {
      IdPedido:item.IdPedido
    }
    const urlWebServiceEstadoCuenta = `${config.UrlApiProject}Cliente/GetClienteEstadoCuentaRecompensaDetalle`; 
    callApi(urlWebServiceEstadoCuenta, 'POST', paramsService, (response) => {
      const dataPedido = response.data.Recompensa[0];
      if(dataPedido!== undefined){
        item.EsDetalle = !item.EsDetalle;
        const items = [];
        Items.forEach((data) => {
          if(data.Id === item.Id){
            item.Pedido = dataPedido;
            items.push(item);
          }else{
            items.push(data);
          }
        });
        setItems(items);
      }
    });  
  };
  
  const onClickDetallePedido = (item) => {
    setFilters({ ...Filters, IdPedido: item.IdPedido});
    setEsVerRecompensaDetalle(true);
  }

  const resetCliente = () => { 
    setClaCliente(0);  
    setNombreCliente('');
  } 

  const customizedMarker = (item) => {
    return (
      <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.Color }}>
        <i className={item.Icono}></i>
      </span>
    );
};
 
const customizedContent = (item) => {
  return (
    <Card title={item.TipoRecompensa}>
      <CardHeader className={`${item.Clase  } text-center`}>
        <Row>
          <Col>
            <b> {item.TipoRecompensa} </b> 
          </Col>
          <Col hidden={item.Clase!=="success"} className="text-right Cursor">
            <i
              className="pi pi-info-circle" 
              onClick={(e) => onClickDetalle(item)}
            >
            </i>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row hidden={item.EsDetalle}>
          <Col className="text-center">
            <h2 className='paddignBottom5 border-bottom'>{Highcharts.numberFormat(item.Puntos,0,'.',',')}</h2>
            <small> {moment(item.FechaRecompensa).format("LL")} </small>
          </Col>
        </Row> 
        <Row hidden={!item.EsDetalle}>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <div className="p-grid small">
              <div className="p-col text-left bold secondary-color">{t('estadocuenta:Pedido')}</div>
              <div className="p-col text-right">{(item.Pedido!== undefined ? item.Pedido.NumPedido : '')}</div> 
            </div>
            <div className="p-grid small">
              <div className="p-col text-left bold secondary-color">{t('estadocuenta:Subtotal')}</div>
              <div className="p-col text-right">$ {(item.Pedido!== undefined ? Highcharts.numberFormat(item.Pedido.Subtotal,2,'.',',') : '')}  </div> 
            </div>
            <div className="p-grid small">
              <div className="p-col text-left bold danger-color">{t('estadocuenta:Descuento')}</div>
              <div className="p-col text-right danger-color">$ {(item.Pedido!== undefined ? Highcharts.numberFormat(item.Pedido.ImporteDescuento,2,'.',',') : '')}  </div> 
            </div>
            <div className="p-grid small border-bottom">
              <div className="p-col text-left bold secondary-color">{t('estadocuenta:Total')}</div>
              <div className="p-col text-right">$ {(item.Pedido!== undefined ? Highcharts.numberFormat(item.Pedido.Total,2,'.',',') : '')}  </div> 
            </div>
            <Row>
              <Col className="text-center">
                <div className="p-grid small border-bottom">
                  <div className="p-col text-center bold secondary-color">{t('estadocuenta:PuntosGenerados')}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                {(item.Pedido!== undefined ? Highcharts.numberFormat(item.Pedido.PuntosGenerados,0,'.',',') : '')}
              </Col>
            </Row>
            <Row className="padding10">
              <Col className="text-center">
                <Button
                  onClick={(e) => onClickDetallePedido(item)} 
                  label='Detail'
                  className="p-button-warning p-button-sm"
                />
              </Col>
            </Row>
          </Col>
        </Row> 
      </CardBody>
    </Card>
  );
};

const onClickLista = () => {
  setEsLista(!EsLista);
};

const onClickExpiracion = () => {
  setEsInfoExpiracion(!EsInfoExpiracion);
};

const onClickReferencia = () => {
  setEsInfoReferencia(!EsInfoReferencia);
};

const onChangeReedem = (e) => { 
  const val = (e.value>PuntosDisponibles?PuntosDisponibles:e.value);
  setPuntosAUtilizar(val);
  const iImporte = (ImportePorPuntos === null ? 1 : ImportePorPuntos);
  setMontoPorPuntos((val / iImporte).toFixed(2));
}

const onClickReedem = () => {
  const paramsService = {
    ClaCliente,
    PuntosAUtilizar,
    ClaEmpresa
  }
  const urlServiceReedem = `${config.UrlApiProject}Cliente/PostReedem`; 
  callApi(urlServiceReedem, 'POST', paramsService, (response) => {
    response.data.Cupon.MontoPorPuntos = MontoPorPuntos;
    onDetalleCliente(props.filters);
    setCuponGenerateReedem(response.data.Cupon);
    setAbrirModalReedem(true); 
  });  
};

const onSaveTypePayment = (bPermitirPagoConTarjeta,bPermitirPagoConCuenta,bPermitirPagoEnTienda) => {
  const paramsService = {
    ClaCliente,
    EsPermitirPagoConTarjetaDeCredito:bPermitirPagoConTarjeta,
    EsPermitirPagarConCuentaDeComercio:bPermitirPagoConCuenta,
    EsPermitirPagoEnTienda:bPermitirPagoEnTienda
  }
  const urlServiceTypePayment = `${config.UrlApiProject}Cliente/PostTypePayment`; 
  callApi(urlServiceTypePayment, 'POST', paramsService, (response) => {
    const data = response;
  });  
};

const regresar = () => {
  if (props.onAfterReturn !=  null){ 
    props.onAfterReturn();
  }
}

const verRecompensas = () => {
  setFilters({ ...Filters, ClaCliente});
  setEsVerRecompensas(true);
}

const verCupones = () => {
  setFilters({ ...Filters, ClaCliente});
  setEsVerCupones(true);
}

const regresarDetalle = () => {
  setEsVerRecompensas(false);
}
const regresarDetalleCupones = () => {
  setEsVerCupones(false);
}

const headerPanelDatosPersonales= (
  <Row>
    <Col className="text-center">
      <span className="orange-color">{t('estadocuenta:DatosPersonales')}</span>
    </Col>
  </Row>
);
const headerPanelInformacion= (
  <Row>
    <Col className="text-center">
      <span className="default-color">{t('estadocuenta:InformacionAdicional')}</span>
    </Col>
  </Row>
);

const cerrarModalReedem = () => {
  setCuponGenerateReedem({});
  setAbrirModalReedem(false);
}  

const modalFooter = () => {
return (
  <>
    <div className="text-center">
      &nbsp;
    </div>
  </>
);
} 

const onChangeEsPermitirPagoConTarjetaDeCredito = (checked) => {   
  setEsPermitirPagoConTarjetaDeCredito(checked); 
  onSaveTypePayment(checked,EsPermitirPagarConCuentaDeComercio,EsPermitirPagoEnTienda);
}   
const onChangeEsPermitirPagoEnTienda = (checked) => {   
  setEsPermitirPagoEnTienda(checked); 
  onSaveTypePayment(EsPermitirPagoConTarjetaDeCredito,EsPermitirPagarConCuentaDeComercio,checked);
}   
const onChangeEsPermitirPagarConCuentaDeComercio = (checked) => {   
  setEsPermitirPagarConCuentaDeComercio(checked); 
  onSaveTypePayment(EsPermitirPagoConTarjetaDeCredito,checked,EsPermitirPagoEnTienda);
}   

  return (
    <>
      <div> 
        {
              EsVerRecompensas ?
              (
                <div>
                  <Row className="paddignBottom10">
                    <Col>
                      <h2> {`${NombreCliente  } ${  ApellidoPaterno  } ${  ApellidoMaterno}`} </h2> 
                    </Col>
                    <Col className="text-right">
                      <button
                        type="button" 
                        className="p-button p-button-rounded p-button-icon-only p-button-black" 
                        onClick={regresarDetalle}
                      >
                        <i className="pi pi-arrow-left"></i>
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <EstadoDeCuentaRecompensas isHidden={true} filters={Filters} />
                    </Col>
                  </Row>
                </div>
              ):(
                <> 
                  {
              EsVerCupones ?
              (
                <>
                  <div>
                    <Row className="paddignBottom10">
                      <Col>
                        <h2> {`${NombreCliente  } ${  ApellidoPaterno  } ${  ApellidoMaterno}`} </h2> 
                      </Col>
                      <Col className="text-right">
                        <button
                          type="button" 
                          className="p-button p-button-rounded p-button-icon-only p-button-black" 
                          onClick={regresarDetalleCupones}
                        >
                          <i className="pi pi-arrow-left"></i>
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <EstadoDeCuentaCupones isHidden={true} filters={Filters} />
                      </Col>
                    </Row>
                  </div>
                </>
):
              (
                <>
                  <div>
                    <Row className="align-items-start padding10">
                      <Col lg={4} md={4} sm={4} xs={12}>
                        <Row className="align-items-start">
                          <Col>
                            <Card>
                              <CardHeader className="text-center gradient-container gradientblue"> 
                                <p className="text-right">
                                  <button
                                    type="button" 
                                    className="p-button p-button-rounded p-button-icon-only"
                                    onClick={regresar}
                                  >
                                    <i className="pi pi-chevron-left"></i>
                                  </button>
                                </p>  
                                <p>
                                  <h1>{NombreCliente} {ApellidoPaterno}</h1>
                                  <small>{NomEmpresa}</small>
                                </p>  
                              </CardHeader>
                              <CardBody>
                                <Row className="align-items-start">
                                  <Col lg={6} md={12} sm={12} xs={12}>
                                    <Row className='pt-2'>
                                      <Col lg={12} md={12} sm={12} xs={12} className='text-center'>
                                        <Row>
                                          <Col>
                                            <img height={40} src={logo} alt="rwp" />  
                                          </Col>
                                          <Col>
                                            <Row>
                                              <Col>
                                                <small> {t('estadocuenta:AvailablePoints')} </small>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <span className="bold">{Highcharts.numberFormat(PuntosDisponibles,0,'.',',')}</span>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row> 
                                      </Col> 
                                    </Row>
                                    <Row className='pt-2'>
                                      <Col lg={12} md={12} sm={12} xs={12} className='text-center'>
                                        <Row>
                                          <Col>
                                            <Avatar
                                              label={ClaveCategoria}
                                              className="p-mr-2"
                                              size="large"
                                              style={{ backgroundColor: {Color}, color: '#000', fontWeight:'bold' }}
                                              shape="circle"
                                            /> 
                                          </Col>
                                          <Col>
                                            <Row>
                                              <Col>
                                                <small>{t('estadocuenta:Categoria')}</small>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <span className="bold"> {NomCategoria} </span>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      
                                      </Col> 
                                    </Row>

                                    <Row className='pt-2'>
                                      <Col lg={6} md={12} sm={12} xs={12}>
                                        <span className="info-color">  {t('estadocuenta:MiembroDesde')} </span> 
                                      </Col>
                                      <Col lg={6} md={12} sm={12} xs={12}>
                                        <span className="default-color text-right"> <small> {FechaRegistro} </small></span> 
                                      </Col>
                                    </Row>
                                    <Row className='pt-2'>
                                      <Col lg={6} md={12} sm={12} xs={12}>
                                        <span className="info-color">  {t('estadocuenta:Referencia')} </span>  
                                      </Col>
                                      <Col lg={6} md={12} sm={12} xs={12}>
                                        <span className="default-color text-right"> <small> {Referencia} </small> &nbsp; 
                                          <i className="pi pi-info-circle Cursor" onClick={onClickReferencia}></i>
                                        </span> 
                                      </Col>
                                    </Row>
                                    <Row className='pt-2'>
                                      <Col lg={6} md={12} sm={12} xs={12}>
                                        <span className="info-color">  {t('estadocuenta:Expiracion')} </span> 
                                      </Col>
                                      <Col lg={6} md={12} sm={12} xs={12}>
                                        <span className="danger-color text-right"> <small> {FechaExpiracionCategoria} </small> &nbsp; 
                                          <i className="pi pi-info-circle Cursor" onClick={onClickExpiracion}></i>
                                        </span> 
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={6} md={12} sm={12} xs={12}>
                                    <Row className='text-center pt-2'>
                                      <Col>
                                        <Knob value={PuntosAUtilizar} step={ImportePorPuntos} valueTemplate={Highcharts.numberFormat(PuntosAUtilizar,0,'.',',')} min={0} max={PuntosDisponibles} onChange={onChangeReedem}  />
                                        <FieldTag className="default" onClick={decrement} label={t('estadocuenta:Clear')}></FieldTag>&nbsp;
                                        <FieldTag className="primary" onClick={increment} label={t('estadocuenta:Max')}></FieldTag>
                                      </Col>
                                    </Row>
                                    <Row> 
                                      <Col>
                                        <small className=''> {t('estadocuenta:PointsToReedem')} </small>
                                      </Col>
                                      <Col className='text-right'>
                                        <small className='bold'> {t('estadocuenta:Pts')} &nbsp; {Highcharts.numberFormat(PuntosAUtilizar,0,'.',',')}   </small>
                                      </Col>
                                    </Row>
                                    <Row> 
                                      <Col>
                                        <small className=''> {t('estadocuenta:Points Remaining')} </small>
                                      </Col>
                                      <Col className='text-right'>
                                        <small className='bold'>  $ &nbsp; {Highcharts.numberFormat(MontoPorPuntos,2,'.',',')}  </small>
                                      </Col>
                                    </Row>
                                    <Row className='text-center pt-2 pb-2'>
                                      <Col lg={12} md={12} sm={12} xs={12}>
                                        <Button 
                                          disabled={PuntosAUtilizar===0} 
                                          className={PuntosAUtilizar===0?'p-button p-component p-button-outlined p-button-secondary':'p-button p-component p-button-outlined gradientblue'}
                                          onClick={onClickReedem}
                                        > 
                                          {t('estadocuenta:CreateCouponof')}  &nbsp; $ {Highcharts.numberFormat(MontoPorPuntos,2,'.',',')}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6} md={12} sm={12} xs={12}>
                                   
                                  </Col>
                                  <Col lg={6} md={12} sm={12} xs={12}>
                                   
                                  </Col>
                                </Row>
                                <Row hidden={!EsInfoExpiracion}>
                                  <Col>
                                    <Card className="padding10"> 
                                      <CardBody className="padding10"> 
                                        <h3>{t('estadocuenta:Expiracion')}</h3>
                                        <p className="labelexpiracion">
                                          <Row>
                                            <Col> {t('estadocuenta:ExpiracionMensaje1')} <b> {NomCategoria}</b> {t('estadocuenta:ExpiracionMensaje2')} <b>{FechaExpiracionCategoria}</b>, 
                                              {t('estadocuenta:ExpiracionMensaje3')} <b> {NomCategoria}</b> {t('estadocuenta:ExpiracionMensaje4')} 
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="info-color padding10"> {t('estadocuenta:ComoFunciona')}</Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              {t('estadocuenta:ExpiracionMensaje5')}
                                              <div>
                                                <p>
                                                  <small><b> {t('estadocuenta:Importante')}: </b> {t('estadocuenta:ExpiracionMensaje6')}</small>   
                                                </p>
                                              </div>
                                            </Col>
                                          </Row>
                                        </p>
                                      </CardBody> 
                                    </Card>
                                  </Col>
                                </Row>
                                <Row hidden={!EsInfoReferencia}>
                                  <Col>
                                    <Card className="padding10"> 
                                      <CardBody className="padding10"> 
                                        <h3>{t('estadocuenta:Referencia')}</h3>
                                        <p className="labelexpiracion">
                                          <Row>
                                            <Col> 
                                              {t('estadocuenta:ReferenciaMensaje1')}  <b>{Referencia}</b> {t('estadocuenta:ReferenciaMensaje2')} 
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col className="info-color padding10"> {t('estadocuenta:ComoFunciona')}</Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              {t('estadocuenta:ReferenciaMensaje3')} <b> 500 {t('estadocuenta:Puntos')}  </b>.
                                            </Col>
                                          </Row>
                                        </p>
                                      </CardBody> 
                                    </Card>
                                  </Col>
                                </Row>
                                <Row hidden={true}>
                                  <Col>
                                    <Panel 
                                      className="customPanel"
                                      header={headerPanelInformacion}
                                      toggleable
                                    > 
                                      <Row className="align-items-start padding10">
                                        <Col lg={3} md={3} sm={3} xs={3}>
                                          <Avatar
                                            icon="pi pi-calendar"
                                            className="p-mr-2"
                                            size="large" 
                                            style={{ backgroundColor: '#D0CECE', color: '#ffffff' }}
                                            shape="circle"
                                          />
                                        </Col> 
                                        <Col>
                                          <Row>
                                            <Col>
                                              <small>{t('estadocuenta:FechaNacimiento')}</small>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <small>{FechaNacimiento}</small>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Panel>
                                  </Col>
                                </Row> 
                                <Row>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <Panel 
                                      className="customPanel"
                                      header={headerPanelDatosPersonales}
                                      toggleable
                                    > 
                                      <Row>
                                        <Col>
                                          <div className="p-fluid p-field p-col-12"> 
                                            <label htmlFor="binary">
                                              <Checkbox 
                                                checked={EsPermitirPagoConTarjetaDeCredito} 
                                                onChange={(e) => onChangeEsPermitirPagoConTarjetaDeCredito(e.checked)}
                                              />    
                                              &nbsp; {t('estadocuenta:PermitirPagoTarjetaCredito')} 
                                            </label>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="p-fluid p-field p-col-12"> 
                                            <label htmlFor="binary">
                                              <Checkbox 
                                                checked={EsPermitirPagoEnTienda} 
                                                onChange={(e) => onChangeEsPermitirPagoEnTienda(e.checked)}
                                              />    
                                              &nbsp; {t('estadocuenta:PermitirPagoEnTienda')} 
                                            </label>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="p-fluid p-field p-col-12"> 
                                            <label htmlFor="binary">
                                              <Checkbox 
                                                checked={EsPermitirPagarConCuentaDeComercio} 
                                                onChange={(e) => onChangeEsPermitirPagarConCuentaDeComercio(e.checked)}
                                              />    
                                              &nbsp; {t('estadocuenta:PermitirPagarConCuentaDeComercio')}
                                            </label>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-start padding10">
                                        <Col lg={3} md={3} sm={3} xs={3}>
                                          <Avatar
                                            icon="pi pi-calendar"
                                            className="p-mr-2"
                                            size="large" 
                                            style={{ backgroundColor: '#D0CECE', color: '#ffffff' }}
                                            shape="circle"
                                          />
                                        </Col> 
                                        <Col>
                                          <Row>
                                            <Col>
                                              <small><b>{t('estadocuenta:FechaNacimiento')}</b></small>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <small>{FechaNacimiento}</small>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-start padding10">
                                        <Col lg={3} md={3} sm={3} xs={3}>
                                          <Avatar
                                            icon="pi pi-envelope"
                                            className="p-mr-2"
                                            size="large" 
                                            style={{ backgroundColor: '#D0CECE', color: '#ffffff' }}
                                            shape="circle"
                                          />
                                        </Col> 
                                        <Col>
                                          <Row>
                                            <Col>
                                              <small><b> {t('estadocuenta:Correo')}</b></small>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <small>{CorreoElectronico}</small>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-start padding10">
                                        <Col lg={3} md={3} sm={3} xs={3}>
                                          <Avatar
                                            icon="pi pi-mobile"
                                            className="p-mr-2"
                                            size="large" 
                                            style={{ backgroundColor: '#D0CECE', color: '#ffffff' }}
                                            shape="circle"
                                          />
                                        </Col> 
                                        <Col>
                                          <Row>
                                            <Col>
                                              <small><b>{t('estadocuenta:Telefono')} </b></small>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <small>{TelefonoMovil}</small>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row> 
                                      <Row className="align-items-start padding10">
                                        <Col lg={3} md={3} sm={3} xs={3}>
                                          <Avatar
                                            icon="pi pi-map-marker"
                                            className="p-mr-2"
                                            size="large" 
                                            style={{ backgroundColor: '#D0CECE', color: '#ffffff' }}
                                            shape="circle"
                                          />
                                        </Col> 
                                        <Col>
                                          <Row>
                                            <Col>
                                              <small><b> {t('estadocuenta:Direccion')}</b></small>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <small>{Direccion1}</small>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>  
                                      <Row className="align-items-start paddignTop10 text-center">
                                        <Col lg={12} md={12} sm={12} xs={12}> 
                                          <Row className="padding5">
                                            &nbsp;
                                          </Row>
                                        </Col>
                                      </Row> 
                                    </Panel>
                                  </Col>
                                </Row> 
                              </CardBody> 
                            </Card> 
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={12}>
                        <Card>
                          <CardHeader className="warning text-center">
                            <Row>
                              <Col>
                                <div className='p-2'>{t('estadocuenta:MisRecompensasHistorial')}</div>
                              </Col> 
                            </Row>
                            <Row>
                              <Col hidden={true} className="text-right">
                                <button 
                                  type="button" 
                                  className="p-button p-button-rounded p-button-icon-only p-button-black" 
                                  onClick={onClickLista}
                                >
                                  <i className={EsLista?'pi pi-ellipsis-v':'pi pi-bars'}></i>
                                </button>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row><Col>&nbsp;</Col></Row>
                            <Row>
                              <Col lg={12} md={12} sm={12} xs={12}>
                                {
                                  EsLista ? 
                                  (
                                    <div>
                                      {Items.map((item) => { 
                                        return (
                                          <Row className="align-items-start padding5 border-bottom">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                              <span className={`${item.Clase  }-color`}>  {item.TipoRecompensa} </span> 
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="text-right">
                                              <Row>
                                                <Col>
                                                  <h3 className={`${item.Clase  }-color`}>{item.Puntos}</h3>
                                                  <small> {moment(item.FechaRecompensa).format("LL")} </small>
                                                </Col>
                                              </Row> 
                                            </Col>
                                          </Row>
                                        );
                                    })}  
                                    </div>
                                    ):
                                    ( 
                                      <div>
                                        <div className="timeline-demo">
                                          <Timeline
                                            value={Items}
                                            align="alternate" 
                                            className="customized-timeline"
                                            marker={customizedMarker} 
                                            content={customizedContent}
                                          />
                                        </div>
                                        <Row className="align-items-start padding5 border-bottom">
                                          <Col>&nbsp;</Col>
                                        </Row> 
                                      </div>
                                    )
                                  } 
                              </Col>
                            </Row>
                          </CardBody> 
                        </Card>  
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={12}>
                        <Card className="default-form">
                          <CardHeader className="success text-center">
                            <Row>
                              <Col>
                                <div className='p-2'>{t('estadocuenta:MisRecompensas')}</div>
                              </Col> 
                            </Row>
                          </CardHeader>
                          <CardBody>    
                            <div>
                              {CuponesDisponibles.map((item) => { 
                              return (
                                <Row className="padding5">
                                  <Col lg={12} md={12} sm={12} xs={12}> 
                                    {
                                      item.ClaTipoCupon=== 3 ? (
                                        <> 
                                          <Card
                                            style={{ border: 'solid #ADD89D 1px', backgroundColor: '#F5FAF3' }} 
                                            className="radiuscard padding10 text-center"
                                          > 
                                            <CardBody> 
                                              <Row className='align-items-start'>
                                                <Col lg={4} md={4} sm={4} xs={4}>
                                                  <Row>
                                                    <Col>
                                                      <img height={40} src={logo} alt="rwp" /> 
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col>
                                                      <h1>
                                                        ${Highcharts.numberFormat(item.Descuento,2,'.',',')} 
                                                      </h1> 
                                                    </Col>
                                                  </Row> 
                                                </Col>
                                                <Col lg={8} md={8} sm={8} xs={8}>
                                                  <Row>
                                                    <Col>
                                                      <h1>
                                                        {item.CodigoPromocion}
                                                      </h1>
                                                    </Col>
                                                  </Row> 
                                                  <Row>
                                                    <Col className='danger-color'>
                                                      <span>   
                                                        <b> {t('estadocuenta:Expires')} </b> {moment(item.FechaFinalExpiracion).format("LL")}
                                                      </span>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row> 
                                            </CardBody> 
                                          </Card>
                                        </>
                                      ):(
                                        <></>
                                      )
                                    } 
                                  </Col>
                                </Row>
                                  );
                                })}  
                            </div>
                          </CardBody> 
                          <CardFooter>
                            &nbsp;
                          </CardFooter>
                        </Card>
                        <Row>
                          <Col>&nbsp;</Col>
                        </Row> 
                        <Card className="default-form">
                          <CardHeader className="orange text-center">
                            <Row>
                              <Col>
                                <div className='p-2'>{t('estadocuenta:PromocionesDisponibles')}</div>
                              </Col> 
                            </Row>
                          </CardHeader>
                          <CardBody>    
                            <div>
                              {CuponesDisponibles.map((item) => { 
                              return (
                                <Row className="align-items-start padding5">
                                  <Col lg={12} md={12} sm={12} xs={12}> 
                                    {
                                      item.ClaTipoCupon=== 1 ? (
                                        <>
                                          <Card className="radiuscard padding10"> 
                                            <CardTitle>
                                              <Row>
                                                <Col>
                                                  <h1>
                                                    {t('estadocuenta:DescuentoPorCupon')} 
                                                  </h1>
                                                </Col>
                                              </Row>
                                            </CardTitle>
                                            <CardBody> 
                                              <Row className="align-items-start padding5">
                                                <Col lg={2} md={2} sm={2} xs={2}>
                                                  {
                                                      item.Img !== "" ? (
                                                        <>
                                                          <img alt="" src={item.Img} role="presentation" className='img-fluid img-thumbnail fa-5x' />
                                                        </>
                                                      ):(
                                                        <>
                                                          <i className='fa fa-file-image fa-5x marginRight2'></i>
                                                        </>
                                                      )
                                                    }
                                                </Col>
                                                <Col lg={10} md={10} sm={10} xs={10}>
                                                  <Row id="producto">
                                                    <Col> 
                                                      <Row>
                                                        <Col>
                                                          <h2> {item.NomCuponDescuento} </h2>
                                                        </Col>  
                                                        <Col className='text-right'> 
                                                          {item.CodigoPromocion}  
                                                        </Col>
                                                      </Row> 
                                                      <Row>
                                                        <Col> 
                                                          {
                                                            item.ClaTipoDescuento===1 ? (
                                                              <>
                                                                {t('recompensas:AplicarTipoDescuento')} {item.Descuento}{t('cupones:EsDescuentoPorcentajeSimbol')}
                                                              </>
                                                            ):(
                                                              <>
                                                                {t('recompensas:AplicarTipoDescuento')} {item.Descuento}{t('cupones:EsDescuentoImporteSimbol')} 
                                                              </>
                                                            )
                                                          }
                                                        </Col> 
                                                      </Row> 
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </CardBody>  
                                            <CardFooter>
                                              <Row> 
                                                <Col className='text-right orange-color'> 
                                                  {t('recompensas:Expires')} &nbsp; {moment(item.FechaFinalExpiracion).format("LL")}  
                                                </Col> 
                                              </Row>
                                            </CardFooter>
                                          </Card>  
                                        </>
                                      ):(
                                        item.ClaTipoCupon=== 2 ? (
                                          <>

                                            <Card className="radiuscard padding10"> 
                                              <CardTitle>
                                                <Row>
                                                  <Col>
                                                    <h1>
                                                      {t('estadocuenta:Deal')} 
                                                    </h1>
                                                  </Col>
                                                </Row>
                                              </CardTitle>
                                              <CardBody> 
                                                <Row className="align-items-start padding5">
                                                  <Col lg={2} md={2} sm={2} xs={2}>
                                                    <img alt="" src={item.Img} role="presentation" className='img-fluid img-thumbnail' />
                                                  </Col>
                                                  <Col lg={10} md={10} sm={10} xs={10}>
                                                    <Row id="producto">
                                                      <Col> 
                                                        <Row>
                                                          <Col>
                                                            <h2> {item.NomCuponDescuento} </h2>
                                                          </Col>  
                                                        </Row> 
                                                        <Row>
                                                          <Col> 
                                                            {
                                                              item.ClaTipoDescuento===1 ? (
                                                                <>
                                                                  {t('recompensas:AplicarTipoDescuento')} {item.Descuento}{t('cupones:EsDescuentoPorcentajeSimbol')}
                                                                </>
                                                              ):(
                                                                <>
                                                                  {t('recompensas:AplicarTipoDescuento')} {item.Descuento}{t('cupones:EsDescuentoImporteSimbol')} 
                                                                </>
                                                              )
                                                            }
                                                          </Col> 
                                                        </Row>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              </CardBody>  
                                              <CardFooter>
                                                <Row> 
                                                  <Col className='text-right orange-color'> 
                                                    {t('recompensas:Expires')} &nbsp; {moment(item.FechaFinalExpiracion).format("LL")}  
                                                  </Col> 
                                                </Row>
                                              </CardFooter>
                                            </Card>  
                                          </>
                                        ):(
                                          <>
                                          </>
                                        )
                                      )
                                    }
                                  </Col>
                                </Row>
                                  );
                                })}  
                            </div>
                            <div>
                              {CampaniasDisponibles.map((item) => { 
                              return (
                                <Row className="align-items-start padding5">
                                  <Col lg={12} md={12} sm={12} xs={12}> 


                                    <Card className="radiuscard padding10"> 
                                      <CardTitle>
                                        <Row>
                                          <Col>
                                            <h1>
                                              {t('estadocuenta:CampaniaRecompensa')} 
                                            </h1>
                                          </Col>
                                        </Row>
                                      </CardTitle>
                                      <CardBody> 
                                        <Row className="align-items-start padding5">
                                          <Col lg={2} md={2} sm={2} xs={2}>
                                            {
                                                      item.Img !== "" ? (
                                                        <>
                                                          <img alt="" src={item.Img} role="presentation" className='img-fluid img-thumbnail fa-5x' />
                                                        </>
                                                      ):(
                                                        <>
                                                          <i className='fa fa-file-image fa-5x marginRight2'></i>
                                                        </>
                                                      )
                                                    }
                                          </Col>
                                          <Col>
                                            <Row id="producto">
                                              <Col> 
                                                <Row>
                                                  <Col>
                                                    <h2> {item.NomCampania} </h2>
                                                  </Col>
                                                </Row> 
                                                <Row>
                                                  <Col>
                                                    {t('recompensas:Factor')} {item.FactorBase}
                                                  </Col>
                                                </Row> 
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </CardBody>  
                                      <CardFooter>
                                        <Row>
                                          <Col className='text-right orange-color'> 
                                            {t('recompensas:Expires')} &nbsp; {moment(item.FechaFinalExpiracion).format("LL")}  
                                          </Col> 
                                        </Row>
                                      </CardFooter>
                                    </Card>  
                                  </Col>
                                </Row>
                                  );
                                })}  
                            </div>  
                          </CardBody> 
                        </Card>            
                      </Col>
                    </Row> 
                    <Row>
                      <Col> 
                      &nbsp;
                      </Col>
                    </Row>
                    {
                          EsVerRecompensaDetalle ? (
                            <>
                              <EstadoDeCuentaRecompensasDetalle filters={Filters} isModal={true} />
                            </>
                        )
                        :
                            <></>
                        } 
                    <Dialog
                      className='dialogradius headerdialog'
                      visible={AbrirModalReedem} 
                      style={{width: '30vw' }} 
                      onHide={cerrarModalReedem}
                      footer={modalFooter}
                    >
                      <div>
                        <Card
                          style={{ border: 'solid #ADD89D 1px', backgroundColor: '#F5FAF3' }} 
                          className="radiuscard padding10 text-center"
                        > 
                          <CardBody> 
                            <Row className='align-items-start'>
                              <Col lg={4} md={4} sm={4} xs={4}>
                                <Row>
                                  <Col>
                                    <img height={40} src={logo} alt="rwp" /> 
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <h1>
                                      ${Highcharts.numberFormat(CuponGenerateReedem.MontoPorPuntos,2,'.',',')} 
                                    </h1> 
                                  </Col>
                                </Row> 
                              </Col>
                              <Col lg={8} md={8} sm={8} xs={8}>
                                <Row>
                                  <Col>
                                    <h1>
                                      {CuponGenerateReedem.CodigoPromocion}
                                    </h1>
                                  </Col>
                                </Row> 
                                <Row>
                                  <Col className='danger-color'>
                                    <span>   
                                      <b> {t('estadocuenta:Expires')} </b> {moment(CuponGenerateReedem.FechaFin).format("LL")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row> 
                          </CardBody> 
                        </Card>
                      </div>
                    </Dialog>
                  </div>
                </>
)
}
                 
                </>
              )
            }
      </div> 
    </>
);
};
export default EstadoDeCuentaDetalle;
