/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';      
import { Tag } from 'primereact/tag'; 

const FieldTag =  (props) => {
    const onClick = (value) => {  
        if (props.onClick !== undefined){
            props.onClick(value);
        } 
    }  
    return (  
      <>  
        <span onClick={onClick}> 
          <Tag  
            className={props.onClick !== undefined ? `${props.className  } Cursor` : props.className}
          > 
            {props.label}
          </Tag>
        </span>
      </> 
    )
}   
export default FieldTag; 


