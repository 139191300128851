/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';     
import { InputTextarea } from 'primereact/inputtextarea';

const FieldTextArea =  (props) => {
    
    const onChange = (value) => {  
        if (props.onChange !== null){
            props.onChange(value);
        } 
    }  

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => { 
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    return (  
      <>  
        <span className="p-float-label">
          <InputTextarea
            rows={props.rows}
            value={props.value === null ? '' : props.value}
            onChange={onChange} 
            className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
          />
          <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
        </span>
        { getFormErrorMessage(props.name) } 
      </> 
    )
}   

    
export default FieldTextArea; 
