/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';     
import { InputNumber } from 'primereact/inputnumber';

const FieldNumber =  (props) => {
    
    const onChange = (value) => {  
        if (props.onChange !== null){
            props.onChange(value);
        } 
    }  
    const onBlur = (value) => {  
      if (props.onBlur !== null && props.onBlur !== undefined){
          props.onBlur(value);
      } 
    }  
    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => { 
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    if(props.isInputGroup){
      return ( 
        <> 
          <div className="p-inputgroup">
            <InputNumber
              value={props.value === null ? '' : props.value} 
              onChange={onChange}
              onBlur={onBlur}
              className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
              mode="decimal"
              locale="en-US"
              minFractionDigits={props.minFractionDigits}
              showButtons={props.showButtons}
              min={props.min}
              max={props.max}
              disabled={props.disabled}
            />  
            <span className="p-inputgroup-addon">
              <span className={props.classGroup}>
                {props.labelGroup}
              </span>
            </span>
          </div>
          <span className="p-float-label">
            <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
          </span>
          { getFormErrorMessage(props.name) } 
        </> 
      )
    }
      return (
        <> 
          <span className="p-float-label">
            <InputNumber
              value={props.value === null ? '' : props.value} 
              onChange={onChange}
              onBlur={onBlur}
              className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
              mode="decimal"
              locale="en-US"
              minFractionDigits={props.minFractionDigits}
              showButtons={props.showButtons}
              min={props.min}
              max={props.max}
              disabled={props.disabled}
            />  
            <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
          </span>
          { getFormErrorMessage(props.name) } 
        </> 
      )
}   

    
export default FieldNumber; 
