import React, { useState, useEffect, useRef } from 'react';
import {Row,Col} from 'reactstrap'; 
import { useTranslation } from 'react-i18next';
import CuponesDescuentoLista from './CuponesDescuentoLista.jsx' 
import CuponesDescuentoDetalle from './CuponesDescuentoDetalle.jsx'
import PageContent from '../../layouts/PageContent'; 


// START COMPONENT
const CuponesDescuento = (props) => { 
  const { t } = useTranslation(['formulario','common','cupones']);
  const [EsListado, setEsListado] = useState(true); 
  const [Filters, setFilters] = useState({});  

  useEffect(() => {
    const filtersData = {};
    filtersData.IdCupon = 0;
    filtersData.EsListado = EsListado;
    filtersData.ClaTipoCupon = 1; // CuponDescuento
    setFilters(filtersData); 
  }, [props.filters]);
 
const onAfterSave = () => {  
   setEsListado(true);
   setFilters({ ...Filters, EsListado : true});
}

const onAfterNew = () => {  
  setEsListado(false);
  setFilters({ ...Filters, EsListado : false, IdCupon: 0});
}

const onAfterReturn = () => {  
  setEsListado(true);
  setFilters({ ...Filters, EsListado : true});
}

const onAfterSelect = (data) => {  
  setEsListado(false);
  setFilters({ ...Filters, EsListado : false, IdCupon: data.IdCupon, isDuplicate: data.isDuplicate});
}
 
  return (
    <> 
      <PageContent title={t('cupones:Cupones')}>
        <Row>
          <Col>
            {
              EsListado ?
              (
                <CuponesDescuentoLista onAfterNew={onAfterNew} onAfterSelect={onAfterSelect} filters={Filters} />)
              :
              (<></>)
            }   
            <div hidden={EsListado}>
              <CuponesDescuentoDetalle isHidden={false} isModal={true} onAfterSave={onAfterSave} onAfterReturn={onAfterReturn} filters={Filters} />
            </div>
          </Col>
        </Row>
      </PageContent> 
    </>
);
};
export default CuponesDescuento;
