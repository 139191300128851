import React, { useState, useEffect } from 'react';
import {  Row,  Col } from 'reactstrap'; 
import { useTranslation } from 'react-i18next'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'; 
import { config } from '../../utils/config';
import { callApi} from '../../utils/utils';  
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator'; 
import FieldAutoComplete from '../../components/Controls/FieldAutoComplete.jsx'
import { Checkbox } from 'primereact/checkbox';
import PageContent from '../../layouts/PageContent'; 
import { Panel } from 'primereact/panel';
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'

// START COMPONENT
const SeguridadUsuarioEmpresa = (props) => { 
  const { t } = useTranslation(['formulario','common','seguridad']);
  const [errors, setErrors] = useState({});   
  const [rowsGrid, setRowsGrid] = useState([]);  
  const [rowsEmpresasGrid, setRowsEmpresasGrid] = useState([]);
  const [originalRowsEmpresas, setOriginalRowsEmpresas] = useState([]);   
  const [ClaUsuario, setClaUsuario] = useState(null); 
  const [ClaUsuarioReferencia, setClaUsuarioReferencia] = useState(null);
  const [AbrirModal, setAbrirModal] = useState(false); 
  const [FilterClaUsuario, setFilterClaUsuario] = useState([]); 

  useEffect(() => {
    onGridUsuarioEmpresa();
  }, []);

  const onGridUsuarioEmpresa = () => {
        const paramsToService = {
          ClaUsuario:null
        }
        const GetCategpriaGridService = `${config.UrlApiProject}Seguridad/GetRelUsuarioEmpresa`;
        callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
            setRowsGrid(response.data.UsuariosEmpresa); 
            setRowsEmpresasGrid(response.data.Empresas);  
            setOriginalRowsEmpresas(response.data.Empresas);
        });    
  };

  const onDetalle = () =>{ 
    const AutoCompleteData = {
      ClaUsuario: ClaUsuarioReferencia.ClaUsuario,
      NombreCompleto: ClaUsuarioReferencia.NombreCompleto
    };
    setClaUsuario(AutoCompleteData); 
    const urlWebService = `${config.UrlApiProject}Seguridad/GetRelUsuarioEmpresa`;
    const paramsToService = {
      ClaUsuario:ClaUsuarioReferencia.ClaUsuario
    }
    callApi(urlWebService, 'POST', paramsToService, (response) => { 
      const listaEmpresas = response.data.Empresas;
      if(listaEmpresas !== undefined){
        setRowsEmpresasGrid(listaEmpresas); 
        setOriginalRowsEmpresas(listaEmpresas); 
        setAbrirModal(true);
      }
    });    
  }

  const onDetalleUsuario = (user) =>{ 
    const urlWebService = `${config.UrlApiProject}Seguridad/GetRelUsuarioEmpresa`;
    const paramsToService = {
      ClaUsuario:user
    }
    callApi(urlWebService, 'POST', paramsToService, (response) => { 
      const data = response.data.UsuariosEmpresa[0];
      const listaEmpresas = response.data.Empresas;
      if(data !== undefined){
        const AutoCompleteData = {
          ClaUsuario: data.ClaUsuario,
          NombreCompleto: data.Usuario
        };
        setClaUsuario(AutoCompleteData); 
        setRowsEmpresasGrid(listaEmpresas); 
        setOriginalRowsEmpresas(listaEmpresas); 
        setAbrirModal(true);
      }
    });    
  }

  const onRowEditInit = (event) => {
    originalRowsEmpresas[event.index] = { ...rowsEmpresasGrid[event.index] };
    setOriginalRowsEmpresas(originalRowsEmpresas); 
  };

  const onRowEditCancel = (event) => {
    const data = [...rowsEmpresasGrid];
    data[event.index] = originalRowsEmpresas[event.index];
    delete originalRowsEmpresas[event.index];
    setRowsEmpresasGrid(data);
  };

  const seleccionarEditor = ( productKey, props ) => {  
    return (
      <Checkbox 
        checked={props.rowData.EsActivo} 
        onChange={(e) => onEditorValueChange(productKey, props, e.checked)}
      />
      )
  }; 

  const seleccionarBodyTemplate = (rowData) => {
    return rowData.EsActivo ? 'SI' : 'NO';
  }

  const onEditorValueChange = ( productKey, props, value ) => {  
    const updatedData = [...props.value];
    updatedData[props.rowIndex][props.field] = value;
    setRowsEmpresasGrid(updatedData);
  }; 

  const onChangeClaUsuario = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaUsuarioReferencia;   
    setErrors(newErrors)
    setClaUsuarioReferencia(e.value)
  }

  const FilteredClaUsuario = (event) => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Seguridad/GetUsuarios?Busqueda=${event.query}`;
    callApi(urlWebService, 'GET',  paramsService, (response) => {  
        setFilterClaUsuario(response.data.Usuarios);
    });    
  }  

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button onClick={(e) => onDetalleUsuario(rowData.ClaUsuario)} icon="pi pi-pencil" className="p-button-rounded p-button-info p-mr-2" />
      </>
    );
  } 

  const nuevoForm = async () => {
    const value = {
      ClaUsuarioReferencia:(ClaUsuarioReferencia !== null ? ClaUsuarioReferencia.ClaUsuario : null)
   }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      return
    }
    onDetalle();
    setAbrirModal(true); 
  }

  const resetErrors = () => {
    const newErrors = { ...errors } 
    delete newErrors.ClaUsuario;
    setErrors(newErrors)  
  }

  const cerrarModal = () => {
    resetErrors();
    setAbrirModal(false);
  }  

  const valitationScheme = yup.object().shape({
    ClaUsuarioReferencia: yup.mixed().required(t('common:RequiredField')).nullable()
  });

  const valitationSchemeNew = yup.object().shape({
    ClaUsuarioReferencia: yup.mixed().required(t('common:RequiredField')).nullable()
  });

  const guardar = async () => { 
    const urlWebServiceSave = `${config.UrlApiProject}Seguridad/PostRelUsuarioEmpresa`;
    const aLista = [];
    rowsEmpresasGrid.forEach((item) => {
      if(item.EsActivo){
        aLista.push(item);
      }
    });
    const paramsService = {
      ClaUsuario:ClaUsuario.ClaUsuario,
      RelUsuarioEmpresaLista:aLista
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      setClaUsuarioReferencia(null);
      onGridUsuarioEmpresa();
      cerrarModal();
    });  
  }

  const modalFooter = () => {
    return (
      <>
        <div>
          <Button label={t('common:Cancel')} icon="pi pi-times" className="p-button-text" onClick={cerrarModal}    />
          <Button label={t('common:Save')} icon="pi pi-check" className="p-button-text" onClick={guardar} />
        </div>
      </>
    );
  } 

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;
  
    return (
      <div className={`card-header danger collapsed-header${  className}`}>
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={`${toggleIcon  } light-color`}></span>
              </button>
              &nbsp;
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div> 
          <div className="p-toolbar-group-right">
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={nuevoForm}>
              <i className="pi pi-plus"></i>
            </button>
            &nbsp;
          </div>
        </div> 
      </div>
    )
  }

  return (
    <> 
      <PageContent title={t('seguridad:Empresas')}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <Panel className="panel-radius" header="Filters" toggleable headerTemplate={filterHeaderTemplate}>
              <Row>
                <Col lg={4} md={4} sm={8} xs={8}>
                  <div className="p-fluid p-field p-col-12"> 
                    <FieldAutoComplete
                      dropdown
                      name="ClaUsuarioReferencia"
                      label={t('seguridad:TitleGridUsuarios')}
                      value={ClaUsuarioReferencia} 
                      onChange={onChangeClaUsuario}  
                      suggestions={FilterClaUsuario}
                      field="NombreCompleto"
                      completeMethod={FilteredClaUsuario}
                      errors={errors}
                    >
                    </FieldAutoComplete> 
                  </div>
                </Col> 
              </Row>
              <Row>&nbsp;</Row>
            </Panel> 
            <Row>&nbsp;</Row> 
          </Col>
        </Row>  
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FieldDataTable 
              value={rowsGrid}  
              header={t('seguridad:TitleGridUsuarios')}
              scrollable 
              className="p-datatable-striped"  
              selectionMode="single"  
              metaKeySelection={false}
            >
              <Column field="Usuario" header={t('seguridad:Nombre')} columnKey="Usuario" headerStyle={{ width: '300px' }}></Column> 
              <Column body={actionBodyTemplate} headerStyle={{ width: '80px' }}></Column>
            </FieldDataTable>  
          </Col>  
        </Row>  
        <Dialog
          className='dialogradius'
          header={t('seguridad:UsuarioEmpresas')}
          visible={AbrirModal} 
          style={{ width: '450px' }} 
          footer={modalFooter}
          onHide={cerrarModal}
        >
          <div className="p-fluid p-field p-col-12"> 
            <FieldAutoComplete
              dropdown
              disabled
              name="ClaUsuario"
              label={t('seguridad:TitleGridUsuarios')}
              value={ClaUsuario} 
              onChange={onChangeClaUsuario}  
              suggestions={FilterClaUsuario}
              field="NombreCompleto"
              completeMethod={FilteredClaUsuario}
            >
            </FieldAutoComplete> 
          </div>  
          <div className="p-fluid p-field p-col-12"> 
            <FieldDataTable 
              scrollable 
              scrollHeight="200px"
              value={rowsEmpresasGrid} 
              editMode="row" 
              className="editable-cells-table"
              onRowEditInit={onRowEditInit} 
              onRowEditCancel={onRowEditCancel}
            > 
              <Column field="NombreEmpresa" columnKey="NombreEmpresa" header={t('seguridad:Empresas')} headerStyle={{ width: '200px' }}></Column> 
              <Column field="EsActivo" header={t('seguridad:Acceso')} body={seleccionarBodyTemplate} editor={(props) => seleccionarEditor('EsActivo', props)}></Column>
              <Column rowEditor bodyStyle={{ textAlign: 'center' }}></Column>
            </FieldDataTable>
          </div>
        </Dialog>     
      </PageContent>
    </>
);
};
export default SeguridadUsuarioEmpresa;