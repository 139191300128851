import React, { useState, useEffect, useRef } from 'react';
import {Card,CardBody,Row,Col}        from 'reactstrap'; 
import { DataTable }                  from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel'; 
import moment from 'moment';  
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'; 
import Highcharts from 'highcharts';
import EstadoDeCuentaRecompensasDetalle from './EstadoDeCuentaRecompensasDetalle.jsx' 
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'

// START COMPONENT
const EstadoDeCuentaCupones = (props) => { 
  const { t } = useTranslation(['recompensas','cupones','common']);
  const [errors, setErrors] = useState({});
  const [rowsGrid, setRowsGrid] = useState([]);  
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [Filters, setFilters] = useState({}); 
  const [AbrirModal, setAbrirModal] = useState(false);


  useEffect(() => {
    onGridCupones(); 
  }, [props.filters]);

  const onGridCupones = () => {
        const paramsToService = {
          ClaCliente:props.filters.ClaCliente,
          ClaEmpresa,
          Idioma:''
        }
        const GetClienteGridService = `${config.UrlApiProject}Cliente/GetClienteCupon`;
        callApi(GetClienteGridService, 'POST', paramsToService, (response) => { 
            setRowsGrid(response.data.CuponListado);
        });    
  };

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={`card-header danger collapsed-header${  className}`}>
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={`${toggleIcon  } light-color`}></span>
                <Ripple />
              </button>
              &nbsp;
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div> 
          <div className="p-toolbar-group-right">
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={onGridCupones}>
              <i className="pi pi-search"></i>
            </button>
          </div>
        </div> 
      </div>
    )
}

const FechaCuponTemplate = (rowData) => {
  return (
    <>
      { rowData.FechaCupon !== null ? moment(rowData.FechaCupon).format("MM/DD/YYYY") : rowData.FechaCupon }
    </>
  );
}
const ImporteDescuentoTemplate = (rowData) => {
  return (
    <>
      <span>
        ${Highcharts.numberFormat(rowData.ImporteDescuento,2,'.',',')}
      </span>
    </>
  );
}
const verBodyTemplate = (rowData) => {
  return (
    <>
      <Button
        onClick={(e) => onDetalle(rowData)} 
        icon="pi pi-eye" 
        className="p-button-rounded p-button-info p-button-sm"
      />
    </>
    ); 
} 

const onDetalle = (rowData) => {
  setFilters({ ...Filters, IdPedido: rowData.IdPedido});
  setAbrirModal(true);
};

const cerrarModal = () => {
  setAbrirModal(false);
}  

const modalFooter = () => {
  return (
    <>
      <div className="text-center">
        &nbsp;
      </div>
    </>
  );
} 

  return (
    <>
      <Row hidden={props.isHidden} className="pb-5">
        <Col lg={12} md={12} sm={12} xs={12}>
          <Panel className="panel-radius" header="Filters" toggleable headerTemplate={filterHeaderTemplate}> 
            <div className="row">
              <Row>&nbsp;</Row>
            </div>
            <div className="p-fluid p-grid">
              <div className="p-grid p-field"> 
                    &nbsp;
              </div> 
            </div> 
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={rowsGrid}
            header={t('estadocuenta:Cupones')}
            scrollable
            className="p-datatable-striped"  
            selectionMode="single"  
            metaKeySelection={false}
          >
            <Column field="FechaCupon" columnKey="FechaCupon" body={FechaCuponTemplate} header={t('estadocuenta:FechaCupon')} headerStyle={{ width: '120px' }}></Column>  
            <Column field="CodigoDescuento" columnKey="CodigoDescuento" header={t('estadocuenta:Codigo')} headerStyle={{ width: '120px' }}></Column> 
            <Column field="NombreCupon" columnKey="NombreCupon" header={t('estadocuenta:Cupon')} headerStyle={{ width: '120px' }}></Column> 
            <Column body={ImporteDescuentoTemplate} header={t('estadocuenta:Importe')} headerStyle={{ width: '100px' }}></Column>
            <Column body={verBodyTemplate} headerStyle={{ width: '100px' }}></Column>
          </FieldDataTable>   
        </Col>
      </Row> 
      <Row>
        <Col>
          <Dialog
            className='dialogradius'
            visible={AbrirModal} 
            style={{width: '80vw' }}
            onHide={cerrarModal} 
            footer={modalFooter}
          >
            {
               AbrirModal ? (
                 <>
                   <EstadoDeCuentaRecompensasDetalle filters={Filters} />
                 </>
             )
             :
                 <></>
             }
          </Dialog>  
        </Col>
      </Row>
    </>
);
};
export default EstadoDeCuentaCupones;
