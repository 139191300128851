import React, { useState, useEffect } from 'react';
import {
  Card, 
  CardBody, 
  Row,
  Col, 
  label,
} from 'reactstrap'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'; 
import { config } from '../../utils/config';
import { callApi, getEmpresa } from '../../utils/utils'; 
import { confirmDialog } from 'primereact/confirmdialog'; 
import { useTranslation } from 'react-i18next'; 
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator'; 
import FieldTextArea from '../../components/Controls/FieldTextArea.jsx'  
import CuponesDescuentoDetalle from '../CuponesDescuento/CuponesDescuentoDetalle'
import moment from 'moment';
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'

const SeguridadAutorizacionCuponesDescuento = (props) => { 
  const { t } = useTranslation(['formulario','common']);
  const [errors, setErrors] = useState({});   
  const [Filters, setFilters] = useState({});  
  const [rowsGrid, setRowsGrid] = useState([]);  
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [IdCupon, setIdCupon] = useState(null);  
  const [Comentarios, setComentarios] = useState(null);    
  const [AbrirModal, setAbrirModal] = useState(false); 
  const [AbrirModalCuponDescuento, setAbrirModalCuponDescuento] = useState(false); 

  useEffect(() => {
    onGridAutorizacion();
  }, [props.filters]);

  const onGridAutorizacion = () => {
    const paramsToService = { 
        ClaEmpresa,
        ClaTipoAutorizacion:props.filters.ClaTipoAutorizacion,
        ClaTipoCupon:props.filters.ClaTipoCupon
    }
    console.log('SeguridadAutorizacionCuponesDescuento');
    console.log(paramsToService);
    const GetCategpriaGridService = `${config.UrlApiProject}Seguridad/GetAutorizacionCuponDescuento`;
    callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
        setRowsGrid(response.data.CuponDescuentoAutorizaciones); 
    });    
  };

  const onVer = (row) =>{   
    setIdCupon(row.IdCupon);
    setFilters({ ...Filters, IdCupon:row.IdCupon, ClaTipoCupon:row.ClaTipoCupon});
    setAbrirModalCuponDescuento(true);
  } 

  const onRechazar = () =>{
    setAbrirModal(true); 
  } 
 
const confirmar = () => {
  confirmDialog({
      message: t('recompensas:MessageConfirmApproveCampaign'),
      header: t('common:Confirmation'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => (updateCuponDescuento(true)) 
  });
}

const actionBodyTemplate = (rowData) => {
 return (
   <>
     <Button onClick={(e) => onVer(rowData)} icon="pi pi-eye" className="p-button-rounded p-button-info" />
   </>
   );
  }
  
  const cerrarModal = () => {
    setAbrirModal(false);
  }  

  const valitationScheme = yup.object().shape({
    Comentarios: yup.string().required(t('common:RequiredField')).nullable()
  });

  const updateCuponDescuento = (bEsAprobar) => {
    const urlWebServiceSave = `${config.UrlApiProject}CuponDescuento/PostCuponDescuentoEstatus`;
    const paramsService = { 
      IdCupon,
      Comentarios,
      EsAprobar: bEsAprobar,
      ClaEmpresa
    };  
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      onGridAutorizacion();
      cerrarModal();
      cerrarModalCuponDescuento();
    });  
  }  

  const aceptarCancelacion = async () => {
    const value = { 
      Comentarios
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      return;
    }
    updateCuponDescuento(false);
  }

  const modalFooter = () => {
    return (
      <>
        <div>
          <Button label={t('common:Cancel')} icon="pi pi-times" className="p-button-text" onClick={cerrarModal}    />
          <Button label={t('common:Save')} icon="pi pi-check" className="p-button-text" onClick={aceptarCancelacion} />
        </div>
      </>
    );
  } 

  const cerrarModalCuponDescuento = () => {
    setAbrirModalCuponDescuento(false);
  }  

  const onAfterSave = () => {    
    cerrarModalCuponDescuento();
  }

  const modalFooterCuponDescuento = () => {
    return (
      <>
        <div className="text-center pt-3">
          <Button
            label={t('common:Rechazar')}
            icon="pi pi-ban"
            className="p-button-rounded p-button-danger p-mr-2"
            onClick={onRechazar}
          />
          <Button
            label={t('common:Aprobar')}
            icon="pi pi-check"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={confirmar}
          />
        </div>
      </>
    );
  } 

  const onChangeComentarios = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentarios;   
    setErrors(newErrors)    
    setComentarios(e.target.value)
  }

  const periodoInicialTemplate = (rowData) => { 
    return (
      <>
        { rowData.FechaInicioExpiracion !== null ? moment(rowData.FechaInicioExpiracion).format("MM/DD/YYYY") : rowData.FechaInicioExpiracion}
      </>
    );
  }

  const periodoFinalTemplate = (rowData) => {
    return (
      <>
        { rowData.FechaFinalExpiracion !== null ? moment(rowData.FechaFinalExpiracion).format("MM/DD/YYYY") : rowData.FechaFinalExpiracion}
      </>
    );
  }
  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="p-fluid p-field p-col-12"> 
            <FieldDataTable 
              value={rowsGrid}  
              header={t('seguridad:TitleGridAutorizacionCuponesDescuento')}
              scrollable 
              className="p-datatable-striped"  
              selectionMode="single"  
              metaKeySelection={false}
            >
              <Column field="NomCuponDescuento" header={t('seguridad:CuponDescuento')} columnKey="NomCuponDescuento" headerStyle={{ width: '200px' }}></Column> 
              <Column field="CodigoPromocion" header={t('seguridad:CodigoPromocion')} columnKey="CodigoPromocion" style={{ display : (props.filters.ClaTipoCupon===2?'none':'')}} headerStyle={{ width: '120px', display :(props.filters.ClaTipoCupon===2?'none':'') }}></Column> 
              <Column field="UsuarioCreo" header={t('seguridad:CreadoPor')} columnKey="UsuarioCreo" headerStyle={{ width: '200px' }}></Column> 
              <Column body={periodoInicialTemplate} field="PeriodoInicia" columnKey="PeriodoInicia" header={t('seguridad:FechaInicial')} headerStyle={{ width: '150px' }}></Column>  
              <Column body={periodoFinalTemplate} field="PeriodoTermina" columnKey="PeriodoTermina" header={t('seguridad:FechaFinal')} headerStyle={{ width: '150px' }}></Column>  
              <Column body={actionBodyTemplate} headerStyle={{ width: '80px' }}></Column>
            </FieldDataTable>   
          </div> 
        </Col>
      </Row>   

      <Dialog
        className='dialogradius'
        header={t('recompensas:Rechazar')} 
        visible={AbrirModal} 
        style={{ width: '450px', maxHeight:'500px'}} 
        footer={modalFooter} 
        onHide={cerrarModal}
        contentStyle={{maxHeight: "600px", overflow:"auto"}}
      >
        <div className="p-fluid p-field p-col-12"> 
          <span>{t('recompensas:EtiquetaRechazar')}</span>
          <FieldTextArea
            name="Comentarios"
            value={Comentarios} 
            onChange={onChangeComentarios}   
            rows={4}
            cols={30}
            autoResize
            errors={errors}
          />
        </div>
      </Dialog>    
      <Dialog
        className='dialogradius'
        maximized={true}
        header={props.filters.ClaTipoCupon===1 ? t('seguridad:Cupones') : t('seguridad:Deals')} 
        visible={AbrirModalCuponDescuento} 
        footer={modalFooterCuponDescuento} 
        onHide={cerrarModalCuponDescuento}
        blockScroll={true}
      >
        <div className="p-fluid p-field p-col-12">
          <CuponesDescuentoDetalle visible={AbrirModalCuponDescuento} isModal={false} isHidden={true} filters={Filters} /> 
        </div>
      </Dialog>     
    </>
);
};
export default SeguridadAutorizacionCuponesDescuento;
