import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import EstadoDeCuentaListado from '../EstadoDeCuenta/EstadoDeCuentaListado.jsx'   
import RecompensasDashboardDetalle from './RecompensasDashboardDetalle.jsx'  
import PageContent from '../../layouts/PageContent'; 
// START COMPONENT
const RecompensasDashboard = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [EsDashboard, setEsDashboard] = useState(true);
  const [EsRecompensas, setEsRecompensas] = useState(false); 
  const [Filters, setFilters] = useState({});  

  useEffect(() => {
    const filtersData = {};
    filtersData.ClaCliente = 0;
    filtersData.EsDashboard = EsDashboard;
    setFilters(filtersData); 

  }, [props.filters]);
 
const onAfterNew = () => {  
  setEsDashboard(false);
  setFilters({ ...Filters, EsDashboard : false, IdCampania: 0});
}

const onAfterSelect = (data) => {  
  setEsDashboard(false);
  setFilters({ ...Filters, EsDashboard : false, ClaCliente: data.ClaCliente});
}
 
  return (
    <>
      <PageContent title={t('recompensas:Dashboard')}>
        {
          EsDashboard ? 
          (
            <RecompensasDashboardDetalle onAfterNew={onAfterNew} onAfterSelect={onAfterSelect} filters={Filters} />
          ):
          (
            <></>
          )
        }
      </PageContent>
    </>
);
};
export default RecompensasDashboard;
