import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEN from "./locales/en/Common/common.json";
import estadocuentaEN from "./locales/en/EstadoCuenta/estadocuenta.json";
import recompensasEN from "./locales/en/Recompensas/recompensas.json";
import seguridadEN from "./locales/en/Seguridad/seguridad.json";
import cuponesEN from "./locales/en/Cupones/cupones.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
 en: {
    // Namspaces
    common: commonEN,
    estadocuenta: estadocuentaEN,
    recompensas: recompensasEN,
    seguridad: seguridadEN,
    cupones: cuponesEN
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;