import React, { useState, useEffect } from 'react';
import {
  Card, 
  CardBody, 
  Row,
  Col, 
  label,
} from 'reactstrap'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'; 
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils'; 
import { confirmDialog } from 'primereact/confirmdialog'; 
import { useTranslation } from 'react-i18next';
import FieldText from '../../components/Controls/FieldText.jsx' 
import FieldNumber from '../../components/Controls/FieldNumber.jsx' 
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx'
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator';
import {ColorPicker} from 'primereact/colorpicker';
import { Avatar } from 'primereact/avatar';
import PageContent from '../../layouts/PageContent'; 
import { Panel } from 'primereact/panel';
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

// START COMPONENT
const RecompensasCategoria = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [errors, setErrors] = useState({});
  const [originalRows, setOriginalRows] = useState([]);     
  const [rowsGrid, setRowsGrid] = useState([]);    
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [ClaCategoria, setClaCategoria] = useState(null);  
  const [ClaveCategoria, setClaveCategoria] = useState(null);  
  const [NomCategoria, setNomCategoria] = useState(null);  
  const [AbrirNuevaCategoria, setAbrirNuevaCategoria] = useState(false); 
  const [AbrirConfiguracionCategoria, setAbrirConfiguracionCategoria] = useState(false); 
  const [Descripcion, setDescripcion] = useState(null); 
  const [Color, setColor] = useState(null); 
  const [PuntosRangoInicial, setPuntosRangoInicial] = useState(null); 
  const [PuntosRangoFinal, setPuntosRangoFinal] = useState(null); 
  const [Nivel, setNivel] = useState(0); 
  const [Frecuencias, setFrecuencias] = useState([]);  
  const [EditingRows, setEditingRows] = useState({});  
  // useEffect 

  useEffect(() => {
    const paramsToService = {
      Idioma:'',
      BajaLogica:0
    }
    const urlWebServiceFrecuencia = `${config.UrlApiProject}Frecuencia/GetFrecuencia`;
    callApi(urlWebServiceFrecuencia, 'POST', paramsToService, (response) => { 
      setFrecuencias(response.data.Frecuencias); 
      onGridCategoria();
    });    
  }, []);


  const onGridCategoria = () => {
        const paramsToService = {
          ClaCategoria:null,
          ClaEmpresa
        }
        const GetCategpriaGridService = `${config.UrlApiProject}Categoria/GetCategoria`;
        callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
            setRowsGrid(response.data.Categorias); 
        });    
  };

  const onSetupCategories = () => { 
    const paramsToService = {
      ClaCategoria:null,
      ClaEmpresa
    }
    const GetCategpriaGridService = `${config.UrlApiProject}Categoria/GetCategoria`;
    callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
      setAbrirConfiguracionCategoria(true);
      setRowsGrid(response.data.Categorias); 
      setOriginalRows(response.data.Categorias); 
    });    
  }

  const onDetalleCategoria = (row) =>{ 
      resetErrors();
      const urlWebService = `${config.UrlApiProject}Categoria/GetCategoria`;
      const paramsToService = {
      ClaCategoria:row.ClaCategoria,
      ClaEmpresa
    }
    callApi(urlWebService, 'POST', paramsToService, (response) => { 
      const data = response.data.Categorias[0];
      if(data !== undefined){
        setClaCategoria(data.ClaCategoria);
        setClaveCategoria(data.ClaveCategoria);
        setNomCategoria(data.NomCategoria);
        setDescripcion(data.Descripcion);
        setColor(data.Color);
        setPuntosRangoInicial(data.PuntosRangoInicial);
        setPuntosRangoFinal(data.PuntosRangoFinal);
        setNivel(data.Nivel);
        setAbrirNuevaCategoria(true);
      }
    });    
  }
  
  const confirmar = (rowData) => {
      confirmDialog({
          message: (rowData.BajaLogica===0 ? t('recompensas:MessageConfirmInActive') : t('recompensas:MessageConfirmActive')),
          header: t('common:Confirmacion'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => (rowData.BajaLogica===0 ? InactivarConfirmacion(rowData) : ActivarConfirmacion(rowData)) 
      });
  }

  const InactivarConfirmacion = (row) =>{   
    const urlWebService = `${config.UrlApiProject}Categoria/PostCategoria`;
    const paramsService = {
      ClaEmpresa,
      ClaCategoria : row.ClaCategoria,
      ClaveCategoria : row.ClaveCategoria,
      NomCategoria: row.NomCategoria,
      Color : row.Color,
      Descripcion : row.Descripcion,
      EsBaja:1
    };
    callApi(urlWebService, 'POST', paramsService, (response) => {  
      onGridCategoria();
    });  
  } 

  const ActivarConfirmacion = (row) =>{   
    const urlWebService = `${config.UrlApiProject}Categoria/PostCategoria`;
    const paramsService = {
      ClaEmpresa,
      ClaCategoria : row.ClaCategoria,
      ClaveCategoria : row.ClaveCategoria,
      NomCategoria: row.NomCategoria,
      Color : row.Color,
      Descripcion : row.Descripcion,
      EsBaja:0
    };
    callApi(urlWebService, 'POST', paramsService, (response) => {  
      onGridCategoria();
    });  
  } 

  

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;
  
    return (
      <div className={`card-header danger collapsed-header${  className}`}>
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={`${toggleIcon  } light-color`}></span>
              </button>
              &nbsp;
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div> 
          <div className="p-toolbar-group-right">
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={nuevaCategoria}>
              <i className="pi pi-plus"></i>
            </button>
            &nbsp;
          </div>
        </div> 
      </div>
    )
  }

  const iconTemplate = (rowData) => {
      return rowData.BajaLogica === 0 ? "pi pi-check" : "pi pi-minus";
  }

  const colorTemplate = (rowData) => {
    return rowData.BajaLogica === 0 ? "p-button-rounded p-button-success" : "p-button-rounded p-button-danger";
  }

 const claveCatgoriaTemplate = (rowData) =>{
    return ( 
      <>
        <Avatar label={rowData.ClaveCategoria} className="p-mr-2" style={{ backgroundColor: `#${  rowData.Color}`, color: '#ffffff' }} shape="circle" />
      </>
    );
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button onClick={(e) => onDetalleCategoria(rowData)} icon="pi pi-pencil" className="p-button-rounded p-button-info p-mr-2" />
        <Button onClick={(e) => confirmar(rowData)} icon={iconTemplate(rowData)} className={colorTemplate(rowData)} />
      </>
    );
  }
  const header  = ( 
    <>
      <Row>
        <Col>
          {t('recompensas:TitleGridCategoria')}
        </Col>
        <Col>
          &nbsp;
        </Col>
        <Col className='text-center'>
          <Button
            label={t('recompensas:SetupCategories')}
            icon="fa fa-cog" 
            onClick={(e) => onSetupCategories()} 
            className="p-1 p-button-sm warning"
          />
        </Col>
      </Row>
    </>
  )

  const nuevaCategoria = () => { 
    setClaCategoria(0);  
    setClaveCategoria('');
    setNomCategoria('');
    setDescripcion('');
    setColor('777777');
    setPuntosRangoInicial(null);
    setPuntosRangoFinal(null);
    setNivel(0);
    resetErrors();
    setAbrirNuevaCategoria(true); 
  } 

  const cerrarModal = () => {
    setAbrirNuevaCategoria(false);
  }  

  const cerrarModalCategoria = () => {
    setAbrirConfiguracionCategoria(false);
  }  

  const valitationScheme = yup.object().shape({
    ClaveCategoria: yup.string().required(t('common:RequiredField')).nullable(),
    NomCategoria: yup.string().required(t('common:RequiredField')).nullable(),
    Nivel: yup.mixed().required(t('common:RequiredField')).nullable(),
  });

  const valitationSchemeCategoria3 = yup.object().shape({
    rowsGrid: yup.array().of(
      yup.object().shape({  
        PuntosRangoFinal: yup.mixed().required(t('common:RequiredField')).nullable(), 
      })
    )
  });

  const valitationSchemeCategoria = yup.object().shape({
    rowsGrid: yup
      .array()
      .of(
        yup.object().shape({
          PuntosRangoFinal: yup.string().required(t('common:RequiredField')).nullable()
        })
      )
  });

  const getCategorias = () => {
    const CategoriaList = [];
    rowsGrid.forEach((item) => {
      const data = {}
      data.ClaCategoria = item.ClaCategoria
      data.PuntosRangoInicial = item.PuntosRangoInicial
      data.PuntosRangoFinal = item.PuntosRangoFinal
      CategoriaList.push(data);
    });
    return CategoriaList
  }

  const guardarCategoria = async () => {
    const value = {
      ClaCategoria,
      ClaveCategoria,
      NomCategoria,
      Nivel
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}Categoria/PostCategoria`;
    const paramsService = {
      ClaEmpresa,
      ClaCategoria,
      ClaveCategoria,
      NomCategoria,
      Descripcion,
      Color,
      PuntosRangoInicial,
      PuntosRangoFinal,
      Nivel
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      onGridCategoria();
      cerrarModal();
    });  
  }

  const guardarConfiguracionCategoria = async () => {
    const value = {
      rowsGrid
    }
    const validator = new YupValidator(valitationSchemeCategoria);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}Categoria/PostCategoriaConfiguracion`;
    const paramsService = {
      CategoriaConfiguracion:getCategorias()
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      onGridCategoria();
      cerrarModalCategoria();
    });  
  }

  const modalFooter = () => {
    return (
      <>
        <div className="text-center">
          <Button label={t('common:Cancel')} icon="pi pi-times" className="p-button-rounded p-button-warning p-mr-2" onClick={cerrarModal}    />
          <Button label={t('common:Save')} icon="pi pi-check" className="p-button-rounded p-button-success p-mr-2" onClick={guardarCategoria} />
        </div>
      </>
    );
  }  

  const modalFooterCategoria = () => {
    return (
      <>
        <div className="text-center">
          <Button label={t('common:Cancel')} icon="pi pi-times" className="p-button-rounded p-button-warning p-mr-2" onClick={cerrarModalCategoria}    />
          <Button label={t('common:Save')} icon="pi pi-check" className="p-button-rounded p-button-success p-mr-2" onClick={guardarConfiguracionCategoria} />
        </div>
      </>
    );
  } 

  const onChangeCategoria = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomCategoria;   
    setErrors(newErrors)    
    setNomCategoria(e.target.value)
  }

  const onChangeDescripcion = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.Descripcion;   
    setErrors(newErrors)   
    setDescripcion(e.target.value)
  }

  const onChangeClave = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaveCategoria;   
    setErrors(newErrors)   
    setClaveCategoria(e.target.value)
  }
 
  const onChangeNivel = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Nivel;   
    setErrors(newErrors)   
    setNivel(e.value)
  } 

  const resetErrors = () => {
    const newErrors = { ...errors } 
    delete newErrors.ClaCategoria;   
    delete newErrors.ClaveCategoria;  
    delete newErrors.NomCategoria;  
    delete newErrors.PuntosRangoInicial;  
    delete newErrors.PuntosRangoFinal;  
    delete newErrors.Nivel;
    setErrors(newErrors)  
  }

  const onRowEditInit = (event) => {
    originalRows[event.index] = { ...rowsGrid[event.index] };
    setOriginalRows(originalRows); 
  };

  const onRowEditChange = (event) => {
    setEditingRows(...rowsGrid[event.index]); 
  };
  
  const onRowEditCancel = (event) => {
    const products = [...rowsGrid];
    products[event.index] = originalRows[event.index];
    delete originalRows[event.index];
    setRowsGrid(products);
  };

  const PuntosRangoInicialBodyTemplate = (rowData) => {
    return rowData.PuntosRangoInicial;
 }

 const PuntosRangoInicialEditor = ( props ) => { 
  return (
    <FieldNumber
      isInputGroup
      labelGroup={t('common:De')}
      disabled={true}
      name="PuntosRangoInicial"
      value={props.rowData.PuntosRangoInicial}
      mode="decimal"
    />  
)
}; 

const PuntosRangoFinalEditor = ( props ) => { 
  return (
    <FieldNumber
      isInputGroup
      labelGroup={t('common:A')}
      name="PuntosRangoFinal"
      value={props.rowData.PuntosRangoFinal}
      onChange={(e) => onPuntosRangoFinalValueChange(props, e.value)} 
      onBlur={(e) => onPuntosRangoFinalValueBlur(props, e.value)}
      mode="decimal"
      errors={errors}
    />  
)
}; 

const onPuntosRangoFinalValueChange = ( props, value ) => {  
  const updatedProducts = [...props.value];
  updatedProducts[props.rowIndex][props.field] = value;
  setRowsGrid(updatedProducts);
}; 

const onPuntosRangoFinalValueBlur = (props, value ) => {
  const updatedProducts = [...props.value];
  const rangoInicialValue = updatedProducts[props.rowIndex].PuntosRangoInicial;
  const rangoFinalValue = updatedProducts[props.rowIndex].PuntosRangoFinal;
  // Validar rango
  if(rangoFinalValue<rangoInicialValue)
  {
    const l = 0;
  }
  updatedProducts[props.rowIndex].isClose=true;
  if(updatedProducts[props.rowIndex+1]!==undefined){
    updatedProducts[props.rowIndex+1].PuntosRangoInicial = rangoFinalValue + 1;
  }
  setRowsGrid(updatedProducts); 
  // props.selectOnEdit = false;
}; 

 const PuntosRangoFinalBodyTemplate = (rowData) => {
  // console.log('PuntosRangoFinalBodyTemplate',rowData);
  return rowData.PuntosRangoFinal;
}

const  onEditorValueChange =(productKey, props, value)=> {
  const updatedProducts = [...props.value];
  updatedProducts[props.rowIndex][props.field] = value;
  setRowsGrid(updatedProducts);
}


const rowEditorTemplate =(rowData, props)=> {
  const {rowEditor} = props; 
  if (rowEditor.editing) {
      return rowEditor.element; // default element
  } 
  return rowEditor.element; // default element
  /* 
      return (
        <button type="button" onClick={rowEditor.onInitClick} className={rowEditor.initClassName}>
          <span className='p-row-editor-init-icon pi pi-fw pi-home p-clickable'></span>
        </button>
      )
      */
}


  return (
    <>
      <PageContent title={t('recompensas:Categoria')}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <Panel className="panel-radius" header="Filters" toggleable headerTemplate={filterHeaderTemplate}>
              <Row>
                <Col lg={4} md={4} sm={8} xs={8}>
                  &nbsp;
                </Col> 
              </Row>
              <Row>&nbsp;</Row>
            </Panel> 
            <Row>&nbsp;</Row> 
          </Col>
        </Row>  
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FieldDataTable 
              value={rowsGrid}  
              header={header}
              scrollable 
              className="p-datatable-striped"  
              selectionMode="single"  
              metaKeySelection={false}
            >  
              <Column body={claveCatgoriaTemplate} header={t('recompensas:ClaveCategoria')} columnKey="ClaveCategoria"></Column> 
              <Column field="NomCategoria" header={t('recompensas:Categoria')} columnKey="Categoria"></Column> 
              <Column field="Descripcion" className="d-none d-md-block d-lg-block" headerClassName="d-none d-md-block d-lg-block" header={t('recompensas:Descripcion')} columnKey="Descripcion"></Column> 
              <Column body={actionBodyTemplate}></Column>
            </FieldDataTable>   
          </Col>
        </Row>
           
        
        <Dialog
          header={t('recompensas:Categoria')} 
          className='dialogradius'
          visible={AbrirNuevaCategoria} 
          style={{width: '80vw' }}
          footer={modalFooter}
          onHide={cerrarModal}
          contentStyle={{borderRadius: '0px 0px 0px 0px'}}
        >
          <Row>&nbsp;</Row>
          <Row className='align-items-start'>
            <Col lg={6} md={6} sm={6} xs={6}> 
              <div className="p-fluid p-field p-col-12"> 
                <FieldText  
                  maxLength={3}
                  name="ClaveCategoria"
                  label={t('recompensas:ClaveCategoria')}
                  value={ClaveCategoria}  
                  errors={errors}
                  onChange={onChangeClave}
                /> 
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}> 
              <div className="p-field  p-col-6"> 
                <ColorPicker value={Color} onChange={(e) => setColor(e.value)}></ColorPicker>
                <span className="RWP-p-inputgroup-label">
                  {t('recompensas:Color')}&nbsp;
                </span> 
              </div>  
            </Col>
          </Row> 
          <div className="p-fluid p-field p-col-12"> 
            <FieldText  
              name="NomCategoria"
              label={t('recompensas:Categoria')}
              value={NomCategoria}  
              errors={errors}
              onChange={onChangeCategoria}
            /> 
          </div>
          <div className="p-fluid p-field p-col-12"> 
            <FieldText  
              name="Descripcion"
              label={t('recompensas:Descripcion')}
              value={Descripcion}  
              errors={errors}
              onChange={onChangeDescripcion}
            /> 
          </div>
          <Row className='align-items-start'>
            <Col lg={6} md={6} sm={6} xs={6}> 
              <div className="p-fluid p-field p-col-12"> 
                <FieldNumber
                  name="Nivel"
                  label={t('recompensas:Nivel')}
                  value={Nivel} 
                  onChange={onChangeNivel}
                  mode="decimal"
                  errors={errors}
                  showButtons
                  min={0}
                />  
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              &nbsp;
            </Col> 
          </Row> 
          <Row>&nbsp;</Row> 
        </Dialog>   
        <Dialog
          header={t('recompensas:SetupCategories')} 
          className='dialogradius'
          visible={AbrirConfiguracionCategoria} 
          style={{width: '80vw' }}
          footer={modalFooterCategoria}
          onHide={cerrarModalCategoria}
          contentStyle={{borderRadius: '0px 0px 0px 0px'}}
        >
          <FieldDataTable 
            value={rowsGrid}  
            header={t('recompensas:Categoria')}
            editMode="row" 
            className="editable-cells-table"
            onRowEditInit={onRowEditInit} 
            onRowEditCancel={onRowEditCancel}
            editingRows={EditingRows} 
            onRowEditChange={onRowEditChange}
            dataKey="ClaCategoria" 
          >
            <Column field="NomCategoria" header={t('recompensas:Nombre')} columnKey="Categoria"></Column> 
            <Column field="PuntosRangoInicial" header={t('recompensas:PuntosRangoInicial')} body={PuntosRangoInicialBodyTemplate} editor={(props) => PuntosRangoInicialEditor(props)}></Column>
            <Column field="PuntosRangoFinal" header={t('recompensas:PuntosRangoFinal')} body={PuntosRangoFinalBodyTemplate} editor={(props) => PuntosRangoFinalEditor(props)}></Column>
            <Column rowEditor body={rowEditorTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
          </FieldDataTable>
        </Dialog>  
      </PageContent>
     
    </>
);
};
export default RecompensasCategoria;
