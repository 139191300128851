/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState }  from 'react';     
import { RadioButton } from 'primereact/radiobutton'; 

const FieldRadioButton =  (props) => {
    
    const onChange = (e) => {  
        if (props.onChange !== null){
            props.onChange(e.value);
        } 
    }  

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => {  
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };
 
      return (  
        <>  
          <div className="p-field-radiobutton">
            <RadioButton   
              inputId={props.inputId} 
              name={props.name} 
              value={props.value} 
              onChange={onChange}
              checked={props.checked} 
              disabled={props.disabled} 
              className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
            />
            <label style={{marginTop: '.5rem'}} htmlFor={props.inputId} className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label> 
          </div> 
        </> 
      )
}   
    
export default FieldRadioButton; 
