import React, { useState, useEffect } from 'react';
import {
  Card, 
  CardBody, 
  Row,
  Col, 
  label,
} from 'reactstrap'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'; 
import { config } from '../../utils/config';
import { callApi, getEmpresa } from '../../utils/utils'; 
import { confirmDialog } from 'primereact/confirmdialog'; 
import { useTranslation } from 'react-i18next'; 
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator'; 
import FieldTextArea from '../../components/Controls/FieldTextArea.jsx'  
import RecompensasCampaniaDetalle from '../RecompensasCampania/RecompensasCampaniaDetalle.jsx'
import moment from 'moment';
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'

// START COMPONENT
const SeguridadAutorizacionRecompensas = (props) => { 
  const { t } = useTranslation(['formulario','common']);
  const [errors, setErrors] = useState({});   
  const [Filters, setFilters] = useState({});  
  const [rowsGrid, setRowsGrid] = useState([]);  
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [IdCampania, setIdCampania] = useState(null);  
  const [Comentarios, setComentarios] = useState(null);    
  const [AbrirModal, setAbrirModal] = useState(false); 
  const [AbrirModalCampania, setAbrirModalCampania] = useState(false); 

  useEffect(() => {
    onGridAutorizacion();
  }, []);

  const onGridAutorizacion = () => {
    const paramsToService = { 
        ClaEmpresa 
    }
    const GetCategpriaGridService = `${config.UrlApiProject}Seguridad/GetAutorizacionReward`;
    callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
        setRowsGrid(response.data.RecompensaAutorizaciones); 
    });    
  };

  const onVer = (row) =>{   
    setIdCampania(row.IdCampania);
    setFilters({ ...Filters, IdCampania:row.IdCampania});
    setAbrirModalCampania(true);
  } 

  const onRechazar = () =>{
    setAbrirModal(true); 
  } 
 
const confirmar = () => {
  confirmDialog({
      message: t('recompensas:MessageConfirmApproveCampaign'),
      header: t('common:Confirmation'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => (updateCampania(true)) 
  });
}

const actionBodyTemplate = (rowData) => {
 return (
   <>
     <Button onClick={(e) => onVer(rowData)} icon="pi pi-eye" className="p-button-rounded p-button-info" />
   </>
   );
  }
  
  const cerrarModal = () => {
    setAbrirModal(false);
  }  

  const valitationScheme = yup.object().shape({
    Comentarios: yup.string().required(t('common:RequiredField')).nullable()
  });

  const updateCampania = (bEsAprobar) => {
    const urlWebServiceSave = `${config.UrlApiProject}CampaniaRecompensa/PostCampaniaEstatus`;
    const paramsService = { 
      IdCampania,
      Comentarios,
      EsAprobar: bEsAprobar,
      ClaEmpresa
    };  
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      onGridAutorizacion();
      cerrarModal();
      cerrarModalCampania();
    });  
  }  

  const aceptarCancelacion = async () => {
    const value = { 
      Comentarios
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      return;
    }
    updateCampania(false);
  }

  const modalFooter = () => {
    return (
      <>
        <div>
          <Button label={t('common:Cancel')} icon="pi pi-times" className="p-button-text" onClick={cerrarModal}    />
          <Button label={t('common:Save')} icon="pi pi-check" className="p-button-text" onClick={aceptarCancelacion} />
        </div>
      </>
    );
  } 

  const cerrarModalCampania = () => {
    setAbrirModalCampania(false);
  }  

  const onAfterSave = () => {    
    cerrarModalCampania();
  }

  const modalFooterCampania = () => {
    return (
      <>
        <div className="text-center">
          <Button
            label={t('common:Rechazar')}
            icon="pi pi-ban"
            className="p-button-rounded p-button-danger p-mr-2"
            onClick={onRechazar}
          />
          <Button
            label={t('common:Aprobar')}
            icon="pi pi-check"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={confirmar}
          />
        </div>
      </>
    );
  } 

  const onChangeComentarios = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentarios;   
    setErrors(newErrors)    
    setComentarios(e.target.value)
  }

  const periodoInicialTemplate = (rowData) => { 
    return (
      <>
        { rowData.FechaInicioExpiracion !== null ? moment(rowData.FechaInicioExpiracion).format("MM/DD/YYYY") : rowData.FechaInicioExpiracion}
      </>
    );
  }

  const periodoFinalTemplate = (rowData) => {
    return (
      <>
        { rowData.FechaFinalExpiracion !== null ? moment(rowData.FechaFinalExpiracion).format("MM/DD/YYYY") : rowData.FechaFinalExpiracion}
      </>
    );
  }
  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
          <FieldDataTable 
            value={rowsGrid}  
            header={t('seguridad:TitleGridAutorizacion')}
            scrollable 
            className="p-datatable-striped"  
            selectionMode="single"  
            metaKeySelection={false}
          >   
            <Column field="NomCampania" header={t('seguridad:Campania')} columnKey="NomCampania" headerStyle={{ width: '200px' }}></Column> 
            <Column field="UsuarioCreo" header={t('seguridad:CreadoPor')} columnKey="UsuarioCreo" headerStyle={{ width: '260px' }}></Column> 
            <Column body={periodoInicialTemplate} field="PeriodoInicia" header={t('seguridad:FechaInicial')} headerStyle={{ width: '120px' }} columnKey="CompletedDate"></Column>  
            <Column body={periodoFinalTemplate} field="PeriodoTermina" header={t('seguridad:FechaFinal')} headerStyle={{ width: '120px' }} columnKey="CompletedDate"></Column>  
            <Column field="FactorBase" header={t('seguridad:FactorBase')} columnKey="FactorBase" headerStyle={{ width: '120px' }}></Column> 
            <Column body={actionBodyTemplate} headerStyle={{ width: '120px' }}></Column>
          </FieldDataTable>    
        </Col>
      </Row>   
      <Dialog
        className='dialogradius'
        header={t('recompensas:Rechazar')} 
        visible={AbrirModal} 
        style={{ width: '450px', maxHeight:'500px'}} 
        footer={modalFooter} 
        onHide={cerrarModal}
        contentStyle={{maxHeight: "600px", overflow:"auto"}}
      >
        <div className="p-fluid p-field p-col-12"> 
          <span>{t('recompensas:EtiquetaRechazar')}</span>
          <FieldTextArea
            name="Comentarios"
            value={Comentarios} 
            onChange={onChangeComentarios}   
            rows={4}
            cols={30}
            autoResize
            errors={errors}
          />
        </div>
      </Dialog>    
      <Dialog
        className='dialogradius'
        maximized={true}
        header={t('recompensas:Campaña')} 
        visible={AbrirModalCampania} 
        footer={modalFooterCampania} 
        onHide={cerrarModalCampania}
        blockScroll={true}
      >
        <div className="p-fluid p-field p-col-12">
          <RecompensasCampaniaDetalle isHidden={true} isModal={false} onAfterSave={onAfterSave} filters={Filters} />
        </div>
      </Dialog>     
    </>
);
};
export default SeguridadAutorizacionRecompensas;
