import swal from 'sweetalert';
import notify from 'devextreme/ui/notify';
import { config } from './config';
import { useTranslation } from 'react-i18next'; 

require('./prototypes');

let totalExecs = 0;
/**
 * showLoad()
 * Muestra la animacion de espera de carga de la pagina
 *
 */
 function showLoad() {

  if (totalExecs === 0 || totalExecs < 0) {
      ShowLoading("mswSpinner", "#f96332");
  }
  ++totalExecs;
}
/**
* hideLoad()
*Oculta la animacion de espera de carga de la pagina
*/
function hideLoad() {
  --totalExecs;
  
  if (totalExecs < 0){
    totalExecs = 0;
  }

  if (totalExecs === 0) {
      HideLoading();
  }
}


/**
 * Función para mostrar spinner en medio de la pantalla
 * @param {string} spinner clases de font awesome que se va a mostrar como spinner
 * @param {string} color color que se le quiera dar al spinner
 */
 function ShowLoading(spinner, color) {
  if (!color) {
      color = "#000000";
  }

  if (!spinner) {
      spinner = "fa fa-spinner";
  }

  const divParent = document.createElement("div");
  const divInner = document.createElement("div");
  const iChild = document.createElement("div");

  divParent.id = "dialogLoading";
  divParent.classList.add("loader-parent");
  divInner.style.color = color;
  divInner.classList.add("loader-content");

  spinner.split(" ").forEach((item) => {
      iChild.classList.add(item);
  });

  // iChild.classList.add(spinner);
  iChild.classList.add("fa-pulse");
  iChild.classList.add("fa-2x");
  iChild.classList.add("fa-fw");

  divInner.append(iChild);
  divParent.append(divInner);

  document.getElementsByTagName("body")[0].append(divParent);
}

/**
* Función para ocultar spinner
*/
function HideLoading() {
  try{ 
  const divParent = document.getElementById("dialogLoading");
  document.getElementsByTagName("body")[0].removeChild(divParent);
  }
  catch(e){
    // 
  }
}


/**
 * Realiza una peticion a una URL especificada.
 *
 * @param {String} url Dirección donde se realizara la peticioón
 * @param {String} method Tipo de peticion a ejecutar (POST, GET, PUT, DELETE)
 * @param {JSON} [data={}] Objeto que se adjuntará al body de la petición
 * @returns
 */
async function callApi(url, method, data = {}, callBack,  onCalbbackError, multipart = false, showOnLoad = true) {
  method = method.toUpperCase();

  const headers = {
    'x-api-key': config.ApiKey,
    'x-access-token': getSessionItem('Token', ''),
    ...(!multipart && {
      'Content-Type': 'application/json',
    }),
  };
 
  /*
  if (config.DebuggingMode) { 
    console.log(`Url: ${url}`);
  }
  */

  try {
    if (showOnLoad){ 
      showLoad();
    }
    
    // console.log(method === 'GET' ? null : !multipart ? JSON.stringify( data ) : data);

    const response = await fetch(url, {
      method,
      headers,
      body: method === 'GET' ? null : !multipart ? JSON.stringify( data ) : data,
      dataType: !multipart ? 'json' : null,
    });
    
    const res = await response.json();
    if (response.status === 200) {
      
      if (config.DebuggingMode) {
        console.log(`Url: ${url}, Response: `, res);
      }
      
      // si regreso error response....
      if (res.HasError)
      {
        if (res.IsControlledException){
          swal('Warning', res.message, 'warning', {
            buttons: {
              confirm: {
                text: 'OK',
                className: 'animation-on-hover btn btn-success',
              },
            },
          });

          if (showOnLoad){   
            hideLoad();
          }

          return;
        }
        // error no controlado por el api
        
          swal('Error', res.message, 'error', {
            buttons: {
              confirm: {
                text: 'OK',
                className: 'animation-on-hover btn btn-success',
              },
            },
          });

          if (showOnLoad){ 
           hideLoad();
          }
          return;
        
      }


      callBack(res);
      if (showOnLoad){ 
        hideLoad();
       }
    } else { 
      if (showOnLoad){ 
        hideLoad();
       }
        const errX = new Error();
				errX.message = res.message ?? res.mensaje;
				errX.name = response.status;
				errX.description = res.message;
				errX.status = response.status;

				throw errX;

      // throw Error(`${response.message}`);
    }
  } catch (err) {
    if (typeof(onCalbbackError)==='function'){
      onCalbbackError();
      hideLoad();
      return 
    }

    if (showOnLoad){ 
     hideLoad();
    } 
     

    // si atrapamos un error y es un error por problemas de vencimiento de token, redireccionamos  
    if (typeof(err.message) === 'string'){
      if (err.message.indexOf('System.IdentityModel.Tokens.Jwt.JwtSecurityToken') !== -1
      ||  err.message.indexOf('Internal Server Error. IDX10000: The parameter') !== -1
      )
      {
        // 
        swal('', "Your sesion has ended.", 'info', {
          buttons: {
            confirm: {
              text: 'OK',
              className: 'animation-on-hover btn btn-success',
            },
          },
        }).then((value) => {
          logOut();
          location.reload();
        }); 
      }
      else
      {
        swal('Error', err.message, 'error', {
            buttons: {
              confirm: {
                text: 'OK',
                className: 'animation-on-hover btn btn-success',
              },
            },
          }); 
      }
    }
    else {
        swal('Error', err.message, 'error', {
        buttons: {
          confirm: {
            text: 'OK',
            className: 'animation-on-hover btn btn-success',
          },
        },
      });
    } 
  }
}


/**
 * Función para realizar la petición a Kraken por medio de su servicio en Node js
 * @param {Number} claProducto número de el producto, dueño de la entidad
 * @param {Number} idEntidad número de la entidad que se desea consultar
 * @param {Object} paramsSP parametros que necesita la entidad
 * @param {Object} tipoEstructura número
 * @param {Function} callback función de callback para manejo del resultado
 */
async function callKrakenApi(claProducto, idEntidad, paramsSP, tipoEstructura, callback) {
  const method = 'POST';

  const params = {
    parameters: JSON.stringify(paramsSP),
    tipoEstructura,
  };

  const url = `${config.KrakenService}/${claProducto}/${idEntidad}`;

  await callApi(url, method, params, callback);
}

/**
 * Guarda en el localStorage cualquier valor que se mande en un objeto
 *
 * @param {object} params objeto donde cada propiedad se va a guardar
 */
function setSessionData(params) {
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const element = params[key];

      if (typeof element === 'object') {
        localStorage.setItem(key, JSON.stringify(element));
      } else {
        localStorage.setItem(key, element);
      }
    }
  }
}
 
/**
 * Regresa del localStorage cualquier valor que se pase como primer parametro,
 * en caso de no encontrarlo, regresa el valor por default del segundo parametro
 *
 * @param {string} key
 * @param {any} def
 */
function getSessionItem(key, def) {
  let value;

  try {
    value = JSON.parse(localStorage.getItem(key));
  } catch (error) {
    value = localStorage.getItem(key);
  }

  if (!value) {
    return def;
  }
  return value;
}

/**
 * Regresa del sessionStorage el NumUsuario
 *
 * @returns {Number} NumUsuario
 */
function getCliente() {
  return localStorage.getItem('NumUsuario');
}

function getEmpresa() {
  return localStorage.getItem('ClaEmpresa');
}

/**
 * Remueve del localStorage el JWT token y el nombre del usuario
 *
 */
function logOut() {
  localStorage.clear();
}

/**
 * Hace el decode del JWT token
 *
 * @param {string} token
 * @returns {object} payload del token
 */
function decodeToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

/**
 * Valida la si existe un token guardado en localStorage y en caso de existir
 * se valida su expiracion
 *
 * @returns {boolean} retorna si el token es valido o no
 */
function sessionAlive() {
  const jwtToken = localStorage.getItem('Token');
  let resp = false;

  if (jwtToken) {
    const { exp } = decodeToken(jwtToken);

    if (Date.now() < exp * 1000) {
      resp = true;
    }
  }

  if (!resp) {
    logOut();
  }

  return resp;
}

/**
 * Tipo de la notificacion de DevExtreme
 */
const notifyType = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
};

/**
 * Posicion la notificacion de DevExtreme
 * 'bottom' | 'center' | 'left' | 'left bottom' | 'left top' | 'right' | 'right bottom' | 'right top' | 'top'
 */
const notifyPosition = {
  centerBottom: 'center bottom',
  centerTop: 'center top',
  rightBottom: 'right bottom',
  rightTop: 'right top',
};

/**
 * Manda Un mensaje Generico con el Notify de DevExtreme
 *
 * @param {string} message mensaje de la alerta
 * @param {notifyPosition} notifyPosition posicion de la alerta
 * @param {notifyType} notifyType tipo de notificacion
 */
function showNotify(message, notifyPosition, notifyType) {
  notify(
    {
      message,
      position: {
        my: notifyPosition,
        at: notifyPosition,
      },
      width: '400px',
      closeOnSwipe: true,
    },
    notifyType,
    3000
  );
}

function showSweetAlert(title, message, notifyType) {
  
  if (title===null){
    switch(notifyType){
      case 'success':
        title = 'Success';
      break;
      case 'error':
        title = 'Error';
      break;
      case 'info':
        title = 'Information';
      break;
      case 'warning':
        title = 'Warning';
      break;
      
      default:
      break;
    }
    
  }

  swal(title,  message, notifyType, {
    buttons: {
      confirm: {
        text: 'OK',
        className: 'animation-on-hover btn btn-success',
      },
    },
  });
}

export {
  callApi,
  callKrakenApi,
  setSessionData,
  getSessionItem,
  getCliente,
  getEmpresa,
  logOut,
  decodeToken,
  sessionAlive,
  notifyType,
  notifyPosition,
  showNotify,
  showSweetAlert, 
  showLoad,
  hideLoad 
};
