import React from 'react';
// react library for routing
import { Route, Switch, Redirect } from 'react-router-dom';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import Components  from 'routes.js'; 
// import routes from 'routes.js';
import { config } from '../utils/config';
import { callApi, getCliente } from '../utils/utils';   

class NoMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidenavOpen: false, 
      routes: []
    };
  }
 
  componentDidMount()   
  {  
     /* obenemos la informacion de las opciones de menu */
      const urlWebService = `${config.UrlApiProject}Seguridad/GetMenu?LoginName=${getCliente()}`; 

      const paramsService = null;
 
      callApi(urlWebService, 'GET', paramsService, (response) => {
           // this.setState({routes: routesHardCoded});

            this.setState({routes: response.data});
      });   
  }      
  

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
 
  getRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/layout') {
        return <Route path={prop.layout + prop.path} component={Components[prop.component]} key={key} />;
      }
      return null;
    });

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    } 
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };

  getNavbarTheme = () =>
    this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';

  render() {
    return (
      <> 
        <div className="main-content" ref="mainContent"> 
          <div className="content"> 
            <Switch>
              {this.getRoutes(this.state.routes)}
              <Redirect from="*" to="/layout/Formulario" />
            </Switch>
            <AdminFooter /> 
          </div>
        </div> 
      </>
    );
  }
}

export default NoMenu;
