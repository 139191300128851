import RewardsSettings from './views/RecompensasConfiguracion/RecompensasConfiguracion.jsx';
import RewardsCategories from './views/RecompensasCategoria/RecompensasCategoria.jsx';
import RewardsCampaign from './views/RecompensasCampania/RecompensasCampania.jsx'; 
import SecurityAuthorizationFlow from './views/SeguridadFlujoAutorizacion/SeguridadFlujoAutorizacion.jsx'; 
import SecurityAuthorization from './views/SeguridadAutorizacion/SeguridadAutorizacion.jsx'; 
import SecurityUserCompany from './views/SeguridadUsuarioEmpresa/SeguridadUsuarioEmpresa.jsx';
import DiscountCoupon from './views/CuponesDescuento/CuponesDescuento.jsx';  
import AccountStatus from './views/EstadoDeCuenta/EstadoDeCuenta.jsx';  
import RewardsDashboard from './views/RecompensasDashboard/RecompensasDashboard.jsx'; 
import Deals from './views/Deals/Deals.jsx';   

const Components = {
    "RewardsCategories":RewardsCategories,
    "RewardsSettings":RewardsSettings,
    "RewardsCampaign":RewardsCampaign,
    "SecurityAuthorizationFlow":SecurityAuthorizationFlow,
    "SecurityAuthorization":SecurityAuthorization,
    "SecurityUserCompany":SecurityUserCompany,
    "DiscountCoupon":DiscountCoupon,
    "AccountStatus":AccountStatus,
    "RewardsDashboard":RewardsDashboard,
    "Deals":Deals   
};
export default Components;