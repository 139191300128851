import React, { useState, useEffect, useRef } from 'react';
import {Card,CardBody,Row,Col, CardHeader}        from 'reactstrap'; 
import { DataTable }                  from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import FieldText from '../../components/Controls/FieldText.jsx'  
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel'; 
import FieldCalendar from '../../components/Controls/FieldCalendar.jsx'
import moment from 'moment';  
import {SplitButton} from 'primereact/splitbutton'; 
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'; 
import RecompensasCampaniaDetalle from './RecompensasCampaniaDetalle.jsx'
import RecompensasCampaniaAlcance from './RecompensasCampaniaAlcance.jsx'
import Highcharts from 'highcharts'; 
import FieldDataTable from '../../components/Controls/FieldDataTable.jsx'
import FieldDropdownMultiSelect from '../../components/Controls/FieldDropdownMultiSelect.jsx'

// START COMPONENT
const RecompensasCampaniaLista = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [errors, setErrors] = useState({});
  const [rowsGrid, setRowsGrid] = useState([]);  
  const [abrirNuevaCampania, setAbrirNuevaCampania] = useState(false); 
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [FechaInicialFiltro, setFechaInicialFiltro] = useState(null);
  const [FechaFinalFiltro, setFechaFinalFiltro] = useState(null);
  const [NomCampaniaFiltro, setNomCampaniaFiltro] = useState(null);
  const [Filters, setFilters] = useState({}); 
  const [IdCampania, setIdCampania] = useState(null);
  const [AbrirModalCampania, setAbrirModalCampania] = useState(false);
  const [AbrirPantallaAlcance, setAbrirPantallaAlcance] = useState(false);
  const [EsLista, setEsLista] = useState(true);    
  const [CampaniaInfo, setCampaniaInfo] = useState({});
  const [ClaCampaniaRecompensaEstatus, setClaCampaniaRecompensaEstatus] = useState([]);
  const [CampaniaRecompensaStatusList, setCampaniaRecompensaStatusList] = useState([]);

  useEffect(() => {
    obtenerEstatus(); 
    setEsLista(true);
    onGridCampania(); 
  }, [props.filters]);

  const obtenerEstatus = () => {
    const urlGetRecompensaEstatus = `${config.UrlApiProject}CampaniaRecompensa/GetRecompensaEstatus`;
    const paramsToService = {
      Idioma:''
    }
    callApi(urlGetRecompensaEstatus, 'POST', paramsToService, (response) => {
      const options = response.data.RecompensaEstatus.map((d) => ({
        value: d.ClaCampaniaRecompensaEstatus,
        label: d.NomCampaniaRecompensaEstatus,
      }));
      setCampaniaRecompensaStatusList(options);
    });
  };

  const onGridCampania = () => {
      let estatus = "";
        if (ClaCampaniaRecompensaEstatus !== null) {
          for (let index = 0; index < ClaCampaniaRecompensaEstatus.length; index++) {
            const element = ClaCampaniaRecompensaEstatus[index].value;
            estatus = `${estatus + element},`;
          } 
        }
        const paramsToService = {
          IdCampania:null,
          ClaEmpresa,
          NomCampania: NomCampaniaFiltro,
          FechaInicial: FechaInicialFiltro,
          FechaFinal: FechaFinalFiltro,
          Idioma:'',
          ClaCampaniaRecompensaEstatus:(props.filters.ClaCampaniaRecompensaEstatus !== undefined ? props.filters.ClaCampaniaRecompensaEstatus : null),
          ClaCampaniaRecompensaEstatusList:estatus
        }
        const GetCategpriaGridService = `${config.UrlApiProject}CampaniaRecompensa/GetCampania`;
        callApi(GetCategpriaGridService, 'POST', paramsToService, (response) => { 
            setRowsGrid(response.data.Campanias);
        });    
  }; 
  
  const onClickLista = () => {
    setEsLista(!EsLista);
  };

  const onDetalleCampania = (rowData) => {
    if (props.onAfterSelect !=  null){ 
      props.onAfterSelect(rowData);
    }
  };
 
  const dataInfo = (rowData) => {
    const arreglo = [];
    rowData.Opciones.forEach((item) => { 
      const arrItem =   { 
        label: item.NomCampaniaRecompensaEstatus,
        icon: sIcon(item.ClaCampaniaRecompensaEstatus),
        command: () => {
          if(item.ClaCampaniaRecompensaEstatus===1 || item.ClaCampaniaRecompensaEstatus===2){
            if(item.ClaCampaniaRecompensaEstatus===2){
              item.isDuplicate = true;
            }
            onDetalleCampania(item);
          }else if(item.ClaCampaniaRecompensaEstatus!==-1){
              updateCampania(item);
            }
        }
      }
      arreglo.push(arrItem);
    })
    return (
      arreglo
    );
  }

  const sIcon = (EstatusId) => {
    let sclass = '';
    switch (EstatusId) {
      case -1:
        sclass = ''; 
        break;
      case 1:
        sclass = 'pi pi-pencil'; 
        break;
      case 2:
        sclass = 'pi pi-copy'; 
        break;
      case 50:
          sclass = 'pi pi-ban'; 
          break;
      default:
        sclass = 'pi pi-send'; 
    }
    return (
      sclass
    );
  }

  const sClase = (EstatusId) => {
    let sclass = '';
    switch (EstatusId) {
      case 10:
        sclass = 'secondary'; 
        break;
      case 20:
        sclass = 'warning'; 
        break;
      case 30:
        sclass = 'success'; 
        break;
      case 40:
        sclass = 'danger'; 
        break;
      case 50:
          sclass = 'orange'; 
          break;
      default:
        sclass = 'default'; 
    }
    return (
      sclass
    );
  }

  const estatusBodyTemplate = (rowData) => { 
    return (
      <>
        <Card className={`radiuscard ${  sClase(rowData.ClaCampaniaRecompensaEstatus)}`}>
          <CardBody className="text-center">    
            {rowData.NomEstatus}
          </CardBody> 
        </Card> 
      </>
    ); 
  }


  const verBodyTemplate = (rowData) => {
    console.log('verBodyTemplate')
    console.log(rowData)
    if (rowData.EsUsuarioSeguimiento){
      return (
        <>
          {props.IsHideActions ? <> </> : <SplitButton className="hidebutton" buttonTemplate="danger" dropdownIcon="pi pi-bars" model={dataInfo(rowData)}></SplitButton>} 
          &nbsp; 
          {props.IsHideScope ? <> </> : <Button onClick={(e) => onVerAlcance(rowData)} icon="pi pi-users" className="p-button-rounded p-button-success" />} 
          &nbsp; 
          <Button onClick={(e) => onVer(rowData)} icon="pi pi-eye" className="p-button-rounded p-button-info" />
        </>
      );
    }
      return (
        <>
          <Button onClick={(e) => onVer(rowData)} icon="pi pi-eye" className="p-button-rounded p-button-info" />
        </>
      ); 
  }
  
  const onChangeRecompensaStatus = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaCampaniaRecompensaEstatus;
    setErrors(newErrors);
    setClaCampaniaRecompensaEstatus(e); 
  };

  const nuevaCampania = () => { 
    if (props.onAfterNew !=  null){ 
      props.onAfterNew();
    }
  } 

  const updateCampania = (item) => {
    const urlWebServiceSave = `${config.UrlApiProject}CampaniaRecompensa/PostCampaniaSeguimiento`;
    const paramsService = { 
      IdCampania: item.IdCampania,
      ClaCampaniaRecompensaEstatus: item.ClaCampaniaRecompensaEstatus,
      ClaEmpresa
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      onGridCampania(); 
    });  
  }  

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;
    const listIcon = EsLista ? 'pi pi-th-large' : 'pi pi-bars';

    return (
      <div className={`card-header danger collapsed-header${  className}`}>
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={`${toggleIcon  } light-color`}></span>
                <Ripple />
              </button>
              &nbsp;
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div> 
          <div className="p-toolbar-group-right">
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={onGridCampania}>
              <i className="pi pi-search"></i>
            </button>
            &nbsp;
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={nuevaCampania}>
              <i className="pi pi-plus"></i>
            </button>
            &nbsp;
            <button type="button" className="p-button p-button-rounded p-button-icon-only p-button-black" onClick={onClickLista}>
              <i className={listIcon}></i>
            </button>
          </div>
        </div> 
      </div>
    )
}

const informationHeaderTemplate = (options) => {
  const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
  const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
  const titleClassName = `${options.titleClassName} p-pl-1`;
  return (
    <div className="card-header info">
      <div className="p-toolbar p-component">
        <div className="p-toolbar-group-left">
          <div className="card-title">
            <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
              <span className={`${toggleIcon  } light-color`}></span>
              <Ripple />
            </button>
              &nbsp;
            <span className={titleClassName}>
              {t('common:Information')}
            </span>
          </div>  
        </div> 
        <div className="p-toolbar-group-right">
          &nbsp;
        </div>
      </div> 
    </div>
  )
}


const onChangeFechaInicialFiltro = (e) => {  
  setFechaInicialFiltro(e.target.value);
} 
const onChangeFechaFinalFiltro = (e) => {  
  setFechaFinalFiltro(e.target.value);
} 
const onChangeNomCampaniaFiltro  = (e) => {  
  setNomCampaniaFiltro(e.target.value);
} 

const FechaInicialTemplate = (rowData) => {
  return (
    <>
      { rowData.FechaInicioExpiracion !== null ? moment(rowData.FechaInicioExpiracion).format("MM/DD/YYYY") : rowData.FechaInicioExpiracion }
    </>
  );
}

const FechaFinalTemplate = (rowData) => {
  return (
    <>
      { rowData.FechaFinalExpiracion !== null ? moment(rowData.FechaFinalExpiracion).format("MM/DD/YYYY") : rowData.FechaFinalExpiracion }
    </>
  );
}

const onVer = (row) =>{   
  setIdCampania(row.IdCampania);
  setFilters({ ...Filters, IdCampania:row.IdCampania});
  setAbrirModalCampania(true);
} 

const onVerAlcance = (row) =>{   
  const urlWebServiceSave = `${config.UrlApiProject}CampaniaRecompensa/GetCampaniaInformacion`;
  const paramsService = { 
    IdCampania: row.IdCampania,
    ClaEmpresa
  }; 
  callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
      setIdCampania(row.IdCampania);
      setCampaniaInfo(response.data.CampaniaInformacion[0])
      setFilters({ ...Filters, IdCampania:row.IdCampania});
      setAbrirPantallaAlcance(true);
  });  
} 

const onRegresar = () => {
  setAbrirPantallaAlcance(false);
}

const cerrarModalCampania = () => {
  setAbrirModalCampania(false);
}  

const modalFooter = () => {
  return (
    <>
      <div>
        &nbsp;
      </div>
    </>
  );
}
  return (
    <> 
      {
        AbrirPantallaAlcance ?
        (
          <>
            <Row className="text-center">
              <Col>
                <Card className="radiuscard">
                  <CardHeader className="default-light">
                    <Row>
                      <Col className="text-right">
                        <button
                          type="button" 
                          className="p-button p-button-rounded p-button-icon-only p-button-default"
                          onClick={onRegresar}
                        >
                          <i className="pi pi-angle-left"></i>
                        </button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="paddignBottom5">
                      <Col>
                        <div className="pt-2 pb-1 text-center">
                          <div className="fsize-4">
                            <Row>
                              <Col className="text-center">
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-black"
                                    >
                                      <i className="pi pi-users"></i>
                                    </button>
                                  </Col>
                                </Row> 
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>{CampaniaInfo.ClientesTotal}</span>
                                  </Col>
                                </Row> 
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:ClientesTotal')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row> 
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1">
                          <div className="fsize-4">
                            <Row className="text-center">
                              <Col>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-info"
                                    >
                                      <i className="pi pi-users"></i>
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>{CampaniaInfo.ClientesBeneficiados}</span>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:ClientesBeneficiados')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1">
                          <div className="fsize-4">
                            <Row className="text-center">
                              <Col>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-success"
                                    >
                                      <i className="pi pi-percentage"></i>
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>{CampaniaInfo.EfectividadCampania}</span>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:EfectividadCampania')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1">
                          <div className="fsize-4">
                            <Row className="text-center">
                              <Col>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-warning"
                                    >
                                      <i className="pi pi-plus"></i>
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span> 
                                      {Highcharts.numberFormat(CampaniaInfo.PuntosGanados,0,'.',',')}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:PuntosGanados')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="pt-2 pb-1">
                          <div className="fsize-4">
                            <Row className="text-center">
                              <Col>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <button
                                      type="button" 
                                      className="p-button p-button-rounded p-button-icon-only p-button-secondary"
                                    >
                                      <i className="pi pi-dollar"></i>
                                    </button>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom10">
                                  <Col>
                                    <span>${Highcharts.numberFormat(CampaniaInfo.MontoPuntos,2,'.',',')}</span>
                                  </Col>
                                </Row>
                                <Row className="paddignBottom5">
                                  <Col> 
                                    <h6>
                                      {t('recompensas:Importe')}
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row> 
            <p>&nbsp;</p>
            <RecompensasCampaniaAlcance isHidden={true} filters={Filters} />
          </>
        ):(
          <>
            <Row hidden={props.isHidden}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Panel className="panel-radius" header="Filters" toggleable headerTemplate={filterHeaderTemplate}> 
                  <div className="row">
                    <Row>&nbsp;</Row>
                  </div>
                  <div className="p-fluid p-grid">
                    <div className="p-grid p-field p-col-12"> 
                      <div className="p-field col-lg-2">
                        <FieldCalendar
                          className="p-calendar-danger"
                          name="FechaInicialFiltro"
                          label={t('recompensas:FechaInicial')}
                          value={FechaInicialFiltro}
                          onChange={(e) => onChangeFechaInicialFiltro(e)}
                        /> 
                      </div>
                      <div className="p-field col-lg-2">
                        <FieldCalendar
                          className="p-calendar-danger"
                          name="FechaFinalFiltro"
                          label={t('recompensas:FechaFinal')}
                          value={FechaFinalFiltro}
                          onChange={(e) => onChangeFechaFinalFiltro(e)}
                        /> 
                      </div>
                      <div className="p-field col-lg-2">
                        <FieldText  
                          name="NomCampaniaFiltro"
                          value={NomCampaniaFiltro}  
                          onChange={onChangeNomCampaniaFiltro}
                          label={t('recompensas:Campania')}
                        /> 
                      </div>
                      <div className="p-field col-lg-6">
                        <FieldDropdownMultiSelect
                          isMulti={true}
                          options={CampaniaRecompensaStatusList}
                          isClearable={true}
                          isSearchable={true}
                          value={ClaCampaniaRecompensaEstatus}
                          name="ClaCampaniaRecompensaEstatus"
                          onChange={(e) => onChangeRecompensaStatus(e)}
                          errors={errors}
                          placeholder={t('recompensas:Status')}
                        />
                      </div>
                    </div> 
                  </div> 
                </Panel>
                 &nbsp;
              </Col>
            </Row>
            <Row> 
              <Col lg={12} md={12} sm={12} xs={12}>
                {
                  EsLista ? (   
                    <FieldDataTable
                      value={rowsGrid}
                      header={t('recompensas:Campania')}
                      scrollable 
                      className="p-datatable-striped"  
                      selectionMode="single"  
                      metaKeySelection={false}
                    >
                      <Column field="NomCampania" header={t('recompensas:Nombre')} columnKey="NomCampania" headerStyle={{ width: '200px' }}></Column> 
                      <Column field="UsuarioCreo" header={t('recompensas:CreadoPor')} columnKey="UsuarioCreo" headerStyle={{ width: '220px' }}></Column> 
                      <Column field="FactorBase" header={t('recompensas:Factor')} columnKey="FactorBase" headerStyle={{ width: '90px' }}></Column> 
                      <Column field="FechaInicioExpiracion" body={FechaInicialTemplate} header={t('recompensas:FechaInicial')} columnKey="FechaInicial" headerStyle={{ width: '110px' }}></Column>  
                      <Column field="FechaFinalExpiracion" body={FechaFinalTemplate} header={t('recompensas:FechaFinal')} columnKey="FechaFinal" headerStyle={{ width: '110px' }}></Column>  
                      <Column body={estatusBodyTemplate} header={t('recompensas:Estatus')} headerStyle={{ width: '160px' }}></Column>
                      <Column body={verBodyTemplate} headerStyle={{ width: '130px' }}></Column>
                    </FieldDataTable>   
                ) : (
                  <Row className="align-items-start"> 
                    {rowsGrid.map((item) => { 
                      return (
                        <Col className="paddignTop10" lg={4} md={6} sm={12} xs={12} key={item.IdCampania}> 
                          <Card className="radiuscard boxshadow padding10"> 
                            <CardBody> 
                              <Row>
                                <Col className="secondary-color bold">{t('recompensas:Nombre')}</Col>
                                <Col className="secondary-color bold">{t('recompensas:FechaInicial')}</Col>
                                <Col className="secondary-color bold">{t('recompensas:FechaFinal')}</Col>
                              </Row>
                              <Row>
                                <Col className="primary-color">
                                  <b> {item.NomCampania} </b>
                                </Col>
                                <Col className="success-color">
                                  {FechaInicialTemplate(item)}
                                </Col>
                                <Col className="danger-color">
                                  {FechaFinalTemplate(item)} 
                                </Col>
                              </Row>
                              <Row>&nbsp;</Row>
                              <Row>
                                <Col lg={8} md={6} sm={8} xs={8} className="secondary-color bold"> 
                                  {t('recompensas:CreadoPor')} 
                                </Col>
                                <Col lg={4} md={6} sm={4} xs={4} className="secondary-color bold"> 
                                  {t('recompensas:Factor')}
                                </Col> 
                              </Row>
                              <Row>
                                <Col lg={8} md={6} sm={8} xs={8}> 
                                  {item.UsuarioCreo}
                                </Col>
                                <Col lg={4} md={6} sm={4} xs={4}> 
                                  {item.FactorBase}
                                </Col> 
                              </Row>
                              <Row>&nbsp;</Row>
                              <Row className="align-items-start">
                                <Col lg={6} md={6} sm={8} xs={8}> 
                                  <Card className={`radiuscard ${  sClase(item.ClaCampaniaRecompensaEstatus)}`}>
                                    <CardBody className="text-center">    
                                      {item.NomEstatus}
                                    </CardBody> 
                                  </Card> 
                                </Col>
                                <Col lg={6} md={6} sm={4} xs={4}> 
                                  <div className="text-right">  
                                    {verBodyTemplate(item)}
                                  </div>
                                </Col> 
                              </Row>
                            </CardBody> 
                          </Card>
                        </Col>
                      );
                  })} 
                  </Row>
                  )
                }
              </Col>
              {
              AbrirModalCampania ? (
                <>
                  <div className="p-fluid p-field p-col-12">
                    <RecompensasCampaniaDetalle visible={AbrirModalCampania} isModal={true} isHidden={true} filters={Filters} />
                  </div>
                </>
              ):(
                <>
                </>
              )
            }
            </Row>
          </>
        )
      } 
    </>
);
};
export default RecompensasCampaniaLista;
