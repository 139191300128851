/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from "react"; 
import PropTypes from "prop-types";  
import "./FieldDataTable.css";
import { Dropdown } from 'primereact/dropdown'; 
import { DataTable } from 'primereact/datatable'; 
import { useTranslation } from 'react-i18next'; 

const FieldDataTable = ({
  reference,
    className, 
    value,
    metaKeySelection,
    scrollHeight,
    paginatorClassName, 
    selectionMode,
    children,
    header,
    selection,
    onSelectionChange,
    onRowSelect,
    onRowUnselect,
    editMode
}) => {
     
  const { t } = useTranslation(['common']);
    
  const [first, setFirst] = useState(0);
  const [rows, setRow] = useState(50);

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
        const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
        ];

        return (
          <>
            <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>{t('common:ItemsByPage')}: </span>
            <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
          </>
        );
    },
    'CurrentPageReport': (options) => {
        return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
            {options.first} - {options.last} of {options.totalRecords}
          </span>
        )
    }
};

  const onPageChange = (event) => {
    setFirst(event.first);
    setRow(event.rows);
  }


  return (
    <> 
      <DataTable
        ref={reference}
        className={className}
        value={value}   
        metaKeySelection={metaKeySelection}
        scrollHeight={scrollHeight}
        scrollable
        paginator
        paginatorTemplate={paginatorTemplate} 
        first={first} 
        rows={rows} 
        onPage={onPageChange}
        paginatorClassName={paginatorClassName}
        selectionMode={selectionMode}
        header={header} 
        selection={selection} 
        onSelectionChange={onSelectionChange} 
        onRowSelect={onRowSelect}
        onRowUnselect={onRowUnselect}
        editMode={editMode}
      >
        {children}
      </DataTable>
    </>
  );
};

export default  FieldDataTable;
