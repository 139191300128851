/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';     
import { Calendar } from 'primereact/calendar';

const FieldCalendar =  (props) => {
    
    const onChange = (value) => {  
        if (props.onChange !== null){
            props.onChange(value);
        } 
    }  

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => {   
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    if(props.isInputGroup){
      return(
        <>  
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <span className={props.classGroup}>
                {props.labelGroup}
              </span>
            </span>
            <Calendar 
              value={props.value}
              onChange={onChange}
              showIcon  
              className={isFormFieldInValid(props.name) === true ? 'p-invalid' : props.className}
            />  
          </div>
          <span className="p-float-label">
            <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
          </span>
          {getFormErrorMessage(props.name)}
        </> 
      )
    }
    
      return(
        <>  
          <span className="p-float-label">
            <Calendar 
              value={props.value}
              onChange={onChange}
              showIcon  
              className={isFormFieldInValid(props.name) === true ? 'p-invalid' : props.className}
            />  
            <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
          </span>
          {getFormErrorMessage(props.name)}
        </> 
      )
     
}   

    
export default FieldCalendar; 


