import { LegendTitle } from "devextreme-react/chart";

function mergeObject(properties, destinationObject, value){
  if (properties.length === 1){
      destinationObject[properties[0]] = value;
      return;
  }

  const propertyName = properties.shift();
  let childObject = destinationObject[propertyName];
  if (childObject == null){
      childObject = {};
      destinationObject[propertyName] = childObject;
  }

  if (propertyName.includes('[')){
      destinationObject[propertyName.substring(0, propertyName.indexOf('['))] = {
          type: 'itemArray',
          message: 'error, at least one property contain an array'
      }
  }

  mergeObject(properties, childObject, value);
}

class YupValidator {
    constructor (schema){
        this.schema = schema;
        this.submitted = false;
        this.errors = {};
    }

    validate(values){
        const self = this;
        this.submitted = false;
        self.errors = {};

        return new Promise( (resolve, reject) => {
            this.schema.validate(values, {abortEarly: false})
            .then(() =>{
                resolve(true);
            })
            .catch((err)=> {
                for (const er of err.inner){
                    mergeObject(er.path.split('.'), self.errors,  {type: er.type, message: er.message});
                }

                resolve(false);
            });
        });

    }
}

export default YupValidator;