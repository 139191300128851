import React, { useState, useEffect, useRef } from 'react';
import {Card,CardHeader,CardBody,CardFooter,Row,Col}        from 'reactstrap'; 
import { DataTable }                  from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { config } from '../../utils/config';
import { callApi, showSweetAlert, getEmpresa } from '../../utils/utils';  
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'primereact/progressbar';
import { Avatar } from 'primereact/avatar';
import Highcharts from 'highcharts';
import RecompensasCampaniaLista from '../RecompensasCampania/RecompensasCampaniaLista.jsx'  

// START COMPONENT
const RecompensasDashboardDetalle = (props) => { 
  const { t } = useTranslation(['recompensas','common']);
  const [errors, setErrors] = useState({});
  
  const [RecompensaDashboardInfo, setRecompensaDashboardInfo] = useState({}); 
  const [RecompensasActivaInfo, setRecompensasActivaInfo] = useState({}); 
  const [CuponesActivosInfo, setCuponesActivosInfo] = useState({}); 
  const [CardCampaniaEstatus, setCardCampaniaEstatus] = useState([]); 
  const [CardCuponEstatus, setCardCuponEstatus] = useState([]);  
  const [ClaEmpresa, setClaEmpresa] = useState(getEmpresa());
  const [EsListadoRecompensa, setEsListadoRecompensa] = useState(false);  
  const [Filters, setFilters] = useState({}); 
  const [EsCampania, setEsCampania] = useState(true);

  useEffect(() => {
    onCards(); 
  }, [props.filters]);

  const onCards = () => {
        const paramsToService = {
          ClaCliente:0,
          ClaEmpresa
        }
        const GetService = `${config.UrlApiProject}Cliente/GetInformacion`;
        callApi(GetService, 'POST', paramsToService, (response) => {
           
          setCardCampaniaEstatus(response.data.CampaniaEstatus);
          setCardCuponEstatus(response.data.CuponEstatus);
          setRecompensasActivaInfo(response.data.CampaniaActivaInfo[0]);
          setCuponesActivosInfo(response.data.CuponActivoInfo[0]);
          setRecompensaDashboardInfo(response.data.CampaniaDashboardInfo[0]);

        
            // setRecompensasActiva(response.data.CampaniasActivas[0]);
            // setCuponesActivos(response.data.CuponesActivos[0]);
        });    
  };

  const cerrarListadoCampanias = () => { 
    setEsListadoRecompensa(false);
  }

  const verDetalle = () => { 
    setEsCampania(!EsCampania);
    cerrarListadoCampanias();
  }


  return (
    <>
      {
        RecompensaDashboardInfo!= null ? (
          <>
            <div>
              <Row className="align-items-start pb-5">
                <Col lg={8} md={8} sm={12} xs={12}>
                  <Card className="radiuscard">
                    <CardBody>
                      <Row className="pb-2 pt-2">
                        <Col lg={4} md={4} sm={12} xs={12} className="secondary-color bold text-center">
                          {t('recompensas:AcumuladoDeCampania')}
                        </Col>
                        <Col className="secondary-color bold text-center">
                          <i className='pi pi-circle-on warning-color'> </i>
                        </Col>
                        <Col className="secondary-color bold text-center"> 
                          <i className='pi pi-user info-color'> </i>
                        </Col>
                        <Col className="secondary-color bold text-center"> 
                          <i className='pi pi-dollar success-color'> </i>
                        </Col>
                      </Row>
                      <Row className="pb-2 pt-2 secondary-color bold">
                        <Col lg={4} md={4} sm={12} xs={12}>
                          {t('recompensas:Campanias')}
                        </Col>
                        <Col className="text-center">
                          {RecompensaDashboardInfo.Campanias}
                        </Col>
                        <Col className="text-center">
                          {RecompensaDashboardInfo.CantidadCampaniaExpiradaActiva}
                        </Col>
                        <Col className="text-center">
                          {Highcharts.numberFormat(RecompensaDashboardInfo.ImporteCampaniaExpiradaActiva,2,'.',',')}
                        </Col>
                      </Row>
                      <Row className="pb-2 pt-2 secondary-color bold">
                        <Col lg={4} md={4} sm={12} xs={12}>
                          {t('recompensas:Cupones')}
                        </Col>
                        <Col className="text-center">
                          {RecompensaDashboardInfo.Cupones}
                        </Col>
                        <Col className="text-center">
                          {RecompensaDashboardInfo.CantCuponesExpirado}
                        </Col>
                        <Col className="text-center">
                          {RecompensaDashboardInfo.CuponesDescuento}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <Card className="radiuscard">
                    <CardBody>
                      <Row className="text-center pb-2 pt-2">
                        <Col lg={6} md={6} sm={12} xs={12} className="secondary-color bold">
                          {t('recompensas:NuevosClientes')}
                        </Col>
                        <Col className="secondary-color bold">
                          <Row>
                            <Col className="secondary-color bold">
                              <Avatar
                                icon="pi pi-user"
                                className="p-mr-2 avatar-exLarge"
                                size="large" 
                                style={{ backgroundColor: '#FA911D', color: '#ffffff' }}
                                shape="circle"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="text-center pb-2 pt-2 secondary-color">
                        <Col lg={6} md={6} sm={12} xs={12}>
                          <span className="fsize-4 bold">
                            {RecompensaDashboardInfo.NuevosClientes}
                          </span>
                        </Col>
                        <Col>
                          {t('recompensas:Ultimos30dias')}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="align-items-start pb-5">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Card className="radiuscard"> 
                    <CardBody>
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <div className="col text-center">
                            <div className="fsize-1 bold secondary-color pb-3">
                              {t('recompensas:RecompensasActivas')}
                            </div>
                            <div className="pb-4">
                              <span className="fsize-4 bold secondary-color">
                                {RecompensaDashboardInfo.CampaniasActivas}
                              </span>
                            </div>
                            <div>
                              <ProgressBar className="warning-color" value={RecompensasActivaInfo.EfectividadCampania}></ProgressBar>
                            </div>
                          </div>
                        </Col> 
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <Row className="pb-1 pt-1 bold">
                            <Col lg={2} md={2} sm={12} xs={12} className="text-center">
                              <i className='pi pi-circle-on warning-color'> </i>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12} className="secondary-color text-center">
                              {RecompensasActivaInfo.ClientesBeneficiados}
                            </Col>
                            <Col className="secondary-color">
                              {t('recompensas:ClientesBeneficiados')}
                            </Col>
                          </Row>
                          <Row className="pb-1 pt-1 bold">
                            <Col lg={2} md={2} sm={12} xs={12} className="text-center">
                              <i className='pi pi-circle-on default-form-color'> </i>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12} className="secondary-color text-center">
                              {RecompensasActivaInfo.ClientesTotal}
                            </Col>
                            <Col className="secondary-color">
                              {t('recompensas:ClientesTotal')}
                            </Col>
                          </Row>
                          <Row className="pb-1 pt-1 bold">
                            <Col lg={2} md={2} sm={12} xs={12} className="text-center">
                              <i className='pi pi-percentage info-color'> </i>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12} className="secondary-color text-center">
                              {RecompensasActivaInfo.EfectividadCampania}
                            </Col>
                            <Col className="secondary-color">
                              {t('recompensas:Efectividad')}
                            </Col>
                          </Row>
                          <Row className="pb-1 pt-1 bold">
                            <Col lg={2} md={2} sm={12} xs={12} className="text-center">
                              <i className='pi pi-dollar success-color'> </i>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12} className="secondary-color text-center">
                              {Highcharts.numberFormat(RecompensasActivaInfo.MontoPuntos,2,'.',',')}
                            </Col>
                            <Col className="secondary-color">
                              {t('recompensas:ImporteCampania')}
                            </Col>
                          </Row>
                        </Col> 
                        <Col lg={4} md={4} sm={12} xs={12}>
                          {CardCampaniaEstatus.map((item) => { 
                                    return (
                                      <div className="p-col-12"> 
                                        <Card className={`radiuscard10 ${item.Color  } `}>
                                          <CardBody className="text-center bold">    
                                            <div>
                                              <div>
                                                {item.Cantidad}
                                              </div>
                                              <div>
                                                <span>
                                                  {item.Estatus}
                                                </span>
                                              </div>
                                            </div>
                                          </CardBody> 
                                        </Card> 
                                      </div>
                                    );
                                })}
                        </Col> 
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="align-items-start pb-5">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Card className="radiuscard"> 
                    <CardBody>
                      <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <div className="col text-center">
                            <div className="fsize-1 bold secondary-color pb-3">
                              {t('recompensas:CuponesActivos')}
                            </div>
                            <div className="pb-4">
                              <span className="fsize-4 bold secondary-color">
                                {RecompensaDashboardInfo.CuponesActivos}
                              </span>
                            </div>
                            <div>
                              <ProgressBar className="success-color" value={CuponesActivosInfo.EfectividadCupon}></ProgressBar>
                            </div>
                          </div>
                        </Col> 
                        <Col lg={4} md={4} sm={12} xs={12}>
                          <Row className="pb-1 pt-1 bold">
                            <Col lg={2} md={2} sm={12} xs={12} className="text-center">
                              <i className='pi pi-circle-on success-color'> </i>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12} className="secondary-color text-center">
                              {CuponesActivosInfo.CuponesAplicados}
                            </Col>
                            <Col className="secondary-color">
                              {t('recompensas:CuponesAplicados')}
                            </Col>
                          </Row>
                          <Row className="pb-1 pt-1 bold">
                            <Col lg={2} md={2} sm={12} xs={12} className="text-center">
                              <i className='pi pi-circle-on default-form-color'> </i>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12} className="secondary-color text-center">
                              {CuponesActivosInfo.CuponesTotales}
                            </Col>
                            <Col className="secondary-color">
                              {t('recompensas:CuponesTotales')}
                            </Col>
                          </Row>
                          <Row className="pb-1 pt-1 bold">
                            <Col lg={2} md={2} sm={12} xs={12} className="text-center">
                              <i className='pi pi-percentage info-color'> </i>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12} className="secondary-color text-center">
                              {CuponesActivosInfo.EfectividadCupon}
                            </Col>
                            <Col className="secondary-color">
                              {t('recompensas:Efectividad')}
                            </Col>
                          </Row>
                          <Row className="pb-1 pt-1 bold">
                            <Col lg={2} md={2} sm={12} xs={12} className="text-center">
                              <i className='pi pi-dollar success-color'> </i>
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12} className="secondary-color text-center">
                              {CuponesActivosInfo.ImporteDescuento}
                            </Col>
                            <Col className="secondary-color">
                              {t('recompensas:ImporteDescuento')}
                            </Col>
                          </Row>
                          <Row className="pb-1 pt-1 bold">
                            <Col lg={2} md={2} sm={12} xs={12} className="text-center">
                              &nbsp;
                            </Col>
                            <Col lg={2} md={2} sm={12} xs={12} className="secondary-color text-center">
                              {CuponesActivosInfo.ClientesBeneficiados}
                            </Col>
                            <Col className="secondary-color">
                              {t('recompensas:ClientesBeneficiados')}
                            </Col>
                          </Row>
                         
                        </Col> 
                        <Col lg={4} md={4} sm={12} xs={12}>
                          {CardCuponEstatus.map((item) => { 
                                    return (
                                      <div className="p-col-12"> 
                                        <Card className={`radiuscard10 ${item.Color  } `}>
                                          <CardBody className="text-center bold">    
                                            <div>
                                              <div>
                                                {item.Cantidad}
                                              </div>
                                              <div>
                                                <span>
                                                  {item.Estatus}
                                                </span>
                                              </div>
                                            </div>
                                          </CardBody> 
                                        </Card> 
                                      </div>
                                    );
                                })}
                        </Col> 
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        ):
        (
          <></>
        )
} 
    </>
);
};
export default RecompensasDashboardDetalle;
